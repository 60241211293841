export const hsk1Wordlist: [string, string][] = [
    ['爱', 'to love; affection; to be fond of; to like'],
    ['八', 'eight; 8'],
    ['爸爸', 'father (informal); '],
    ['杯子', 'cup; glass; '],
    ['北京', 'Beijing; capital of People\'s Republic of China; Peking; PRC government'],
    ['本', 'origin; source; roots or stems of plants; this; the current; root; foundation; basis; classifier for books, periodicals, files etc; originally'],
    ['不客气', 'you\'re welcome; it\'s my pleasure (answer to someone who thanks)'],
    ['不', 'not (negative prefix); no'],
    ['菜', 'dish (type of food); vegetables; vegetable; cuisine; '],
    ['茶', 'tea; tea plant; '],
    ['吃', 'to eat; to have one\'s meal; to eradicate; to destroy; to absorb; to suffer; to exhaust'],
    ['出租车', 'taxi'],
    ['打电话', 'to make a phone call'],
    ['大', 'big; huge; large; major; great; wide; deep; oldest; eldest'],
    ['的', 'of; structural particle: used before a noun, linking it to preceding possessive or descriptive attributive'],
    ['点', 'a little; a bit; drop (of liquid); stain; spot; speck; jot; dot stroke (in Chinese characters); decimal point; point; mark (of degree or level); a place (with certain characteristics); iron bell; o’clock; some; (point) unit of measurement for type; to touch on briefly; to make clear; to light; to ignite; to kindle; period of time at night (24 minutes) (old); a drip; to dibble; classifier for small indeterminate quantities'],
    ['电脑', 'computer; '],
    ['电视', 'television; TV; '],
    ['电影', 'movie; film; '],
    ['东西', 'thing; stuff; person; '],
    ['都', 'all, both; entirely (due to) each; even; already'],
    ['读', 'to read; to study; reading of word (i.e. pronunciation), similar to 拼音[pin1 yin1]'],
    ['对不起', 'I\'m sorry; excuse me; pardon me; if you please; sorry? (please repeat); unworthy; to let down;'],
    ['多', 'many; much; a lot of; numerous; multi-'],
    ['多少', 'how much; how many'],
    ['儿子', 'son'],
    ['二', 'two; 2; stupid (Beijing dialect)'],
    ['饭店', 'restaurant; hotel; '],
    ['飞机', 'airplane; '],
    ['分钟', 'minute'],
    ['高兴', 'happy; glad; willing (to do sth); in a cheerful mood'],
    ['个', 'classifier for people or objects in general; individual; this; that; size'],
    ['工作', 'job; work; construction; task; '],
    ['狗', 'dog; '],
    ['汉语', 'Chinese language'],
    ['好', 'good; well; proper; good to; easy to; very; so; (suffix indicating completion or readiness)'],
    ['号', 'day of a month; (suffix used after) name of a ship; (ordinal) number'],
    ['喝', 'to drink; to shout (a command)'],
    ['和', 'and; together with; with; sum; union; peace; harmony; surname He'],
    ['很', 'very; (adverb of degree); quite; awfully'],
    ['后面', 'rear; back; behind; later; afterwards'],
    ['回', 'to go back; to turn around; to answer; to return; to circle; to revolve; Hui ethnic group; time; classifier for acts of a play; section or chapter (of a classic book)'],
    ['会', 'can; be possible; be able to; will; be likely to; be sure to; to assemble; to meet; to gather; to see; union; group; association; a moment (Taiwan pr. for this sense is hui3)'],
    ['几', 'how much; how many; several; a few'],
    ['家', 'home; family; classifier for families or businesses; refers to the philosophical schools of pre-Han China; noun suffix for specialists in some activity such as musician or revolutionary, corresponds to English -ist, -er, -ary or -ian; surname Jia; '],
    ['叫', 'to call; to shout; to order; to ask; to be called; by (indicates agent in the passive mood)'],
    ['今天', 'today; at the present'],
    ['九', 'nine; 9'],
    ['开', 'to open; to start; to turn on; to operate (vehicle)'],
    ['看', 'to look at'],
    ['看见', 'to see; to catch sight of'],
    ['块', 'unit of currency; lump (of earth); chunk; piece; classifier for pieces of cloth, cake, soap etc; colloquial word for yuan (or other unit of currency such as Hong Kong or US dollar etc), usually as 块钱'],
    ['来', 'to come; to arrive; to come round; ever since; next'],
    ['老师', 'teacher; '],
    ['了', '(completed action marker); (modal particle intensifying preceding clause)'],
    ['冷', 'cold'],
    ['里', 'inside; lining; interior; internal'],
    ['六', 'six; 6'],
    ['吗', '(question tag)'],
    ['妈妈', 'mum; mama; mommy; mother; '],
    ['买', 'to buy; to purchase'],
    ['猫', 'cat; '],
    ['没关系', 'it doesn\'t matter'],
    ['没有', 'haven\'t; hasn\'t; doesn\'t exist; to not have; to not be;'],
    ['米饭', '(cooked) rice'],
    ['名字', 'name (of a person or thing); '],
    ['明天', 'tomorrow'],
    ['哪', 'which? (interrogative, followed by classifier or numeral-classifier);'],
    ['哪儿', 'where?; wherever; anywhere'],
    ['那', 'that; those; then (in that case); commonly pr. [nei4] before a classifier, esp. in Beijing;'],
    ['呢', '(question particle for subjects already mentioned)'],
    ['能', 'to be able to; to be capable of; ability; capability; able; capable; can possibly; (usually used in the negative) to have the possibility of'],
    ['你', 'you (informal)'],
    ['年', 'year; '],
    ['女儿', 'daughter'],
    ['朋友', 'friend; '],
    ['漂亮', 'pretty; beautiful'],
    ['苹果', 'apple; '],
    ['七', 'seven; 7'],
    ['前面', 'ahead; in front; preceding; above'],
    ['钱', 'coin; money; '],
    ['请', 'to ask; to invite; please (do sth); to treat (to a meal etc); to request'],
    ['去', 'to go; to go to (a place); to cause to go or send (sb); to remove; to get rid of; (when used either before or after a verb) to go in order to do sth; to be apart from in space or time; (after a verb of motion indicates movement away from the speaker); (used after certain verbs to indicate detachment or separation); (of a time or an event etc) just passed or elapsed'],
    ['热', 'heat; to heat up; fervent; hot (of weather); warm up'],
    ['人', 'man; person; people; '],
    ['认识', 'to know; to recognize; to be familiar with; acquainted with sth; knowledge; understanding; awareness; cognition'],
    ['三', 'three; 3'],
    ['商店', 'store; shop; '],
    ['上', 'on'],
    ['上午', 'morning; '],
    ['少', 'few; little; lack'],
    ['谁', 'who; also pronounced shui2'],
    ['什么', 'what?; who?; something; anything'],
    ['十', 'ten; 10'],
    ['时候', 'time; length of time; moment; period'],
    ['是', 'to be; yes'],
    ['书', 'book; letter; '],
    ['水', 'water; river; liquid; beverage'],
    ['水果', 'fruit'],
    ['睡觉', 'to go to bed; to go to sleep'],
    ['说', 'to speak; to say; to explain; to scold; to tell off; a theory (usually in compounds such as 日心说 heliocentric theory);'],
    ['四', 'four; 4'],
    ['岁', 'classifier for years (of age); year; year (of crop harvests)'],
    ['他', 'he or him; (used for either sex when the sex is unknown or unimportant); (used before sb\'s name for emphasis); (used as a meaningless mock object); other; another'],
    ['她', 'she'],
    ['太', 'too (much); very; extremely; highest; greatest'],
    ['天气', 'weather'],
    ['听', 'to listen; to hear; to obey; a can (loanword from English \'tin\'); classifier for canned beverages'],
    ['同学', '(fellow) classmate; '],
    ['喂', 'hello (interj., esp. on telephone); hey'],
    ['我', 'I; me; my'],
    ['我们', 'we; us; ourselves; our'],
    ['五', 'five; 5'],
    ['喜欢', 'to like; to be fond of'],
    ['下', 'down; downwards; below; lower; later; next (week etc); second (of two parts); to decline; to go down'],
    ['下午', 'afternoon; p.m.; '],
    ['下雨', 'to rain; rainy'],
    ['先生', 'Mister (Mr.); husband; doctor (topolect); teacher; '],
    ['现在', 'now; at present; at the moment; modern; current; nowadays'],
    ['想', 'to think; to believe; to suppose; to wish; to want; to miss'],
    ['小', 'small; tiny; young'],
    ['小姐', 'young lady; Miss; (slang) prostitute; '],
    ['些', 'some; few; several; (a measure word)'],
    ['写', 'to write'],
    ['谢谢', 'to thank; thanks'],
    ['星期', 'week; '],
    ['学生', 'student; school child'],
    ['学习', 'to learn; to study'],
    ['学校', 'school; '],
    ['一', 'one; 1; single; a (article); as soon as; entire; whole; all; throughout; \'one\' radical in Chinese characters (Kangxi radical 1)'],
    ['一点儿', 'a little (bit)'],
    ['医生', 'doctor; '],
    ['医院', 'hospital; '],
    ['衣服', 'clothes; '],
    ['椅子', 'chair; '],
    ['有', 'to have; there is; there are; to exist; to be'],
    ['月', 'month; moon; '],
    ['再见', 'goodbye; see you again later'],
    ['在', '(located) at; (to be) in; to exist; in the middle of doing sth; (indicating an action in progress)'],
    ['怎么', 'how?; what?; why?'],
    ['怎么样', 'how are things? how?; how about?; how was it?'],
    ['这', 'this; these; (commonly pr. [zhei4] before a classifier, esp. in Beijing);'],
    ['中国', 'China; Middle Kingdom'],
    ['中午', 'noon; midday; '],
    ['住', 'to live; to dwell; to stay; to reside; to stop'],
    ['桌子', 'table; desk; '],
    ['字', 'letter; character; word; symbol; '],
    ['昨天', 'yesterday'],
    ['做', 'to do; to make; to produce; to write; to compose; to act as; to engage in; to hold (a party); to be; to become; to function (in some capacity); to serve as; to be used for; to form (a bond or relationship); to pretend; to feign; to act a part; to put on appearance'],
    ['坐', 'to sit; to take a seat; to take (a bus, airplane etc); to bear fruit'],
];