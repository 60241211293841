export const hsk4Wordlist: [string, string][] = [
    ['爱情', 'romance; love (romantic); CL:个'],
    ['安排', 'to arrange; to plan; to set up'],
    ['安全', 'safe; secure; safety; security'],
    ['按时', 'on time; before deadline; on schedule'],
    ['按照', 'according to; in accordance with; in the light of; on the basis of'],
    ['百分之', 'percent;'],
    ['棒', 'a stick; club or cudgel; smart; capable; strong; wonderful; classifier for legs of a relay race'],
    ['包子', 'steamed stuffed bun; CL:个'],
    ['保护', 'to protect; to defend; to safeguard; protection; CL:种[zhong3]'],
    ['保证', 'guarantee; to guarantee; to ensure; to safeguard; to pledge; CL:个'],
    ['报名', 'to sign up; to enter one\'s name; to apply; to register; to enroll; to enlist'],
    ['抱', 'to hold; to carry (in one\'s arms); to hug or embrace; surround; cherish'],
    ['抱歉', 'sorry; We apologize!; to feel apologetic; to regret'],
    ['倍', '(two, three etc) -fold; times (multiplier); double; to increase or multiply'],
    ['本来', 'original; originally; at first; it goes without saying; of course'],
    ['笨', 'stupid; foolish; silly; slow-witted; clumsy'],
    ['比如', 'for example; for instance; such as'],
    ['毕业', 'to graduate; graduation; to finish school'],
    ['遍', 'a time; everywhere; turn; all over; one time'],
    ['标准', '(an official) standard; norm; criterion; CL:个'],
    ['表格', 'form; table; CL:张[zhang1] ,份[fen4]'],
    ['表示', 'to express; to show; to say; to state; to indicate; to mean'],
    ['表演', 'play; show; performance; exhibition; to perform; to act; to demonstrate; CL:场[chang3]'],
    ['表扬', 'to praise; to commend'],
    ['饼干', 'biscuit; cracker; cookie; CL:片[pian4] ,块[kuai4]'],
    ['并且', 'moreover; furthermore; in addition; besides; and'],
    ['博士', 'doctor; court academician (in feudal China); Ph.D.'],
    ['不过', 'only; merely; no more than; but; however; anyway (to get back to a previous topic)'],
    ['不得不', 'have no choice or option but to; cannot but; have to; can\'t help it; can\'t avoid'],
    ['不管', 'no matter (what, how); regardless of; no matter'],
    ['不仅', 'not only (this one); not just (...) but also'],
    ['部分', 'part; share; section; piece; CL:个'],
    ['擦', 'to wipe; to erase; rubbing (brush stroke in painting); to clean; to polish'],
    ['猜', 'to guess'],
    ['材料', 'material; data; makings; stuff; CL:个,种[zhong3]'],
    ['参观', 'to look around; to inspect; visit and observe'],
    ['餐厅', 'dining-hall; dining-room; restaurant; CL:间[jian1] ,家[jia1]'],
    ['厕所', 'toilet; lavatory; CL:间[jian1] ,处[chu4]'],
    ['差不多', 'almost; nearly; more or less'],
    ['尝', 'to try (to taste); to experience; flavor; (past tense marker); already; formerly; ever; once; test'],
    ['长城', 'the Great Wall'],
    ['长江', 'Changjiang river; Yangtze river'],
    ['场', 'large place used for a specific purpose; stage; scene (of a play); threshing floor; classifier for sporting or recreational activities; classifier for number of exams'],
    ['超过', 'to surpass; to exceed; to outstrip'],
    ['乘坐', 'to ride (in a vehicle)'],
    ['成功', 'success; to succeed; CL:次[ci4] ,个'],
    ['成为', 'to become; to turn into'],
    ['诚实', 'honest; honesty; honorable; truthful'],
    ['吃惊', 'to be startled; to be shocked; to be amazed'],
    ['重新', 'again; once more; re-'],
    ['抽烟', 'to smoke (a cigarette, tobacco)'],
    ['出差', 'to go on an official or business trip'],
    ['出发', 'to start out; to set off'],
    ['出生', 'to be born'],
    ['出现', 'to appear; to arise; to emerge; to show up'],
    ['厨房', 'kitchen; CL:间[jian1]'],
    ['传真', 'fax; facsimile'],
    ['窗户', 'window; CL:个,扇[shan4]'],
    ['词语', 'word (general term including monosyllables through to short phrases); term (e.g. technical term); expression'],
    ['从来', 'always; at all times; never (if used in negative sentence)'],
    ['粗心', 'careless; thoughtless'],
    ['存', 'exist; deposit; store; keep; survive'],
    ['错误', 'error; mistake; mistaken; CL:个'],
    ['答案', 'answer; solution; CL:个'],
    ['打扮', 'to decorate; to dress; to make up; to adorn; manner of dressing; style of dress'],
    ['打扰', 'to disturb; to bother; to trouble'],
    ['打印', 'to print; to seal; to stamp'],
    ['打招呼', 'to greet sb by word or action; to give prior notice'],
    ['打折', 'to give a discount'],
    ['打针', 'to give or have an injection'],
    ['大概', 'roughly; probably; rough; approximate; about'],
    ['大使馆', 'embassy; CL:座[zuo4] ,个'],
    ['大约', 'approximately; about'],
    ['大夫', 'doctor; minister of state (in pre-Han states); CL:个,位[wei4]'],
    ['戴', 'to put on or wear (glasses, hat, gloves etc); to respect; to bear; to support'],
    ['当', 'to be; manage; withstand; when; during; ought; should; match equally; equal; same; obstruct; just at (a time or place); on the spot; right; just at'],
    ['当时', 'then; at that time; while'],
    ['刀', 'knife; CL:把[ba3]'],
    ['导游', 'tour guide; to conduct a tour; a guidebook'],
    ['倒', 'to move backwards; converse'],
    ['到处', 'in all places; everywhere'],
    ['到底', 'finally; in the end; when all is said and done; after all; to the end; to the last'],
    ['道歉', 'to apologize; to make an apology'],
    ['得', 'to have to, to need to (particle used to express capability, possibility, degree, effect...); must; ought to; to need to'],
    ['得意', 'proud of oneself; pleased with oneself; complacent'],
    ['登机牌', 'boarding pass'],
    ['等', 'etc (indicating the end of an enumeration)'],
    ['低', 'low; beneath; to lower (one\'s head); to let droop; to hang down; to incline'],
    ['底', 'background; bottom; base; the end of a period of time; towards the end of (last month)'],
    ['地点', 'place; site; location; venue; CL:個|个[ge4];'],
    ['地球', 'the Earth; planet; CL:个'],
    ['地址', 'address; CL:个'],
    ['掉', 'to fall; to drop; to lag behind; to lose; to go missing; to reduce; fall (in prices); to lose (value, weight etc); to wag; to swing; to turn; to change; to exchange; to swap; to show off; to shed (hair)'],
    ['调查', 'investigation; inquiry; to investigate; to survey; survey; (opinion) poll; CL:项[xiang4] ,个'],
    ['丢', 'to lose; to put aside; to throw'],
    ['动作', 'movement; motion; action; CL:个'],
    ['堵车', 'traffic jam; choking'],
    ['肚子', 'belly; abdomen; stomach; CL:个'],
    ['短信', 'text message; SMS'],
    ['对话', 'dialog; CL:个'],
    ['对面', 'opposite'],
    ['对于', 'regarding; as far as sth is concerned; with regards to'],
    ['儿童', 'child; CL:个'],
    ['而', 'yet (not); and; as well as; and so; but (not); (indicates causal relation); (indicates change of state); (indicates contrast)'],
    ['发生', 'to happen; to occur; to take place; to break out'],
    ['发展', 'development; growth; to develop; to grow; to expand'],
    ['法律', 'law; CL:条[tiao2] , 套[tao4] , 个'],
    ['翻译', 'to translate; to interpret; translator; interpreter; translation; interpretation; CL:个,位[wei4] ,名[ming2]'],
    ['烦恼', 'agonize; agony; annoyance; upset; vexation; worries'],
    ['反对', 'to fight against; to oppose; to be opposed to; opposition'],
    ['方法', 'method; way; means; CL:个'],
    ['方面', 'respect; aspect; field; side; CL:个'],
    ['方向', 'direction; orientation; path to follow; CL:个'],
    ['房东', 'landlord'],
    ['放弃', 'to renounce; to abandon; to give up'],
    ['放暑假', 'take summer vacation'],
    ['放松', 'to loosen; to relax'],
    ['份', 'part; share; portion; copy; classifier for gifts, newspaper, magazine, papers, reports, contracts etc'],
    ['丰富', 'rich; plentiful; abundant'],
    ['否则', 'if not; otherwise; else; or else'],
    ['符合', 'in keeping with; in accordance with; tallying with; in line with; to agree with; to accord with; to conform to; to correspond with; to manage; to handle'],
    ['付款', 'pay; payment'],
    ['复印', 'to photocopy; to duplicate a document'],
    ['复杂', 'complicated; complex'],
    ['富', 'rich; surname Fu'],
    ['父亲', 'father; also pr. with light tone [fu4 qin5]; CL:个'],
    ['负责', 'to be in charge of; to take responsibility for; to be to blame; conscientious'],
    ['改变', 'to change; to alter; to transform'],
    ['干杯', 'to drink a toast; Cheers! (proposing a toast); Here\'s to you!; Bottoms up!; lit. dry cup'],
    ['感动', 'to move (sb); to touch (sb emotionally); moving'],
    ['感觉', 'to feel; to become aware of; feeling; sense; perception; CL:个'],
    ['感情', 'feeling; emotion; sensation; likes and dislikes; deep affection for sb or sth; relationship (i.e. love affair); CL:个,种[zhong3]'],
    ['感谢', '(express) thanks; gratitude; grateful; thankful; thanks'],
    ['敢', 'to dare; daring; (polite) may I venture'],
    ['赶', 'to overtake; to catch up with; to hurry; to rush; to try to catch (the bus etc); to drive (cattle etc) forward; to drive (sb) away; to avail oneself of (an opportunity); until;'],
    ['干', 'to do (some work); to manage; to work'],
    ['刚', 'just; barely; exactly; hard; firm; strong'],
    ['高速公路', 'expressway; highway; freeway'],
    ['胳膊', 'arm; CL:只[zhi1] ,条[tiao2] ,双[shuang1]'],
    ['各', 'each; every'],
    ['公里', 'kilometer'],
    ['功夫', 'skill; art; kung fu; labor; effort'],
    ['工资', 'wages; pay; CL:个,份[fen4] ,月[yue4]'],
    ['共同', 'common; joint; jointly; together; collaborative'],
    ['够', 'to reach; to be enough'],
    ['购物', 'shopping'],
    ['估计', 'to estimate; to reckon; CL:个'],
    ['鼓励', 'to encourage'],
    ['故意', 'deliberately; on purpose'],
    ['顾客', 'client; customer; CL:位[wei4]'],
    ['挂', 'to hang or suspend (from a hook etc); (of a telephone call) to hang up; to be worried or concerned; to make a phone call (topolect); to register or record; to hitch; classifier for sets or clusters of objects'],
    ['关键', 'crucial point; crux; CL:个; key; crucial; pivotal'],
    ['观众', 'spectators; audience; visitors (to an exhibition etc)'],
    ['管理', 'to supervise; to manage; to administer; management; administration; CL:个'],
    ['光', 'light (ray); light; ray; CL:道[dao4]; bright; only; merely; to use up'],
    ['广播', 'broadcast; widely spread; CL:个'],
    ['广告', 'to advertise; a commercial; advertisement; CL:项[xiang4]'],
    ['逛', 'to stroll; to visit'],
    ['规定', 'to stipulate; to fix; to set; to formulate; to provide; regulation; rule; CL:个'],
    ['国籍', 'nationality'],
    ['国际', 'international'],
    ['果汁', 'fruit juice'],
    ['过程', 'course of events; process; CL:个'],
    ['海洋', 'ocean; CL:个'],
    ['害羞', 'blush; shy'],
    ['寒假', 'winter vacation'],
    ['汗', 'perspiration; sweat; Khan (Persian or Mongol king or emperor); Khan (name); to be speechless (out of helplessness, embarrassment etc) (Internet slang used as an interjection); CL:滴[di1] ,头[tou2] ,身[shen1]'],
    ['航班', 'scheduled flight; flight number; plane; scheduled sailing; sailing number; passenger ship'],
    ['好处', 'benefit; advantage; gain; profit; also pronounced hao3 chu4; CL:个'],
    ['好像', 'as if; to seem like'],
    ['号码', 'number; CL:堆[dui1] ,个'],
    ['合格', 'qualified; meeting a standard; eligible (voter)'],
    ['合适', 'suitable; fitting; decent; to fit'],
    ['盒子', 'box'],
    ['厚', 'thick; deep or profound; kind; generous; rich or strong in flavor; to favor; to stress'],
    ['后悔', 'to regret; to repent'],
    ['互联网', 'the Internet'],
    ['互相', 'each other; mutually; mutual'],
    ['护士', 'nurse; CL:个'],
    ['怀疑', 'to suspect; to doubt; doubt; suspicion; skeptical'],
    ['回忆', 'to recall; recollection; CL:个'],
    ['活动', 'to exercise; to move about; to operate; activity; loose; shaky; active; movable; maneuver; to use connections; CL:项[xiang4] ,个'],
    ['活泼', 'lively; vivacious; brisk; active'],
    ['火', 'fire; CL:把[ba3]'],
    ['获得', 'to obtain; to receive; to get'],
    ['基础', 'base; foundation; basis; underlying; CL:个'],
    ['激动', 'to excite; to agitate; exciting'],
    ['积极', 'active; energetic; vigorous; positive (outlook); proactive'],
    ['积累', 'to accumulate; accumulation; cumulative; cumulatively'],
    ['即使', 'even if; even though; given that'],
    ['及时', 'in time; promptly; without delay; timely'],
    ['寄', 'to live (in a house); to lodge; to mail; to send; to entrust; to depend'],
    ['技术', 'technology; technique; skill; CL:门[men2] ,种[zhong3] ,项[xiang4]'],
    ['既然', 'since; as; this being the case'],
    ['继续', 'to continue; to proceed with; to go on with'],
    ['计划', 'plan; project; program; to plan; to map out; CL:个,项[xiang4]'],
    ['记者', 'reporter; journalist; CL:个'],
    ['加班', 'to work overtime'],
    ['加油站', 'gas station'],
    ['家具', 'furniture; CL:件[jian4] ,套[tao4]'],
    ['假', 'fake; false; artificial; to borrow; if; suppose'],
    ['价格', 'price; CL:个'],
    ['坚持', 'to continue upholding; to remain committed to; persistence; to persist; to uphold; to insist on; persevere'],
    ['减肥', 'to lose weight'],
    ['减少', 'to lessen; to decrease; to reduce; to lower'],
    ['建议', 'to propose; to suggest; to recommend; proposal; suggestion; recommendation; CL:个,点[dian3]'],
    ['将来', 'in the future; future; the future; CL:个'],
    ['奖金', 'award money; premium; a bonus'],
    ['降低', 'to reduce; to lower; to bring down'],
    ['降落', 'to descend; to land'],
    ['交', 'to hand over; to deliver; to pay (money); to turn over; to make friends; to intersect (lines)'],
    ['交流', 'exchange; give-and-take; to exchange; to alternate; communication; alternating current (electricity)'],
    ['交通', 'traffic; communications; liaison; to be connected'],
    ['郊区', 'suburban district; outskirts; suburbs; CL:个'],
    ['骄傲', 'arrogant; full of oneself; conceited; proud of sth; CL:个'],
    ['饺子', 'dumpling; pot-sticker; CL:个,只[zhi1]'],
    ['教授', 'professor; to instruct; to lecture on; CL:个,位[wei4]'],
    ['教育', 'to educate; to teach; education'],
    ['接受', 'to accept; to receive'],
    ['接着', 'to catch and hold on; to continue; to go on to do sth; to follow; to carry on; then; after that; subsequently; to proceed; to ensue; in turn; in one\'s turn'],
    ['结果', 'result; outcome; effect; in the event; so that'],
    ['节', 'festival; holiday; node; joint; section; segment; part; to economize; to save; to abridge; moral integrity; classifier for segments, e.g. lessons, train wagons, biblical verses; CL:个'],
    ['节约', 'to economize; to conserve (resources); economy; frugal'],
    ['解释', 'explanation; to explain; to interpret; to resolve; CL:个'],
    ['尽管', 'despite; although; even though; in spite of; unhesitatingly; do not hesitate (to ask, complain etc); (go ahead and do it) without hesitating'],
    ['紧张', 'nervous; keyed up; intense; tense; strained; in short supply; scarce; CL:阵[zhen4]'],
    ['禁止', 'to prohibit; to forbid; to ban'],
    ['进行', 'to advance; to conduct; underway; in progress; to do; to carry out; to carry on; to execute'],
    ['京剧', 'Beijing opera; CL:场[chang3] ,出[chu1]'],
    ['精彩', 'splendid; brilliant'],
    ['经济', 'economy; economic'],
    ['经历', 'experience; to experience; to go through; CL:个, 次[Ci4]'],
    ['经验', 'to experience; experience'],
    ['景色', 'scenery; scene; landscape; view'],
    ['警察', 'police; policeman; policewoman; CL:个'],
    ['竞争', 'to compete; competition'],
    ['竟然', 'unexpectedly; to one\'s surprise; in spite of everything; in that crazy way; actually; to go as far as to'],
    ['镜子', 'mirror; CL:面[mian4] ,个'],
    ['究竟', 'after all (when all is said and done); actually; outcome; result'],
    ['举', 'to lift; to hold up; to cite; to enumerate; to act; to raise; to choose; to elect'],
    ['举办', 'to conduct; to hold'],
    ['举行', 'to hold (a meeting, ceremony etc)'],
    ['拒绝', 'to refuse; to decline; to reject'],
    ['聚会', 'party; gathering; to meet; to get together'],
    ['距离', 'distance; to be apart; CL:个'],
    ['开玩笑', 'to play a joke; to make fun of; to joke'],
    ['开心', 'to feel happy; to rejoice; to have a great time; to make fun of sb'],
    ['看法', 'way of looking at a thing; view; opinion; CL:个'],
    ['烤鸭', 'roast duck'],
    ['考虑', 'to think over; to consider; consideration'],
    ['棵', 'classifier for trees, cabbages, plants etc'],
    ['科学', 'science; scientific knowledge; scientific; CL:门[men2] ,个,种[zhong3]'],
    ['咳嗽', 'to cough; CL:阵[zhen4]'],
    ['可怜', 'pitiful; pathetic'],
    ['可是', 'but; however'],
    ['可惜', 'it is a pity; what a pity; (it\'s) too bad'],
    ['客厅', 'drawing room (room for arriving guests); living room; CL:间[jian1]'],
    ['肯定', 'to be sure; to be certain; sure; certain; definite; to confirm; to affirm; affirmative'],
    ['空', 'to empty; vacant; unoccupied; space; leisure; free time;'],
    ['空气', 'air; atmosphere'],
    ['恐怕', 'to fear; to dread; I\'m afraid that...; perhaps; maybe'],
    ['苦', 'bitter; hardship; pain; to suffer; painstaking'],
    ['矿泉水', 'mineral spring water; CL:瓶[ping2] ,杯[bei1]'],
    ['困', 'to be trapped; to surround; to be hard-pressed; stranded; destitute'],
    ['困难', '(financial etc) difficulty; problem; issue; CL:个'],
    ['垃圾桶', 'rubbish bin'],
    ['拉', 'to pull; to play (string instruments); to drag; to draw'],
    ['辣', 'hot (spicy); pungent'],
    ['来不及', 'there\'s not enough time (to do sth); it\'s too late (to do sth)'],
    ['来得及', 'there\'s still time; able to do sth in time'],
    ['来自', 'to come from (a place); From: (in email header)'],
    ['懒', 'lazy'],
    ['浪费', 'to waste; to squander'],
    ['浪漫', 'romantic'],
    ['老虎', 'tiger; CL:只[zhi1]'],
    ['冷静', 'calm; cool-headed'],
    ['理发', 'a barber; hairdressing'],
    ['理解', 'to comprehend; to understand; comprehension; understanding'],
    ['理想', 'a dream; an ideal; perfection; ideal; perfect; desirable; CL:个'],
    ['礼拜天', 'Sunday'],
    ['礼貌', 'courtesy; manners'],
    ['例如', 'for example; for instance; such as'],
    ['力气', 'strength; CL:把[ba3]'],
    ['厉害', 'difficult to deal with; difficult to endure; ferocious; radical; serious; terrible; violent; tremendous; awesome'],
    ['俩', 'two (colloquial equivalent of 两个); both; some'],
    ['联系', 'connection; contact; relation; in touch with; to integrate; to link; to touch'],
    ['连', 'to link; to join; to connect; continuously; in succession; including; (used with 也, 都 etc) even; company (military)'],
    ['凉快', 'nice and cold; pleasantly cool'],
    ['零钱', 'change (of money); small change; pocket money'],
    ['另外', 'additional; in addition; besides; separate; other; moreover; furthermore'],
    ['流利', 'fluent'],
    ['流行', 'to spread; to rage (of contagious disease); popular; fashionable; prevalent; (math.) manifold'],
    ['留', 'to retain; to stay; to remain; to keep; to preserve; to leave (eg a message)'],
    ['旅行', 'to travel; journey; trip; CL:趟[tang4],次[ci4],個|个[ge4];'],
    ['乱', 'in confusion or disorder; in a confused state of mind; disorder; upheaval; riot; illicit sexual relations; to throw into disorder; to mix up; indiscriminate; random; arbitrary'],
    ['律师', 'lawyer'],
    ['麻烦', 'inconvenient; troublesome; to trouble or bother sb; to put sb to trouble'],
    ['马虎', 'careless; sloppy; negligent; skimpy'],
    ['满', 'full; filled; packed; fully; completely; quite; to reach the limit; to satisfy; satisfied; contented; to fill; abbr. for Manchurian'],
    ['毛', 'hair; feather; down'],
    ['毛巾', 'towel; CL:条[tiao2]'],
    ['美丽', 'beautiful'],
    ['梦', 'dream; CL:场[chang2] ,个'],
    ['迷路', 'to lose the way; lost; labyrinth; labyrinthus vestibularis (of the inner ear)'],
    ['密码', 'code; secret code; password; pin number'],
    ['免费', 'free (of charge)'],
    ['秒', 'second (of time); unit of angle or arc equivalent to one sixtieth of a degree'],
    ['民族', 'nationality; ethnic group; CL:个'],
    ['母亲', 'mother; also pr. with light tone [mu3 qin5]; CL:个'],
    ['目的', 'purpose; aim; goal; target; objective; CL:个'],
    ['耐心', 'to be patient; patient (adjective)'],
    ['难道', 'don\'t tell me...; could it be that...?'],
    ['难受', 'to feel unwell; to suffer pain; to be difficult to bear'],
    ['内', 'inside; inner; internal; within; interior'],
    ['内容', 'content; substance; details; CL:个,项[xiang4]'],
    ['能力', 'capability; capable; able; ability; CL:个'],
    ['年龄', '(a person\'s) age; CL:把[ba3] ,个'],
    ['弄', 'to do (to manage); to manage; to handle; to play with; to fool with; to mess with; to fix; to toy with'],
    ['暖和', 'warm (weather); nice and warm'],
    ['偶尔', 'occasionally; once in a while; sometimes'],
    ['排队', 'to line up; queue up; form a line'],
    ['排列', 'to arrange; arrangement; array; permutation (i.e. ordered choice of n elements out of m)'],
    ['判断', 'to decide; to determine; CL:个'],
    ['陪', 'to accompany; to keep sb company'],
    ['批评', 'to criticize; criticism; CL:个'],
    ['皮肤', 'skin; CL:种[zhong3]'],
    ['脾气', 'temperament; disposition; temper; CL:个'],
    ['篇', 'piece of writing; sheet; bound set of bamboo slips used for record keeping (old); classifier for written items: chapter, article'],
    ['骗', 'to cheat; to swindle; to deceive; to fool; to hoodwink; to trick'],
    ['乒乓球', 'table tennis; ping-pong; ping pong; table tennis ball; CL:个'],
    ['平时', 'in normal times; in peacetime'],
    ['破', 'broken; damaged; worn out; to break, split or cleave; to get rid of; to destroy; to break with; to defeat; to capture (a city etc); to expose the truth of'],
    ['葡萄', 'grape'],
    ['普遍', 'universal; general; widespread; common'],
    ['普通话', 'Mandarin (common language); Putonghua (common speech of the Chinese language); ordinary speech'],
    ['其次', 'next; secondly'],
    ['其中', 'among; in; included among these'],
    ['气候', 'climate; atmosphere; situation; CL:种[zhong3]'],
    ['千万', 'ten million; countless; many; one must by all means'],
    ['签证', 'visa; certificate; to certify; CL:个'],
    ['敲', 'extort; knock; to strike; to knock (at a door); to hit'],
    ['桥', 'bridge; CL:座[zuo4]'],
    ['巧克力', 'chocolate (loanword); CL:块[kuai4]'],
    ['亲戚', 'a relative (i.e. family relation); CL:门[men2] ,个,位[wei4]'],
    ['轻', 'light (not heavy); light; easy; gentle; soft; reckless; unimportant; frivolous; small in number; unstressed; neutral'],
    ['轻松', 'gentle; relaxed'],
    ['情况', 'circumstances; state of affairs; situation; CL:个,种[zhong3]'],
    ['穷', 'exhausted; poor'],
    ['区别', 'difference; to distinguish; to discriminate; to make a distinction; CL:个'],
    ['取', 'to take; to get; to choose; to fetch'],
    ['全部', 'whole; entire; complete'],
    ['缺点', 'weak point; fault; shortcoming; CL:个'],
    ['缺少', 'lack; shortage of; shortfall; to be short (of); to lack'],
    ['却', 'but; yet; however; while; to go back; to decline; to retreat; nevertheless; even though'],
    ['确实', 'indeed; really; reliable; real; true'],
    ['然而', 'however; yet; but'],
    ['热闹', 'bustling with noise and excitement; lively'],
    ['任何', 'any; whatever; whichever; whatsoever'],
    ['任务', 'mission; assignment; task; duty; role; CL:项[xiang4] ,个'],
    ['扔', 'to throw; to throw away'],
    ['仍然', 'still; yet'],
    ['日记', 'diary; CL:则[ze2] ,本[ben3] ,篇[pian1]'],
    ['入口', 'entrance'],
    ['散步', 'to take a walk; to go for a walk'],
    ['森林', 'forest; CL:片[pian4]'],
    ['沙发', 'sofa; CL:条[tiao2] ,张[zhang1]'],
    ['伤心', 'to grieve; to be broken-hearted; to feel deeply hurt; sad'],
    ['商量', 'to consult; to talk over; to discuss'],
    ['稍微', 'a little bit'],
    ['勺子', 'scoop; ladle; CL:把[ba3]'],
    ['社会', 'society; CL:个'],
    ['深', 'deep; late; profound; dark (of color, water etc); deep'],
    ['申请', 'to apply for sth; application (form etc); CL:份[fen4]'],
    ['甚至', 'so much so that; even'],
    ['生活', 'life; activity; to live; livelihood'],
    ['生命', 'life; living; biological; CL:个'],
    ['生意', 'life force; vitality;'],
    ['省', 'to do without; to economize; to save; to omit; to leave out; province; CL:个'],
    ['剩', 'to remain; to be left; to have as remainder'],
    ['失败', 'to be defeated; to lose; to fail (e.g. experiments); failure; defeat; CL:次[ci4]'],
    ['失望', 'disappointed; to lose hope; to despair'],
    ['师傅', 'master; qualified worker; respectful form of address for older men; CL:个,位[wei4] ,名[ming2]'],
    ['十分', 'to divide into ten equal parts; very; hundred percent; completely; extremely; utterly; absolutely'],
    ['实际', 'actual; reality; practice'],
    ['实在', 'in reality; honestly; really; verily; concrete'],
    ['使', 'to cause; to enable; to use; to employ; to make; to send; to instruct sb to do sth; envoy; messenger'],
    ['使用', 'to use; to employ; to apply; to make use of'],
    ['世纪', 'century; CL:个'],
    ['是否', 'whether (or not); if; is or isn\'t'],
    ['适合', 'to fit; to suit'],
    ['适应', 'to suit; to fit; to be suitable; to adapt; to get used to sth'],
    ['收', 'to receive; to accept; to collect; in care of (used on address line after name)'],
    ['收入', 'to take in; income; revenue; CL:笔[bi3] ,个'],
    ['收拾', 'to put in order; to tidy up; to pack; to repair; to punish (colloquial); to manage'],
    ['首都', 'capital (city); CL:个'],
    ['首先', 'first (of all); in the first place'],
    ['受不了', 'unbearable; unable to endure; can\'t stand'],
    ['受到', 'to suffer; obtained; given; to receive'],
    ['售货员', 'salesperson; CL:个'],
    ['输', 'to transport; to lose; to donate; to enter (a password)'],
    ['熟悉', 'to be familiar with; to know well'],
    ['数量', 'quantity; amount; CL:个'],
    ['数字', 'numeral; digit; number; figure; amount; digital (electronics etc); CL:个'],
    ['帅', 'handsome; graceful; smart; commander in chief'],
    ['顺便', 'conveniently; in passing; without much extra effort'],
    ['顺利', 'smoothly; without a hitch'],
    ['顺序', 'sequence; order'],
    ['说明', 'to explain; to illustrate; explanation; directions; caption; CL:个'],
    ['硕士', 'master\'s degree; learned person'],
    ['死', 'to die; impassable; uncrossable; inflexible; rigid; extremely'],
    ['塑料袋', 'plastic bag'],
    ['速度', 'speed; rate; velocity; CL:个'],
    ['酸', 'sour; tart; sick at heart; grieved; sore; aching; acid'],
    ['随便', 'as one wishes; as one pleases; at random; negligent; casual; wanton'],
    ['随着', 'along with; in the wake of; following'],
    ['孙子', 'grandson (paternal)'],
    ['所有', 'all; to possess; to have; to own'],
    ['台', 'desk; platform; stage; terrace; stand; support; station; broadcasting station; desk; classifier for vehicles or machines; Taiwan (abbr.)'],
    ['抬', 'to lift; to raise; (of two or more persons) to carry'],
    ['态度', 'manner; bearing; attitude; approach; CL:个'],
    ['弹钢琴', 'play the piano'],
    ['谈', 'to discuss; to chat; to converse; to talk; to speak'],
    ['汤', 'soup; broth; hot water'],
    ['糖', 'sugar; sweets; candy; CL:颗[ke1] ,块[kuai4]'],
    ['躺', 'to recline; to lie down'],
    ['趟', 'quantifier for the number of trips or runs made'],
    ['讨论', 'to talk over; to discuss; CL:个'],
    ['讨厌', 'to dislike; to loathe; disagreeable; troublesome; annoying; nasty; to hate doing something'],
    ['特点', 'characteristic (feature); trait; feature; CL:个'],
    ['提', 'to carry; to lift; to raise'],
    ['提供', 'to supply; to provide; to furnish; to offer'],
    ['提前', 'to shift to an earlier date; to bring forward; to advance; in advance'],
    ['提醒', 'to call attention to; to warn of; to remind'],
    ['填空', 'to fill a job vacancy; to fill in a blank (e.g. on questionnaire or exam paper)'],
    ['条件', 'circumstances; condition; term; factor; requirement; prerequisite; qualification; CL:个'],
    ['停', 'to halt; to stop; to park (a car);'],
    ['挺', 'to (physically) straighten up; to stick out; to endure or hold out; straight; stiff; outstanding; extraordinary; rather; quite; very; classifier for machine guns'],
    ['通过', 'to pass through; to get through; by means of; through; via; to adopt; to pass (a bill); to switch over'],
    ['通知', 'to notify; to inform; notice; notification; CL:个'],
    ['同情', 'compassion; relent; sympathize; sympathy'],
    ['同时', 'at the same time; simultaneously'],
    ['推', 'to push; to cut; to refuse; to reject; to decline; to shirk (responsibility); to put off; to delay; to push forward; to nominate; to elect'],
    ['推迟', 'to postpone; to put off; to defer'],
    ['脱', 'to shed; to take off; to escape; to get away from'],
    ['袜子', 'socks; stockings; CL:只[zhi1] ,对[dui4] ,双[shuang1]'],
    ['完全', 'complete; whole; totally; entirely'],
    ['往往', 'often; frequently'],
    ['网球', 'tennis; CL:个'],
    ['网站', 'website; network station; node'],
    ['危险', 'danger; dangerous'],
    ['卫生间', 'bathroom; toilet; WC; CL:间[jian1]'],
    ['味道', 'flavor; smell; hint of'],
    ['温度', 'temperature; CL:个'],
    ['文章', 'article; essay; literary works; writings; hidden meaning; CL:篇[pian1] ,段[duan4] ,页[ye4]'],
    ['污染', 'pollution; contamination; CL:个'],
    ['无', 'un-; to lack; -less; not to have; no; none; not; to lack'],
    ['无聊', 'nonsense; bored'],
    ['无论', 'no matter what or how; regardless of whether...'],
    ['误会', 'to misunderstand; to mistake; misunderstanding; CL:个'],
    ['吸引', 'to attract (interest, investment etc); CL:个'],
    ['西红柿', 'tomato; CL:只[zhi1]'],
    ['咸', 'salty'],
    ['现金', 'cash'],
    ['羡慕', 'envious; envy; to admire'],
    ['相反', 'opposite; contrary'],
    ['相同', 'identical; same'],
    ['香', 'fragrant; sweet smelling; aromatic; savory or appetizing; (to eat) with relish; (of sleep) sound; perfume or spice; joss or incense stick; CL:根[gen1]'],
    ['详细', 'detailed; in detail; minute'],
    ['响', 'to make a sound; to sound; to ring; loud; classifier for noises'],
    ['橡皮', 'rubber; an eraser; CL:块[kuai4]'],
    ['消息', 'news; information; CL:条[tiao2]'],
    ['小吃', 'snack; refreshments; CL:家[jia1]'],
    ['小伙子', 'lad; young fellow; young man; young guy; youngster; CL:个'],
    ['小说', 'novel; fiction; CL:本[ben3] ,部[bu4]'],
    ['效果', 'result; effect; quality; CL:个'],
    ['笑话', 'joke; jest; CL:个'],
    ['心情', 'mood; frame of mind; CL:个'],
    ['辛苦', 'hard; exhausting; with much toil; thanks for your trouble'],
    ['信封', 'envelope; CL:个'],
    ['信息', 'information; news; message'],
    ['信心', 'confidence; faith (in sb or sth); CL:个'],
    ['兴奋', 'excited; excitement'],
    ['行', 'to walk; to go; to travel; a visit; temporary; makeshift; current; in circulation; to do; to perform; capable; competent; effective; all right; OK!; will do'],
    ['醒', 'to wake up; to awaken; to be awake'],
    ['幸福', 'happiness; happy; blessed; well-being'],
    ['性别', 'gender; sex; distinguishing between the sexes'],
    ['性格', 'nature (temperament); nature; disposition; temperament; character; CL:个'],
    ['修理', 'to repair; to perform maintenance; to overhaul; to fix; to prune; to trim'],
    ['许多', 'a great deal of; many; a lot of; much'],
    ['学期', 'school term; semester; CL:个'],
    ['呀', '(particle equivalent to 啊 after a vowel, expressing surprise or doubt)'],
    ['压力', 'pressure'],
    ['牙膏', 'toothpaste; CL:管[guan3]'],
    ['亚洲', 'Asia; Asian'],
    ['严格', 'strict; stringent; tight; rigorous'],
    ['严重', 'serious; severe; critical; grave'],
    ['盐', 'salt; CL:粒[li4]'],
    ['研究', 'research; a study; CL:項|项[xiang4];'],
    ['演出', 'to act (in a play); to perform; to put on (a performance); performance; concert; show; CL:场[chang2] ,次[ci4]'],
    ['演员', 'actor or actress; performer; CL:个,位[wei4] ,名[ming2]'],
    ['眼镜', 'spectacles; eyeglasses; CL:副[fu4]'],
    ['阳光', 'sunlight; sunshine; transparent (open to public scrutinity); CL:线[xian4]'],
    ['养成', 'to cultivate; to raise; to form (a habit); to acquire'],
    ['样子', 'manner; air; looks; aspect'],
    ['邀请', 'to invite; invitation; CL:个'],
    ['要是', 'if'],
    ['钥匙', 'key; CL:把[ba3]'],
    ['也许', 'perhaps; maybe; probably'],
    ['叶子', 'foliage; leaf; CL:片[pian4]'],
    ['页', 'page; leaf'],
    ['一切', 'everything; every; all'],
    ['以', 'to use as; according to; because of'],
    ['以为', 'to believe; to think; to consider; to be under the impression'],
    ['意见', 'opinion; suggestion; idea; objection; complaint; CL:点[dian3] ,条[tiao2]'],
    ['艺术', 'art; CL:种[zhong3]'],
    ['因此', 'consequently; as a result; thus'],
    ['引起', 'to give rise to; to lead to; to cause; to arouse'],
    ['印象', 'impression; reflection; perception; CL:个'],
    ['赢', 'to win; to beat; to profit'],
    ['应聘', 'to accept a job offer; to apply for an advertised position'],
    ['勇敢', 'brave; courageous'],
    ['永远', 'forever; eternal'],
    ['优点', 'merit; benefit; strong point; advantage; CL:个'],
    ['优秀', 'outstanding; excellent'],
    ['幽默', 'humor; humorous'],
    ['尤其', 'especially; particularly'],
    ['由', 'to follow; from; it is for...to; reason; cause; because of; due to; to; to leave it (to sb); by (introduces passive verb)'],
    ['由于', 'due to; as a result of; thanks to; owing to; since; because'],
    ['邮局', 'post office; CL:家[jia1] ,个'],
    ['友好', 'friendly; amicable'],
    ['友谊', 'companionship; fellowship; friendship'],
    ['有趣', 'interesting; fascinating; amusing'],
    ['于是', 'thereupon; as a result; consequently; thus; hence'],
    ['愉快', 'cheerful; cheerily; delightful; pleasant; pleasantly; pleasing; happy; delighted'],
    ['与', 'to give; to help; to get along with; and; with'],
    ['羽毛球', 'badminton; shuttlecock; CL:个'],
    ['语法', 'grammar'],
    ['语言', 'language; CL:门[men2] ,种[zhong3]'],
    ['预习', 'to prepare a lesson'],
    ['原来', 'original; former; originally; formerly; at first; so... actually'],
    ['原谅', 'to excuse; to forgive; to pardon'],
    ['原因', 'cause; origin; root cause; reason; CL:个'],
    ['约会', 'appointment; engagement; date; CL:次[ci4] ,个'],
    ['阅读', 'to read; reading'],
    ['云', 'cloud; CL:朵[duo3]'],
    ['允许', 'to permit; to allow'],
    ['杂志', 'magazine; CL:本[ben3] ,份[fen4] ,期[qi1]'],
    ['咱们', 'we or us (including both the speaker and the person(s) spoken to); I or me; you'],
    ['暂时', 'temporary; provisional; for the time being'],
    ['脏', 'dirty'],
    ['责任', 'responsibility; blame; duty; CL:个'],
    ['增加', 'to raise; to increase'],
    ['占线', 'busy (telephone)'],
    ['招聘', 'recruitment; to invite applications for a job'],
    ['照', 'according to; in accordance with; to shine; to illuminate; to reflect; to look at (one\'s reflection); to take (a photo); photo; as requested; as before;'],
    ['真正', 'genuine; real; true; genuinely'],
    ['整理', 'to arrange; to tidy up; to sort out; to straighten out; to list systematically; to collate (data, files); to pack (luggage)'],
    ['正常', 'regular; normal; ordinary'],
    ['正好', 'just (in time); just right; just enough; to happen to; to chance to; by chance; it just so happens that'],
    ['正确', 'correct; proper'],
    ['正式', 'formal; official'],
    ['证明', 'proof; certificate; identification; testimonial; to prove; to testify; to confirm the truth of; CL:个'],
    ['之', '(possessive particle, literary equivalent of 的); him; her; its'],
    ['支持', 'to be in favor of; to support; to back; support; backing; to stand by; CL:个'],
    ['知识', 'intellectual; knowledge-related; knowledge; CL:门[men2]'],
    ['值得', 'to be worth; to deserve'],
    ['植物', 'botanical; plant; vegetation; CL:种[zhong3]'],
    ['直接', 'direct; opposite: indirect 间接; immediate; directly; straightforward'],
    ['职业', 'occupation; profession; vocation; professional'],
    ['只好', 'without any better option; to have to; to be forced to'],
    ['只要', 'if only; so long as'],
    ['指', 'finger; to point at or to; to indicate or refer to; to depend on; to count on; (of hair) to stand on end'],
    ['至少', 'at least; (to say the) least'],
    ['质量', 'quality; mass (in physics); CL:个'],
    ['重', 'heavy; serious'],
    ['重点', 'emphasis; focal point; priority; key; with the emphasis on; focusing on'],
    ['重视', 'to attach importance to sth; to value'],
    ['周围', 'surroundings; environment; to encompass'],
    ['主意', 'plan; idea; decision; CL:个'],
    ['祝贺', 'to congratulate; congratulations; CL:个'],
    ['著名', 'famous; noted; well-known; celebrated'],
    ['专门', 'specialist; specialized; customized'],
    ['专业', 'specialty; specialized field; main field of study (at university); major; professional; CL:门[men2] ,个'],
    ['转', 'to turn; to change direction; to transfer; to forward (mail);'],
    ['赚', 'earn; make a profit'],
    ['准确', 'accurate; exact; precise'],
    ['准时', 'on time; punctual; on schedule'],
    ['仔细', 'careful; attentive; cautious'],
    ['自然', 'at ease; free from affectation; natural'],
    ['自信', 'confidence; self-confidence'],
    ['总结', 'to sum up; to conclude; summary; resume; CL:个'],
    ['租', 'to rent; to charter; to rent out; to lease out; to hire; rent; land tax'],
    ['最好', 'best; (you) had better (do what we suggest)'],
    ['尊重', 'esteem; respect; to honor; to value sth'],
    ['左右', 'approximately; about; left and right; around; (dial.) anyway'],
    ['作家', 'author; CL:個|个[ge4],位[wei4];'],
    ['作用', 'to act on; to affect; action; function; activity; impact; result; effect; purpose; intent; to play a role; corresponds to English -ity, -ism, -ization; CL:个'],
    ['作者', 'writer; author; CL:个'],
    ['座', 'seat; base; stand; classifier for buildings, mountains and similar immovable objects; CL:个'],
    ['座位', 'a place to sit; CL:个'],
];