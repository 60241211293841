import {Lang} from "./languages";
import {WordEntry, MatcherDict} from "./matcherDict";

// This is a complete list of the changes between traditional and simplified.
// This can be used for a matching game and is of general interest.
const simplifiedTraditionalWords: [WordEntry, WordEntry][] =
    [
        ['万', '萬'],
        ['与', '與'],
        ['专', '專'],
        ['业', '業'],
        ['丛', '叢'],
        ['东', '東'],
        ['丝', '絲'],
        ['丢', '丟'],
        ['两', '兩'],
        ['严', '嚴'],
        ['丧', '喪'],
        ['个', '個'],
        ['临', '臨'],
        ['为', '為'],
        ['丽', '麗'],
        ['举', '舉'],
        ['义', '義'],
        ['乌', '烏'],
        ['乐', '樂'],
        ['乔', '喬'],
        ['习', '習'],
        ['乡', '鄉'],
        ['书', '書'],
        ['买', '買'],
        ['乱', '亂'],
        ['争', '爭'],
        ['亏', '虧'],
        ['亚', '亞'],
        ['产', '產'],
        ['亩', '畝'],
        ['亲', '親'],
        ['亿', '億'],
        ['什', '甚'],
        ['仅', '僅'],
        ['从', '從'],
        ['仓', '倉'],
        ['仪', '儀'],
        ['们', '們'],
        ['众', '眾'],
        ['优', '優'],
        ['会', '會'],
        ['伞', '傘'],
        ['伟', '偉'],
        ['传', '傳'],
        ['伤', '傷'],
        ['伦', '倫'],
        ['伪', '偽'],
        ['体', '體'],
        ['佣', '傭'],
        ['侠', '俠'],
        ['侣', '侶'],
        ['侦', '偵'],
        ['侧', '側'],
        ['侨', '僑'],
        ['俩', '倆'],
        ['债', '債'],
        ['倾', '傾'],
        ['偿', '償'],
        ['储', '儲'],
        ['儿', '兒'],
        ['兑', '兌'],
        ['党', '黨'],
        ['兰', '蘭'],
        ['关', '關'],
        ['兴', '興'],
        ['兹', '茲'],
        ['养', '養'],
        ['兽', '獸'],
        ['内', '內'],
        ['冈', '岡'],
        ['册', '冊'],
        ['写', '寫'],
        ['军', '軍'],
        ['农', '農'],
        ['冯', '馮'],
        ['决', '決'],
        ['况', '況'],
        ['冻', '凍'],
        ['净', '凈'],
        ['凉', '涼'],
        ['减', '減'],
        ['凑', '湊'],
        ['凛', '凜'],
        ['凤', '鳳'],
        ['凭', '憑'],
        ['凯', '凱'],
        ['击', '擊'],
        ['刘', '劉'],
        ['则', '則'],
        ['刚', '剛'],
        ['创', '創'],
        ['别', '別'],
        ['刹', '剎'],
        ['剂', '劑'],
        ['剑', '劍'],
        ['剥', '剝'],
        ['剧', '劇'],
        ['劝', '勸'],
        ['办', '辦'],
        ['务', '務'],
        ['动', '動'],
        ['励', '勵'],
        ['劲', '勁'],
        ['劳', '勞'],
        ['势', '勢'],
        ['匀', '勻'],
        ['区', '區'],
        ['医', '醫'],
        ['千', '仟'],
        ['华', '華'],
        ['协', '協'],
        ['单', '單'],
        ['卖', '賣'],
        ['卢', '盧'],
        ['卧', '臥'],
        ['卫', '衛'],
        ['却', '卻'],
        ['厂', '廠'],
        ['厅', '廳'],
        ['厉', '厲'],
        ['压', '壓'],
        ['厌', '厭'],
        ['厕', '廁'],
        ['厢', '廂'],
        ['厦', '廈'],
        ['厨', '廚'],
        ['县', '縣'],
        ['参', '參'],
        ['双', '雙'],
        ['变', '變'],
        ['叙', '敘'],
        ['叠', '疊'],
        ['只', '祇'],
        ['号', '號'],
        ['叹', '嘆'],
        ['吓', '嚇'],
        ['吕', '呂'],
        ['吗', '嗎'],
        ['吨', '噸'],
        ['听', '聽'],
        ['启', '啟'],
        ['吴', '吳'],
        ['员', '員'],
        ['呜', '嗚'],
        ['响', '響'],
        ['哑', '啞'],
        ['哗', '嘩'],
        ['哟', '喲'],
        ['唤', '喚'],
        ['啸', '嘯'],
        ['喷', '噴'],
        ['嘱', '囑'],
        ['团', '團'],
        ['园', '園'],
        ['围', '圍'],
        ['国', '國'],
        ['图', '圖'],
        ['圆', '圓'],
        ['圣', '聖'],
        ['场', '場'],
        ['块', '塊'],
        ['坚', '堅'],
        ['坝', '壩'],
        ['坟', '墳'],
        ['坠', '墜'],
        ['垄', '壟'],
        ['垒', '壘'],
        ['堕', '墮'],
        ['墙', '牆'],
        ['壮', '壯'],
        ['声', '聲'],
        ['壳', '殼'],
        ['壶', '壺'],
        ['处', '處'],
        ['备', '備'],
        ['够', '夠'],
        ['头', '頭'],
        ['夸', '誇'],
        ['夹', '夾'],
        ['夺', '奪'],
        ['奋', '奮'],
        ['奖', '獎'],
        ['奥', '奧'],
        ['妆', '妝'],
        ['妇', '婦'],
        ['妈', '媽'],
        ['娇', '嬌'],
        ['娱', '娛'],
        ['婴', '嬰'],
        ['孙', '孫'],
        ['宁', '寧'],
        ['宝', '寶'],
        ['实', '實'],
        ['宠', '寵'],
        ['审', '審'],
        ['宪', '憲'],
        ['宫', '宮'],
        ['宽', '寬'],
        ['宾', '賓'],
        ['对', '對'],
        ['寻', '尋'],
        ['导', '導'],
        ['寿', '壽'],
        ['将', '將'],
        ['尔', '爾'],
        ['尘', '塵'],
        ['尝', '嘗'],
        ['尸', '屍'],
        ['层', '層'],
        ['届', '屆'],
        ['属', '屬'],
        ['岁', '歲'],
        ['岂', '豈'],
        ['岗', '崗'],
        ['岛', '島'],
        ['岭', '嶺'],
        ['峡', '峽'],
        ['崭', '嶄'],
        ['巩', '鞏'],
        ['币', '幣'],
        ['帅', '帥'],
        ['师', '師'],
        ['帐', '帳'],
        ['帜', '幟'],
        ['带', '帶'],
        ['帮', '幫'],
        ['广', '廣'],
        ['庄', '莊'],
        ['庆', '慶'],
        ['庐', '廬'],
        ['库', '庫'],
        ['应', '應'],
        ['庙', '廟'],
        ['庞', '龐'],
        ['废', '廢'],
        ['开', '開'],
        ['异', '異'],
        ['弃', '棄'],
        ['张', '張'],
        ['弥', '彌'],
        ['弯', '彎'],
        ['弹', '彈'],
        ['强', '強'],
        ['归', '歸'],
        ['录', '錄'],
        ['彦', '彥'],
        ['彻', '徹'],
        ['径', '徑'],
        ['忆', '憶'],
        ['忧', '憂'],
        ['念', '唸'],
        ['怀', '懷'],
        ['态', '態'],
        ['怜', '憐'],
        ['总', '總'],
        ['恋', '戀'],
        ['恒', '恆'],
        ['恳', '懇'],
        ['恶', '惡'],
        ['恼', '惱'],
        ['悦', '悅'],
        ['悬', '懸'],
        ['惊', '驚'],
        ['惧', '懼'],
        ['惨', '慘'],
        ['惩', '懲'],
        ['惯', '慣'],
        ['愤', '憤'],
        ['愿', '願'],
        ['懒', '懶'],
        ['戏', '戲'],
        ['战', '戰'],
        ['户', '戶'],
        ['才', '纔'],
        ['扑', '撲'],
        ['执', '執'],
        ['扩', '擴'],
        ['扫', '掃'],
        ['扬', '揚'],
        ['扰', '擾'],
        ['抚', '撫'],
        ['抛', '拋'],
        ['抢', '搶'],
        ['护', '護'],
        ['报', '報'],
        ['担', '擔'],
        ['拟', '擬'],
        ['拢', '攏'],
        ['拣', '揀'],
        ['拥', '擁'],
        ['拦', '攔'],
        ['拨', '撥'],
        ['择', '擇'],
        ['挂', '掛'],
        ['挡', '擋'],
        ['挣', '掙'],
        ['挤', '擠'],
        ['挥', '揮'],
        ['捞', '撈'],
        ['损', '損'],
        ['捡', '撿'],
        ['换', '換'],
        ['捣', '搗'],
        ['据', '據'],
        ['掷', '擲'],
        ['搁', '擱'],
        ['搂', '摟'],
        ['搅', '攪'],
        ['携', '攜'],
        ['摄', '攝'],
        ['摆', '擺'],
        ['摇', '搖'],
        ['摊', '攤'],
        ['撑', '撐'],
        ['敌', '敵'],
        ['数', '數'],
        ['斋', '齋'],
        ['斩', '斬'],
        ['断', '斷'],
        ['无', '無'],
        ['旧', '舊'],
        ['时', '時'],
        ['旷', '曠'],
        ['昼', '晝'],
        ['显', '顯'],
        ['晋', '晉'],
        ['晒', '曬'],
        ['晓', '曉'],
        ['晕', '暈'],
        ['暂', '暫'],
        ['术', '術'],
        ['机', '機'],
        ['杀', '殺'],
        ['杂', '雜'],
        ['权', '權'],
        ['条', '條'],
        ['来', '來'],
        ['杨', '楊'],
        ['杰', '傑'],
        ['极', '極'],
        ['构', '構'],
        ['枢', '樞'],
        ['枪', '槍'],
        ['枫', '楓'],
        ['标', '標'],
        ['栋', '棟'],
        ['栏', '欄'],
        ['树', '樹'],
        ['样', '樣'],
        ['档', '檔'],
        ['桥', '橋'],
        ['桩', '樁'],
        ['梦', '夢'],
        ['检', '檢'],
        ['楼', '樓'],
        ['横', '橫'],
        ['欢', '歡'],
        ['欧', '歐'],
        ['残', '殘'],
        ['毁', '毀'],
        ['毕', '畢'],
        ['毙', '斃'],
        ['气', '氣'],
        ['汉', '漢'],
        ['汤', '湯'],
        ['汹', '洶'],
        ['沟', '溝'],
        ['没', '沒'],
        ['沦', '淪'],
        ['沪', '滬'],
        ['泪', '淚'],
        ['泼', '潑'],
        ['泽', '澤'],
        ['洁', '潔'],
        ['浅', '淺'],
        ['浆', '漿'],
        ['浇', '澆'],
        ['测', '測'],
        ['济', '濟'],
        ['浑', '渾'],
        ['浓', '濃'],
        ['涂', '塗'],
        ['涛', '濤'],
        ['润', '潤'],
        ['涨', '漲'],
        ['渊', '淵'],
        ['渐', '漸'],
        ['渔', '漁'],
        ['温', '溫'],
        ['湾', '灣'],
        ['湿', '濕'],
        ['溃', '潰'],
        ['滚', '滾'],
        ['滞', '滯'],
        ['满', '滿'],
        ['滥', '濫'],
        ['滨', '濱'],
        ['滩', '灘'],
        ['潇', '瀟'],
        ['潜', '潛'],
        ['灭', '滅'],
        ['灯', '燈'],
        ['灵', '靈'],
        ['灾', '災'],
        ['灿', '燦'],
        ['炉', '爐'],
        ['点', '點'],
        ['炼', '煉'],
        ['烁', '爍'],
        ['烂', '爛'],
        ['烛', '燭'],
        ['烦', '煩'],
        ['烧', '燒'],
        ['烫', '燙'],
        ['热', '熱'],
        ['爱', '愛'],
        ['爷', '爺'],
        ['牵', '牽'],
        ['牺', '犧'],
        ['状', '狀'],
        ['犹', '猶'],
        ['独', '獨'],
        ['狭', '狹'],
        ['狮', '獅'],
        ['狱', '獄'],
        ['猎', '獵'],
        ['猪', '豬'],
        ['猫', '貓'],
        ['献', '獻'],
        ['玛', '瑪'],
        ['环', '環'],
        ['现', '現'],
        ['琼', '瓊'],
        ['电', '電'],
        ['画', '畫'],
        ['畅', '暢'],
        ['疗', '療'],
        ['疯', '瘋'],
        ['症', '癥'],
        ['痒', '癢'],
        ['百', '佰'],
        ['皱', '皺'],
        ['盐', '鹽'],
        ['监', '監'],
        ['盖', '蓋'],
        ['盗', '盜'],
        ['盘', '盤'],
        ['着', '著'],
        ['睁', '睜'],
        ['瞒', '瞞'],
        ['矿', '礦'],
        ['码', '碼'],
        ['砖', '磚'],
        ['础', '礎'],
        ['硕', '碩'],
        ['确', '確'],
        ['碍', '礙'],
        ['礼', '禮'],
        ['祸', '禍'],
        ['禅', '禪'],
        ['离', '離'],
        ['秃', '禿'],
        ['种', '種'],
        ['积', '積'],
        ['称', '稱'],
        ['税', '稅'],
        ['稣', '穌'],
        ['稳', '穩'],
        ['穷', '窮'],
        ['窃', '竊'],
        ['窑', '窯'],
        ['窜', '竄'],
        ['窝', '窩'],
        ['竖', '豎'],
        ['竞', '競'],
        ['笔', '筆'],
        ['笼', '籠'],
        ['筑', '築'],
        ['筹', '籌'],
        ['签', '簽'],
        ['简', '簡'],
        ['篮', '籃'],
        ['类', '類'],
        ['粪', '糞'],
        ['粮', '糧'],
        ['紧', '緊'],
        ['纠', '糾'],
        ['红', '紅'],
        ['纤', '纖'],
        ['约', '約'],
        ['级', '級'],
        ['纪', '紀'],
        ['纯', '純'],
        ['纱', '紗'],
        ['纲', '綱'],
        ['纳', '納'],
        ['纵', '縱'],
        ['纷', '紛'],
        ['纸', '紙'],
        ['纹', '紋'],
        ['纺', '紡'],
        ['纽', '紐'],
        ['线', '線'],
        ['练', '練'],
        ['组', '組'],
        ['绅', '紳'],
        ['细', '細'],
        ['织', '織'],
        ['终', '終'],
        ['绍', '紹'],
        ['经', '經'],
        ['绑', '綁'],
        ['绒', '絨'],
        ['结', '結'],
        ['绕', '繞'],
        ['绘', '繪'],
        ['给', '給'],
        ['络', '絡'],
        ['绝', '絕'],
        ['绞', '絞'],
        ['统', '統'],
        ['绣', '繡'],
        ['继', '繼'],
        ['绩', '績'],
        ['绪', '緒'],
        ['续', '續'],
        ['绰', '綽'],
        ['绳', '繩'],
        ['维', '維'],
        ['绵', '綿'],
        ['绸', '綢'],
        ['综', '綜'],
        ['绿', '綠'],
        ['缅', '緬'],
        ['缆', '纜'],
        ['缓', '緩'],
        ['缕', '縷'],
        ['编', '編'],
        ['缘', '緣'],
        ['缚', '縛'],
        ['缝', '縫'],
        ['缠', '纏'],
        ['缩', '縮'],
        ['缴', '繳'],
        ['网', '網'],
        ['罗', '羅'],
        ['罚', '罰'],
        ['罢', '罷'],
        ['翘', '翹'],
        ['耸', '聳'],
        ['耻', '恥'],
        ['聋', '聾'],
        ['职', '職'],
        ['联', '聯'],
        ['聪', '聰'],
        ['肃', '肅'],
        ['肠', '腸'],
        ['肤', '膚'],
        ['肿', '腫'],
        ['胀', '脹'],
        ['胁', '脅'],
        ['胆', '膽'],
        ['胜', '勝'],
        ['胧', '朧'],
        ['胶', '膠'],
        ['脉', '脈'],
        ['脑', '腦'],
        ['脚', '腳'],
        ['脱', '脫'],
        ['脸', '臉'],
        ['腻', '膩'],
        ['腾', '騰'],
        ['舆', '輿'],
        ['舰', '艦'],
        ['舱', '艙'],
        ['艰', '艱'],
        ['艳', '艷'],
        ['艺', '藝'],
        ['节', '節'],
        ['芦', '蘆'],
        ['苍', '蒼'],
        ['苏', '蘇'],
        ['苹', '蘋'],
        ['荐', '薦'],
        ['荡', '蕩'],
        ['荣', '榮'],
        ['荫', '蔭'],
        ['药', '藥'],
        ['莱', '萊'],
        ['莲', '蓮'],
        ['萝', '蘿'],
        ['营', '營'],
        ['萧', '蕭'],
        ['萨', '薩'],
        ['葱', '蔥'],
        ['蒋', '蔣'],
        ['蓝', '藍'],
        ['蕴', '蘊'],
        ['虑', '慮'],
        ['虚', '虛'],
        ['虫', '蟲'],
        ['虽', '雖'],
        ['虾', '蝦'],
        ['蚀', '蝕'],
        ['蛮', '蠻'],
        ['蜡', '蠟'],
        ['衔', '銜'],
        ['补', '補'],
        ['衬', '襯'],
        ['袭', '襲'],
        ['装', '裝'],
        ['裤', '褲'],
        ['见', '見'],
        ['观', '觀'],
        ['规', '規'],
        ['觅', '覓'],
        ['视', '視'],
        ['览', '覽'],
        ['觉', '覺'],
        ['触', '觸'],
        ['誉', '譽'],
        ['计', '計'],
        ['订', '訂'],
        ['认', '認'],
        ['讥', '譏'],
        ['讨', '討'],
        ['让', '讓'],
        ['训', '訓'],
        ['议', '議'],
        ['讯', '訊'],
        ['记', '記'],
        ['讲', '講'],
        ['讳', '諱'],
        ['讶', '訝'],
        ['许', '許'],
        ['论', '論'],
        ['讼', '訟'],
        ['讽', '諷'],
        ['设', '設'],
        ['访', '訪'],
        ['证', '證'],
        ['评', '評'],
        ['识', '識'],
        ['诈', '詐'],
        ['诉', '訴'],
        ['诊', '診'],
        ['词', '詞'],
        ['译', '譯'],
        ['试', '試'],
        ['诗', '詩'],
        ['诚', '誠'],
        ['话', '話'],
        ['诞', '誕'],
        ['询', '詢'],
        ['该', '該'],
        ['详', '詳'],
        ['语', '語'],
        ['误', '誤'],
        ['诱', '誘'],
        ['说', '說'],
        ['诵', '誦'],
        ['请', '請'],
        ['诸', '諸'],
        ['诺', '諾'],
        ['读', '讀'],
        ['课', '課'],
        ['谁', '誰'],
        ['调', '調'],
        ['谅', '諒'],
        ['谈', '談'],
        ['谊', '誼'],
        ['谋', '謀'],
        ['谎', '謊'],
        ['谐', '諧'],
        ['谓', '謂'],
        ['谜', '謎'],
        ['谢', '謝'],
        ['谣', '謠'],
        ['谦', '謙'],
        ['谨', '謹'],
        ['谬', '謬'],
        ['谭', '譚'],
        ['谱', '譜'],
        ['谴', '譴'],
        ['贝', '貝'],
        ['贞', '貞'],
        ['负', '負'],
        ['贡', '貢'],
        ['财', '財'],
        ['责', '責'],
        ['贤', '賢'],
        ['败', '敗'],
        ['账', '賬'],
        ['货', '貨'],
        ['质', '質'],
        ['贩', '販'],
        ['贪', '貪'],
        ['贫', '貧'],
        ['购', '購'],
        ['贯', '貫'],
        ['贱', '賤'],
        ['贴', '貼'],
        ['贵', '貴'],
        ['贷', '貸'],
        ['贸', '貿'],
        ['费', '費'],
        ['贺', '賀'],
        ['贼', '賊'],
        ['贾', '賈'],
        ['贿', '賄'],
        ['资', '資'],
        ['赋', '賦'],
        ['赌', '賭'],
        ['赏', '賞'],
        ['赐', '賜'],
        ['赔', '賠'],
        ['赖', '賴'],
        ['赚', '賺'],
        ['赛', '賽'],
        ['赞', '贊'],
        ['赠', '贈'],
        ['赢', '贏'],
        ['赵', '趙'],
        ['赶', '趕'],
        ['趋', '趨'],
        ['跃', '躍'],
        ['践', '踐'],
        ['踪', '蹤'],
        ['躯', '軀'],
        ['车', '車'],
        ['轨', '軌'],
        ['转', '轉'],
        ['轮', '輪'],
        ['软', '軟'],
        ['轰', '轟'],
        ['轴', '軸'],
        ['轻', '輕'],
        ['载', '載'],
        ['轿', '轎'],
        ['较', '較'],
        ['辅', '輔'],
        ['辆', '輛'],
        ['辈', '輩'],
        ['辉', '輝'],
        ['辑', '輯'],
        ['输', '輸'],
        ['辞', '辭'],
        ['辩', '辯'],
        ['辫', '辮'],
        ['边', '邊'],
        ['辽', '遼'],
        ['达', '達'],
        ['迁', '遷'],
        ['过', '過'],
        ['迈', '邁'],
        ['运', '運'],
        ['还', '還'],
        ['这', '這'],
        ['进', '進'],
        ['远', '遠'],
        ['违', '違'],
        ['连', '連'],
        ['迟', '遲'],
        ['迹', '跡'],
        ['适', '適'],
        ['选', '選'],
        ['逊', '遜'],
        ['递', '遞'],
        ['逻', '邏'],
        ['遗', '遺'],
        ['遥', '遙'],
        ['邓', '鄧'],
        ['邮', '郵'],
        ['邻', '鄰'],
        ['郑', '鄭'],
        ['酱', '醬'],
        ['酿', '釀'],
        ['释', '釋'],
        ['鉴', '鑒'],
        ['针', '針'],
        ['钉', '釘'],
        ['钓', '釣'],
        ['钢', '鋼'],
        ['钦', '欽'],
        ['钩', '鉤'],
        ['钱', '錢'],
        ['钻', '鑽'],
        ['铁', '鐵'],
        ['铃', '鈴'],
        ['铅', '鉛'],
        ['铜', '銅'],
        ['铭', '銘'],
        ['银', '銀'],
        ['铸', '鑄'],
        ['铺', '鋪'],
        ['链', '鏈'],
        ['销', '銷'],
        ['锁', '鎖'],
        ['锅', '鍋'],
        ['锋', '鋒'],
        ['锐', '銳'],
        ['错', '錯'],
        ['锡', '錫'],
        ['锣', '鑼'],
        ['锤', '錘'],
        ['锦', '錦'],
        ['键', '鍵'],
        ['锻', '鍛'],
        ['镇', '鎮'],
        ['镖', '鏢'],
        ['镜', '鏡'],
        ['镶', '鑲'],
        ['长', '長'],
        ['门', '門'],
        ['闪', '閃'],
        ['闭', '閉'],
        ['问', '問'],
        ['闯', '闖'],
        ['闲', '閑'],
        ['间', '間'],
        ['闷', '悶'],
        ['闹', '鬧'],
        ['闻', '聞'],
        ['阁', '閣'],
        ['阅', '閱'],
        ['阐', '闡'],
        ['阔', '闊'],
        ['队', '隊'],
        ['阳', '陽'],
        ['阴', '陰'],
        ['阵', '陣'],
        ['阶', '階'],
        ['际', '際'],
        ['陆', '陸'],
        ['陈', '陳'],
        ['陕', '陝'],
        ['险', '險'],
        ['随', '隨'],
        ['隐', '隱'],
        ['隶', '隸'],
        ['难', '難'],
        ['雾', '霧'],
        ['静', '靜'],
        ['韦', '韋'],
        ['韩', '韓'],
        ['韵', '韻'],
        ['页', '頁'],
        ['顶', '頂'],
        ['项', '項'],
        ['顺', '順'],
        ['顽', '頑'],
        ['顾', '顧'],
        ['顿', '頓'],
        ['颁', '頒'],
        ['颂', '頌'],
        ['预', '預'],
        ['领', '領'],
        ['颇', '頗'],
        ['颈', '頸'],
        ['颊', '頰'],
        ['频', '頻'],
        ['颖', '穎'],
        ['颗', '顆'],
        ['题', '題'],
        ['颜', '顏'],
        ['额', '額'],
        ['颠', '顛'],
        ['颤', '顫'],
        ['风', '風'],
        ['飘', '飄'],
        ['飞', '飛'],
        ['饥', '飢'],
        ['饭', '飯'],
        ['饮', '飲'],
        ['饰', '飾'],
        ['饱', '飽'],
        ['饶', '饒'],
        ['饼', '餅'],
        ['饿', '餓'],
        ['馆', '館'],
        ['马', '馬'],
        ['驰', '馳'],
        ['驱', '驅'],
        ['驳', '駁'],
        ['驶', '駛'],
        ['驻', '駐'],
        ['驾', '駕'],
        ['骂', '罵'],
        ['骄', '驕'],
        ['骆', '駱'],
        ['验', '驗'],
        ['骑', '騎'],
        ['骗', '騙'],
        ['骚', '騷'],
        ['骤', '驟'],
        ['鱼', '魚'],
        ['鲁', '魯'],
        ['鲍', '鮑'],
        ['鲜', '鮮'],
        ['鸟', '鳥'],
        ['鸡', '雞'],
        ['鸣', '鳴'],
        ['鸦', '鴉'],
        ['鸭', '鴨'],
        ['鸿', '鴻'],
        ['鹅', '鵝'],
        ['鹏', '鵬'],
        ['鹤', '鶴'],
        ['鹰', '鷹'],
        ['麦', '麥'],
        ['黄', '黃'],
        ['齐', '齊'],
        ['齿', '齒'],
        ['龄', '齡'],
        ['龙', '龍'],
        ['龟', '龜'],
        ['泻', '瀉'],
    ].map(words => [
        {lang: Lang.ChineseSimplified, word: words[0]},
        {lang: Lang.ChineseTraditional, word: words[1]}]);

export const simplifiedTraditionalDictionary =
    new MatcherDict(
        'Simplified/Traditional Differences',
        simplifiedTraditionalWords);