export const hsk6Wordlist: [string, string][] = [
    ['挨', 'to suffer from; to endure; to tide over (a difficult period); to delay'],
    ['癌症', 'cancer'],
    ['暧昧', 'vague; ambiguous; equivocal; dubious'],
    ['爱不释手', 'to love sth too much to part with it (idiom); to fondle admiringly'],
    ['爱戴', 'to love and respect; love and respect'],
    ['安宁', 'peaceful; tranquil; calm; composed'],
    ['安详', 'serene'],
    ['安置', 'find a place for; help settle down; arrange for; to get into bed; placement'],
    ['按摩', 'massage'],
    ['暗示', 'to hint; to suggest; suggestion; a hint'],
    ['案件', 'law case; legal case; judicial case; CL:宗[zong1] ,桩[zhuang1] ,起[qi3]'],
    ['案例', 'case (law); CL:个'],
    ['昂贵', 'expensive; costly'],
    ['凹凸', 'bumpy; uneven; slotted and tabbed joint; crenelation'],
    ['熬', '(of cooking) to boil for a long time; to endure; to suffer'],
    ['奥秘', 'profound mystery'],
    ['巴不得', 'to be eager for; to long for; to look forward to'],
    ['巴结', 'to fawn on; to curry favor with; to make up to'],
    ['扒', 'to hold on to; to cling to; to dig up; to rake; to push aside; to climb; to pull out; to strip off'],
    ['疤', 'scar'],
    ['拔苗助长', 'to spoil things through excessive enthusiasm (idiom)'],
    ['把关', 'to guard a pass; to check on sth'],
    ['把手', 'handle; grip; knob'],
    ['罢工', 'a strike; to go on strike'],
    ['霸道', 'overbearing; tyranny; (of liquor, medicine etc) strong; potent; the Way of the Hegemon, abbr. of 霸王之道; despotic rule; rule by might; evil as opposed to the Way of the King 王道;'],
    ['掰', 'to break with both hands'],
    ['摆脱', 'to break away from; to cast off (old ideas etc); to get rid of; to break away (from); to break out (of); to free oneself from; to extricate oneself'],
    ['拜访', 'pay a visit; call on'],
    ['拜年', 'pay a New Year call; wish sb a Happy New Year'],
    ['拜托', 'request sb to do sth; please!'],
    ['败坏', 'to ruin; to corrupt; to undermine'],
    ['斑', 'spot; colored patch; stripe; spotted; striped; variegated;'],
    ['颁布', 'to issue; to proclaim; to enact (laws, decrees etc)'],
    ['颁发', 'to issue; to promulgate; to award'],
    ['版本', 'version; edition; release'],
    ['伴侣', 'partner; companion; mate'],
    ['伴随', 'to accompany; to follow; to occur together with; attendant'],
    ['半途而废', 'to give up halfway (idiom); leave sth unfinished'],
    ['扮演', 'play the part of; act'],
    ['榜样', 'example; model; CL:个'],
    ['绑架', 'to kidnap; to abduct; to hijack; a kidnapping; abduction; staking'],
    ['磅', 'pound (unit of weight, about 454 grams); platform balance;'],
    ['包庇', 'to shield; to harbor; to cover up'],
    ['包袱', 'cloth-wrapper; a bundle wrapped in cloth; load; weight; burden'],
    ['包围', 'to surround; to encircle; to hem in'],
    ['包装', 'package; pack'],
    ['保管', 'to assure; to guarantee; to take care of; to safeguard; certainly; surely'],
    ['保密', 'to keep sth confidential; to maintain secrecy'],
    ['保姆', 'nanny; housekeeper'],
    ['保守', '(politically) conservative; to guard; to keep'],
    ['保卫', 'to defend; to safeguard'],
    ['保养', 'to take good care of (or conserve) one\'s health; to keep in good repair; to maintain; maintenance'],
    ['保障', 'to ensure; to guarantee; to safeguard'],
    ['保重', 'to take care of oneself'],
    ['饱和', 'saturation'],
    ['饱经沧桑', 'having lived through many changes'],
    ['报酬', 'remuneration; reward'],
    ['报仇', 'to revenge (oneself); to avenge'],
    ['报答', 'to repay; to requite'],
    ['报复', 'to make reprisals; to retaliate; revenge; retaliation'],
    ['报警', 'to report sth to the police; to sound an alarm'],
    ['报销', 'submit an expense account; apply for reimbursement; write-off; wipe out'],
    ['抱负', 'aspiration; ambition'],
    ['暴力', 'violence; (use) force; violent'],
    ['暴露', 'to expose; to reveal; to lay bare'],
    ['曝光', 'exposure (e.g. of photosensitive material); to expose (a scandal to the public)'],
    ['爆发', 'to break out; to erupt; to explode; to burst out'],
    ['爆炸', 'explosion; to explode; to blow up; to detonate'],
    ['卑鄙', 'contemptible; despicable; base; mean'],
    ['悲哀', 'grieved; sorrowful'],
    ['悲惨', 'miserable; tragic'],
    ['北极', 'the North Pole; the Arctic Pole; the north magnetic pole'],
    ['备份', 'backup'],
    ['备忘录', 'memorandum; aide-memoire; memorandum book'],
    ['背叛', 'to betray'],
    ['背诵', 'recite; repeat from memory'],
    ['被动', 'passive (not taking initiative)'],
    ['被告', 'defendant'],
    ['贝壳', 'shell; conch; cowry; mother of pearl; hard outer skin; alternative reading bei4 qiao4, mostly written'],
    ['奔波', 'rush about; be busy running about'],
    ['奔驰', 'to run quickly; to travel quickly; to move fast; Mercedes-Benz, German car make'],
    ['本能', 'instinct'],
    ['本钱', 'capital (finance); assets; means'],
    ['本人', 'oneself; I (humble form used in speeches); myself; in person; personal'],
    ['本身', 'in itself; per se; itself'],
    ['本事', 'ability; skill; source material; original story'],
    ['笨拙', 'clumsy; awkward; stupid'],
    ['崩溃', 'to collapse; to crumble; to fall apart'],
    ['甭', 'need not; (contraction of 不 and 用)'],
    ['蹦', 'to leap; to bounce; to hop; to jump'],
    ['迸发', 'to burst out; to gush; to spurt'],
    ['逼迫', 'to force; to compel; to coerce'],
    ['鼻涕', 'nasal mucus; snivel'],
    ['比方', 'instance; example'],
    ['比喻', 'analogy; metaphor; figure of speech; figuratively'],
    ['比重', 'proportion; specific gravity'],
    ['鄙视', 'to despise; to disdain; to look down upon;'],
    ['弊病', 'malady; evil; malpractice; drawback; disadvantage'],
    ['弊端', 'malpractice; abuse; corrupt practice'],
    ['臂', 'arm'],
    ['闭塞', 'stop up; close up; hard to get to; out of the way; inaccessible; unenlightened; blocking'],
    ['编织', 'to weave; to knit; to plait; to braid; (fig.) to create (sth abstract, e.g. a dream, a lie etc);'],
    ['边疆', 'border area; borderland; frontier; frontier region'],
    ['边界', 'boundary; border'],
    ['边境', 'frontier; border'],
    ['边缘', 'edge; fringe; verge; brink; periphery; marginal; borderline'],
    ['鞭策', 'to spur on; to urge on; to encourage sb (e.g. to make progress)'],
    ['扁', 'flat; (old form of character 匾, horizontal tablet with inscription)'],
    ['贬低', 'to belittle; to disparage; to play down; to demean; to degrade; to devalue'],
    ['贬义', 'derogatory sense; negative connotation'],
    ['便利', 'convenient; easy; facilitate'],
    ['便条', '(informal) note; CL:张[zhang1] ,个'],
    ['便于', 'easy to; convenient for'],
    ['变故', 'an unforeseen event; accident; misfortune'],
    ['变迁', 'changes; vicissitudes'],
    ['变质', 'to degenerate; to go bad; to deteriorate; metamorphosis'],
    ['辨认', 'to recognize; to identify'],
    ['辩护', 'to speak in defense of; to argue in favor of; to defend; to plead'],
    ['辩解', 'to explain; to justify; to defend (a point of view etc); to provide an explanation; to try to defend oneself'],
    ['辩证', 'to investigate; dialectical'],
    ['辫子', 'plait; braid; pigtail; a mistake or shortcoming that may be exploited by an opponent; handle; CL:根[gen1] ,条[tiao2]'],
    ['遍布', 'to cover the whole (area); to be found throughout'],
    ['标本', 'specimen; sample; the root cause and symptoms of a disease'],
    ['标记', 'sign; mark; symbol; to mark up'],
    ['标题', 'title; heading; headline; caption; subject'],
    ['表决', 'decide by vote; vote'],
    ['表态', 'to declare one\'s position; to say where one stands'],
    ['表彰', 'to honor; to cite (in dispatches); cite (in dispatches); commend'],
    ['憋', 'to choke; to stifle; to restrain; to hold back; to hold in (urine); to hold (one\'s breath)'],
    ['别墅', 'villa; CL:幢[zhuang4] ,座[zuo4]'],
    ['别致', 'unusual; unique'],
    ['别扭', 'awkward; difficult; uncomfortable; not agreeing; at loggerheads; gauche; awkward (writing or speech)'],
    ['濒临', 'on the verge of; close to'],
    ['冰雹', 'hail; hailstone; CL:场[chang2] ,粒[li4]'],
    ['丙', 'third in order (III); third of 10 heavenly stems 十天干; letter \'C\' or roman \'III\' in list \'A, B, C\', or \'I, II, III\' etc; propyl'],
    ['并非', 'really isn\'t'],
    ['并列', 'to stand side by side; to be juxtaposed'],
    ['剥削', 'to exploit; exploitation'],
    ['拨', 'to push aside; to dial; to allocate; to set aside (money); to poke (the fire); to pluck (a string instrument); to turn round; classifier: group, batch;'],
    ['播种', 'to sow seeds; sowing; seed'],
    ['波浪', 'wave'],
    ['波涛', 'great waves; billows;'],
    ['伯母', 'aunt; wife of father\'s elder brother; (polite form of address for a woman who is about the age of one\'s mother); CL:个'],
    ['博大精深', 'wide-ranging and profound; broad and deep'],
    ['博览会', 'exposition; international fair'],
    ['搏斗', 'to wrestle; to fight; to struggle'],
    ['薄弱', 'weak; frail'],
    ['不顾', 'in spite of; regardless of'],
    ['不愧', 'be worthy of; deserve to be called; prove oneself to be'],
    ['不料', 'unexpectedly; to one\'s surprise'],
    ['不像话', 'unreasonable; shocking; outrageous'],
    ['不屑一顾', 'to disdain as beneath contempt'],
    ['哺乳', 'breast feeding; to suckle; to nurse'],
    ['捕捉', 'to catch; to seize; to capture'],
    ['补偿', 'compensate; make up'],
    ['补救', 'remedy'],
    ['补贴', 'to subsidize; subsidy; allowance'],
    ['不得已', 'act against one\'s will; have no alternative but to; have to; to have no choice; must'],
    ['不妨', 'there is no harm in; might as well'],
    ['不敢当', 'lit. I dare not (accept the honor); fig. I don\'t deserve your praise; you flatter me'],
    ['不禁', 'can\'t help (doing sth); can\'t refrain from'],
    ['不堪', 'cannot bear; cannot stand; utterly; extremely'],
    ['不可思议', 'inconceivable (idiom); unimaginable; unfathomable'],
    ['不免', 'unavoidable'],
    ['不时', 'frequently; often'],
    ['不惜', 'not stint; not spare; not hesitate (to do sth); not scruple (to do sth)'],
    ['不相上下', 'equally matched; about the same'],
    ['不言而喻', 'it goes without saying; it is self-evident'],
    ['不由得', 'can\'t help; cannot but'],
    ['不择手段', 'by fair means or foul; by hook or by crook; unscrupulously'],
    ['不止', 'incessantly; without end; more than; not limited to'],
    ['布告', 'posting on a bulletin board; notice; bulletin; to announce'],
    ['布局', 'arrangement; composition; layout; opening (chess jargon)'],
    ['布置', 'to put in order; to arrange; to decorate; to fix up; to deploy'],
    ['步伐', 'pace; (measured) step; march'],
    ['部署', 'to dispose; to deploy; deployment'],
    ['部位', 'position; place'],
    ['才干', 'ability; competence'],
    ['裁缝', 'tailor; dressmaker'],
    ['裁判', 'judgment; to referee; umpire; judge; referee; CL:个,位[wei4] ,名[ming2]'],
    ['裁员', 'to cut staff; to lay off employees'],
    ['财富', 'wealth; riches'],
    ['财务', 'financial affairs'],
    ['财政', 'finances (public); financial'],
    ['彩票', 'lottery ticket'],
    ['采购', 'to procure (for an enterprise etc); to purchase'],
    ['采集', 'to gather; to collect; to harvest'],
    ['采纳', 'to accept; to adopt (ideas)'],
    ['参谋', 'staff officer; to give advice'],
    ['参照', 'to consult a reference; to refer to (another document)'],
    ['残疾', 'disabled; handicapped; deformity on a person or animal'],
    ['残酷', 'cruel; ruthless; cruelty'],
    ['残留', 'to remain; left over; surplus; remnant'],
    ['残忍', 'merciless; ruthless; cruel; mean'],
    ['灿烂', 'to glitter; brilliant; splendid'],
    ['仓促', 'all of a sudden; hurriedly'],
    ['仓库', 'depot; storehouse; warehouse'],
    ['舱', 'cabin; the hold of a ship or airplane'],
    ['苍白', 'pale; wan'],
    ['操劳', 'to work hard; to look after'],
    ['操练', 'drill; practice'],
    ['操纵', 'to operate; to control; to rig; to manipulate'],
    ['操作', 'to work; to operate; to manipulate'],
    ['嘈杂', 'noisy; clamorous'],
    ['草案', 'draft (legislation, proposal etc)'],
    ['草率', 'careless; negligent; sloppy; not serious'],
    ['侧面', 'lateral side; side; aspect; profile'],
    ['测量', 'to survey; to measure; to gauge; to determine'],
    ['策划', 'to plot; to scheme; to bring about; to engineer; planning; producer; planner'],
    ['策略', 'tactics; to be tactful'],
    ['层出不穷', 'more and more emerge; innumerable succession; breeding like flies (idiom)'],
    ['层次', 'arrangement of ideas; administrative level; level; stage; phase'],
    ['差别', 'difference; distinction; diversity; disparity'],
    ['插座', 'socket; outlet;'],
    ['查获', 'to investigate and capture (a criminal); to ferret out; to hunt down and arrest'],
    ['刹那', 'an instant (Sanskrit: ksana); split second; the twinkling of an eye'],
    ['岔', 'fork in road; bifurcation; branch in road, river, mountain range etc; to branch off; to turn off; to diverge; to stray (from the path); to change the subject; to interrupt; to stagger (times)'],
    ['诧异', 'flabbergasted; astonished'],
    ['柴油', 'diesel fuel'],
    ['搀', 'to assist by the arm; to mix; to mix; to support; to sustain'],
    ['缠绕', 'twisting; to twine; to wind; to pester; to bother'],
    ['馋', 'gluttonous; greedy; to have a craving'],
    ['产业', 'industry; estate; property; industrial'],
    ['阐述', 'to expound (a position); to elaborate (on a topic); to treat (a subject)'],
    ['颤抖', 'to shudder; to shiver; to shake; to tremble'],
    ['昌盛', 'prosperous'],
    ['偿还', 'to repay; to reimburse'],
    ['尝试', 'to try; to attempt; CL:次[ci4]'],
    ['场合', 'situation; occasion'],
    ['场面', 'scene; occasion'],
    ['场所', 'location; place'],
    ['敞开', 'wide open; to open up'],
    ['倡导', 'to advocate; to initiate; to propose; to be a proponent of (an idea or school of thought)'],
    ['倡议', 'to suggest; to initiate; proposal; initiative'],
    ['畅通', 'unimpeded; free-flowing; straight path; unclogged; move without obstruction'],
    ['畅销', 'best seller; chart-topping'],
    ['超越', 'to surpass; to exceed; to transcend'],
    ['钞票', 'paper money; a bill (e.g. 100 yuan); CL:张[zhang1] ,扎[za1]'],
    ['嘲笑', 'jeer; mockery; scoff; sneer; make fun of; laugh at (derogatory)'],
    ['巢穴', 'lair; nest; den; hideout;'],
    ['朝代', 'dynasty; reign (of a king)'],
    ['潮流', 'tide; current; trend'],
    ['撤退', 'to withdraw; to pull out; to retreat'],
    ['撤销', 'to repeal; to revoke; to undo (computing)'],
    ['沉淀', 'to settle; to precipitate (solid sediment out of a solution)'],
    ['沉闷', 'oppressive (of weather); heavy; depressed; not happy'],
    ['沉思', 'contemplate; contemplation; meditation; ponder'],
    ['沉重', 'heavy; hard; serious; critical'],
    ['沉着', 'steady; calm and collected; not nervous'],
    ['陈旧', 'old-fashioned'],
    ['陈列', 'to display; to exhibit'],
    ['陈述', 'an assertion; to declare; to state'],
    ['称心如意', 'after one\'s heart (idiom); gratifying and satisfactory; everything one could wish'],
    ['衬托', 'to set off;'],
    ['称号', 'name; term of address; title'],
    ['乘', 'to ride; to mount; to make use of; to avail oneself of; to take advantage of; to multiply (mathematics); Buddhist sect or creed'],
    ['呈现', 'to appear; to emerge; to present (a certain appearance); demonstrate'],
    ['城堡', 'castle; rook (chess piece)'],
    ['惩罚', 'punishment; penalty; to punish'],
    ['成本', '(manufacturing, production etc) costs'],
    ['成交', 'to complete a contract; to reach a deal'],
    ['成天', '(coll.) all day long; all the time'],
    ['成效', 'effect; result'],
    ['成心', 'intentional; deliberate; on purpose'],
    ['成员', 'member'],
    ['承办', 'to undertake; to accept a contract'],
    ['承包', 'to contract; to undertake (a job)'],
    ['承诺', 'to promise; to undertake to do something; commitment'],
    ['橙', 'orange (fruit, color)'],
    ['澄清', 'clear (of liquid); limpid; to clarify; to make sth clear; to be clear (about the facts)'],
    ['盛', 'to hold; to contain; to ladle; pick up with a utensil'],
    ['诚挚', 'sincere; cordial'],
    ['秤', 'steelyard; Roman balance; CL:台[tai2]'],
    ['吃苦', 'to bear; hardships'],
    ['吃力', 'entail strenuous effort; be a strain'],
    ['持久', 'lasting; enduring; persistent; permanent; protracted; endurance; persistence; to last long'],
    ['迟钝', 'slow in one\'s reactions; sluggish (in movement or thought);'],
    ['迟缓', 'slow; sluggish'],
    ['迟疑', 'to hesitate'],
    ['赤道', 'equator (of the earth or astronomical body)'],
    ['赤字', '(financial) deficit; red letter'],
    ['充当', 'to serve as; to act as; to play the role of'],
    ['充沛', 'abundant; plentiful; vigorous'],
    ['充实', 'rich; substantial; enrich; substantiate'],
    ['充足', 'adequate; sufficient; abundant'],
    ['冲动', 'impetus; impulse; emotional impulse; impulsive'],
    ['冲击', 'an attack; under attack; a shock'],
    ['冲突', 'conflict; to conflict; clash of opposing forces; collision (of interests); contention'],
    ['崇拜', 'to worship; adoration'],
    ['崇高', 'majestic; sublime'],
    ['崇敬', 'to revere; high esteem'],
    ['重叠', 'to overlap; to superimpose; to telescope; to run together; to duplicate; one over another; superposition; an overlap; redundancy; reduplication (in Chinese grammar, e.g. 散散步[san4 san4 bu4] to have a stroll)'],
    ['稠密', 'dense'],
    ['筹备', 'preparations; to get ready for sth'],
    ['丑恶', 'ugly; repulsive'],
    ['出路', 'a way out (of a difficulty etc)'],
    ['出卖', 'to offer for sale; to sell; to sell out or betray'],
    ['出身', 'family background; class origin'],
    ['出神', 'entranced; Trance (music genre)'],
    ['出息', 'promise; prospects; future'],
    ['初步', 'initial; preliminary; tentative'],
    ['除', 'to get rid of; to remove; to exclude; to eliminate; to wipe out; to divide; except; not including'],
    ['储备', 'to store up; reserves'],
    ['储存', 'stockpile; to store; to stockpile; storage'],
    ['储蓄', 'to deposit money; to save; savings'],
    ['处分', 'to discipline sb; to punish; disciplinary action; to deal with (a matter); CL:个'],
    ['处境', 'plight; unfavorable situation'],
    ['处置', 'to handle; to take care of; to punish'],
    ['触犯', 'to offend; to violate; to go against'],
    ['川流不息', 'the stream flows without stopping (idiom); unending flow'],
    ['穿越', 'to pass through; to cross; to overcome'],
    ['传达', 'to pass on; to convey; to relay; to transmit; transmission'],
    ['传单', 'leaflet; flier; pamphlet'],
    ['传授', 'to impart; to pass on; to teach'],
    ['船舶', 'shipping; boats'],
    ['喘气', 'to breathe deeply; to pant'],
    ['串', 'to string together; to mix up; to conspire; to rove; bunch or cluster; string (computing); classifier for rows or strings'],
    ['床单', 'sheet (bed); CL:条[tiao2] ,件[jian4] ,张[zhang1] ,床[chuang2]'],
    ['创立', 'to found; to establish; originate'],
    ['创新', 'innovation; to bring forth new ideas; to blaze new trails'],
    ['创业', 'to begin an undertaking; to start a major task; to initiate; to venture; venture'],
    ['创作', 'to create; to produce; to write; creative work; creation; CL:个'],
    ['吹牛', 'to brag; to chat (dialect)'],
    ['吹捧', 'to flatter; to laud sb\'s accomplishments; adulation'],
    ['炊烟', 'smoke from kitchen chimneys;'],
    ['垂直', 'perpendicular; vertical'],
    ['锤', 'hammer; to hammer into shape; weight (e.g. of a steelyard or balance)'],
    ['纯粹', 'purely'],
    ['纯洁', 'pure; clean and honest; to purify'],
    ['慈善', 'benevolent; charitable;'],
    ['慈祥', 'kindly; benevolent (often of older person)'],
    ['磁带', 'magnetic tape; CL:盘[pan2] ,盒[he2]'],
    ['雌雄', 'male and female'],
    ['伺候', 'to serve; to act as a valet'],
    ['刺', 'thorn; splinter; to stab; to pierce; to prick'],
    ['次品', 'substandard products; defective; seconds'],
    ['次序', 'sequence; order'],
    ['丛', 'cluster; collection; collection of books; thicket'],
    ['从容', 'to go easy; unhurried; calm; Taiwan pr. [cong1 rong2];'],
    ['凑合', 'to bring together; to make do in a bad situation; to just get by; to improvise; passable; not too bad'],
    ['粗鲁', 'crude; coarse; rough'],
    ['窜', 'to flee; to scuttle; to exile or banish; to amend or edit'],
    ['摧残', 'to ravage; to ruin'],
    ['脆弱', 'weak; frail'],
    ['搓', 'to rub or roll between the hands or fingers; to twist'],
    ['磋商', 'to consult; to discuss seriously; to negotiate; to confer; negotiations; consultations'],
    ['挫折', 'setback; reverse; check; defeat; frustration; disappointment; to frustrate; to discourage; to set sb back; to blunt; to subdue'],
    ['搭', 'to put up; to build (scaffolding); to hang (clothes on a pole); to connect; to join; to arrange in pairs; to match; to add; to throw in (resources); to take (boat, train)'],
    ['搭档', 'to cooperate; partner'],
    ['搭配', 'to pair up; to match; to arrange in pairs; to add sth into a group'],
    ['答辩', 'to reply (to an accusation)'],
    ['答复', 'to answer; to reply; Reply to: (in email header)'],
    ['达成', 'to reach (an agreement); to accomplish'],
    ['打包', 'to wrap; to pack; to ask for a doggy bag (at a restaurant)'],
    ['打官司', 'to file a lawsuit; to sue; to dispute'],
    ['打击', 'to hit; to strike; to attack; to crack down on sth; a setback; a blow; percussion (music)'],
    ['打架', 'to fight; to scuffle; to come to blows; CL:场[chang2]'],
    ['打量', 'to size sb up; to take measure of; to suppose; to reckon'],
    ['打猎', 'to go hunting'],
    ['打仗', 'to fight a battle; to go to war'],
    ['大不了', 'at worst; if worst comes to worst; serious; alarming'],
    ['大臣', 'minister (of a monarchy)'],
    ['大伙儿', 'everybody; everyone; we all'],
    ['大肆', 'wantonly; without restraint (of enemy or malefactor); unbridled'],
    ['大体', 'in general; more or less; in rough terms; basically; on the whole'],
    ['大意', 'general idea; main idea'],
    ['大致', 'more or less; roughly; approximately'],
    ['歹徒', 'evil-doer; malefactor; gangster; hoodlum'],
    ['代价', 'price; cost; consideration (in share dealing)'],
    ['代理', 'to act on behalf of sb in a responsible position; to act as an agent or proxy; surrogate'],
    ['带领', 'to guide; to lead'],
    ['怠慢', 'to slight; to neglect'],
    ['逮捕', 'to arrest; to apprehend; an arrest'],
    ['担保', 'to guarantee; to vouch for'],
    ['胆怯', 'timid; cowardly'],
    ['淡季', 'off season; slow business season; (see also 旺季)'],
    ['淡水', 'potable water (water with low salt content); fresh water'],
    ['蛋白质', 'protein'],
    ['诞辰', 'birthday'],
    ['诞生', 'to be born'],
    ['当场', 'at the scene; on the spot'],
    ['当初', 'at that time; originally'],
    ['当代', 'the present age; the contemporary era'],
    ['当面', 'to sb\'s face; in sb\'s presence'],
    ['当前', 'current; today\'s; modern; present; to be facing (us)'],
    ['当事人', 'persons involved or implicated; party (to an affair)'],
    ['当务之急', 'top priority job; matter of vital importance'],
    ['当选', 'be elected'],
    ['党', 'party; association; club; society; surname Dang; CL:个'],
    ['档案', 'file; record; archive; Taiwan pr. dang3 an4'],
    ['档次', 'grade; class; quality; level'],
    ['倒闭', 'to go bankrupt; to close down'],
    ['导弹', 'guided missile; cruise missile; missile; CL:枚[mei2]'],
    ['导航', 'navigation'],
    ['导向', 'to be oriented towards; orientation'],
    ['捣乱', 'to disturb; to look for trouble; to stir up a row; to bother sb intentionally'],
    ['盗窃', 'to steal'],
    ['稻谷', 'rice crops'],
    ['得不偿失', '(saying) the gains do not make up for the losses'],
    ['得力', 'able; capable; competent; efficient'],
    ['得天独厚', '(of an area) rich in resources; (of a person) gifted or able (idiom)'],
    ['得罪', 'to commit an offense; to violate the law; excuse me! (formal); see also 得得罪[de2 zui5]'],
    ['灯笼', 'lantern'],
    ['登录', 'to register; to login'],
    ['登陆', 'to land; to come ashore; to make landfall (of typhoon etc); to log in'],
    ['蹬', 'to tread on; to step on'],
    ['等候', 'waiting'],
    ['等级', 'degree; rate'],
    ['瞪', 'to open (one\'s eyes) wide; to stare at; to glare at'],
    ['堤坝', 'dam;'],
    ['敌视', 'hostile; malevolence; antagonism; to view as enemy; to stand against'],
    ['抵达', 'arrive; reach (a destination)'],
    ['抵抗', 'to resist; resistance'],
    ['抵制', 'to resist; to boycott; to refuse (to cooperate); to reject; resistance; refusal'],
    ['地步', 'condition; situation; plight; extent; room for action'],
    ['地势', 'terrain; topography relief'],
    ['地质', 'geology'],
    ['递增', 'to increase by degrees; in increasing order; incremental; progressive'],
    ['颠簸', 'to shake; to jolt; to bump'],
    ['颠倒', 'to turn upside-down; to reverse; back-to-front; confused; deranged; crazy'],
    ['典礼', 'celebration; ceremony'],
    ['典型', 'model; typical case; archetype; typical; representative'],
    ['点缀', 'to decorate; an ornament; to adorn; only for show'],
    ['垫', 'pad; cushion; mat'],
    ['奠定', 'to establish; to fix; to settle'],
    ['惦记', 'remember with concern; worry about'],
    ['电源', 'electric power source'],
    ['叼', 'to hold in the mouth'],
    ['雕刻', 'to carve; to engrave; carving'],
    ['雕塑', 'a statue; a Buddhist image; sculpture; to carve'],
    ['吊', 'to suspend; to hang up; to hang a person'],
    ['调动', 'to transfer; to maneuver (troops etc)'],
    ['跌', 'to drop; to fall; to tumble; Taiwan pr. die2'],
    ['丁', 'fourth of 10 heavenly stems 十天干; man; members of a family'],
    ['叮嘱', 'to warn repeatedly; to urge; to exhort again and again'],
    ['盯', 'to watch attentively; to fix attention on; to stare; to gaze; to follow; to shadow sb'],
    ['定期', 'regularly; at regular intervals'],
    ['定义', 'definition'],
    ['丢人', 'to lose face'],
    ['丢三落四', 'forgetful; scatterbrained'],
    ['东道主', 'host; official host (e.g. venue for games or a conference)'],
    ['东张西望', 'to look in all directions (idiom); to glance around'],
    ['董事长', 'chairman of the board; chairman'],
    ['冻结', 'to freeze (loan, wage, price etc)'],
    ['动荡', 'unrest (social or political); turmoil; upheaval; commotion'],
    ['动机', 'motor; locomotive; motive; motivation; intention'],
    ['动静', 'sound of activity or people talking; news of activity'],
    ['动力', 'power; motion; propulsion; force'],
    ['动脉', 'artery'],
    ['动身', 'go on a journey; leave'],
    ['动手', 'to hit with hands or fists; to start work; to touch'],
    ['动态', 'development; trend; dynamic state; movement; moving'],
    ['动员', 'mobilize; arouse; CL:次[ci4] ,个'],
    ['栋', 'classifier for houses or buildings; ridgepole (old)'],
    ['兜', 'pocket; bag; to wrap up or hold in a bag; to move in a circle; to canvas or solicit; to take responsibility for; to disclose in detail; combat armor (old)'],
    ['陡峭', 'precipitous'],
    ['斗争', 'a struggle; fight; battle'],
    ['督促', 'to urge sb to complete a task'],
    ['毒品', 'drugs; narcotics; poison'],
    ['独裁', 'dictatorship'],
    ['堵塞', 'to block; to stop; blockage'],
    ['赌博', 'to gamble'],
    ['杜绝', 'put an end to'],
    ['端', 'end; extremity; item; port; to hold sth level with both hands; to carry; regular'],
    ['端午节', 'The Dragon Boat Festival (the 5th day of the 5th lunar month)'],
    ['端正', 'upright; regular; proper; correct'],
    ['短促', 'short in time; fleeting; brief'],
    ['断定', 'to conclude; to determine; to come to a judgment'],
    ['断绝', 'to sever; to break off'],
    ['堆积', 'to pile up; to heap; accumulation'],
    ['兑现', '(of a cheque etc) to cash; to honor a commitment'],
    ['对策', 'countermeasure for dealing with a situation'],
    ['对称', 'symmetry; symmetrical'],
    ['对付', 'to handle; to deal with; to cope; to get by with'],
    ['对抗', 'to withstand; to resist; to stand off; antagonism; confrontation'],
    ['对立', 'to oppose; to set sth against; to be antagonistic to; antithetical; relative opposite; opposing; diametrical'],
    ['对联', 'rhyming couplet; pair of lines of verse written vertically down the sides of a doorway; CL:幅[fu2]'],
    ['对应', 'to correspond; a correspondence; corresponding; homologous; matching with sth; counterpart'],
    ['对照', 'to contrast; to compare; to place side by side for comparison (as parallel texts); to check'],
    ['队伍', 'ranks; troops; CL:个'],
    ['顿时', 'at once; immediately; forthwith'],
    ['哆嗦', 'to tremble; to shiver; uncontrolled shaking of the body'],
    ['多元化', 'diversification; pluralism; to diversify'],
    ['堕落', 'to morally degenerate; to become depraved; a fall from grace; a fall into sin or depravity'],
    ['额外', 'extra; added; additional'],
    ['恶心', 'nausea; to feel sick; disgust; nauseating'],
    ['恶化', 'worsen'],
    ['遏制', 'to check; to contain; to hold back; to keep within limits; to constrain; to restrain'],
    ['恩怨', '(feeling of) resentment; (longstanding) grudge'],
    ['而已', 'that\'s all; nothing more'],
    ['二氧化碳', 'carbon dioxide; CO2'],
    ['发布', 'to release; to issue; to announce; to distribute; also written 發发布[fa1 bu4]'],
    ['发财', 'to get rich'],
    ['发呆', 'to daydream; lost in thought'],
    ['发动', 'to start; to launch; to unleash; to mobilize; to arouse'],
    ['发觉', 'to find; to detect; to discover'],
    ['发射', 'to shoot (a projectile); to fire (a rocket); to launch; to emit (a particle); to discharge; emanation; emission'],
    ['发誓', 'to vow; to pledge; to swear'],
    ['发行', 'to publish; to issue (stocks, currency etc); to release; to distribute (a film)'],
    ['发炎', 'inflamed from infection or injury; inflammation'],
    ['发扬', 'to develop; to make full use of'],
    ['发育', 'to develop; growth; development'],
    ['法人', 'legal person; corporation; see also 自然自然人[zi4 ran2 ren2]'],
    ['番', 'to take turns; order in series; time; a kind of; barbarians'],
    ['凡是', 'every; all'],
    ['繁华', 'flourishing; bustling'],
    ['繁忙', 'busy; bustling'],
    ['繁体字', 'traditional Chinese character'],
    ['繁殖', 'to breed; to reproduce; to propagate'],
    ['反驳', 'to retort; to refute'],
    ['反常', 'unusual; abnormal'],
    ['反感', 'to be disgusted with; to dislike; bad reaction; antipathy'],
    ['反抗', 'to resist; to rebel'],
    ['反馈', 'to send back information; feedback'],
    ['反面', 'reverse side of sth; opposite side of some topic; the other side'],
    ['反射', 'to reflect; reflection (from a mirror etc); reflex (i.e. automatic reaction of organism)'],
    ['反思', 'to think back over sth; to review; to revisit; to rethink; reflection; reassessment'],
    ['反问', 'to ask (a question) in reply; to answer a question with a question; rhetorical question'],
    ['反之', 'on the other hand...; conversely...'],
    ['泛滥', 'to be in flood; to overflow (the banks); to inundate; to spread unchecked'],
    ['范畴', 'category'],
    ['贩卖', 'to sell; to peddle; to traffic'],
    ['方位', 'direction; points of the compass; bearing; position; azimuth'],
    ['方言', 'dialect'],
    ['方圆', 'circumference;'],
    ['方针', 'policy; guidelines; CL:个'],
    ['防守', 'defend; protect (against)'],
    ['防御', 'defense'],
    ['防止', 'to prevent; to guard against; to take precautions'],
    ['防治', 'prevention and cure'],
    ['纺织', 'spinning and weaving'],
    ['访问', 'to visit; to call on; to interview; CL:次[ci4]'],
    ['放大', 'to enlarge; to magnify'],
    ['放射', 'to radiate; radioactive'],
    ['非法', 'illegal'],
    ['飞禽走兽', 'birds and animals; the beasts of the field and the birds of the air'],
    ['飞翔', 'fly'],
    ['飞跃', 'to leap'],
    ['肥沃', 'fertile'],
    ['诽谤', 'slander; libel'],
    ['废除', 'to abolish; to abrogate; to repeal'],
    ['废寝忘食', 'to neglect sleep and forget about food (idiom); to skip one\'s sleep and meals; to be completely wrapped up in one\'s work;'],
    ['废墟', 'ruins'],
    ['沸腾', 'boiling; ebullition'],
    ['肺', 'lung; CL:个'],
    ['分辨', 'to distinguish; to differentiate; to resolve; to defend against an accusation; to exculpate'],
    ['分寸', 'propriety; appropriate behavior; proper speech or action; within the norms'],
    ['分红', 'a bonus; to award a bonus'],
    ['分解', 'to resolve; to decompose; to break down'],
    ['分裂', 'to split up; to divide; to break up; fission'],
    ['分泌', 'secrete'],
    ['分明', 'clearly demarcated; sharply contoured'],
    ['分歧', 'difference (of opinion, position); bifurcation'],
    ['分散', 'scatter; disperse; distribute'],
    ['吩咐', 'to tell; to instruct; to command'],
    ['坟墓', 'sepulcher; tomb'],
    ['粉末', 'fine powder; dust'],
    ['粉色', 'white; light pink; erotic; beautiful woman; powdered (with make-up)'],
    ['粉碎', 'crash; break up'],
    ['分量', 'weight; measure'],
    ['愤怒', 'angry; indignant'],
    ['丰满', 'plentiful; rich; plump; well-rounded'],
    ['丰盛', 'rich; sumptuous'],
    ['丰收', 'bumper harvest'],
    ['封闭', 'to seal; to close; to confine; to seal off; to close down; sealed; confined; closed; unreceptive'],
    ['封建', 'system of enfeoffment; feudalism; feudal; feudalistic'],
    ['封锁', 'to blockade; to seal off'],
    ['锋利', 'sharp (e.g. knife blade); incisive; to the point'],
    ['风暴', 'storm; violent commotion; fig. crisis (e.g. revolution, uprising, financial crisis etc)'],
    ['风度', 'elegance (for men); elegant demeanor; grace; poise'],
    ['风光', 'scene; view; sight; landscape; to be well-regarded; to be well-off; grand (topolect); impressive (topolect)'],
    ['风气', 'general mood; atmosphere; common practice'],
    ['风趣', 'humor; wit; humorous; witty'],
    ['风土人情', 'local conditions and customs (idiom)'],
    ['风味', 'local flavor; local style'],
    ['逢', 'to meet by chance; to come across; to fawn upon'],
    ['奉献', 'to consecrate; to dedicate; to devote'],
    ['否决', 'veto; to overrule; to veto; to reject'],
    ['夫妇', 'a (married) couple; husband and wife; CL:对[dui4]'],
    ['夫人', 'lady; madam; Mrs.; CL:位[wei4]'],
    ['敷衍', 'to elaborate (on a theme); to expound (the classics); perfunctory; to skimp; to botch; to do sth half-heartedly or just for show; barely enough to get by'],
    ['俘虏', 'captive'],
    ['幅度', 'width; extent; range; scope'],
    ['服从', 'to obey (an order); to comply; to defer'],
    ['服气', 'to be convinced; to accept'],
    ['福利', '(material) welfare; well-being'],
    ['福气', 'good fortune; to enjoy good fortune'],
    ['符号', 'symbol; mark; sign'],
    ['辐射', 'radiation'],
    ['俯视', 'to look down at; to overlook'],
    ['抚摸', 'to gently caress and stroke; to pet; to fondle;'],
    ['抚养', 'to foster; to bring up; to raise'],
    ['腐败', 'corruption; corrupt; rotten'],
    ['腐烂', 'to rot; to become gangrenous'],
    ['腐蚀', 'corrosion; to corrode (degrade chemically); to rot; corruption'],
    ['腐朽', 'rotten; decayed; decadent; degenerate'],
    ['辅助', 'to assist; to aid; supplementary; auxiliary; subsidiary'],
    ['副', 'secondary; auxiliary; deputy; assistant; vice-; abbr. for 副副词 adverb; classifier for pairs'],
    ['复活', 'to bring back to life; to revive; resurrection'],
    ['复兴', 'to revive; rebirth'],
    ['富裕', 'abundant; affluent; richness; affluence'],
    ['腹泻', 'diarrhea; to have the runs'],
    ['覆盖', 'to cover'],
    ['负担', 'burden; to bear a burden'],
    ['赋予', 'to assign; to entrust (a task); to give; to bestow'],
    ['附和', 'to parrot; to crib; to copy sb\'s action or words; to trail sb\'s footsteps; copy-cat'],
    ['附件', 'enclosure; attachment (email); appendix'],
    ['附属', 'subsidiary; auxiliary; attached; affiliated; subordinate; subordinating'],
    ['改良', 'improve'],
    ['盖章', 'to affix a seal (to sth)'],
    ['钙', 'calcium (chemistry);'],
    ['尴尬', 'awkward; embarrassed'],
    ['干旱', 'drought; arid; dry'],
    ['干扰', 'to interfere; obstruction'],
    ['干涉', 'to interfere; to meddle; interference'],
    ['干预', 'to meddle; to intervene; intervention'],
    ['感慨', 'lament; with a tinge of emotion or regret'],
    ['感染', 'infection; to infect; to influence'],
    ['干劲', 'enthusiasm for doing sth'],
    ['扛', 'to raise aloft with both hands'],
    ['纲领', 'program; guiding principle'],
    ['岗位', 'a post; a job'],
    ['港口', 'port; harbor'],
    ['港湾', 'natural harbor; bay serving as harbor'],
    ['杠杆', 'lever; pry bar; crowbar; financial leverage'],
    ['高超', 'excellent; superlative'],
    ['高潮', 'high tide; high water; upsurge; climax; orgasm; chorus (part of a song)'],
    ['高峰', 'peak; summit; height'],
    ['高明', 'wise; brilliant; superior'],
    ['高尚', 'nobly; lofty'],
    ['高涨', 'upsurge; (tensions etc) run high'],
    ['稿件', 'rough draft; material contributing to a final document'],
    ['告辞', 'to take leave'],
    ['告诫', 'to warn; to admonish'],
    ['割', 'to cut; to cut apart'],
    ['搁', 'to place; to put aside; to shelve'],
    ['歌颂', 'sing the praises of; extol; eulogize'],
    ['疙瘩', 'swelling or lump on skin; pimple; knot; preoccupation; problem'],
    ['鸽子', 'pigeon; dove; various birds of the species Columbidae'],
    ['格局', 'structure; pattern; layout'],
    ['格式', 'form; specification; format'],
    ['隔阂', 'estrangement'],
    ['隔离', 'to separate; to isolate'],
    ['革命', 'revolution; revolutionary (politics); CL:次[ci4]'],
    ['个体', 'individual'],
    ['各抒己见', 'everyone gives their own view'],
    ['根深蒂固', 'deep-rooted (problem etc)'],
    ['根源', 'origin; root (cause)'],
    ['跟前', 'in front of; close to; nearby; the time just before'],
    ['跟随', 'to follow'],
    ['跟踪', 'to follow sb\'s tracks; to tail; to shadow'],
    ['更新', 'to replace the old with new; to renew; to renovate; to upgrade; to update; to regenerate'],
    ['更正', 'to correct; to make a correction'],
    ['耕地', 'arable land; to plow land'],
    ['供不应求', 'supply does not meet demand'],
    ['供给', 'to furnish; to provide; supply (as in supply and demand)'],
    ['公安局', 'Public Security Bureau'],
    ['公道', 'justice; fairness; public highway'],
    ['公告', 'post; announcement'],
    ['公关', 'public relations'],
    ['公民', 'citizen'],
    ['公然', 'openly; publicly; undisguised'],
    ['公认', 'publicly known (to be); accepted (as)'],
    ['公式', 'formula'],
    ['公务', 'official business'],
    ['公正', 'just; fair; equitable'],
    ['公证', 'notarization; notarized; acknowledgement'],
    ['功劳', 'contribution; meritorious service; credit'],
    ['功效', 'efficacy'],
    ['宫殿', 'palace; CL:座[zuo4]'],
    ['工艺品', 'handicraft article; handiwork; CL:个'],
    ['恭敬', 'deferential; with respect'],
    ['攻击', 'to attack; to accuse; to charge; an attack (terrorist or military)'],
    ['攻克', 'to capture; to take'],
    ['巩固', 'to consolidate; consolidation; to strengthen'],
    ['共和国', 'republic'],
    ['共计', 'to sum up to; to total'],
    ['共鸣', 'physical resonance; sympathetic response to sth'],
    ['勾结', 'to collude with; to collaborate with; to gang up with'],
    ['钩子', 'hook'],
    ['构思', 'to outline a story; to make a preliminary sketch'],
    ['姑且', 'temporarily; the time being; for the moment; provisional; tentatively'],
    ['孤独', 'lonely; solitary'],
    ['孤立', 'isolate; isolated'],
    ['辜负', 'to fail to live up (to expectations); unworthy (of trust); to let down; to betray (hopes); to disappoint'],
    ['古董', 'curio; antique'],
    ['古怪', 'eccentric; grotesque; oddly; queer'],
    ['股东', 'shareholder; stockholder'],
    ['股份', 'a share (in a company); stock'],
    ['骨干', 'diaphysis (long segment of a bone); fig. backbone'],
    ['鼓动', 'to agitate; to arouse; to instigate; to encite'],
    ['固然', 'admittedly (it\'s true that...)'],
    ['固体', 'solid'],
    ['固有', 'intrinsic to sth; inherent; native'],
    ['固执', 'persistent; stubborn'],
    ['故乡', 'home; homeland; native place; CL:个'],
    ['故障', 'malfunction; breakdown; defect; shortcoming; fault; failure; impediment; error; bug (in software)'],
    ['雇佣', 'to employ; to hire'],
    ['顾虑', 'misgivings; apprehensions'],
    ['顾问', 'adviser; consultant'],
    ['拐杖', 'crutches; crutch; walking stick'],
    ['关怀', 'care; solicitude; to show care for; concerned about; attentive to'],
    ['关照', 'to take care; to keep an eye on; to look after; to tell; to remind'],
    ['官方', 'official; (by the) government'],
    ['观光', 'to tour; sightseeing; tourism'],
    ['管辖', 'to administer; to have jurisdiction (over)'],
    ['惯例', 'conventional'],
    ['灌溉', 'to irrigate'],
    ['罐', 'can; jar; pot'],
    ['贯彻', 'to implement; to put into practice; to carry out'],
    ['光彩', 'luster; splendor; radiance; brilliance'],
    ['光辉', 'radiance; glory; brilliant; magnificent'],
    ['光芒', 'rays of light; brilliant rays; radiance'],
    ['光荣', 'honor and glory; CL:个'],
    ['广阔', 'wide; vast'],
    ['归根到底', '(saying) to sum it up...'],
    ['归还', 'to return sth; to revert'],
    ['规范', 'norm; standard; specification; regulation; rule; within the rules; to fix rules; to regulate; to specify'],
    ['规格', 'standard; norm; specification'],
    ['规划', 'plan; program'],
    ['规章', 'rule; regulation'],
    ['轨道', 'orbit; railway or tram line; fig. conventional way of thinking'],
    ['贵族', 'lord; nobility; nobleman; noblewoman; aristocrat; aristocracy'],
    ['跪', 'kneel'],
    ['棍棒', 'club'],
    ['国防', 'national defense'],
    ['国务院', 'State Council (PRC); State Department (USA)'],
    ['果断', 'firm; decisive'],
    ['过度', 'excessive; over-; excess; going too far; extravagant; intemperate; overdue'],
    ['过渡', 'to cross over (by ferry); transition; interim; caretaker (administration)'],
    ['过奖', 'to over-praise; to flatter'],
    ['过滤', 'to filter; filter'],
    ['过失', 'defect; fault'],
    ['过问', 'to show an interest in; to get involved with'],
    ['过瘾', 'to satisfy a craving; to get a kick out of sth; gratifying; immensely enjoyable; satisfying; fulfilling'],
    ['过于', 'too much; excessively'],
    ['嗨', 'oh alas; hey!; hi! (loanword)'],
    ['海拔', 'height above sea level; elevation'],
    ['海滨', 'shore; seaside'],
    ['含糊', 'ambiguous; vague'],
    ['含义', 'meaning (implicit in a phrase); implied meaning; hidden meaning; hint; connotation'],
    ['寒暄', 'exchanging conventional greetings; to talk about the weather'],
    ['罕见', 'rare; rarely seen'],
    ['捍卫', 'defend; uphold; safeguard'],
    ['航空', 'aviation'],
    ['航天', 'space flight'],
    ['航行', 'to sail; to fly; to navigate'],
    ['行列', 'procession'],
    ['毫米', 'millimeter'],
    ['毫无', 'not in the least; none whatsoever; completely without'],
    ['豪迈', 'bold; open-minded; heroic'],
    ['号召', 'to call; to appeal'],
    ['耗费', 'to waste; to spend; to consume; to squander'],
    ['呵', 'to scold in a loud voice; to yawn'],
    ['合并', 'to merge; to annex'],
    ['合成', 'to compose; to constitute; compound; synthesis; mixture; synthetic'],
    ['合伙', 'to act jointly; to form a partnership'],
    ['合算', 'worthwhile; be a good deal; be a bargain; reckon up; calculate'],
    ['和蔼', 'kindly; nice; amiable'],
    ['和解', 'to settle (a dispute out of court); to reconcile; settlement; conciliation; to become reconciled'],
    ['和睦', 'peaceful relations; harmonious'],
    ['和气', 'friendly; polite; amiable'],
    ['和谐', 'harmonious'],
    ['嘿', 'hey'],
    ['痕迹', 'vestige; mark; trace'],
    ['狠心', 'callous; heartless'],
    ['恨不得', 'wishing one could do sth; to hate to be unable; itching to do sth; can\'t wait for; to wish one could do sth; to desire strongly'],
    ['哼', 'to groan; to snort; to hum; to croon; humph!'],
    ['横', 'horizontal; across; (horizontal character stroke)'],
    ['哄', 'roar of laughter (onomatopoeia); hubbub; to roar (as a crowd)'],
    ['烘', 'to bake; to heat by fire'],
    ['轰动', 'sensation; stir'],
    ['宏观', 'macro-; macroscopic; holistic'],
    ['宏伟', 'grand; imposing; magnificent'],
    ['洪水', 'deluge; flood'],
    ['喉咙', 'throat; larynx'],
    ['吼', 'roar or howl of an animal; bellow of rage'],
    ['候选', 'candidate'],
    ['后代', 'posterity; later periods; later ages; later generations'],
    ['后顾之忧', 'fears of trouble in the rear (idiom); family worries (obstructing freedom of action); worries about the future consequences; often in negative expressions, meaning \'no worries about anything\''],
    ['后勤', 'logistics'],
    ['呼唤', 'to call out (a name etc); to shout;'],
    ['呼啸', 'to whistle; to scream; to whiz'],
    ['呼吁', 'to call on (sb to do sth); to appeal (to); an appeal'],
    ['忽略', 'to neglect; to overlook; to ignore'],
    ['湖泊', 'lake'],
    ['胡乱', 'careless; reckless; casually; absent-mindedly; at will; at random; any old how'],
    ['胡须', 'beard; CL:根[gen1] ,绺[liu3]'],
    ['花瓣', 'petal; CL:片[pian4];'],
    ['花蕾', 'bud; flower bud;'],
    ['华丽', 'gorgeous'],
    ['华侨', 'overseas Chinese; CL:个,位[wei4]'],
    ['划分', 'to divide'],
    ['化肥', 'fertilizer'],
    ['化石', 'fossil'],
    ['化验', 'laboratory test; chemical experiment; assay'],
    ['化妆', 'to put on make-up'],
    ['画蛇添足', 'lit. draw legs on a snake (idiom); fig. to ruin the effect by adding sth superfluous; to overdo it'],
    ['话筒', 'microphone'],
    ['欢乐', 'gaiety; gladness; glee; merriment; pleasure; happy; joyous; gay'],
    ['环节', 'round segment; segment (of annelid worms); connection; link; sector; annular ring'],
    ['还原', 'to restore to the original state; reduction (chemistry)'],
    ['缓和', 'to ease (tension); to alleviate; to moderate; to allay; to make more mild'],
    ['患者', 'patient; sufferer'],
    ['荒凉', 'desolate'],
    ['荒谬', 'absurd; ridiculous'],
    ['荒唐', 'beyond belief; preposterous; absurd; intemperate; dissipated'],
    ['皇帝', 'emperor; CL:个'],
    ['皇后', 'empress; imperial consort'],
    ['黄昏', 'dusk; evening; nightfall'],
    ['恍然大悟', 'to suddenly realize; to suddenly see the light'],
    ['晃', 'to sway; to shake; to wander about;'],
    ['挥霍', 'to squander money; extravagant; prodigal; free and easy; agile'],
    ['辉煌', 'splendid; glorious'],
    ['回报', '(in) return; reciprocation; payback; retaliation; to report back; to reciprocate'],
    ['回避', 'to evade; to shun; to avoid'],
    ['回顾', 'to look back; to review'],
    ['回收', 'to recycle; to reclaim; to retrieve; to recover'],
    ['悔恨', 'remorse; repentance'],
    ['毁灭', 'perish; ruin; destroy'],
    ['会晤', 'to meet; meeting; conference'],
    ['汇报', 'to report; to give an account of; to collect information and report back'],
    ['贿赂', 'to bribe; a bribe'],
    ['昏迷', 'to lose consciousness; to be in a coma; stupor; coma; stunned; disoriented'],
    ['荤', 'meat, fish; strong-smelling vegetable (garlic etc); non-vegetarian food (meat, fish, spicy food etc); vulgar; obscene;'],
    ['浑身', 'all over; from head to foot'],
    ['混合', 'to mix; to blend'],
    ['混乱', 'confusion; chaos; disorder'],
    ['混淆', 'to obscure; to confuse; to mix up; to blur; to mislead'],
    ['混浊', 'turbid; muddy; dirty'],
    ['活该', '(coll.) serve sb right; deservedly; ought; should'],
    ['活力', 'energy; vitality; vigor; vital force'],
    ['火箭', 'rocket; CL:枚[mei2]'],
    ['火焰', 'blaze; flame'],
    ['火药', 'gunpowder'],
    ['货币', 'currency; monetary; money'],
    ['基地', 'base (of operations); industrial or military base; al-Qaeda'],
    ['基金', 'fund'],
    ['基因', 'gene (loanword)'],
    ['机动', 'locomotive; motorized; power-driven; adaptable; flexible (use, treatment, timing etc)'],
    ['机构', 'mechanism; structure; organization; agency; institution; CL:所[suo3]'],
    ['机灵', 'clever; quick-witted'],
    ['机密', 'secret; classified (information)'],
    ['机械', 'machine; machinery; mechanical'],
    ['机遇', 'opportunity; favorable circumstance; stroke of luck'],
    ['机智', 'quick-witted; tact; witty; resourceful'],
    ['激发', 'to arouse; to excite'],
    ['激励', 'to encourage; to urge; motivation; incentive'],
    ['激情', 'passion; fervor; enthusiasm; strong emotion'],
    ['讥笑', 'to sneer'],
    ['饥饿', 'hunger; starvation; famine'],
    ['即便', 'even if; even though; right away; immediately'],
    ['即将', 'on the eve of; to be about to; to be on the verge of'],
    ['及早', 'at the earliest possible time; as soon as possible'],
    ['吉祥', 'lucky; auspicious; propitious'],
    ['嫉妒', 'to be jealous; to envy; to hate'],
    ['急功近利', 'seeking instant benefit (idiom); shortsighted vision, looking only for fast return'],
    ['急剧', 'rapid; sudden'],
    ['急切', 'eager; impatient'],
    ['急于求成', 'anxious for quick results (idiom); to demand instant success; impatient for result; impetuous'],
    ['急躁', 'irritable; irascible; impetuous'],
    ['极端', 'extreme'],
    ['极限', 'limit; extreme boundary'],
    ['疾病', 'disease; sickness; ailment'],
    ['籍贯', 'one\'s native place; place of ancestry; registered birthplace'],
    ['级别', '(military) rank; level; grade'],
    ['集团', 'group; bloc; corporation; conglomerate'],
    ['给予', 'to accord; to give; to show (respect)'],
    ['季军', 'third in a race; bronze medalist'],
    ['季度', 'quarter (finance, publishing, schools etc); season (sports); period of three months'],
    ['寂静', 'quiet'],
    ['寄托', 'to have sb look after sb; to entrust the care of sb; to place (hope etc) on'],
    ['忌讳', 'taboo; to avoid as taboo; to abstain from'],
    ['技巧', 'skill; technique'],
    ['纪要', 'minutes; written summary of a meeting'],
    ['继承', 'to inherit; to carry on; to succeed'],
    ['计较', 'to haggle; to bicker; to argue'],
    ['记性', 'memory; recall'],
    ['记载', 'write down; record; written account'],
    ['迹象', 'mark; indication; sign; indicator'],
    ['佳肴', 'fine food; delicacies; delicious food'],
    ['加工', 'to process; processing; working (of machinery)'],
    ['加剧', 'to intensify; to sharpen; to accelerate; to aggravate; to exacerbate; to embitter'],
    ['夹杂', 'to mix together (disparate substances); to mingle; a mix; to be tangled up with'],
    ['家常', 'the daily life of a family'],
    ['家伙', 'household dish, implement or furniture; domestic animal; guy (slang); chap'],
    ['家属', 'family member; (family) dependent'],
    ['家喻户晓', 'understood by everyone (idiom); well known; a household name'],
    ['坚定', 'firm; steady; staunch; resolute'],
    ['坚固', 'firm; firmly; hard; stable'],
    ['坚韧', 'tough and durable; tenacious'],
    ['坚实', 'firm and substantial; solid'],
    ['坚硬', 'hard; solid'],
    ['尖端', 'sharp pointed end; the tip; the cusp; tip-top; most advanced and sophisticated; highest peak; the best'],
    ['尖锐', 'sharp; intense; penetrating; pointed; acute (illness)'],
    ['煎', 'to pan fry; to sauté'],
    ['监督', 'to control; to supervise; to inspect'],
    ['监视', 'to monitor; to oversee; to keep a close watch over; to spy on'],
    ['监狱', 'prison'],
    ['艰难', 'difficult; hard; challenging'],
    ['剪彩', 'to cut the ribbon (at a launching or opening ceremony)'],
    ['拣', 'to choose; to pick; to sort out; to pick up'],
    ['检讨', 'to examine or inspect; self-criticism; review'],
    ['检验', 'to inspect; to examine; to test'],
    ['简化', 'simplify'],
    ['简陋', 'simple and crude (of a room or building)'],
    ['简体字', 'simplified Chinese character, as opposed to traditional Chinese character 繁体字[fan2 ti3 zi4]'],
    ['简要', 'concise; brief'],
    ['健全', 'robust; strong'],
    ['剑', 'double-edged sword; CL:口[kou3],把[ba3]; classifier for blows of a sword;'],
    ['溅', 'splash'],
    ['舰艇', 'warship; naval vessel'],
    ['见多识广', 'experienced and knowledgeable (idiom)'],
    ['见解', 'opinion; view; understanding'],
    ['见闻', 'what one sees and hears; knowledge; information'],
    ['见义勇为', 'to see what is right and act courageously (idiom, from Analects); to stand up bravely for the truth; acting heroically in a just cause'],
    ['践踏', 'to trample'],
    ['鉴别', 'to differentiate; to distinguish'],
    ['鉴定', 'to appraise; to identify; to evaluate'],
    ['鉴于', 'in view of; seeing that; considering; whereas'],
    ['间谍', 'spy'],
    ['间隔', 'compartment; gap; interval; to divide'],
    ['间接', 'indirect'],
    ['僵硬', 'stark; stiff'],
    ['将军', '(Military) admiral; (chess) to check; to embarrass'],
    ['将近', 'almost; nearly; close to'],
    ['将就', 'to accept (a bit reluctantly); to put up with;'],
    ['奖励', 'reward (as encouragement)'],
    ['奖赏', 'reward; prize; an award'],
    ['桨', 'oar; paddle'],
    ['降临', 'to descend to'],
    ['交叉', 'to cross; to intersect'],
    ['交代', 'to hand over; to explain; to make clear; to brief (sb); to account for; to justify oneself; to confess; to finish (colloquial)'],
    ['交涉', 'to negotiate; relating to'],
    ['交易', '(business) transaction; business deal; CL:笔[bi3]'],
    ['娇气', 'delicate; squeamish; finicky'],
    ['焦点', 'focus; focal point'],
    ['焦急', 'anxiety; anxious'],
    ['侥幸', 'luckily; by a fluke;'],
    ['搅拌', 'to stir; to agitate'],
    ['缴纳', 'to pay (taxes etc)'],
    ['角落', 'nook; corner'],
    ['教养', 'to train; to educate; to bring up; to nurture; education; culture; upbringing; early conditioning'],
    ['较量', 'to have a contest with sb; to cross swords; to measure up against; to compete with; to haggle; to quibble'],
    ['接连', 'on end; in a row; in succession'],
    ['揭露', 'to expose; to unmask; to ferret out; to disclose; disclosure'],
    ['皆', 'all; each and every; in all cases'],
    ['阶层', 'hierarchy; stratum'],
    ['截止', 'to stop; to close; to put a stop to sth; cut-off point; stopping point; deadline;'],
    ['截至', 'up to (a time); by (a time)'],
    ['杰出', 'outstanding; distinguished; remarkable; prominent; illustrious'],
    ['竭尽全力', 'to spare no effort (idiom); to do one\'s utmost'],
    ['结晶', 'crystallization; crystalline; crystal'],
    ['结局', 'conclusion; ending'],
    ['结算', 'to settle a bill; to close an account'],
    ['节制', 'to control; to restrict; to moderate; to temper; moderation; sobriety; to administer;'],
    ['节奏', 'rhythm; tempo; musical pulse; cadence; beat'],
    ['解除', 'to remove; to sack; to get rid of; to relieve (sb of their duties); to free; to lift (an embargo); to rescind (an agreement)'],
    ['解放', 'to liberate; to emancipate; liberation; refers to the Communists\' victory over the Nationalists in 1949; CL:次[ci4]'],
    ['解雇', 'to fire; to sack; to dismiss'],
    ['解剖', 'to dissect (an animal); to analyze; anatomy'],
    ['解散', 'dissolve; disband'],
    ['解体', 'to break up into components; to disintegrate; to collapse; to crumble'],
    ['借鉴', 'to use other people\'s experience; to borrow from a source; to use as reference'],
    ['借助', 'to draw support from; with the help of'],
    ['戒备', 'to take precautions; to guard against (emergency)'],
    ['界限', 'boundary; marginal'],
    ['津津有味', 'with keen interest pleasure (idiom); with gusto; to relish; eagerly; with great interest'],
    ['金融', 'banking; finance; financial'],
    ['紧迫', 'pressing; urgent'],
    ['锦上添花', 'lit. on brocade, add flowers (idiom); to decorate sth already perfect; gilding the lily;'],
    ['晋升', 'to promote to a higher position'],
    ['浸泡', 'to steep; to soak; to immerse'],
    ['近来', 'recently; lately'],
    ['进而', 'and then (what follows next)'],
    ['进攻', 'to attack; to assault; to go on the offensive; attack; assault; offense (sports)'],
    ['进化', 'evolution; CL:个'],
    ['进展', 'to make headway; to make progress'],
    ['兢兢业业', 'cautious and conscientious'],
    ['惊动', 'alarm; alert; disturb'],
    ['惊奇', 'to be amazed; to be surprised; to wonder'],
    ['惊讶', 'amazed; astonished; to surprise; amazing; astonishment; awe'],
    ['精打细算', '(saying) meticulous planning and careful accounting'],
    ['精华', 'best feature; most important part of an object; quintessence; essence; soul'],
    ['精简', 'to simplify; to reduce'],
    ['精密', 'accuracy; exact; precise; refined'],
    ['精确', 'accurate; precise'],
    ['精通', 'proficient'],
    ['精心', 'with utmost care; fine; meticulous; detailed'],
    ['精益求精', 'to perfect sth that is already outstanding (idiom); constantly improving'],
    ['精致', 'delicate; fine; exquisite; refined'],
    ['经费', 'funds; expenditure; CL:笔[bi3]'],
    ['经纬', 'warp and woof; longitude and latitude; main points'],
    ['茎', 'stalk; stem; CL:条[tiao2]'],
    ['井', 'warn; well; surname Jing; CL:口[kou3]'],
    ['警告', 'to warn; to admonish'],
    ['警惕', 'to be on the alert; vigilant; alert; on guard; to warn'],
    ['颈椎', 'cervical vertebra; the seven cervical vertebrae in the neck of humans and most mammals'],
    ['境界', 'boundary; state; realm'],
    ['敬礼', 'salute'],
    ['敬业', 'to be dedicated to one\'s work; to respect one\'s work;'],
    ['竞赛', 'race; competition; CL:个'],
    ['竞选', 'to take part in an election; to run for office'],
    ['镜头', 'camera shot (in a movie etc); scene'],
    ['纠纷', 'a dispute; entanglement (law)'],
    ['纠正', 'to correct; to make right'],
    ['酒精', 'alcohol; ethanol CH3CH2OH; ethyl alcohol; also written 乙醇; grain alcohol'],
    ['就近', 'nearby; in a close neighborhood'],
    ['就业', 'looking for employment; getting a job; to start a career'],
    ['就职', 'take office; assume a post'],
    ['救济', 'emergency relief; to help the needy with cash or goods'],
    ['居民', 'resident; inhabitant;'],
    ['居住', 'to reside; to dwell; to live in a place; resident in'],
    ['拘留', 'to detain (a prisoner); to keep sb in custody'],
    ['拘束', 'to restrict; to restrain; constrained; awkward; ill at ease; uncomfortable; reticent'],
    ['鞠躬', 'to bow'],
    ['局部', 'part; local'],
    ['局面', 'aspect; phase; situation'],
    ['局势', 'situation; state (of affairs)'],
    ['局限', 'limit'],
    ['举动', 'act; action; activity; move; movement'],
    ['举世瞩目', 'attract worldwide attention'],
    ['举足轻重', 'a foot\'s move sways the balance (idiom); to hold the balance of power; to play the decisive role'],
    ['咀嚼', 'to chew'],
    ['沮丧', 'dispirited; dejected; dismayed'],
    ['剧本', 'script for play, opera, movie etc; screenplay'],
    ['剧烈', 'violent; acute; severe; fierce'],
    ['据悉', 'according to reports; it is reported (that)'],
    ['聚精会神', 'to concentrate one\'s attention (idiom)'],
    ['卷', 'to roll up'],
    ['倔强', 'stubborn; obstinate; unbending;'],
    ['决策', 'strategic decision; decision-making; policy decision; to determine policy'],
    ['绝望', 'desperation; forlorn; hopeless'],
    ['觉悟', 'consciousness; awareness; Buddhist enlightenment (Sanskrit: cittotpāda)'],
    ['觉醒', 'to awaken; to come to realize; awakened to the truth; the truth dawns upon one; scales fall from the eyes; to become aware'],
    ['军队', 'army troops; CL:支[zhi1] ,个'],
    ['君子', 'nobleman; person of noble character;'],
    ['卡通', 'cartoon'],
    ['开采', 'to extract (ore or other resource from a mine); to exploit; to mine'],
    ['开除', 'to expel'],
    ['开阔', 'wide; open (spaces); to open up'],
    ['开朗', 'spacious and well-lit; open and clear; to open out (onto a wider vista); optimistic; cheerful; carefree; easy-going; open-minded'],
    ['开明', 'enlightened; open-minded; enlightenment'],
    ['开辟', 'to open up; to set up; to start; to build'],
    ['开拓', 'to break new ground (for agriculture); to open up (a new seam); to develop (border regions); fig. to open up (new horizons)'],
    ['开展', '(begin to) develop; unfold; to start; to launch; to open; to carry out'],
    ['开支', 'expenditures; pay; expenses; CL:笔[bi3]'],
    ['刊登', 'to carry a story; to publish (in a newspaper or magazine)'],
    ['刊物', 'publication'],
    ['勘探', 'exploration'],
    ['侃侃而谈', 'to speak frankly with assurance;'],
    ['砍伐', 'to hew; to cut down'],
    ['看待', 'to look upon; to regard'],
    ['慷慨', 'vehement; fervent; generous; giving; liberal'],
    ['抗议', 'protest'],
    ['考察', 'to inspect; to observe and study; on-the-spot investigation'],
    ['考古', 'archaeology'],
    ['考核', 'to examine; to check up on; to assess; to review; appraisal; review; evaluation'],
    ['考验', 'to test; to put to the test'],
    ['靠拢', 'to draw close to'],
    ['磕', 'to tap; knock'],
    ['科目', 'subject'],
    ['可观', 'considerable'],
    ['可口', 'tasty; to taste good'],
    ['可恶', 'repulsive; vile; hateful; abominable'],
    ['可行', 'feasible'],
    ['渴望', 'to thirst for; to long for'],
    ['克制', 'to restrain; to control; restraint; self-control;'],
    ['刻不容缓', 'to brook no delay; to demand immediate action'],
    ['客户', 'client; customer'],
    ['课题', 'task; problem; issue'],
    ['啃', 'to gnaw; to nibble; to bite'],
    ['恳切', 'earnest; sincere'],
    ['坑', 'pit; to defraud'],
    ['空洞', 'cavity; empty; vacuous'],
    ['空前绝后', 'unprecedented and never to be duplicated; the first and the last; unmatched; unique'],
    ['空想', 'daydream; fantasy; to fantasize'],
    ['空虚', 'hollow; emptiness; meaningless'],
    ['孔', 'aperture; opening; hole'],
    ['恐怖', 'terrible; frightful; frightening; terror; terrorist'],
    ['恐吓', 'to threaten; to menace'],
    ['恐惧', 'fear; dread; phobia'],
    ['空白', 'blank space'],
    ['空隙', 'crack; gap between two objects; gap in time between two events'],
    ['口气', 'tone of voice; the way one speaks; manner of expression; tone'],
    ['口腔', 'oral cavity'],
    ['口头', 'oral; verbal'],
    ['口音', 'accent'],
    ['扣', 'to fasten; to button; button; buckle; knot; to arrest; to confiscate; to deduct (money); discount; to knock; to smash, spike or dunk (a ball); to cover (with a bowl etc); fig. to tag a label on sb;'],
    ['哭泣', 'to weep;'],
    ['枯萎', 'to wilt; to wither; wilted; withered; drained; enervated; exhausted;'],
    ['枯燥', 'dry and dull; uninteresting; dry-as-dust'],
    ['苦尽甘来', 'bitterness finishes, sweetness begins (idiom); the hard times are over, the good times just beginning'],
    ['苦涩', 'bitter and astringent; pained; agonized;'],
    ['挎', 'to carry (esp. slung over the arm, shoulder or side)'],
    ['跨', 'to step across; to stride over; to straddle; to span'],
    ['快活', 'happy; cheerful'],
    ['宽敞', 'spacious; wide'],
    ['宽容', 'lenient; tolerant; indulgent; charitable; to forgive;'],
    ['款待', 'to entertain; to treat cordially'],
    ['款式', 'pattern; style; design; CL:种[zhong3]'],
    ['筐', 'basket; CL:只[zhi1]'],
    ['况且', 'moreover; besides; in addition; furthermore'],
    ['旷课', 'to play truant; to cut classes'],
    ['框架', 'frame; framework; fig. pattern; outline; organizing plan'],
    ['矿产', 'minerals;'],
    ['亏待', 'to treat sb unfairly'],
    ['亏损', 'deficit; (financial) loss'],
    ['捆绑', 'to bind'],
    ['扩充', 'to expand'],
    ['扩散', 'to spread; to proliferate; to diffuse; spread; proliferation; diffusion'],
    ['扩张', 'expansion; dilation; to expand (e.g. one\'s power or influence); to broaden'],
    ['啦', 'a phrase-final particle'],
    ['喇叭', 'horn (automobile, alarm siren, musical etc) which produces a sound; loudspeaker; brass wind instrument; trumpet; suona 鎖锁呐[suo3 na4]'],
    ['蜡烛', 'candle; CL:根[gen1] ,支[zhi1]'],
    ['来历', 'history; antecedents; origin'],
    ['来源', 'source (of information etc); origin'],
    ['栏目', 'a column (in a newspaper or on a news website)'],
    ['懒惰', 'idle; lazy'],
    ['狼狈', 'in a difficult situation; to cut a sorry figure; scoundrel! (derogatory)'],
    ['狼吞虎咽', 'to wolf down one\'s food (idiom); to devour ravenously; to gorge oneself;'],
    ['捞', 'to fish up; to dredge up'],
    ['唠叨', 'to prattle; to chatter; talkative; garrulous; to nag'],
    ['牢固', 'firm; secure'],
    ['牢骚', 'discontent; complaint'],
    ['乐趣', 'delight; pleasure; joy'],
    ['乐意', 'to be willing to do sth; to be ready to do sth; to be happy to do sth; content; satisfied'],
    ['雷达', 'radar (loanword)'],
    ['类似', 'similar; analogous'],
    ['冷酷', 'grim; unfeeling; callous'],
    ['冷落', 'desolate; unfrequented; to treat sb coldly; to snub; to cold shoulder;'],
    ['冷却', 'to cool off; cooling'],
    ['愣', 'to look distracted; to stare blankly; distracted; blank; (coll.) unexpectedly'],
    ['黎明', 'dawn; daybreak'],
    ['理睬', 'to heed; to pay attention to'],
    ['理所当然', 'as it should be by rights (idiom); proper and to be expected as a matter of course; inevitable and right'],
    ['理直气壮', 'in the right and self-confident (idiom); bold and confident with justice on one\'s side; to have the courage of one\'s convictions; just and forceful'],
    ['理智', 'reason; intellect; rationality'],
    ['礼节', 'etiquette'],
    ['礼尚往来', 'lit. proper behavior is based on reciprocity (idiom); fig. to return politeness for politeness;'],
    ['里程碑', 'milestone'],
    ['例外', '(make an) exception'],
    ['利害', 'pros and cons; advantages and disadvantages; gains and losses'],
    ['力求', 'to make every effort to; striving to do one\'s best;'],
    ['力所能及', 'as far as one\'s capabilities extend (idiom); to the best of one\'s ability; within one\'s powers'],
    ['力争', 'to work hard for; to do all one can; to contend strongly'],
    ['历代', 'successive generations; successive dynasties; past dynasties'],
    ['历来', 'always; throughout (a period of time); (of) all-time'],
    ['立场', 'position; standpoint; CL:个'],
    ['立方', 'cube'],
    ['立交桥', 'overpass; flyover'],
    ['立体', 'three-dimensional; solid; stereoscopic'],
    ['立足', 'to stand; to have a footing; to be established; to base oneself on'],
    ['粒', 'grain; granule; classifier for small round things (peas, bullets, peanuts, pills, grains etc)'],
    ['廉洁', 'honest; not coercive; honesty; integrity; incorruptible'],
    ['联欢', 'have a get-together'],
    ['联络', 'communication; to get in touch with; to contact; connection (math.)'],
    ['联盟', 'alliance; union; coalition'],
    ['联想', 'to associate with something in thinking; abbr. for 聯想集联想集团[Lian2 xiang3 Ji2 tuan2]'],
    ['连年', 'successive years; over many years'],
    ['连锁', 'chain'],
    ['连同', 'together with; along with'],
    ['良心', 'conscience'],
    ['晾', 'to dry in the air'],
    ['谅解', '(reach) an understanding'],
    ['辽阔', 'vast; extensive'],
    ['列举', 'a list; to list; to enumerate'],
    ['临床', 'clinical'],
    ['淋', 'to drain; to drench; to drip; diseases of the bladder'],
    ['吝啬', 'stingy; mean; miserly'],
    ['伶俐', 'clever; witty; intelligent'],
    ['凌晨', 'early in the morning'],
    ['灵感', 'inspiration; insight; a burst of creativity in scientific or artistic endeavor'],
    ['灵魂', 'soul; spirit'],
    ['灵敏', 'smart; clever; sensitive; keen; quick; sharp'],
    ['零星', 'fragmentary; random; bits and pieces; sporadic'],
    ['领会', 'to understand; to comprehend; to grasp'],
    ['领事馆', 'consulate'],
    ['领土', 'territory'],
    ['领悟', 'to understand; to comprehend'],
    ['领先', 'to lead; to be in front'],
    ['领袖', 'leader; CL:个,位[wei4] ,名[ming2]'],
    ['溜', 'to slip away; to escape in stealth; to skate'],
    ['流浪', 'to drift about; to wander; to roam; nomadic; homeless; unsettled (e.g. population); vagrant'],
    ['流露', 'to express; to reveal (one\'s thoughts or feelings)'],
    ['流氓', 'rogue; hoodlum; gangster; immoral behavior'],
    ['流通', 'to circulate'],
    ['留恋', 'reluctant to leave; to hate to have to go; to recall fondly'],
    ['留念', 'to keep as a souvenir; to recall fondly'],
    ['留神', 'to take care; to be careful'],
    ['聋哑', 'deaf and dumb; deaf-mute; surdimutism'],
    ['隆重', 'grand; prosperous; ceremonious; solemn'],
    ['垄断', 'enjoy market dominance; monopolize'],
    ['笼罩', 'to envelop; to shroud'],
    ['搂', 'to hold or embrace in one\'s arms'],
    ['炉灶', 'stove'],
    ['轮船', 'steamship; CL:艘[sou1]'],
    ['轮廓', 'an outline; silhouette'],
    ['轮胎', 'tire; pneumatic tire'],
    ['论坛', 'forum (for discussion)'],
    ['论证', 'to prove a point; proof; to expound on; demonstrate or prove (through argument)'],
    ['啰唆', 'to grumble'],
    ['络绎不绝', 'continuously; in an endless stream (idiom)'],
    ['落成', 'to complete a construction project'],
    ['落实', 'practical; workable; to implement; to carry out; to decide'],
    ['屡次', 'repeatedly; time and again'],
    ['履行', 'to fulfill (one\'s obligations); to carry out (a task); to implement (an agreement); to perform'],
    ['掠夺', 'to plunder; to rob; also written 略略夺'],
    ['嘛', '(modal particle to express that something is obvious)'],
    ['麻痹', 'paralysis; palsy; numbness; to benumb'],
    ['麻木', 'numb; insensitive; apathetic'],
    ['麻醉', 'anesthesia; fig. to poison (sb\'s mind)'],
    ['码头', 'dock; pier; wharf; CL:个'],
    ['蚂蚁', 'ant;'],
    ['埋伏', 'ambush'],
    ['埋没', 'oblivion'],
    ['埋葬', 'to bury'],
    ['脉搏', 'a pulse (both medical and figurative)'],
    ['迈', 'take a step'],
    ['埋怨', 'to complain'],
    ['慢性', 'slow and patient; chronic (disease); slow to take effect (e.g. a slow poison)'],
    ['漫长', 'very long; endless'],
    ['漫画', 'caricature; cartoon; Japanese manga'],
    ['蔓延', 'to extend; to spread'],
    ['忙碌', 'busy; bustling'],
    ['盲目', 'blind; aimless'],
    ['茫茫', 'boundless; vast and obscure'],
    ['茫然', 'ignorant; to have no knowledge of sth'],
    ['冒充', 'to feign; to pretend to be; to pass oneself off as'],
    ['冒犯', 'to offend;'],
    ['茂盛', 'lush'],
    ['媒介', 'media; medium'],
    ['枚', 'classifier for coins, rings, badges, pearls, sporting medals, rockets, satellites etc'],
    ['美观', 'pleasing to the eye; beautiful; artistic'],
    ['美满', 'happy; blissful'],
    ['美妙', 'beautiful; wonderful; splendid'],
    ['萌芽', 'sprout; germ of a plant'],
    ['猛烈', 'fierce; violent (criticism etc)'],
    ['眯', 'to narrow one\'s eyes; (Dialect) to nap; to take a nap'],
    ['弥补', 'to complement; to make up for a deficiency'],
    ['弥漫', 'to pervade; to fill the air; diffuse; everywhere present; about to inundate (water); permeated by (smoke); filled with (dust); to saturate (the air with fog, smoke etc)'],
    ['谜语', 'riddle; conundrum'],
    ['迷惑', 'to puzzle; to confuse; to baffle'],
    ['迷人', 'fascinating; enchanting; charming; tempting'],
    ['迷信', 'superstition'],
    ['密度', 'density; thickness'],
    ['密封', 'seal up'],
    ['棉花', 'cotton'],
    ['免得', 'so as not to; so as to avoid'],
    ['免疫', 'immunity (to disease)'],
    ['勉励', 'to encourage'],
    ['勉强', 'to do with difficulty; to force sb to do sth; reluctant; barely enough'],
    ['面貌', 'appearance; face; features; CL:个'],
    ['面子', 'outer surface; outside; honor; reputation; face (as in \'losing face\'); self-respect; feelings; (medicinal) powder'],
    ['描绘', 'to describe; to portray'],
    ['瞄准', 'to aim (a weapon at a target); fig. to aim (for a higher standard);'],
    ['渺小', 'minute; tiny; negligible; insignificant'],
    ['藐视', 'to despise; to look down on;'],
    ['灭亡', 'to be destroyed; to become extinct; to perish; to die out; to destroy; to exterminate'],
    ['蔑视', 'to loathe; to despise; contempt'],
    ['民间', 'among the people; popular; folk; non-governmental; involving people rather than governments'],
    ['民主', 'democracy'],
    ['敏捷', 'nimble; quick; shrewd'],
    ['敏锐', 'keen; sharp; acute'],
    ['名次', 'position in a ranking of names'],
    ['名额', 'fixed number of people; quota'],
    ['名副其实', 'not just in name only, but also in reality (idiom)'],
    ['名誉', 'fame; reputation; honor; honorary; emeritus (of retired professor)'],
    ['明明', 'obviously; plainly; undoubtedly; definitely'],
    ['明智', 'sensible; wise; judicious; sagacious;'],
    ['命名', 'to give a name to; to dub; to christen; to designate; named after; naming'],
    ['摸索', 'to feel about; to grope about; to fumble; to do things slowly'],
    ['摩擦', 'friction; rubbing; chafing; fig. disharmony; conflict; same as 磨擦'],
    ['模范', 'model; fine example'],
    ['模式', 'mode; method'],
    ['模型', 'model; mould; matrix; pattern'],
    ['磨合', 'to break in; to wear in'],
    ['膜', 'membrane; film'],
    ['魔鬼', 'devil'],
    ['魔术', 'magic'],
    ['抹杀', 'to erase; to cover traces; to obliterate evidence; to expunge; to blot out; to suppress'],
    ['墨水儿', 'ink; CL:瓶[ping2]'],
    ['莫名其妙', 'unfathomable mystery (idiom); subtle and ineffable; unable to make head or tail of it; boring (e.g. movie)'],
    ['默默', 'in silence; not speaking'],
    ['谋求', 'to seek; to strive for'],
    ['模样', 'look; style; appearance; approximation; about; CL:个'],
    ['母语', 'native language; mother language'],
    ['沐浴', 'to take a bath; to bathe; to immerse'],
    ['目睹', 'to witness; to see at first hand; to see with one\'s own eyes'],
    ['目光', 'sight; vision; view; gaze; look'],
    ['拿手', 'expert in; good at'],
    ['纳闷儿', 'puzzled; bewildered'],
    ['耐用', 'durable'],
    ['南辕北辙', 'to act in a way that defeats one\'s purpose (idiom);'],
    ['难得', 'seldom; rare; hard to come by'],
    ['难堪', 'hard to take; embarrassed'],
    ['难能可贵', 'rare and commendable; estimable'],
    ['恼火', 'to get angry; irritated; to annoy; to aggravate; annoying'],
    ['内涵', 'meaning; content; essential properties implied or reflected by a notion; intention; connotation; self-possessed'],
    ['内幕', 'inside story; non-public information; behind the scenes; internal'],
    ['内在', 'intrinsic; innate'],
    ['能量', 'energy; capabilities'],
    ['拟定', 'to draw up; to draft; to formulate'],
    ['逆行', 'to go the wrong way; to go against one-way traffic regulation;'],
    ['年度', 'year (e.g. school year, fiscal year)'],
    ['捏', 'to pinch (with one\'s fingers); to knead; to make up'],
    ['凝固', 'to freeze; to solidify; to congeal; fig. with rapt attention'],
    ['凝聚', 'to condense; to coagulate; coacervation (i.e. form tiny droplets); aggregation; coherent'],
    ['凝视', 'gaze'],
    ['拧', 'to pinch; wring'],
    ['宁肯', 'would rather...; it would be better...; would prefer'],
    ['宁愿', 'would rather; better'],
    ['扭转', 'to reverse; to turn around (an undesirable situation)'],
    ['纽扣儿', 'button'],
    ['农历', 'the traditional Chinese calendar; the lunar calendar'],
    ['浓厚', 'dense; thick (fog, clouds etc); to have a strong interest in; deep; fully saturated (color)'],
    ['奴隶', 'slave'],
    ['挪', 'to shift; to move'],
    ['虐待', 'to mistreat; to maltreat; to abuse; mistreatment; maltreatment'],
    ['哦', 'oh; ah'],
    ['殴打', 'to beat up; to come to blows; battery (law)'],
    ['偶像', 'idol; false god'],
    ['呕吐', 'to vomit'],
    ['趴', 'to lie on one\'s stomach'],
    ['徘徊', 'to dither; to hesitate; to pace back and forth; by ext. to hover around; to linger'],
    ['排斥', 'to reject; to exclude; to eliminate; to remove; to repel'],
    ['排除', 'to eliminate; to get rid of; to remove'],
    ['排放', 'emission; discharge; exhaust (gas etc)'],
    ['排练', 'to rehearse; rehearsal;'],
    ['派别', 'denomination; group; school; faction; school of thought'],
    ['派遣', 'to send (on a mission); to dispatch'],
    ['攀登', 'to climb; to pull oneself up; to clamber; to scale; fig. to forge ahead in the face of hardships and danger'],
    ['盘旋', 'to spiral; to circle; to go around; to hover; to orbit'],
    ['判决', 'judgment (by a court of law)'],
    ['畔', 'bank; field-path'],
    ['庞大', 'huge; enormous; tremendous'],
    ['抛弃', 'discard; dump; abandon'],
    ['泡沫', 'foam; (soap) bubble; (economic) bubble'],
    ['培育', 'to train; to breed'],
    ['配备', 'to allocate; to provide; to outfit with'],
    ['配偶', 'consort; mate; spouse'],
    ['配套', 'to form a complete set; coherent'],
    ['盆地', 'basin (low-lying geographical feature); depression'],
    ['烹饪', 'cooking; culinary arts'],
    ['捧', 'to clasp; to cup the hands; to hold up with both hands; to offer (esp. in cupped hands); to praise; to flatter'],
    ['劈', 'to hack; to chop; to split open; see 噼劈啪, onomat. for crack, slap, clap, clatter etc'],
    ['批发', 'wholesale; bulk trade; distribution'],
    ['批判', 'criticize; CL:个'],
    ['疲惫', 'beaten; exhausted; tired'],
    ['疲倦', 'to tire; tired'],
    ['皮革', 'leather; CL:张[zhang1]'],
    ['屁股', 'buttocks; bottom; ass'],
    ['譬如', 'for example; for instance; such as'],
    ['偏差', 'bias; deviation'],
    ['偏见', 'prejudice'],
    ['偏僻', 'remote; desolate; far from the city'],
    ['偏偏', '(indicates that sth turns out just the opposite of what one would expect or what would be normal); unfortunately; against expectations'],
    ['片断', 'section; fragment; segment;'],
    ['片刻', 'short period of time; a moment'],
    ['漂浮', 'to float; to hover; to drift (also fig., to lead a wandering life); to rove; showy; superficial'],
    ['飘扬', 'wave; flutter; fly'],
    ['撇', 'to throw; to cast; left-slanting downward brush stroke (calligraphy);'],
    ['拼搏', 'to struggle; to wrestle'],
    ['拼命', 'to do one\'s utmost; with all one\'s might; at all costs; (to work or fight) as if one\'s life depends on it'],
    ['贫乏', 'lack; incomplete'],
    ['贫困', 'impoverished; poverty'],
    ['频繁', 'frequently; often'],
    ['频率', 'frequency'],
    ['品尝', 'to taste a small amount; to sample'],
    ['品德', 'moral character'],
    ['品质', 'quality'],
    ['品种', 'breed; variety; CL:个'],
    ['屏幕', 'screen (TV, computer or movie);'],
    ['屏障', 'protective screen'],
    ['平凡', 'commonplace; ordinary; mediocre'],
    ['平面', 'plane (flat surface); print media'],
    ['平坦', 'level; even; smooth; flat'],
    ['平行', 'parallel; of equal rank; simultaneous'],
    ['平庸', 'mediocre; indifferent; commonplace'],
    ['平原', 'field; plain; CL:个'],
    ['评估', 'to evaluate; to assess; assessment; evaluation'],
    ['评论', 'to comment on; to discuss; comment; commentary; CL:篇[pian1]'],
    ['坡', 'slope; CL:个'],
    ['泼', 'to splash; to spill; rough and coarse; brutish'],
    ['颇', 'considerably; rather; inclined to one side'],
    ['破例', 'to make an exception'],
    ['迫不及待', 'impatient (idiom); in a hurry; itching to get on with it'],
    ['迫害', 'to persecute; persecution'],
    ['魄力', 'courage; daring; boldness; resolution; drive'],
    ['扑', 'to assault; to pounce; to rush at sth; to throw oneself on'],
    ['铺', 'to spread; to extend; to pave; to lay'],
    ['普及', 'popular; to popularize; universal; ubiquitous; pervasive'],
    ['朴实', 'plain; simple; guileless; down-to-earth; sincere and honest'],
    ['朴素', 'plain and simple; unadorned; simple living; not frivolous'],
    ['瀑布', 'waterfall'],
    ['凄凉', 'desolate'],
    ['期望', 'hope; expectation'],
    ['期限', 'time limit; deadline; allotted time'],
    ['欺负', 'to bully'],
    ['欺骗', 'to deceive; to cheat'],
    ['奇妙', 'fantastic; wonderful'],
    ['旗袍', 'Chinese-style dress; cheongsam'],
    ['旗帜', 'ensign; flag'],
    ['歧视', 'to discriminate against; discrimination'],
    ['齐全', 'complete'],
    ['齐心协力', 'to work with a common purpose (idiom); to make concerted efforts; to pull together; to work as one'],
    ['乞丐', 'beggar'],
    ['企图', 'attempt; CL:种[zhong3]'],
    ['启程', 'to set out on a journey'],
    ['启蒙', 'to instruct the young; to initiate; to awake sb from ignorance; to free sb from prejudice or superstition; primer; enlightened; the Enlightenment; Western learning from the late Qing dynasty;'],
    ['启事', 'announcement (written, on billboard, letter, newspaper or website); to post information; a notice'],
    ['启示', 'enlightenment; revelation; apocalypse'],
    ['岂有此理', 'how can this be so? (idiom); preposterous; ridiculous; absurd'],
    ['起草', 'draft (a bill); draw up (plans)'],
    ['起初', 'originally; at first; at the outset'],
    ['起伏', 'to move up and down; to undulate; ups and downs'],
    ['起哄', 'to heckle; rowdy jeering; to create a disturbance'],
    ['起码', 'at the minimum; at the very least'],
    ['起源', 'origin; to originate; to come from'],
    ['器材', 'equipment; material'],
    ['器官', 'organ (part of body tissue); apparatus'],
    ['气概', 'lofty quality; mettle; spirit'],
    ['气功', 'qigong, a system of deep breathing exercises'],
    ['气魄', 'spirit; boldness; positive outlook; imposing attitude'],
    ['气色', 'complexion'],
    ['气势', 'momentum; manner; energy; look of great force or imposing manner; powerful'],
    ['气味', 'odor; scent'],
    ['气象', 'meteorological feature; CL:个; meteorology; atmosphere or scene'],
    ['气压', 'atmospheric pressure; barometric pressure'],
    ['气质', 'temperament; personality traits; manners'],
    ['迄今为止', 'so far; up to now; still (not)'],
    ['掐', 'to pick (flowers); to pinch; to clutch; (slang) to fight'],
    ['恰当', 'appropriate; suitable'],
    ['恰到好处', 'it\'s just perfect; it\'s just right'],
    ['恰巧', 'fortunately; unexpectedly; by coincidence'],
    ['洽谈', 'to discuss'],
    ['千方百计', 'lit. thousand ways, a hundred plans (idiom); by every possible means'],
    ['牵', 'to pull (an animal on a tether); to lead along; to hold hands'],
    ['牵扯', 'to involve; to implicate'],
    ['牵制', 'to control; to curb; to restrict; to impede; to pin down (enemy troops)'],
    ['签署', 'to sign (an agreement)'],
    ['谦逊', 'humble; humility; modesty'],
    ['迁就', 'to yield; to adapt to; to accommodate to (sth)'],
    ['迁徙', 'to migrate; to move'],
    ['前景', 'foreground; vista; (future) prospects; perspective'],
    ['前提', 'premise; precondition; prerequisite'],
    ['潜力', 'potential; capacity'],
    ['潜水', 'to dive; to go under water; lurker (Internet slang for sb who reads forum posts but never replies)'],
    ['潜移默化', 'imperceptible influence; to influence secretly'],
    ['谴责', 'to denounce; to condemn; to criticize; condemnation; criticism'],
    ['抢劫', 'to rob; looting'],
    ['强制', 'to enforce; enforcement; forcibly; compulsory'],
    ['强迫', 'to compel; to force'],
    ['抢救', 'rescue'],
    ['桥梁', 'bridge; fig. connection between two areas'],
    ['窍门', 'a trick; an ingenious method; know-how; the knack (of doing sth);'],
    ['翘', 'to raise'],
    ['切实', 'feasible; earnestly; conscientiously; realistic; practical'],
    ['锲而不舍', 'to chip away at a task and not abandon it (idiom); to chisel away at sth; to persevere; unflagging efforts'],
    ['亲密', 'intimate; close;'],
    ['亲热', 'affectionate; intimate; warm-hearted'],
    ['侵犯', 'to infringe on; to encroach on; to violate'],
    ['侵略', 'invasion; encroachment'],
    ['钦佩', 'to admire; to look up to; to respect sb greatly'],
    ['勤俭', 'hardworking and frugal'],
    ['勤劳', 'hardworking; industrious; diligent'],
    ['倾听', 'to listen attentively'],
    ['倾向', 'trend; tendency; orientation'],
    ['倾斜', 'to incline; to lean; to slant; to slope; to tilt'],
    ['清澈', 'clear; limpid'],
    ['清晨', 'early morning'],
    ['清除', 'eliminate; get rid of'],
    ['清洁', 'clean; purity'],
    ['清理', 'clear; to put in order; to check up'],
    ['清晰', 'clear; distinct'],
    ['清醒', 'clear-headed; sober; awake'],
    ['清真', 'Islamic; Muslim; halal (of food); clean; pure'],
    ['情报', '(spy) intelligence; information-gathering'],
    ['情节', 'plot; circumstances'],
    ['情理', 'reason; sense'],
    ['情形', 'circumstances; situation; CL:个'],
    ['晴朗', 'sunny and cloudless'],
    ['请柬', 'invitation card; written invitation'],
    ['请教', 'consult'],
    ['请示', 'ask for instructions'],
    ['请帖', 'invitation card; written invitation'],
    ['丘陵', 'hills'],
    ['区分', 'to differentiate; to find differing aspects'],
    ['区域', 'area; region; district'],
    ['屈服', 'to surrender; to yield'],
    ['曲折', 'complicated; winding'],
    ['驱逐', 'to expel; to deport; banishment'],
    ['渠道', 'irrigation ditch; medium or channel of communication'],
    ['取缔', 'to ban; to prohibit (publications, customs etc); to outlaw; to suppress (violators)'],
    ['曲子', 'poem for singing; tune; music; CL:支[zhi1]'],
    ['趣味', 'fun; interest; delight; taste; liking; preference'],
    ['圈套', 'trap; snare; trick'],
    ['全局', 'overall situation'],
    ['全力以赴', 'do at all costs; make an all-out effort'],
    ['拳头', 'fist; clenched fist; competitive (product); superior quality; CL: 个'],
    ['权衡', 'to weigh; to consider; to assess; to balance; to trade-off'],
    ['权威', 'authority; authoritative; power and prestige'],
    ['犬', 'dog'],
    ['缺口', 'nick; jag; gap; shortfall'],
    ['缺席', 'absence; absent'],
    ['缺陷', 'a defect; a flaw'],
    ['瘸', 'lame'],
    ['确保', 'to ensure; to guarantee'],
    ['确立', 'to establish; to institute'],
    ['确切', 'definite; exact; precise'],
    ['确信', 'to be convinced; to be sure; to firmly believe; to be positive that; definite news'],
    ['群众', 'mass; multitude; the masses'],
    ['染', 'to dye; to catch (a disease); to acquire (bad habits etc); to contaminate; to add colour washes to a painting'],
    ['嚷', 'blurt out; to shout'],
    ['让步', 'to concede; to give in; to yield; a concession'],
    ['饶恕', 'forgiveness; spare'],
    ['扰乱', 'to disturb; to perturb; to harass'],
    ['惹祸', 'stirring up trouble; to invite disaster'],
    ['热泪盈眶', 'eyes brimming with tears of excitement (idiom); extremely moved'],
    ['热门', 'popular; hot; in vogue'],
    ['人道', 'human sympathy; humanitarianism; humane; the \'human way\', one of the stages in the cycle of reincarnation (Buddhism); sexual intercourse'],
    ['人格', 'personality; integrity; dignity'],
    ['人工', 'artificial; manpower; manual work'],
    ['人家', 'other; other people; him(her, them); I'],
    ['人间', 'man\'s world; the world'],
    ['人士', 'person; figure; public figure'],
    ['人为', 'artificial; man-made; having human cause or origin; human attempt or effort'],
    ['人性', 'human nature; humanity; human; the totality of human attributes'],
    ['人质', 'hostage'],
    ['仁慈', 'benevolent; charitable; kind; kindly; kindness; merciful'],
    ['忍耐', 'to show restraint; to repress (anger etc); to exercise patience'],
    ['忍受', 'to bear; to endure'],
    ['任命', 'to appoint and nominate'],
    ['任性', 'willful; headstrong; uninhibited'],
    ['任意', 'arbitrary; at will; at random'],
    ['任重道远', 'a heavy load and a long road; fig. to bear heavy responsibilities through a long struggle (cf Confucian Analects, 8.7)'],
    ['认定', 'to maintain (that sth is true); to determine (a fact); determination (of an amount); of the firm opinion; to believe firmly; to set one\'s mind on; to identify with'],
    ['认可', 'to approve; approval; acknowledgment; OK'],
    ['仍旧', 'still (remaining); to remain (the same); yet'],
    ['日新月异', 'daily renewal, monthly change (idiom); every day sees new developments; rapid progress'],
    ['日益', 'day by day; more and more; increasingly; more and more with each passing day'],
    ['容貌', 'one\'s appearance; one\'s aspect; looks; features'],
    ['容纳', 'to hold; to contain; to accommodate; to tolerate (different opinions)'],
    ['容器', 'receptacle; vessel'],
    ['容忍', 'to put up with; to tolerate'],
    ['溶解', 'dissolve; solution'],
    ['荣幸', 'honored'],
    ['荣誉', 'honor; credit; glory; (honorable) reputation'],
    ['融化', 'to melt; to thaw; to dissolve; to blend into; to combine; to fuse'],
    ['融洽', 'harmonious; friendly relations; on good terms with one another'],
    ['揉', 'to knead; to massage; to rub'],
    ['柔和', 'gentle; soft'],
    ['儒家', 'Confucianism; Confucian school, founded by Confucius 孔子[Kong3 zi3] (551-479 BC) and Mencius 孟子[Meng4 zi3] (c. 372-c. 289 BC);'],
    ['弱点', 'weak point; failing'],
    ['若干', 'a certain number or amount; how many?; how much?'],
    ['撒谎', 'to tell lies'],
    ['散文', 'prose; essay'],
    ['散布', 'to disseminate;'],
    ['散发', 'distribute; emit; issue'],
    ['丧失', 'to lose; to forfeit'],
    ['骚扰', 'to disturb; to cause a commotion; to harass;'],
    ['嫂子', '(informal) older brother\'s wife; sister-in-law; CL:个'],
    ['刹车', 'to brake (when driving); to stop; to switch off; to check (bad habits); a brake'],
    ['啥', '(dialect) what'],
    ['筛选', 'to filter'],
    ['山脉', 'mountain range; CL:条[tiao2]'],
    ['闪烁', 'flicker; twinkling'],
    ['擅长', 'to be good at; to be expert in'],
    ['擅自', 'without permission; of one\'s own initiative'],
    ['伤脑筋', 'knotty; troublesome; bothersome'],
    ['商标', 'trademark; logo'],
    ['上级', 'higher authorities; superiors; CL:个'],
    ['上进', 'to make progress; to do better; fig. ambitious to improve oneself; to move forwards'],
    ['上任', 'take office'],
    ['上瘾', 'to get into a habit; to become addicted'],
    ['上游', 'upper reaches; advanced position'],
    ['尚且', '(not) even; yet; still;'],
    ['捎', 'to bring sth to sb (news etc)'],
    ['梢', 'tip of branch'],
    ['哨', 'a whistle; sentry'],
    ['奢侈', 'luxurious; extravagant; wasteful'],
    ['舌头', 'tongue; enemy soldier captured for the purpose of extracting information; CL:个'],
    ['摄氏度', 'degrees centigrade'],
    ['涉及', 'to involve; to touch upon (a topic)'],
    ['社区', 'community'],
    ['设立', 'to set up; to establish'],
    ['设想', 'to imagine; to assume; to envisage; tentative plan; to have consideration for'],
    ['设置', 'to set up; to install'],
    ['呻吟', 'to moan; to groan'],
    ['深奥', 'profound; abstruse; recondite; profoundly'],
    ['深沉', 'deep; extreme; dull; low pitched (sound)'],
    ['深情厚谊', 'profound friendship'],
    ['申报', 'to report (to the authorities); to declare (to customs)'],
    ['绅士', 'gentleman'],
    ['神经', 'nerve'],
    ['神奇', 'magical; mystical; miraculous'],
    ['神气', 'expression; manner; spirited; vigorous'],
    ['神圣', 'divine; hallow; holy; sacred'],
    ['神态', 'appearance; manner; bearing; deportment; look; expression; mien'],
    ['神仙', 'Daoist immortal; supernatural entity; (in modern fiction) fairy, elf, leprechaun etc; fig. lighthearted person; used in advertising: Live like an immortal! 活神仙似'],
    ['审查', 'to examine; to investigate; to censor out; censorship'],
    ['审理', 'to hear (a case)'],
    ['审美', 'esthetics; appreciating the arts; taste'],
    ['审判', 'a trial; to try sb'],
    ['慎重', 'cautious; careful; prudent'],
    ['渗透', 'to permeate; to infiltrate; to pervade; osmosis'],
    ['声明', 'statement; declaration; CL:项[xiang4] ,份[fen4]'],
    ['声势', 'momentum'],
    ['声誉', 'reputation; fame'],
    ['牲畜', 'domesticated animals; livestock'],
    ['生存', 'to exist; to survive'],
    ['生机', 'opportunity to live; to reprieve from death; life force; vitality'],
    ['生理', 'physiology'],
    ['生疏', 'unfamiliar; strange; out of practice; not accustomed'],
    ['生态', 'way of life; ecology'],
    ['生物', 'organism; living creature; life form; biological; CL:个'],
    ['生效', 'to take effect; to go into effect'],
    ['生肖', 'animal from the Chinese zodiac; one of the twelve animals symbolic of the earthly branches 地支[di4 zhi1]'],
    ['生锈', 'to rust; to grow rusty; to corrode; oxidization'],
    ['生育', 'to bear; to give birth; to grow; to rear; to bring up (children)'],
    ['省会', 'provincial capital'],
    ['盛产', 'superabundant; to teem with; to produce in abundance; to abound in'],
    ['盛开', 'blooming; in full flower'],
    ['盛情', 'great kindness; magnificent hospitality'],
    ['盛行', 'to be in vogue; to be prevalent'],
    ['胜负', 'victory or defeat; the outcome of a battle'],
    ['失事', 'to have an accident (plane crash, shipwreck, vehicle collision etc); to mess things up'],
    ['失误', 'lapse; mistake; to make a mistake; fault; service fault (in volleyball, tennis etc)'],
    ['失踪', 'missing; lost; unaccounted for'],
    ['尸体', 'dead body; corpse; carcass; CL:具[ju4]'],
    ['师范', 'teacher-training; pedagogical; normal (school, e.g. Beijing Normal University)'],
    ['施加', 'to exert (effort or pressure)'],
    ['施展', 'to use fully; to put to use'],
    ['十足', 'ample; complete; hundred percent; a pure shade (of some color)'],
    ['实惠', 'tangible benefit; material advantages; advantageous (deal); substantial (discount)'],
    ['实力', 'strength'],
    ['实施', 'to implement; to carry out'],
    ['实事求是', 'to seek truth from facts (idiom); to be practical and realistic'],
    ['实行', 'to implement; to carry out; to put into practice'],
    ['实质', 'substance; essence'],
    ['拾', 'to pick up; to collate or arrange; ten (banker\'s anti-fraud numeral)'],
    ['时常', 'often; frequently'],
    ['时而', 'occasionally; from time to time'],
    ['时光', 'time; era; period of time'],
    ['时机', 'fortunate timing; occasion; opportunity'],
    ['时事', 'current trends; the present situation; how things are going'],
    ['石油', 'oil; petroleum'],
    ['识别', 'to distinguish; to discern'],
    ['使命', 'mission (diplomatic or other); set task'],
    ['世代', 'generation; an era; accumulation of years; passing on from generation to generation'],
    ['事故', 'accident; CL:桩[zhuang1] ,起[qi3] ,次[ci4]'],
    ['事迹', 'deed; past achievement; important event of the past'],
    ['事件', 'event; happening; incident; CL:个'],
    ['事态', 'situation; existing state of affairs'],
    ['事务', '(political, economic etc) affairs; work'],
    ['事项', 'matter; item'],
    ['事业', 'undertaking; project; activity; (charitable, political or revolutionary) cause; publicly funded institution, enterprise or foundation; career; occupation; CL:个'],
    ['势必', 'is bound to (happen)'],
    ['势力', 'power; (ability to) influence'],
    ['是非', 'right and wrong; quarrel'],
    ['示范', 'to demonstrate; to show how to do sth; demonstration; a model example'],
    ['示威', 'to demonstrate (as a protest); a demonstration; a military show of force'],
    ['示意', 'to hint; to indicate (an idea to sb)'],
    ['视力', 'vision; eyesight'],
    ['视频', 'video'],
    ['视线', 'line of sight'],
    ['视野', 'field of view; horizon'],
    ['试图', 'to attempt; to try'],
    ['试验', 'experiment; test; experimental; CL:次[ci4] ,个'],
    ['适宜', 'suitable; appropriate'],
    ['逝世', 'to pass away; to die'],
    ['释放', 'to release; to set free; to liberate (a prisoner); to discharge'],
    ['收藏', 'to hoard; to collect; collection'],
    ['收缩', 'to pull back; to shrink; to contract'],
    ['收益', 'earnings; profit'],
    ['收音机', 'radio; CL:台[tai2]'],
    ['守护', 'to guard; to protect'],
    ['手法', 'technique; trick; skill'],
    ['手势', 'gesture; sign; signal'],
    ['手艺', 'craftmanship; workmanship; handicraft; trade'],
    ['首饰', 'jewelry; head ornament;'],
    ['首要', 'the most important; of chief importance'],
    ['受罪', 'to endure; to suffer; hardships; torments; a hard time; a nuisance'],
    ['授予', 'to award; to confer'],
    ['书法', 'calligraphy; handwriting; penmanship'],
    ['书记', 'secretary; clerk; CL:个'],
    ['书籍', 'books; works'],
    ['书面', 'in writing; written (guarantee etc)'],
    ['疏忽', 'to neglect; to overlook; negligence; carelessness'],
    ['疏远', 'to drift apart; to become estranged; to alienate; estrangement;'],
    ['舒畅', 'happy; entirely free from worry'],
    ['数额', 'amount; sum of money; fixed number'],
    ['束', 'to bind; to tie; bundle; cluster; restraint'],
    ['束缚', 'to bind; to restrict; to tie; to commit; fetters'],
    ['树立', 'to set up; to establish'],
    ['竖', 'to erect; vertical; vertical stroke (in Chinese characters)'],
    ['耍', 'to play with; to juggle'],
    ['衰老', 'to age; to deteriorate with age; old and weak'],
    ['衰退', 'to decline; to fall; to drop; to falter; a decline; recession (in economics)'],
    ['率领', 'lead; command; head'],
    ['涮火锅', 'to instant-boil (mutton, beef, vegetables, etc.)'],
    ['双胞胎', 'twin; CL:对[dui4]'],
    ['爽快', 'refreshed; rejuvenated; frank and straightforward'],
    ['水利', 'water conservancy; irrigation works'],
    ['水龙头', 'faucet; tap'],
    ['水泥', 'cement; CL:袋[dai4]'],
    ['瞬间', 'moment; momentary; in a flash;'],
    ['司法', 'judicial; (administration of) justice'],
    ['司令', 'commanding officer'],
    ['思念', 'think of; long for; miss'],
    ['思索', 'think deeply; ponder'],
    ['思维', '(line of) thought; thinking'],
    ['斯文', 'refined; educate; cultured; intellectual; polite; gentle'],
    ['私自', 'private; personal; secretly; without explicit approval'],
    ['死亡', 'death; deadly'],
    ['四肢', 'the four limbs of the body'],
    ['寺庙', 'temple; monastery; shrine'],
    ['肆无忌惮', 'absolutely unrestrained; unbridled; without the slightest scruple'],
    ['饲养', 'to raise; to rear'],
    ['耸', 'to excite; to raise up; to shrug; high; lofty; towering'],
    ['艘', 'classifier for ships; Taiwan pr. sao1'],
    ['苏醒', 'to wake up; to regain consciousness'],
    ['俗话', 'common saying; proverb'],
    ['塑造', 'to model; to mould; plastic (arts); flexible; to portray (in sculpture or in words)'],
    ['素食', 'vegetarian food; vegetables'],
    ['素质', 'inner quality; basic essence; change over time'],
    ['诉讼', 'lawsuit'],
    ['算数', 'to count numbers; to keep to one\'s word; to hold (i.e. to remain valid); to count (i.e. to be important)'],
    ['随即', 'immediately; presently; following which'],
    ['随意', 'as one wishes; according to one\'s wishes; at will; voluntary; conscious'],
    ['岁月', 'years; passing of time'],
    ['隧道', 'tunnel'],
    ['损坏', 'to damage; to injure'],
    ['索取', 'to ask; to demand;'],
    ['索性', 'you might as well (do it); simply; just'],
    ['塌', 'collapse'],
    ['踏实', 'practical; down-to-earth; realistic; firmly based; steadfast; to have peace of mind; free from anxiety'],
    ['塔', 'pagoda; tower; minaret; stupa (abbr. loanword from Sanskrit tapo); CL:座[zuo4]'],
    ['台风', 'hurricane; typhoon'],
    ['太空', 'outer space'],
    ['泰斗', 'leading scholar of his time; magnate'],
    ['摊', 'to spread out; vendor\'s stand;'],
    ['瘫痪', 'paralysis'],
    ['贪婪', 'avaricious; greedy; rapacious; insatiable; avid'],
    ['贪污', 'corruption'],
    ['弹性', 'flexibility; elasticity (physics)'],
    ['坦白', 'honest; forthcoming; to confess'],
    ['叹气', 'to sigh; to heave a sigh'],
    ['探测', 'to probe; to take readings; to explore; exploration'],
    ['探索', 'to explore; to probe; commonly used in names of publications or documentaries'],
    ['探讨', 'to investigate; to probe'],
    ['探望', 'to visit'],
    ['倘若', 'provided that; supposing that; if;'],
    ['掏', 'to fish out (from pocket); to scoop'],
    ['滔滔不绝', 'unceasing torrent (idiom); talking non-stop; gabbling forty to the dozen'],
    ['淘汰', 'to wash out; elimination (by selection); natural selection; to knock out (in a competition); to die out; to phase out'],
    ['陶瓷', 'pottery and porcelain; ceramics'],
    ['陶醉', 'to be infatuated with; to be drunk with; to be enchanted with; to revel in'],
    ['讨好', 'to get the desired outcome; to win favor by fawning on sb; to curry favor with; a fruitful outcome to reward one\'s labor;'],
    ['特长', 'personal strength; one\'s special ability or strong points'],
    ['特定', 'special; specific; designated; particular'],
    ['特意', 'specially; intentionally'],
    ['提拔', 'to promote to a higher job; to select for promotion'],
    ['提炼', 'to extract (ore, minerals etc); to refine; to purify; to process'],
    ['提示', 'to prompt; to present; to point out; to draw attention to sth; hint; brief; cue'],
    ['提议', 'proposal; suggestion; to propose; to suggest'],
    ['题材', 'subject matter'],
    ['体裁', 'genre; style; form of writing'],
    ['体积', 'volume; bulk; CL:个'],
    ['体谅', 'to empathize; to allow (for something); to show understanding; to appreciate'],
    ['体面', 'dignity; face (as in \'losing face\'); honorable; creditable; pretty'],
    ['体系', 'system; setup; CL:个'],
    ['天才', 'talent; gift; genius; talented; gifted'],
    ['天赋', 'gift; innate skill'],
    ['天伦之乐', 'pleasure of heavenly agreement (idiom); family love and joy; domestic bliss'],
    ['天然气', 'natural gas'],
    ['天生', 'innate; natural'],
    ['天堂', 'paradise; heaven'],
    ['天文', 'astronomy'],
    ['田径', 'track and field (athletics)'],
    ['田野', 'field; open land; CL:片[pian4]'],
    ['舔', 'to lick; to lap'],
    ['挑剔', 'picky; fussy'],
    ['条款', 'clause (of contract or law); CL:项[xiang4]'],
    ['条理', 'arrangement; order; tidiness'],
    ['条约', 'treaty; pact; CL:个'],
    ['调和', 'harmonious; harmony'],
    ['调剂', 'to adjust; to balance; to make up a medical prescription'],
    ['调节', 'to adjust; to regulate; to harmonize; to reconcile (accountancy etc)'],
    ['调解', 'to mediate; to bring parties to an agreement'],
    ['调料', 'condiment; seasoning; flavoring'],
    ['挑拨', 'to incite disharmony; to instigate'],
    ['挑衅', 'to provoke; provocation'],
    ['跳跃', 'to jump; to leap; to bound; to skip'],
    ['亭子', 'pavilion'],
    ['停泊', 'anchorage; mooring (of a ship)'],
    ['停顿', 'pause'],
    ['停滞', 'stagnation; at a standstill; bogged down'],
    ['挺拔', 'tall and straight'],
    ['通货膨胀', 'inflation'],
    ['通缉', 'to order the arrest of sb as criminal; to list as wanted;'],
    ['通俗', 'common; everyday; average'],
    ['通讯', 'communications; a news story (e.g. dispatched over the wire); CL:个'],
    ['通用', 'common (use); interchangeable'],
    ['同胞', 'born of the same parents; fellow citizen or countryman'],
    ['同志', 'comrade; homosexual (slang); CL:个'],
    ['童话', 'children\'s fairy tales'],
    ['铜', 'copper (chemistry); see also 紅红铜[hong2 tong2]; CL:块[kuai4]'],
    ['统筹兼顾', 'an overall plan taking into account all factors'],
    ['统计', 'statistics; to count; to add up'],
    ['统统', 'totally'],
    ['统治', 'to rule (a country); to govern; rule; regime'],
    ['投机', 'to speculate (on financial markets); opportunistic; congenial; agreeable'],
    ['投票', 'to vote; vote'],
    ['投诉', 'complaint; to file a complaint; to sue'],
    ['投降', 'to surrender; surrender'],
    ['投掷', 'to throw sth a long distance; to hurl'],
    ['透露', 'to leak out; to divulge'],
    ['秃', 'bald; blunt'],
    ['突破', 'to break through; to make a breakthrough; to surmount or break the back of (a task etc); (of ball sports) to break through a defense'],
    ['图案', 'design; pattern'],
    ['徒弟', 'apprentice; disciple'],
    ['涂抹', 'to paint; to smear; to doodle'],
    ['途径', 'way; channel'],
    ['土壤', 'soil'],
    ['团结', 'a rally; to hold a rally; to join forces'],
    ['团体', 'group; organization; team; CL:个'],
    ['团圆', 'to have a reunion;'],
    ['推测', 'speculation; to conjecture; to surmise; to speculate'],
    ['推翻', 'overthrow'],
    ['推理', 'reasoning; speculative; inference'],
    ['推论', 'to infer; a deduction; a corollary; a reasoned conclusion'],
    ['推销', 'to market; to sell'],
    ['吞吞吐吐', 'to hum and haw (idiom); to mumble as if hiding sth; to speak and break off, then start again; to hold sth back;'],
    ['托运', 'to consign (goods); to check through (baggage)'],
    ['拖延', 'to adjourn; to delay; to defer; adjournment; procrastination'],
    ['脱离', 'to separate oneself from; to break away from; diastasis (medicine); abscission; abjunction (botany)'],
    ['妥当', 'appropriate; proper; ready'],
    ['妥善', 'appropriate; proper'],
    ['妥协', 'to compromise; to reach terms; a compromise'],
    ['椭圆', 'oval; ellipse; elliptic'],
    ['唾弃', 'to spurn; to disdain;'],
    ['哇', 'Wow!; sound of child\'s crying'],
    ['挖掘', 'to excavate; to dig; to unearth'],
    ['娃娃', 'baby; small child; doll'],
    ['瓦解', 'to collapse; to disintegrate; to crumble'],
    ['歪曲', 'to distort; to misrepresent'],
    ['外表', 'external; outside; outward appearance'],
    ['外行', 'layman; amateur'],
    ['外界', 'the outside world; external'],
    ['外向', 'outward-looking; extrovert; extroverted (personality); export-oriented (economy)'],
    ['丸', 'pill'],
    ['完备', 'faultless; complete; perfect; to leave nothing to be desired'],
    ['完毕', 'to finish; to end; to complete'],
    ['玩弄', 'to play with; to engage in; to resort to; to dally with'],
    ['玩意儿', 'toy; plaything; thing; act; trick (in a performance, stage show, acrobatics etc)'],
    ['顽固', 'stubborn; obstinate'],
    ['顽强', 'tenacious; hard to defeat'],
    ['惋惜', 'to feel sorry for a person over sth that should have happened'],
    ['挽回', 'to retrieve; to redeem'],
    ['挽救', 'to save; to remedy; to rescue'],
    ['万分', 'very much; extremely; one ten thousandth part'],
    ['往常', 'habitually in the past; as one used to do formerly; as it used to be'],
    ['往事', 'past events; former happenings'],
    ['妄想', 'to attempt vainly; a vain attempt'],
    ['危机', 'crisis; CL:个'],
    ['威风', 'might; awe-inspiring authority; impressive'],
    ['威力', 'might; formidable power'],
    ['威望', 'prestige'],
    ['威信', '(of a government, etc.) prestige and public reliance'],
    ['微不足道', 'negligible; insignificant'],
    ['微观', 'micro-; sub-atomic'],
    ['为难', 'feel embarrassed or awkward; to make things difficult (for someone); to find things difficult (to do or manage)'],
    ['为期', '(to be done) by (a certain date); lasting (a certain time)'],
    ['唯独', 'only; just (i.e. it is only that...); all except; unique'],
    ['维持', 'to keep; to maintain; to preserve'],
    ['维护', 'to defend; to safeguard; to protect; to uphold; to maintain'],
    ['维生素', 'vitamin'],
    ['违背', 'to violate; to be contrary to'],
    ['伪造', 'to forge; to fake; to counterfeit'],
    ['委托', 'to entrust; to trust; to commission'],
    ['委员', 'committee member; committee; council; CL:个'],
    ['卫星', '(space) satellite; CL:颗[ke1]'],
    ['喂', 'to feed (sb or some animal)'],
    ['慰问', 'to express sympathy, greetings, consolation etc'],
    ['未免', 'unavoidable; a bit too much; over the top (you exaggerate)'],
    ['畏惧', 'to fear; to dread; foreboding'],
    ['蔚蓝', 'azure; sky blue;'],
    ['温带', 'temperate zone'],
    ['温和', 'moderate (policy etc)'],
    ['文凭', 'diploma'],
    ['文物', 'cultural relic; historical relic; CL:件[jian4] ,个'],
    ['文献', 'document'],
    ['文雅', 'elegant; refined'],
    ['文艺', 'literature and art'],
    ['问世', 'to be published; to come out'],
    ['窝', 'nest; pit or hollow on the human body; lair; den; place; to harbor or shelter; to hold in check; to bend; classifier for litters and broods'],
    ['乌黑', 'jet-black; dark'],
    ['污蔑', 'slander'],
    ['诬陷', 'to entrap; to frame; to plant false evidence against sb'],
    ['无比', 'matchless'],
    ['无偿', 'free; no charge; at no cost'],
    ['无耻', 'without any sense of shame; unembarrassed; shameless'],
    ['无动于衷', 'aloof; indifferent; unconcerned'],
    ['无非', 'only; nothing else'],
    ['无辜', 'innocent; innocence; not guilty (law);'],
    ['无精打采', 'dispirited and downcast (idiom); listless; in low spirits; washed out'],
    ['无赖', 'hoodlum; rascal; rogue; rascally; scoundrelly'],
    ['无理取闹', 'to make trouble without reason (idiom); deliberately awkward; pointless provocation; willful trouble maker'],
    ['无能为力', 'impotent (idiom); powerless; helpless'],
    ['无穷无尽', 'vast and limitless (idiom); endless span of time; no vestige of a beginning, no prospect of an end'],
    ['无微不至', 'in every possible way (idiom); meticulous'],
    ['无忧无虑', 'carefree and without worries (idiom)'],
    ['无知', 'ignorance'],
    ['侮辱', 'to insult; to humiliate; dishonor'],
    ['武器', 'weapon; arms; CL:种[zhong3]'],
    ['武侠', 'martial arts chivalry (Chinese literary, theatrical and cinema genre); knight-errant'],
    ['武装', 'arms; equipment; to arm; military; armed (forces)'],
    ['舞蹈', 'dance'],
    ['务必', 'must; to need to; to be sure to'],
    ['物美价廉', 'cheap and fine; attractive in price and quality'],
    ['物业', 'property; real estate; property management;'],
    ['物资', 'goods and materials'],
    ['误差', 'difference; error; inaccuracy'],
    ['误解', 'to misunderstand; to misread; misunderstanding'],
    ['夕阳', 'sunset; the setting sun'],
    ['昔日', 'formerly; in olden days'],
    ['溪', 'creek; rivulet'],
    ['熄灭', 'to stop burning; to go out (of fire); to die out; extinguished'],
    ['牺牲', 'to sacrifice oneself; to lay down one\'s life; to do sth at the expense of; beast slaughtered for sacrifice; sacrifice; CL:个'],
    ['膝盖', 'knee'],
    ['习俗', 'custom; tradition; local tradition; convention'],
    ['媳妇', 'daughter-in-law; wife (of a younger man); young married woman; young woman'],
    ['袭击', 'an attack (esp. surprise attack); raid; to attack'],
    ['喜闻乐见', 'a delight to see (idiom); an attractive spectacle'],
    ['喜悦', 'happy; joyous'],
    ['系列', 'series'],
    ['细菌', 'bacterium; germ; CL:种[zhong3]'],
    ['细胞', 'cell (biology)'],
    ['细致', 'delicate; fine; careful; meticulous; painstaking'],
    ['峡谷', 'canyon; gill; ravine'],
    ['狭隘', 'narrow; tight; narrow minded; lacking in experience'],
    ['狭窄', 'narrow'],
    ['霞', 'red clouds'],
    ['下属', 'subordinate; underling'],
    ['先进', 'advanced (technology); to advance'],
    ['先前', 'before; previously'],
    ['掀起', 'to lift; to raise in height; to begin; upsurge; to set off (a campaign)'],
    ['纤维', 'fiber; CL:种[zhong3]'],
    ['鲜明', 'bright; clear-cut; distinct'],
    ['嫌', 'to dislike; suspicion; resentment; enmity; abbr. for 嫌嫌犯[xian2 fan4] , criminal suspect'],
    ['嫌疑', 'suspicion; (be) suspected (of)'],
    ['弦', 'bow string; string of musical instrument; watchspring; chord (segment of curve); hypotenuse; CL:根[gen1]'],
    ['衔接', 'to join together; to combine'],
    ['贤惠', 'chaste; virtuous'],
    ['闲话', 'digression; gossip; complaint; scandal; CL:段[duan4]'],
    ['显著', 'outstanding; notable; remarkable'],
    ['宪法', 'constitution (of a country)'],
    ['现场', 'lit. actual location; the scene (of the incident); on-the-spot'],
    ['现成', 'ready-made; readily available'],
    ['现状', 'current situation'],
    ['线索', 'trail; clues; thread (of a story)'],
    ['陷害', 'to frame (up); to make false charges against'],
    ['陷阱', 'pitfall; snare; trap;'],
    ['陷入', 'to sink into; to get caught up in; to land in (a predicament)'],
    ['馅儿', 'stuffing; filling, e.g. in 包子[bao3 zi5] or 饺子[jiao3 zi5]'],
    ['乡镇', 'village; township'],
    ['相差', 'to differ; discrepancy between'],
    ['相等', 'equal; equally; equivalent'],
    ['相辅相成', 'to complement one another (idiom)'],
    ['相应', 'to correspond; answering (one another); to agree (among the part); corresponding; relevant; appropriate; (modify) accordingly'],
    ['镶嵌', 'to inlay; to embed; to set (e.g. a jewel in a ring); tiling; tesselation'],
    ['响亮', 'loud and clear; resounding'],
    ['响应', 'respond to; answer; CL:个'],
    ['想方设法', 'to think up every possible method (idiom); to devise ways and means; to try this, that and the other'],
    ['向导', 'guide'],
    ['向来', 'always; all along'],
    ['向往', 'to yearn for; to look forward to'],
    ['巷', 'lane; alley'],
    ['相声', 'comic dialog; sketch; crosstalk;'],
    ['消除', 'to eliminate; to remove'],
    ['消毒', 'to disinfect; to sterilize'],
    ['消防', 'fire-fighting; fire control'],
    ['消耗', 'to use up; to consume'],
    ['消灭', 'to put an end to; to annihilate; to cause to perish; to perish; annihilation (in quantum field theory)'],
    ['潇洒', 'confident and at ease; free and easy;'],
    ['销毁', 'to destroy (by melting or burning); to obliterate'],
    ['小心翼翼', 'cautious and solemn (idiom); very carefully; prudent; gently and cautiously'],
    ['效益', 'benefit'],
    ['肖像', 'portrait'],
    ['协会', 'an association; a society; CL:个,家[jia1]'],
    ['协商', 'to consult with; to talk things over; agreement'],
    ['协调', 'to coordinate; to harmonize; negotiation'],
    ['协议', 'agreement; pact; protocol; CL:项[xiang4]'],
    ['协助', 'provide assistance; aid'],
    ['携带', 'to carry (on one\'s person); to support (old); Taiwan pr. xi1 dai4'],
    ['屑', 'crumbs; filings; worthwhile'],
    ['泄露', 'to leak (information); to divulge'],
    ['泄气', 'discouraged; dejected; demoralized; despairing; to want to give up; to deflate (air from a tyre)'],
    ['谢绝', 'to refuse politely'],
    ['心得', 'knowledge gained; CL:个'],
    ['心甘情愿', 'delighted to (do sth, idiom); perfectly happy to do; most willing to do;'],
    ['心灵', 'bright; smart; quick-witted; heart; thoughts; spirit'],
    ['心态', 'attitude (of the heart); state of one\'s psyche; way of thinking; mentality'],
    ['心疼', 'to love dearly; the pain of love'],
    ['心血', 'heart\'s blood; expenditure (for some project); meticulous care'],
    ['心眼儿', 'one\'s thoughts; mind; intention; willingness to accept new ideas; baseless suspicions'],
    ['新陈代谢', 'metabolism (biology); the new replaces the old (idiom)'],
    ['新郎', 'bridegroom; groom'],
    ['新娘', 'bride'],
    ['新颖', 'lit. new bud; fig. new and original'],
    ['欣慰', 'to be gratified'],
    ['欣欣向荣', 'luxuriant growth (idiom); flourishing; thriving'],
    ['薪水', 'salary; wage'],
    ['辛勤', 'hardworking; industrious'],
    ['信赖', 'to trust; to have confidence in; to have faith in; to rely on'],
    ['信念', 'faith; belief; conviction'],
    ['信仰', 'to believe in (a religion); firm belief; conviction'],
    ['信誉', 'prestige; distinction; reputation; trust'],
    ['兴高采烈', 'happy and excited (idiom); in high spirits; in great delight'],
    ['兴隆', 'Xinglong county in Chengde 承德[Cheng2 de2] , Hebei'],
    ['兴旺', 'prosperous; thriving; to prosper; to flourish'],
    ['腥', 'fishy (smell)'],
    ['刑事', 'criminal; penal'],
    ['形态', 'shape; form; pattern; morphology'],
    ['行政', 'administration; administrative'],
    ['兴致勃勃', 'to become exhilarated (idiom); in high spirits; full of zest'],
    ['性感', 'sex appeal; eroticism; sexuality; sexy'],
    ['性命', 'life'],
    ['性能', 'function; performance'],
    ['凶恶', 'fierce; ferocious; fiendish; frightening'],
    ['凶手', 'assailant; murderer; assassin'],
    ['汹涌', 'to surge up violently (of ocean, river, lake etc); turbulent;'],
    ['胸怀', 'one\'s bosom (the seat of emotions); breast; broad-minded and open; to think about; to cherish'],
    ['胸膛', 'chest'],
    ['雄厚', 'strong and solid; rich (resources)'],
    ['雄伟', 'grand; imposing; magnificent; majestic'],
    ['修复', 'restoration'],
    ['修建', 'to build; to construct'],
    ['修养', 'accomplishment; training; self-cultivation'],
    ['羞耻', '(a feeling of) shame'],
    ['嗅觉', 'sense of smell'],
    ['绣', 'to embroider'],
    ['虚假', 'false; phony; pretense'],
    ['虚荣', 'vanity'],
    ['虚伪', 'false; hypocritical; artificial; sham'],
    ['需求', 'requirement; demand (economics)'],
    ['须知', 'prerequisites; rules that must be known before starting sth'],
    ['许可', 'to allow; to permit'],
    ['序言', 'preface; introductory remarks; preamble; prelude'],
    ['畜牧', 'to raise animals'],
    ['酗酒', 'heavy drinking; to get drunk; to drink to excess'],
    ['喧哗', 'hubbub; clamor; to make a racket;'],
    ['宣誓', 'to swear an oath (of office); to make a vow'],
    ['宣扬', 'to proclaim; to make public or well known'],
    ['悬挂', 'to suspend; to hang; suspension (cable car)'],
    ['悬念', 'suspense in a movie, play etc; concern for sb\'s welfare'],
    ['悬殊', 'a wide gap; big contrast; large disparity; a mismatch;'],
    ['悬崖峭壁', 'cliffside'],
    ['旋律', 'melody; rhythm'],
    ['旋转', 'to rotate; to revolve; to spin; to whirl'],
    ['选拔', 'to select the best'],
    ['选举', 'to elect; election; CL:次[ci4] ,个'],
    ['选手', 'contestant; athlete'],
    ['炫耀', 'to show off; to flaunt;'],
    ['削', 'to peel with a knife; to pare; to cut (a ball at tennis etc);'],
    ['削弱', 'to weaken'],
    ['学说', 'theory; doctrine'],
    ['学位', 'academic degree; e.g.: BSc 学士学位[xue2 shi4 xue2 wei4] , MSc 硕士学位[shuo4 shi4 xue2 wei4] , Diploma 学位证书[xue2 wei4 zheng4 shu1] , PhD 博士学位[bo2 shi4 xue2 wei4]'],
    ['雪上加霜', 'to add hail to snow (idiom); one disaster on top of another; to make things worse in a bad situation'],
    ['血压', 'blood pressure'],
    ['熏陶', 'nurturing (i.e. assimilate some branch of culture); seeped in'],
    ['寻觅', 'to look for'],
    ['巡逻', 'to patrol (police, army or navy)'],
    ['循环', 'to cycle; to circulate; circle; loop'],
    ['循序渐进', 'in sequence, step by step (idiom); to make steady progress incrementally'],
    ['压迫', 'to oppress; to repress; to constrict; oppression; stress (physics)'],
    ['压岁钱', 'money given to children as new year present'],
    ['压缩', 'to compress; compression'],
    ['压抑', 'to constrain or repress emotions; oppressive; stifling; depressing; repression'],
    ['压榨', 'to press; to squeeze; to extract juice, oil etc by squeezing'],
    ['压制', 'to suppress; to inhibit; to stifle'],
    ['鸦雀无声', 'lit. crow and peacock make no sound; absolute silence (idiom); not a single voice can be heard; absolute silence;'],
    ['亚军', 'second place (in a sports contest); runner-up'],
    ['淹没', 'to submerge; to drown; to flood; to drown out (also fig.)'],
    ['烟花爆竹', 'fireworks and crackers'],
    ['严峻', 'grim; severe; rigorous'],
    ['严寒', 'bitter cold; severe winter'],
    ['严禁', 'strictly prohibit'],
    ['严厉', 'severe; strict'],
    ['严密', 'strict; tight (organization, surveillance etc)'],
    ['岩石', 'rock'],
    ['延期', 'to delay; to extend; to postpone; to defer'],
    ['延伸', 'to extend; to spread'],
    ['延续', 'to continue; to go on; to last'],
    ['沿海', 'coastal'],
    ['炎热', 'blistering hot; sizzling hot (weather)'],
    ['言论', 'speech; expression of opinion; public opinion'],
    ['掩盖', 'to conceal; to hide behind; to cover up'],
    ['掩护', 'to screen; to shield; to cover; protection; cover; CL:面[mian4]'],
    ['掩饰', 'to conceal a fault; to gloss over'],
    ['演变', 'to develop; to evolve'],
    ['演习', 'exercise; practice; to put on a play; to act'],
    ['演绎', 'to deduce; to infer'],
    ['演奏', 'to play a musical instrument; to perform music'],
    ['眼光', 'vision'],
    ['眼色', 'a wink; to signal with one\'s eyes'],
    ['眼神', 'expression or emotion showing in one\'s eyes; meaningful glance; wink; eyesight (topolect)'],
    ['厌恶', 'to loath; to hate; disgusted with sth'],
    ['验收', 'to check on receipt; an inventory of received goods; to verify and accept (a delivery)'],
    ['验证', 'to inspect and verify; experimental verification'],
    ['氧气', 'oxygen'],
    ['样品', 'sample; specimen'],
    ['摇摆', 'to waver; swaying (of building in an earthquake)'],
    ['摇滚', 'rock and roll (music); to shake and boil'],
    ['谣言', 'rumor'],
    ['遥控', 'remote control'],
    ['遥远', 'distant; remote'],
    ['耀眼', 'to dazzle; dazzling'],
    ['要点', 'main point; essential'],
    ['要命', 'to cause sb\'s death; very; extremely; frightening; annoying'],
    ['要素', 'essential factor; key constituent'],
    ['野蛮', 'barbarous; uncivilized'],
    ['野心', 'ambition; wild schemes; careerism'],
    ['液体', 'liquid'],
    ['一流', 'top quality; front ranking'],
    ['一如既往', 'just as in the past (idiom); as before; continuing as always'],
    ['依旧', 'as before; still'],
    ['依据', 'according to; basis; foundation'],
    ['依靠', 'to rely on sth (for support etc); to depend on'],
    ['依赖', 'to depend on; to be dependent on'],
    ['依托', 'to rely on; to depend on'],
    ['衣裳', 'clothes'],
    ['一度', 'for a time; at one time; one time; once'],
    ['一贯', 'consistent; constant; from start to finish; all along; persistent'],
    ['一目了然', 'obvious at a glance (idiom)'],
    ['一向', 'all along; the whole time; constantly; earlier; lately'],
    ['仪器', 'instrument; apparatus; CL:台[tai2]'],
    ['仪式', 'ceremony'],
    ['疑惑', 'to doubt; to distrust; unconvincing; to puzzle over; misgivings; suspicions'],
    ['遗产', 'heritage; legacy; inheritance; bequest; CL:笔[bi3]'],
    ['遗传', 'heredity; inheritance; to transmit'],
    ['遗留', '(leave or be a) legacy; left over; hand down (to next generation)'],
    ['遗失', 'to lose; lost'],
    ['以便', 'so that; so as to; in order to'],
    ['以免', 'in order to avoid; so as not to'],
    ['以往', 'in the past; formerly'],
    ['以至', 'down to; up to; to such an extent as to... (also written 以至以至于[yi3 zhi4 yu2])'],
    ['以致', 'to such an extent as to; down to; up to'],
    ['一帆风顺', 'single sail, gentle wind (idiom); plain sailing; to go smoothly'],
    ['一举两得', 'one move, two gains (idiom); two birds with one stone'],
    ['一丝不苟', 'not one thread loose (idiom); strictly according to the rules; meticulous; not one hair out of place'],
    ['亦', 'also'],
    ['异常', 'exceptional; abnormal; an anomaly'],
    ['意料', 'to anticipate; to expect; to reckon ahead'],
    ['意识', 'consciousness; awareness; consciously (i.e. deliberately); to be aware'],
    ['意图', 'intent; intention; intend; schematic diagram'],
    ['意味着', 'to signify; to mean; to imply'],
    ['意向', 'disposition'],
    ['意志', 'will; willpower; determination; CL:个'],
    ['毅力', 'perseverance; willpower'],
    ['毅然', 'firmly; resolutely; without hesitation'],
    ['翼', 'wing'],
    ['阴谋', 'plot; conspiracy'],
    ['音响', 'speakers or speaker (electronic); acoustics; sound field (in a room or theater)'],
    ['引导', 'to guide; to lead; to conduct; introduction'],
    ['引擎', 'engine (transliteration)'],
    ['引用', 'to quote; to cite; to recommend; to appoint'],
    ['隐蔽', 'to conceal; to hide; covert; under cover'],
    ['隐患', 'a danger concealed within sth; hidden damage; misfortune not visible from the surface'],
    ['隐瞒', 'to conceal; to hide (a taboo subject); to cover up the truth'],
    ['隐私', 'secrets; something one hopes to conceal'],
    ['隐约', 'vague; faint; indistinct'],
    ['饮食', 'food and drink'],
    ['婴儿', 'infant; baby; CL:个; lead (Pb)'],
    ['英明', 'wise; brilliant'],
    ['英勇', 'bravery; gallant; valiant'],
    ['盈利', 'profit; gain'],
    ['迎面', 'directly; head-on (collision); in one\'s face (of wind)'],
    ['应酬', 'social niceties; social interaction; a dinner party'],
    ['应邀', 'at sb\'s invitation; on invitation'],
    ['庸俗', 'filthy; vulgar; debased'],
    ['拥护', 'to endorse; to support'],
    ['拥有', 'to have; to possess'],
    ['勇于', 'to dare to; to be brave enough to'],
    ['永恒', 'eternal; everlasting; fig. to pass into eternity (i.e. to die)'],
    ['涌现', 'to emerge in large numbers; to spring up; to emerge prominently'],
    ['踊跃', 'to leap; to jump; eager; enthusiastically'],
    ['用户', 'user; consumer; subscriber; customer'],
    ['优胜劣汰', 'survival of the fittest'],
    ['优先', 'priority'],
    ['优异', 'exceptional; outstandingly good'],
    ['优越', 'superior; superiority'],
    ['忧郁', 'sullen; depressed; melancholy; dejected'],
    ['油腻', 'grease; greasy food; oily; rich (of food); fatty; greasy and dirty; a slippery character'],
    ['油漆', 'oil paints; lacquer; to paint; CL:层[ceng2]'],
    ['犹如', 'similar to; appearing to be'],
    ['有条不紊', 'regular and thorough (idiom); methodically arranged'],
    ['幼稚', 'young; childish; naive'],
    ['诱惑', 'to entice; to lure; to induce; to attract'],
    ['愚蠢', 'silly; stupid'],
    ['愚昧', 'ignorant; uneducated'],
    ['渔民', 'fisherman; fisher folk'],
    ['舆论', 'public opinion'],
    ['与日俱增', 'to increase steadily; to grow with each passing day'],
    ['宇宙', 'universe; cosmos'],
    ['羽绒服', 'down garment'],
    ['寓言', 'fable; CL:则[ze2]'],
    ['愈', 'heal; the more...the more; to recover; better'],
    ['欲望', 'desire; longing; appetite; craving'],
    ['熨', 'reconciled; smooth'],
    ['玉', 'jade'],
    ['预料', 'to forecast; to anticipate; expectation'],
    ['预期', 'expect; expected'],
    ['预算', 'budget'],
    ['预先', 'beforehand; prior'],
    ['预言', 'to predict; prophecy'],
    ['预兆', 'omen; prognosis (in medicine)'],
    ['冤枉', 'hatred; injustice; bad luck; unjust treatment; wronged; not worthwhile'],
    ['元首', 'head of state'],
    ['元素', 'element; element of a set; chemical element'],
    ['元宵节', 'Lantern festival; the final event of the Spring festival 春春节, on 15th of first month of the lunar calendar'],
    ['原告', 'complainant; plaintiff'],
    ['原理', 'principle; theory'],
    ['原始', 'first; original; primitive; original (document etc)'],
    ['原先', 'former; original'],
    ['园林', 'gardens; park; landscape garden'],
    ['圆满', 'satisfactory'],
    ['源泉', 'fountainhead; well-spring; water source; fig. origin'],
    ['缘故', 'reason; cause'],
    ['约束', 'to restrict; to limit to; to constrain; restriction; constraint'],
    ['乐谱', 'a musical score; sheet music'],
    ['岳母', 'wife\'s mother, mother-in-law;'],
    ['孕育', 'to be pregnant; to produce offspring; to nurture (a development, school of thought, artwork etc); fig. replete with (culture etc)'],
    ['蕴藏', 'to hold in store; to contain (untapped reserves etc)'],
    ['运算', '(mathematical) operation'],
    ['运行', 'be in motion; to move; to run'],
    ['酝酿', '(of alcohol) to ferment; (of a crisis) to be brewing; to mull over (an issue); to hold exploratory discussions'],
    ['杂技', 'acrobatics; CL:场[chang3]'],
    ['杂交', 'a hybrid'],
    ['砸', 'smash; smashed; to fail; to get it wrong'],
    ['咋', '(dialect) why; how; what'],
    ['栽培', 'to grow; to cultivate'],
    ['灾难', 'disaster; catastrophe'],
    ['宰', 'to slaughter livestock; to govern or rule; to cheat customers; Imperial official in dynastic China'],
    ['再接再厉', 'to continue the struggle (idiom); to persist; unremitting efforts'],
    ['在意', 'to care about; to mind'],
    ['攒', 'to accumulate; to amass; to hoard; to save'],
    ['暂且', 'for now; for the time being; temporarily'],
    ['赞叹', 'to sigh or gasp in admiration; high praise'],
    ['赞助', 'to support; to assist; sponsor'],
    ['糟蹋', 'to waste; to wreck; to despoil; to abuse; to slander; to insult; to defile; to trample on'],
    ['遭受', 'to suffer; to sustain (loss, misfortune)'],
    ['遭殃', 'to suffer a calamity'],
    ['遭遇', 'to meet with; to encounter; (bitter) experience'],
    ['噪音', 'rumble; noise; static'],
    ['造型', 'modeling; mold-making; model or mold; molding'],
    ['责怪', 'to blame; to rebuke'],
    ['贼', 'thief; wily; deceitful; evil; extremely; disagreeably'],
    ['增添', 'add to; increase'],
    ['赠送', 'give as a present; present as a gift'],
    ['扎', 'to prick; to run or stick (a needle etc) into; jug (a classifier for liquids such as beer)'],
    ['扎实', 'strong; solid; sturdy; firm; practical'],
    ['渣', 'slag (in mining or smelting); dregs'],
    ['眨', 'wink'],
    ['诈骗', 'to defraud; to swindle; to blackmail'],
    ['摘要', 'summary; abstract'],
    ['债券', 'bond; debenture'],
    ['沾光', 'to bask in the light; fig. to benefit from association with sb or sth; reflected glory'],
    ['瞻仰', 'to revere; to admire'],
    ['展示', 'to reveal; to display; to show; to exhibit sth'],
    ['展望', 'outlook; prospect; to look ahead; to look forward to'],
    ['展现', 'to come out; to emerge'],
    ['崭新', 'brand new'],
    ['斩钉截铁', 'to chop the nail and slice the iron (idiom); resolute and decisive; unhesitating; definitely; without any doubt'],
    ['占据', 'to occupy; to hold'],
    ['占领', 'to occupy (a territory); to hold'],
    ['战斗', 'to fight; to battle; CL:场[chang2] ,次[ci4]'],
    ['战略', 'strategy'],
    ['战术', 'tactics'],
    ['战役', 'military campaign'],
    ['章程', 'rules; regulations; constitution; statute; articles of association (of company); articles of incorporation; charter (of a corporation); by-laws'],
    ['帐篷', 'tent; CL:顶[ding3] ,座[zuo4]'],
    ['障碍', 'barrier; obstruction; hindrance; impediment; obstacle'],
    ['招标', 'to invite bids;'],
    ['招收', 'to hire; to recruit'],
    ['朝气蓬勃', 'full of youthful energy (idiom); vigorous; energetic; a bright spark'],
    ['着迷', 'to be fascinated; to be captivated'],
    ['沼泽', 'marsh; swamp; wetlands; glade'],
    ['照样', 'as before; (same) as usual'],
    ['照耀', 'shine; illuminate'],
    ['折腾', 'to toss from side to side (e.g. sleeplessly); to repeat sth over and over again; to torment sb; to play crazy'],
    ['遮挡', 'to shelter from; to keep out'],
    ['折', 'to break; to snap; to suffer the loss of; to turn; to bend; to admire; to amount to; to discoun; to fold; turning stroke (in Chinese character); booklet'],
    ['折磨', 'to persecute; to torment'],
    ['侦探', 'detective; to do detective work'],
    ['斟酌', 'to consider; to deliberate; to fill up a cup to the brim'],
    ['珍贵', 'precious'],
    ['珍稀', 'rare; precious and uncommon'],
    ['珍珠', 'pearl'],
    ['真理', 'truth; CL:个'],
    ['真相', 'the truth about sth; the actual facts'],
    ['真挚', 'sincere; sincerity'],
    ['枕头', 'pillow'],
    ['振奋', 'to stir oneself up; to raise one\'s spirits; to inspire'],
    ['振兴', 'to revive, to revitalize, to invigorate, to re-energize'],
    ['镇定', 'calm; unperturbed; cool'],
    ['镇静', 'calm; cool'],
    ['阵地', 'position; front'],
    ['阵容', 'troop arrangement; battle formation; line-up (of a sports team etc)'],
    ['震撼', 'to shake; to vibrate; to shock; to stun; shocking; stunning; fig. mind-blowing;'],
    ['震惊', 'to shock; to astonish'],
    ['争端', 'dispute; controversy; conflict'],
    ['争夺', 'fight over; contest; vie over'],
    ['争气', 'to work hard for sth; to resolve on improvement; determined not to fall short'],
    ['争先恐后', 'striving to be first and fearing to be last (idiom); outdoing one another'],
    ['争议', 'controversy; dispute'],
    ['征服', 'conquer; subdue; vanquish'],
    ['征收', 'to levy (a fine); to impose (a tariff)'],
    ['挣扎', 'to struggle; struggling'],
    ['正月', 'first month of the lunar year'],
    ['蒸发', 'to evaporate; evaporation'],
    ['整顿', 'to tidy up; to reorganize; to consolidate; to rectify'],
    ['政策', 'policy; CL:个'],
    ['政权', 'regime; (wield) political power'],
    ['正当', 'honest; reasonable; fair; sensible; timely; just (when needed)'],
    ['正负', 'positive and negative'],
    ['正规', 'regular; according to standards'],
    ['正经', 'decent; honorable; proper; serious; according to standards'],
    ['正气', 'healthy environment; healthy atmosphere; righteousness; vital energy (in Chinese medicine)'],
    ['正义', 'justice; righteous; righteousness'],
    ['正宗', 'orthodox school; fig. traditional; old school; authentic; genuine;'],
    ['症状', 'symptom (of an illness)'],
    ['证实', 'to confirm (sth to be true); to verify'],
    ['证书', 'credentials; certificate'],
    ['郑重', 'serious'],
    ['之际', 'during; at the time of;'],
    ['支撑', 'to prop up; to support; strut; brace'],
    ['支出', 'to spend; to pay out; expense'],
    ['支流', 'tributary (river)'],
    ['支配', 'to dominate; to allocate'],
    ['支援', 'to provide assistance; to support; to back'],
    ['支柱', 'mainstay; pillar; prop; backbone'],
    ['枝', 'branch; classifier for sticks, rods, pencils etc'],
    ['知觉', 'perception; consciousness'],
    ['知足常乐', 'satisfied with what one has (idiom)'],
    ['脂肪', 'body fat'],
    ['侄子', 'brother\'s son; nephew;'],
    ['值班', 'to work a shift; on duty'],
    ['执行', 'implement; carry out; to execute; to run'],
    ['执着', 'attachment; stubborn; be attached to; persistent; persevering;'],
    ['殖民地', 'colony'],
    ['直播', 'live broadcast (not recorded); direct internet broadcasting'],
    ['直径', 'diameter;'],
    ['职能', 'function; role'],
    ['职位', 'post; office; position'],
    ['职务', 'post; position; job; duties'],
    ['指标', 'norm; index; target'],
    ['指定', 'to appoint; to assign; to indicate clearly and with certainty; designated'],
    ['指甲', 'fingernail'],
    ['指令', 'order; command; instruction'],
    ['指南针', 'compass'],
    ['指示', 'to point out; to indicate; to instruct; directives; instructions; CL:个'],
    ['指望', 'to hope for sth; to count on; hope'],
    ['指责', 'to criticize; to find fault with; to denounce'],
    ['制裁', 'to punish; punishment; sanctions (incl. economic)'],
    ['制服', 'to subdue; to check; to bring under control; (in former times) what one is allowed to wear depending on social status; uniform (army, party, school etc); livery (for company employees); CL:套[tao4]'],
    ['制约', 'to restrict; condition'],
    ['制止', 'to curb; to put a stop to; to stop; to check; to limit'],
    ['志气', 'ambition; resolve; backbone; drive; spirit'],
    ['智力', 'intelligence; intellect'],
    ['智能', 'intelligent; able; smart (phone, system, bomb etc)'],
    ['智商', 'IQ (intelligence quotient)'],
    ['治安', 'law and order; public security'],
    ['治理', 'to govern; to administer; to manage; to control'],
    ['滞留', 'to detain; retention'],
    ['致辞', 'to express in words or writing; to make a speech (esp. short introduction, vote of thanks, afterword, funeral homily etc); to address (an audience); same as 致致词'],
    ['致力', 'to work for; to devote one\'s efforts to;'],
    ['致使', 'to cause; to result in'],
    ['中断', 'to cut short; to break off; to discontinue'],
    ['中立', 'neutral'],
    ['中央', 'central; middle; center; central authorities (of a state)'],
    ['忠诚', 'devoted; fidelity; loyal; loyalty'],
    ['忠实', 'faithful'],
    ['终点', 'the end; end point; finishing line (in a race); destination; terminus; CL:个'],
    ['终究', 'in the end; after all is said and done'],
    ['终身', 'lifelong; all one\'s life; marriage'],
    ['终止', 'to stop; to terminate (law)'],
    ['衷心', 'heartfelt; wholehearted; cordial'],
    ['种子', 'seed; CL:颗[ke1] ,粒[li4]'],
    ['种族', 'race; ethnicity'],
    ['肿瘤', 'tumor'],
    ['众所周知', 'as everyone knows (idiom)'],
    ['种植', 'to plant; to grow;'],
    ['重心', 'center of gravity; central core; main part'],
    ['周边', 'periphery; rim'],
    ['周密', 'careful; thorough'],
    ['周年', 'anniversary; annual'],
    ['周期', 'period; cycle'],
    ['周折', 'complication; twists and turns; problem; setback'],
    ['周转', 'turnover (in cash or personnel); to have enough resources to cover a need'],
    ['州', 'prefecture; state (e.g. of US); province (old); administrative division (old)'],
    ['粥', 'porridge (of rice or millet)'],
    ['舟', 'boat'],
    ['昼夜', 'day and night; period of 24 hours; continuously, without stop'],
    ['皱纹', 'wrinkle; CL:道[dao4]'],
    ['株', 'tree trunk; stump (tree root); a plant; classifier for trees or plants; to involve others (in shady business)'],
    ['诸位', '(pron) everyone; Ladies and Gentlemen; Sirs'],
    ['逐年', 'year after year; with each passing year; over the years'],
    ['主办', 'to organize; to host (a conference or sports event)'],
    ['主导', 'to lead; to manage'],
    ['主管', 'in charge; responsible for'],
    ['主流', 'main stream (of a river); fig. the essential point; main viewpoint of a matter'],
    ['主权', 'sovereignty'],
    ['主义', 'doctrine; ideology; -ism'],
    ['嘱咐', 'to tell; to exhort; injunction'],
    ['拄', 'to lean on; to prop on'],
    ['住宅', 'residence; tenement'],
    ['助理', 'assistant'],
    ['助手', 'assistant; helper'],
    ['注射', 'injection; to inject'],
    ['注视', 'to watch attentively; to gaze'],
    ['注释', 'marginal notes; annotation; to annotate; to add comments to text'],
    ['注重', 'to pay attention to; to emphasize'],
    ['著作', 'to write; literary work; book; article; writings; CL:部[bu4]'],
    ['铸造', 'to cast (pour metal into a mold)'],
    ['驻扎', 'to station; to garrison (troops)'],
    ['拽', 'to drag; to haul'],
    ['专长', 'specialty; special knowledge or ability'],
    ['专程', 'special-purpose trip'],
    ['专利', 'patent'],
    ['专题', 'special topic'],
    ['砖', 'brick; CL:塊|块[kuai4];'],
    ['转达', 'to pass on; to convey; to communicate'],
    ['转让', 'transfer (technology, goods etc); conveyancing (property)'],
    ['转移', 'to shift; to divert or distract (attention etc); to change; to transform; metastasis (medicine)'],
    ['转折', 'shift in the trend of events; plot shift in a book; twists and turns'],
    ['传记', 'biography; CL:篇[pian1] ,部[bu4]'],
    ['庄稼', 'farm crop; CL:種|种[zhong3];'],
    ['庄严', 'stately'],
    ['庄重', 'grave; solemn; dignified'],
    ['装备', 'equipment; to equip; to outfit'],
    ['装卸', 'to load or unload; to transfer; to assemble and disassemble'],
    ['壮观', 'spectacular; magnificent sight'],
    ['壮丽', 'magnificence; magnificent; majestic; glorious'],
    ['壮烈', 'brave; heroic'],
    ['幢', 'tents; classifier for houses'],
    ['追悼', 'mourning; memorial (service etc)'],
    ['追究', 'investigate; look into'],
    ['坠', 'to fall; to drop; to weigh down'],
    ['准则', 'norm; standard; criterion'],
    ['卓越', 'distinction; excellence; outstanding; surpassing; distinguished; splendid'],
    ['琢磨', 'to think over; to ponder'],
    ['着手', 'to put one\'s hand to it; to start out on a task; to set out'],
    ['着想', 'to give thought (to others); to consider (other people\'s needs)'],
    ['着重', 'put emphasis on; to stress; to emphasize'],
    ['姿态', 'attitude; posture; stance'],
    ['滋润', 'moist; humid; to moisten; to provide moisture; comfortably off;'],
    ['滋味', 'taste; flavor; feeling'],
    ['资本', 'capital (as in capitalism)'],
    ['资产', 'property; assets'],
    ['资深', 'senior (in terms of depth of accumulated experience); deeply qualified'],
    ['资助', 'to subsidize; to provide financial aid; subsidy'],
    ['子弹', 'bullet; CL:粒[li4] ,颗[ke1] ,发[fa1]'],
    ['自卑', 'feeling inferior; self-abased'],
    ['自发', 'spontaneous'],
    ['自力更生', 'regeneration through one\'s own effort (idiom); self-reliance'],
    ['自满', 'complacent; self-satisfied'],
    ['自主', 'to act for oneself; autonomous'],
    ['宗教', 'religion'],
    ['宗旨', 'objective; aim; goal'],
    ['棕色', 'brown'],
    ['踪迹', 'tracks; trail; footprint; trace; vestige'],
    ['总而言之', 'in short; in a word; in brief'],
    ['总和', 'sum'],
    ['纵横', 'lit. warp and weft in weaving; vertically and horizontal; length and breadth; criss-crossed; able to move unhindered; abbr. for 合縱連合纵连横[He2 zong4 Lian2 heng2] , School of Diplomacy during the Warring States Period (475 - 221 BC)'],
    ['走廊', 'corridor; aisle; hallway; colonnade; passageway; piazza; veranda'],
    ['走漏', 'to leak (of information, liquid etc); to divulge'],
    ['走私', 'to smuggle; to have an illicit affair'],
    ['揍', 'to beat up; to break to pieces'],
    ['租赁', 'to rent; to lease; to hire'],
    ['足以', 'sufficient to...; so much so that; so that'],
    ['祖父', 'father\'s father; paternal grandfather'],
    ['祖国', 'ancestral land CL:个; homeland; used for PRC'],
    ['祖先', 'ancestor; forebears'],
    ['阻碍', 'to obstruct; to hinder; to block'],
    ['阻拦', 'to stop; to obstruct'],
    ['阻挠', 'to thwart; to obstruct (sth)'],
    ['钻研', 'to study meticulously; to delve into'],
    ['钻石', 'diamond; CL:颗[ke1]'],
    ['嘴唇', 'lip; CL:片[pian4]'],
    ['罪犯', 'criminal'],
    ['尊严', 'dignity; sanctity; honor; majesty'],
    ['遵循', 'to follow; to abide by; to comply with; compliance'],
    ['作弊', 'to practice fraud; to cheat; to engage in corrupt practices'],
    ['作废', 'to become invalid; to cancel; to delete; to nullify'],
    ['作风', 'style of work; way; style'],
    ['作息', 'work and rest'],
    ['做主', 'make the decision; take charge of; back up; support; be host'],
    ['座右铭', 'motto; maxim'],
];