export const hsk5Wordlist: [string, string][] = [
    ['哎', 'hey!; (interjection used to attract attention or to express surprise or disapprobation);'],
    ['唉', '(interjection to express agreement or recognition); to sigh'],
    ['爱护', 'to take care of; to treasure; to cherish; to love and protect'],
    ['爱惜', 'to cherish; to treasure; to use sparingly'],
    ['爱心', 'compassion; CL:片[pian4]'],
    ['安慰', 'to comfort; to console; CL:个'],
    ['安装', 'to install; to erect; to fix; to mount; installation'],
    ['岸', 'shore; bank; beach; coast; CL:個|个[ge4]'],
    ['暗', 'dark; gloomy; hidden; secret; muddled; obscure; in the dark'],
    ['熬夜', 'to stay up late or all night;'],
    ['把握', 'to grasp (also fig.); to seize; to hold; assurance; certainty; sure (of the outcome)'],
    ['摆', 'to arrange; to exhibit; to move to and fro; a pendulum'],
    ['办理', 'to handle; to transact; to conduct'],
    ['傍晚', 'in the evening; when night falls; towards evening; at night fall; at dusk'],
    ['包裹', 'wrap up; bind up; bundle; parcel; package; CL:个'],
    ['包含', 'to contain; to embody; to include'],
    ['包括', 'to comprise; to include; to involve; to incorporate; to consist of'],
    ['薄', 'thin; cold in manner; indifferent; weak; light; infertile'],
    ['保持', 'to keep; to maintain; to hold; to preserve'],
    ['保存', 'to conserve; to preserve; to keep; to save (a file etc) (computing)'],
    ['保留', 'to preserve; to maintain; to retain; to continue to have; to reserve; reservations; to hold back (approval or acceptance)'],
    ['保险', 'insurance; to insure; safe; secure; be sure; be bound to; CL:份[fen4]'],
    ['宝贝', 'treasured object; treasure; darling; baby; cowry; good-for-nothing or queer character'],
    ['宝贵', 'valuable; precious; value; treasure; set store by'],
    ['报到', 'to check in; to report for duty; to register'],
    ['报道', 'report; to report (new); CL:篇[pian1] ,份[fen4]'],
    ['报告', 'to inform; report; make known; speech; talk; lecture; CL:篇[pian1] ,份[fen4] ,个,通[tong4]'],
    ['报社', 'general office of a newspaper; newspaper office; CL:家[jia1]'],
    ['抱怨', 'to complain; to grumble'],
    ['悲观', 'pessimistic'],
    ['背', 'the back of a body or object; to turn one\'s back; to hide something from; to learn by heart; to recite from memory'],
    ['背景', 'background; backdrop; context; CL:种[zhong3]'],
    ['被子', 'quilt; CL:床[chuang2]'],
    ['本科', 'undergraduate course; undergraduate (adjective)'],
    ['本领', 'skill; ability; capability; CL:项[xiang4] ,个'],
    ['本质', 'essence; nature; innate character; intrinsic quality'],
    ['彼此', 'each other; one another'],
    ['比例', 'proportion; scale'],
    ['必然', 'inevitable; certain; necessity'],
    ['必要', 'necessary; essential; indispensable; required'],
    ['毕竟', 'after all; all in all; when all is said and done; in the final analysis'],
    ['避免', 'to avert; to prevent; to avoid; to refrain from'],
    ['编辑', 'to edit; to compile; editor; compiler'],
    ['鞭炮', 'firecrackers; a string of small firecrackers; CL:枚[mei2]'],
    ['便', 'convenient; as convenient; ordinary; plain; when the chance arises; handy; easy; informal; simple; so; thus; to relieve oneself; to urinate; to defecate; equivalent to 就: then; in that case; even if; soon afterwards'],
    ['辩论', 'debate; argument; to argue over; CL:场[chang3] ,次[ci4]'],
    ['标点', 'punctuation; a punctuation mark; to punctuate; CL:个'],
    ['标志', 'sign; mark; symbol; symbolize; to indicate; to mark'],
    ['表达', 'to voice (an opinion); to express; to convey'],
    ['表面', 'surface; face; outside; appearance'],
    ['表明', 'to make clear; to make known; to state clearly; to indicate; known'],
    ['表情', '(facial) expression; to express one\'s feelings; expression'],
    ['表现', 'to show; to show off; to display; to manifest; expression; manifestation; show; display; performance (at work etc)'],
    ['冰激凌', 'ice cream;'],
    ['病毒', 'virus'],
    ['播放', 'to broadcast; to transmit'],
    ['玻璃', 'glass; nylon; plastic; CL:张[zhang1] ,块[kuai4]'],
    ['博物馆', 'museum'],
    ['脖子', 'neck; CL:个'],
    ['不断', 'unceasing; uninterrupted; continuous; constant'],
    ['不见得', 'not necessarily; not likely'],
    ['不耐烦', 'impatience; impatient'],
    ['不要紧', 'unimportant; not serious; it doesn\'t matter; never mind; it looks all right; but'],
    ['补充', 'to replenish; to supplement; to complement; additional; supplementary; CL:个'],
    ['不安', 'unpeaceful; unstable; uneasy; disturbed; restless; worried'],
    ['不得了', 'disastrous; desperately serious; extremely; exceedingly'],
    ['不然', 'not so; no; or else; otherwise; if not'],
    ['不如', 'not equal to; not as good as; inferior to; it would be better to'],
    ['不足', 'insufficient; lacking; deficiency; not enough; inadequate; not worth; cannot; should not'],
    ['布', 'cloth; to declare; to announce; to spread; to make known'],
    ['步骤', 'step; move; measure'],
    ['部门', 'department; branch; section; division; CL:个'],
    ['财产', 'property; assets; estate; CL:笔[bi3]'],
    ['彩虹', 'rainbow'],
    ['踩', 'to step on; to tread; to stamp; to press a pedal; to pedal (a bike)'],
    ['采访', 'to interview; to gather news; to hunt for and collect; to cover'],
    ['采取', 'to adopt or carry out (measures, policies, course of action); to take'],
    ['参考', 'reference; to consult; to refer; consultation'],
    ['参与', 'to participate (in sth)'],
    ['惭愧', 'ashamed; be ashamed'],
    ['操场', 'playground; sports field; drill ground; CL:个'],
    ['操心', 'to worry about'],
    ['册', 'booklet; book; classifier for books'],
    ['测验', 'test; to test; CL:次[ci4] ,个'],
    ['曾经', 'once; already; former; previously; ever; (past tense marker used before verb or clause)'],
    ['叉子', 'fork; CL:把[ba3]'],
    ['差距', 'disparity; gap'],
    ['插', 'to insert; stick in; pierce; to take part in; to interfere; to interpose'],
    ['拆', 'to tear open; to tear down; to tear apart; to open'],
    ['产品', 'goods; merchandise; product; CL:个'],
    ['产生', 'to arise; to come into being; to come about; to give rise to; to bring into being; to bring about; to produce; to engender; to generate; to appear; appearance; emergence; generation; production; yield'],
    ['常识', 'common sense; general knowledge; CL:门[men2]'],
    ['长途', 'long distance'],
    ['抄', 'to copy; to plagiarize; to search and seize; to go; to transcribe; to take a shortcut; to search and confiscate'],
    ['超级', 'transcending; high grade; super-; ultra-'],
    ['朝', 'imperial or royal court; government; dynasty; reign of a sovereign or emperor; court or assembly held by a sovereign or emperor; to make a pilgrimage to; facing; towards'],
    ['潮湿', 'moist; damp'],
    ['吵', 'to make a noise; noisy; to disturb by making a noise'],
    ['吵架', 'to quarrel; to have a row; CL:顿[dun4]'],
    ['炒', 'pan-fry; to fry; fried; sauté'],
    ['车库', 'garage'],
    ['车厢', 'carriage; CL:节[jie2]'],
    ['彻底', 'thorough; thoroughly; complete'],
    ['沉默', 'taciturn; silent; uncommunicative'],
    ['趁', 'to avail oneself of; to take advantage of'],
    ['称', 'to weigh; to state; to name; name; appellation; to praise'],
    ['称呼', 'to call; to address as; appellation'],
    ['称赞', 'to praise; to acclaim; to commend; to compliment'],
    ['成分', 'composition; make-up; ingredient; element; component; one\'s social status; CL:个'],
    ['成果', 'result; achievement; gain; profit; CL:个'],
    ['成就', 'accomplishment; success; attain a result; achievement; CL:个'],
    ['成立', 'to establish; to set up; to be tenable; to hold water'],
    ['成人', 'adult;'],
    ['成熟', 'mature; ripe'],
    ['成语', 'Chinese set expression of 4 characters or two couplets of 4 characters each: expression often alluding to a story or historical quotation; idiom; proverb; saying; adage; set expression; CL:条[tiao2] ,本[ben3] ,句[ju4]'],
    ['成长', 'to mature; to grow; growth'],
    ['承担', 'to undertake; to assume (responsibility etc)'],
    ['承认', 'to admit; to concede; to recognize; recognition (diplomatic, artistic etc); to acknowledge'],
    ['承受', 'to bear; to support; to inherit'],
    ['程度', 'degree (level or extent); level; CL:个'],
    ['程序', 'procedures; sequence; order; computer program'],
    ['诚恳', 'sincere; honest; cordial'],
    ['吃亏', 'to suffer losses; to come to grief; to lose out; to get the worst of it; to be at a disadvantage; unfortunately'],
    ['持续', 'to continue; to persist; sustainable; preservation'],
    ['池塘', 'pool; pond'],
    ['迟早', 'sooner or later'],
    ['尺子', 'rule; ruler (measuring instrument); CL:把[ba3]'],
    ['翅膀', 'wing; CL:个,对[dui4]'],
    ['充电器', 'battery charger'],
    ['充分', 'full; abundant'],
    ['充满', 'full of; brimming with; very full; permeated'],
    ['冲', '(of water) to dash against; to mix with water; to infuse; to rinse; to flush; to develop (a film); to rise in the air; to clash; to collide with'],
    ['重复', 'to repeat; to duplicate; CL:个'],
    ['宠物', 'house pet'],
    ['抽屉', 'drawer'],
    ['抽象', 'abstract'],
    ['丑', 'clown; disgraceful; surname Chou; 2nd earthly branch: 1-3 a.m., 12th solar month (6th January to 3rd February); year of the Ox'],
    ['臭', 'stench; stink; smelly; to smell (bad)'],
    ['出版', 'to publish; to come off the press; to put out'],
    ['出口', 'an exit; to speak; to export; (of a ship) to leave port; CL:个'],
    ['出色', 'remarkable; outstanding'],
    ['出示', 'to show; to take out and show to others; to display;'],
    ['出席', 'to attend; to participate; present'],
    ['初级', 'junior; primary'],
    ['除非', 'only if (..., or otherwise,...); only when; only in the case that; unless'],
    ['除夕', '(New Year\'s) Eve'],
    ['处理', 'to handle; to treat; to deal with; to process; CL:个'],
    ['传播', 'to propagate; to disseminate; to spread'],
    ['传染', 'to infect; contagious'],
    ['传说', 'legend; folklore; tradition; it is said; they say that...'],
    ['传统', 'tradition; traditional; convention; conventional; CL:个'],
    ['窗帘', 'window curtains'],
    ['闯', 'to rush; to charge; to dash; to break through; to temper oneself (through battling hardships)'],
    ['创造', 'to create; to bring about; to produce; CL:个'],
    ['吹', 'to blow; to play a wind instrument; to blast; to puff; to boast; to brag; to end in failure; to fall through'],
    ['词汇', 'vocabulary; list of words (e.g. for language teaching purposes)'],
    ['辞职', 'to resign'],
    ['此外', 'besides; in addition; moreover; furthermore'],
    ['刺激', 'to irritate; to provoke; to upset; to stimulate; to excite; irritant'],
    ['次要', 'secondary'],
    ['匆忙', 'hasty; hurried'],
    ['从此', 'from now on; since then; henceforth'],
    ['从而', 'thus; thereby'],
    ['从前', 'previously; formerly; once upon a time'],
    ['从事', 'to go for; to engage in; to undertake; to deal with; to handle; to do'],
    ['粗糙', 'crude; gruff; rough; coarse;'],
    ['促进', 'to promote (an idea or cause); to advance; boost'],
    ['促使', 'to induce; to promote; to urge; to impel; to bring about; to provoke; to drive (sb to do sth); to catalyze; to actuate; to contribute to (some development)'],
    ['醋', 'vinegar; acid; jealousy (in love rivalry)'],
    ['催', 'to urge; to press; to prompt; to rush sb; to hasten sth; to expedite'],
    ['存在', 'to exist; to be'],
    ['措施', 'measure; step (to be taken); CL:个'],
    ['答应', 'to agree; to promise; to reply; to respond'],
    ['达到', 'to reach; to achieve; to attain'],
    ['打工', 'to have a part time job; to do manual work'],
    ['打交道', 'to come into contact with; to have dealings'],
    ['打喷嚏', 'to sneeze'],
    ['打听', 'to ask about; to inquire about'],
    ['大方', 'generous; tasteful; stylish; easy-mannered'],
    ['大厦', 'large building; edifice; mansion; CL:座[zuo4]'],
    ['大象', 'elephant; CL:只[zhi1]'],
    ['大型', 'large scale; wide scale; broad scale'],
    ['呆', 'foolish; stupid; expressionless; blank; to stay'],
    ['代表', 'representative; delegate; to represent; to stand for; on behalf of; in the name of; CL:位[wei4],个, 名[ming2]'],
    ['代替', 'instead; to replace; to substitute (X for Y, or a number in an algebraic expression)'],
    ['待遇', 'treatment; pay; wages; status; salary'],
    ['贷款', 'a loan; to provide a loan (e.g. bank); CL:笔[bi3]'],
    ['单纯', 'simple; pure; alone; merely'],
    ['单调', 'monotonous; uniform; unvarying'],
    ['单独', 'by oneself; alone; on one\'s own'],
    ['单位', 'unit (to measure); work unit (one\'s workplace); group of people as a whole; CL:个'],
    ['单元', 'unit (as an entity); element; entrance number'],
    ['担任', 'to hold a governmental office or post; to assume office of; to take charge of; to serve as'],
    ['耽误', 'to delay; to hold-up; to waste time; to interfere with'],
    ['胆小鬼', 'coward'],
    ['淡', 'insipid; diluted; weak; mild; light in color; tasteless; fresh; indifferent; nitrogen'],
    ['当地', 'local; in the locality'],
    ['当心', 'to take care; to look out'],
    ['挡', 'to resist; to obstruct; to hinder; to keep off; to block (a blow); to get in the way of; cover; gear'],
    ['倒霉', 'have bad luck; be out of luck'],
    ['导演', 'direct; director (film etc)'],
    ['导致', 'to lead to; to create; to cause; to bring about'],
    ['岛屿', 'island'],
    ['到达', 'to reach; to arrive'],
    ['道德', 'virtue; morality; ethics; moral; CL:种[zhong3]'],
    ['道理', 'reason; argument; sense; principle; basis; justification; CL:个'],
    ['登记', 'to register (one\'s name); to check in; enter one\'s name'],
    ['等待', 'wait for; await'],
    ['等于', 'to equal; to be tantamount to'],
    ['滴', 'a drop; to drip'],
    ['敌人', 'enemy; foe; CL:个'],
    ['的确', 'really; indeed'],
    ['地道', 'real; pure; genuine; typical; well-done; thorough'],
    ['地理', 'geography'],
    ['地区', 'regional; local; district (not necessarily formal administrative unit); region; area; as suffix to city name, means prefecture or county (area administered by a prefecture level city or county level city); CL:个'],
    ['地毯', 'carpet; rug'],
    ['地位', 'position; status; place; CL:个'],
    ['地震', 'earthquake'],
    ['递', 'to hand over; to pass on sth; to gradually increase or decrease; progressively'],
    ['点心', 'pastry; dimsum (in Cantonese cooking); dessert; light refreshments'],
    ['电池', 'battery; CL:节[jie2] ,组[zu3]'],
    ['电台', 'transmitter-receiver; broadcasting station; radio station; CL:个'],
    ['钓', 'to fish with a hook and bait'],
    ['顶', 'apex; crown of the head; top; roof; to carry on the head; to push to the top; to go against; most; to replace; to substitute; classifier for headwear, hats, veils etc; to agree or support (internet slang, similar to digg)'],
    ['冻', 'to freeze; to feel very cold; aspic or jelly'],
    ['动画片', 'cartoon; animation'],
    ['洞', 'cave; hole; zero (unambiguous spoken form when spelling out numbers); CL:个'],
    ['豆腐', 'tofu; bean curd'],
    ['逗', 'to tease; to play with'],
    ['独立', 'independent; independence; to stand alone'],
    ['独特', 'distinct; unique; having special characteristics'],
    ['度过', 'to spend; to pass: to get through; to survive'],
    ['断', 'to break; to snap; to cut off; to give up or abstain from sth; to judge; (usu. used in the negative) absolutely, definitely,decidedly'],
    ['堆', 'to pile up; to heap up; pile (of things); a mass; heap; stack'],
    ['兑换', 'to convert; to exchange'],
    ['对比', 'contrast; ratio; CL:个'],
    ['对待', 'to treat; treatment'],
    ['对方', 'counterpart; other person involved; opposite side; other side; receiving party'],
    ['对手', 'opponent; adversary; rival; competitor'],
    ['对象', 'target; object; partner; boyfriend; girlfriend; CL:个'],
    ['吨', 'ton'],
    ['蹲', 'to crouch; to squat'],
    ['顿', 'to pause; to stop; to lay out; to kowtow; to stamp; at once; classifier for meals, beating, tellings off etc: time, bout, spell, meal'],
    ['多亏', 'thanks to; luckily'],
    ['多余', 'superfluous; unnecessary; surplus'],
    ['朵', 'earlobe'],
    ['躲藏', 'to conceal oneself; to hide oneself; to take cover'],
    ['恶劣', 'vile; nasty; of very poor quality'],
    ['耳环', 'earring; CL:只[zhi1] ,对[dui4]'],
    ['发表', 'to publish; to issue; to put out; to issue (a statement);'],
    ['发愁', 'to worry; to fret; to be anxious; to become sad'],
    ['发达', 'developed (country etc); flourishing; to develop'],
    ['发抖', 'to tremble; to shake; to shiver; to shudder; tremulous'],
    ['发挥', 'to display; to exhibit; to bring out implicit or innate qualities; to express (a thought or moral); to develop (an idea); to elaborate (on a theme)'],
    ['发明', 'to invent; invention; CL:个'],
    ['发票', 'invoice; receipt or bill for purchase'],
    ['发言', 'to make a speech; statement; utterance'],
    ['罚款', '(impose a) fine; penalty; fine (monetary)'],
    ['法院', 'court of law; court'],
    ['翻', 'to turn over; to flip over; to overturn; to rummage through; to translate; to decode'],
    ['繁荣', 'prosperous; booming (economy)'],
    ['反而', 'instead; on the contrary; contrary (to expectations)'],
    ['反复', 'repeatedly; over and over'],
    ['反应', 'to react; to respond; reaction; response; reply; chemical reaction; CL:个'],
    ['反映', 'to mirror; to reflect; mirror image; reflection; fig. to report; to make known; to render; used erroneously for 反应, response or reaction'],
    ['反正', 'to put things back in order; to return to the correct path; in any event; come what may; whatever happens (I\'m still sure it\'s right); anyway'],
    ['范围', 'limit; range; scope; extent; CL:个'],
    ['方', 'square; power or involution (mathematics); upright; honest; fair and square; direction; side; party (to a contract, dispute etc); place; method; prescription (medicine); upright or honest; just when; only or just; classifier for square things; abbr. for square or cubic meter'],
    ['方案', 'plan (for action); program (for action etc); proposal; proposed bill; CL:个'],
    ['方式', 'way (of life); pattern; style; mode; manner; CL:个'],
    ['妨碍', 'to hinder; to obstruct'],
    ['仿佛', 'to seem; as if; alike; similar'],
    ['非', 'non-; not-; un-; abbr. for Africa 非洲; wrong; evil-doing; insist on; simply must'],
    ['肥皂', 'soap; CL:块[kuai4] ,条[tiao2]'],
    ['废话', 'nonsense; rubbish; superfluous words; You don\'t say!; No kidding! (gently sarcastic)'],
    ['分别', 'to part or leave each other; to distinguish; difference; in different ways; differently; separately or individually'],
    ['分布', 'distributed; to distribute'],
    ['分配', 'to assign; to allocate; to distribute'],
    ['分手', 'to part company; to split up; to break up'],
    ['分析', 'to analyze; analysis; CL:个'],
    ['纷纷', 'one after another; in succession; one by one; continuously; diverse; in profusion; numerous and confused; pell-mell'],
    ['奋斗', 'to strive; to struggle; to fight: struggle'],
    ['疯狂', 'crazy; frantic; madness; extreme popularity'],
    ['风格', 'style; maneer; mode'],
    ['风景', 'scenery; landscape; CL:个'],
    ['风俗', 'social custom; CL:个'],
    ['风险', 'risk; venture; hazard'],
    ['讽刺', 'to satirize; to mock; irony; satire; sarcasm'],
    ['否定', 'to negate; negative (answer); CL:个'],
    ['否认', 'to deny; to declare to be untrue'],
    ['幅', 'width; roll; classifier for textiles or pictures'],
    ['扶', 'to support with hand; to help sb up; to help'],
    ['服装', 'clothing; dress; costume; clothes; CL:身[shen1]'],
    ['辅导', 'to coach; to tutor; to give advice (in study)'],
    ['复制', 'to duplicate; to make a copy of; to copy; to reproduce; to clone'],
    ['妇女', 'woman'],
    ['改革', 'to reform; reform; CL:个'],
    ['改进', 'to improve; to make better; CL:个'],
    ['改善', 'to make better; to improve; CL:个'],
    ['改正', 'to correct; to amend; to put right; correction; CL:个'],
    ['概括', 'to summarize; to generalize; briefly; CL:个'],
    ['概念', 'concept; idea; CL:个'],
    ['盖', 'lid; to cover; canopy; to build'],
    ['干脆', 'straightforward; clear-cut; blunt (e.g. statement); you might as well; simply'],
    ['干燥', 'to dry (of weather, paint, cement etc); desiccation; dull; uninteresting; arid'],
    ['感激', 'to express thanks; grateful; moved to gratitude'],
    ['感受', 'to sense; perception; to feel (through the senses); a feeling; an impression; an experience'],
    ['感想', 'impressions; reflections; thoughts; CL:通[tong4] ,个'],
    ['赶紧', 'to hurry up; hurriedly'],
    ['赶快', 'immediately; at once;'],
    ['干活儿', 'to work manually; manual labor'],
    ['钢铁', 'steel; strong; firm; staunch'],
    ['高档', 'superior quality; high grade; top grade'],
    ['高级', 'high level; high grade; advanced; high-ranking'],
    ['搞', 'to do; to make; to go in for; to set up; to get hold of; to take care of'],
    ['告别', 'to leave; to bid farewell to; to say good-bye to'],
    ['格外', 'especially; particularly'],
    ['隔壁', 'next door; neighbor'],
    ['个别', 'individual; specific; respective; just one or two'],
    ['个人', 'individual; personal; oneself'],
    ['个性', 'individuality; personality'],
    ['各自', 'each; respective; apiece'],
    ['根', 'root; basis; classifier for long slender objects, e.g. cigarettes, guitar strings; CL:条[tiao2]; radical (chemistry)'],
    ['根本', 'fundamental; basic; root; simply; absolutely (not); (not) at all; CL:个'],
    ['公布', 'to announce; to make public; to publish'],
    ['公开', 'public; to publish; to make public'],
    ['公平', 'fair; impartial'],
    ['公寓', 'apartment building; block of flats; CL:套[tao4]'],
    ['公元', '(year) AD; Christian era'],
    ['公主', 'princess'],
    ['功能', 'function; capability'],
    ['工厂', 'factory; plant; CL:家[jia1] ,座[zuo4]'],
    ['工程师', 'engineer; CL:个,位[wei4] ,名[ming2]'],
    ['工具', 'tool; instrument; utensil; means (to achieve a goal etc)'],
    ['工人', 'worker; CL:个,名[ming2]'],
    ['工业', 'industry; CL:种[zhong3]'],
    ['恭喜', 'congratulations; greetings;'],
    ['贡献', 'to contribute; to dedicate; to devote; contribution; CL:个'],
    ['沟通', 'communicate'],
    ['构成', 'to constitute; to form; to compose; to make up; to configure (computing)'],
    ['姑姑', 'paternal aunt; CL:个'],
    ['姑娘', 'young woman; young lady; girl; daughter; CL:个'],
    ['古代', 'ancient times; olden times'],
    ['古典', 'classical'],
    ['股票', 'share; stock (market)'],
    ['骨头', 'bone; strong character; CL:根[gen1] ,块[kuai4]'],
    ['鼓舞', 'heartening (news); boost (morale); CL:个'],
    ['鼓掌', 'to applaud; to clap'],
    ['固定', 'fixed; set; regular'],
    ['挂号', 'to register (at a hospital); to send by registered mail'],
    ['乖', 'well-behaved; (of a child) obedient; clever; shrewd; alert; perverse; contrary to reason; irregular; abnormal'],
    ['拐弯', 'to go round a curve; to turn a corner; fig. a new direction'],
    ['怪不得', 'lit. you can\'t blame it!; no wonder!; so that\'s why!'],
    ['关闭', 'to close; to shut'],
    ['官', 'official; government; organ of body; CL:个'],
    ['观察', 'to observe; to watch; to survey; to examine; observation; view; perspective; CL:个'],
    ['观点', 'point of view; viewpoint; standpoint; CL:个'],
    ['观念', 'thought; notion; concept; sense; views; ideology; general impressions'],
    ['管子', 'tube; pipe; drinking straw; CL:根[gen1]'],
    ['冠军', 'champion; CL:个'],
    ['光滑', 'glossy; sleek; smooth'],
    ['光临', '(honorific) Welcome!; You honor us with your presence.; It is an honor to have you.'],
    ['光明', 'illumination; light; radiance (esp. glory of Buddha etc); fig. bright prospect; openhearted'],
    ['光盘', 'compact disc; CD or DVD; CD ROM; CL:片[pian4] ,张[zhang1]'],
    ['广场', 'a public square (e.g. Tiananmen Square); plaza; CL:个'],
    ['广大', '(of an area) vast or extensive; large-scale; widespread; (of people) numerous'],
    ['广泛', 'extensive; wide range'],
    ['归纳', 'to sum up; to summarize; to conclude from facts; induction (method of deduction in logic)'],
    ['规矩', 'established practice; rule; customs; practices; fig. upright and honest; well-behaved; lit. compass and set square'],
    ['规律', 'rule (e.g. of science); law of behavior; regular pattern; rhythm; discipline'],
    ['规模', 'scale; scope; extent; CL:个'],
    ['规则', 'regulation; rule; rules and regulations'],
    ['柜台', 'sales counter; front desk; bar'],
    ['滚', 'to roll; to get away; to boil; get lost (imperative); take a hike'],
    ['锅', 'pan; pot; boiler; CL:口[kou3] ,只[zhi1]'],
    ['国庆节', 'PRC National Day (October 1st)'],
    ['国王', 'king; CL:個|个[ge4];'],
    ['果然', 'sure enough; as expected; really'],
    ['果实', 'fruit (from work); results; gains'],
    ['过分', 'excessive; undue; overly'],
    ['过敏', 'to be allergic; allergy'],
    ['过期', 'to be overdue; to exceed the time limit; to expire (as in expiration date)'],
    ['哈', 'laughter; yawn; abbr. for Kazakhstan'],
    ['海关', 'customs (i.e. border crossing inspection); CL:个'],
    ['海鲜', 'seafood'],
    ['喊', 'to yell; to call; to cry; to shout'],
    ['行业', 'industry; business'],
    ['豪华', 'luxurious'],
    ['好客', 'hospitality; to treat guests well; to enjoy having guests; hospitable; friendly'],
    ['好奇', 'inquisitive; curious'],
    ['何必', 'there is no need; why should'],
    ['何况', 'much less; let alone'],
    ['合法', 'lawful; legitimate; legal'],
    ['合理', 'rational; reasonable; fair'],
    ['合同', '(business) contract; CL:个'],
    ['合影', 'joint photo; group photo'],
    ['合作', 'to cooperate; to collaborate; to work together; cooperation; CL:个'],
    ['和平', 'peace'],
    ['核心', 'core; nucleus'],
    ['恨', 'to hate; to regret'],
    ['猴子', 'monkey; CL:只[zhi1]'],
    ['后背', 'the back (human anatomy); the back part of sth;'],
    ['后果', 'consequences; aftermath'],
    ['呼吸', 'to breathe'],
    ['忽然', 'suddenly; all of a sudden'],
    ['忽视', 'to neglect; to ignore'],
    ['壶', 'pot; classifier for bottled liquid'],
    ['糊涂', 'muddled; silly; confused'],
    ['胡说', 'to talk nonsense; drivel'],
    ['胡同', 'lane; alley; CL:条[tiao2]'],
    ['蝴蝶', 'butterfly; CL:只[zhi1]'],
    ['花生', 'peanut; groundnut; CL:粒[li4]'],
    ['华裔', 'ethnic Chinese; non-Chinese citizen of Chinese ancestry'],
    ['滑', 'to slip; to slide; smooth; slippery; cunning;'],
    ['划', 'to row; to paddle; profitable; worth (the effort); it pays (to do sth);'],
    ['化学', 'chemistry; chemical'],
    ['话题', 'subject (of a talk or conversation); topic'],
    ['怀念', 'to cherish the memory of; to think of; reminisce'],
    ['怀孕', 'pregnant; to have conceived; gestation; pregnancy'],
    ['缓解', 'to ease; to help relieve (a crisis)'],
    ['幻想', 'delusion; fantasy'],
    ['慌张', 'confused; flustered'],
    ['黄金', 'gold'],
    ['恢复', 'to reinstate; to resume; to restore; to recover; to regain; to rehabilitate'],
    ['挥', 'to wave; to brandish; to wipe away; to command; to conduct; to scatter; to disperse'],
    ['灰', 'ash; dust; lime; gray; discouraged; dejected'],
    ['灰尘', 'dust'],
    ['灰心', 'lose heart; be discouraged'],
    ['汇率', 'exchange rate'],
    ['婚礼', 'wedding ceremony; wedding'],
    ['婚姻', 'matrimony; wedding; marriage; CL:次[ci4]'],
    ['活跃', 'active; vigorous'],
    ['伙伴', 'partner; companion; comrade'],
    ['火柴', 'match (for lighting fire); CL:根[gen1] ,盒[he2]'],
    ['或许', 'perhaps; maybe'],
    ['基本', 'basic; fundamental; main; elementary'],
    ['机器', 'machine; CL:台[tai2] ,部[bu4] ,个'],
    ['激烈', 'intense; acute; fierce'],
    ['肌肉', 'muscle; flesh'],
    ['及格', 'to pass a test'],
    ['急忙', 'hastily'],
    ['急诊', 'emergency call; emergency (medical) treatment;'],
    ['极其', 'extremely'],
    ['集合', 'a congregation; to gather; a set'],
    ['集体', 'collective; social; team; CL:个'],
    ['集中', 'to concentrate; to centralize; to focus; centralized; concentrated; to put together'],
    ['寂寞', 'lonely; lonesome'],
    ['系领带', 'tie one\'s necktie'],
    ['纪录', 'record (sports, written account); note'],
    ['纪律', 'discipline'],
    ['纪念', 'to commemorate; to remember; CL:个'],
    ['计算', 'to count; to calculate; to compute; CL:个'],
    ['记录', 'to take notes; keep the minutes; record; CL:个'],
    ['记忆', 'memories; remember; memory; CL:个'],
    ['嘉宾', 'esteemed guest; honored guest'],
    ['夹子', 'clip; clamp; tongs; folder; wallet'],
    ['家庭', 'family; household; CL:户[hu4] ,个'],
    ['家务', 'household duties; housework'],
    ['家乡', 'hometown; native place; CL:个'],
    ['假如', 'supposing; if'],
    ['假设', 'hypothesis; conjecture; suppose that'],
    ['假装', 'to feign; to pretend'],
    ['甲', 'first (in a list, as a party to a contract, in terms of quality etc); first of the ten heavenly stems 十天十天干[shi2 tian1 gan1]; (used for an unspecified person or thing); armor plating; shell or carapace; (of the fingers or toes) nail; bladed leather or metal armor (old); ranking system used in the Imperial examinations (old); civil administration unit (old)'],
    ['价值', 'value; worth; fig. values (ethical, cultural etc); CL:个'],
    ['嫁', '(of a woman) to marry; to marry off a daughter; to shift (blame etc)'],
    ['驾驶', 'to pilot (ship, airplane etc); to drive'],
    ['兼职', 'to hold concurrent posts; part-time; concurrent job; moonlighting'],
    ['坚决', 'firm; resolute; determined'],
    ['坚强', 'staunch; strong'],
    ['肩膀', 'shoulder'],
    ['艰巨', 'arduous; terrible (task); very difficult; formidable'],
    ['艰苦', 'difficult; hard; arduous'],
    ['剪刀', 'scissors; CL:把[ba3]'],
    ['捡', 'to pick up; to collect; to gather'],
    ['简历', 'Curriculum Vitae (CV); résumé (resume); biographical notes'],
    ['简直', 'simply; at all; practically'],
    ['健身', 'to exercise; to keep fit; to work out; physical exercise;'],
    ['建立', 'to establish; to set up; to found; to build'],
    ['建设', 'to build; to construct; construction; constructive'],
    ['建筑', 'building; to construct; Taiwan pr. jian4 zhu2; CL:个'],
    ['键盘', 'keyboard'],
    ['讲究', 'to pay particular attention to; exquisite; aesthetic'],
    ['讲座', 'a course of lectures; CL:个'],
    ['酱油', 'soy sauce'],
    ['交换', 'to exchange; to swap; to switch (telecom); commutative (math); to commute'],
    ['交际', 'communication; social intercourse'],
    ['交往', 'to associate; to contact; association; contact'],
    ['浇', 'to pour liquid; to irrigate (using waterwheel); to water; to cast (molten metal); to mold'],
    ['胶水', 'glue'],
    ['狡猾', 'crafty; cunning; sly'],
    ['角度', 'angle; point of view'],
    ['教材', 'teaching material; CL:本[ben3]'],
    ['教练', 'instructor; sports coach; trainer; CL:个,位[wei4] ,名[ming2]'],
    ['教训', 'a lesson; a moral; to chide sb; to lecture sb'],
    ['接触', 'to touch; to contact; access; in touch with'],
    ['接待', 'to receive (a visitor); to admit (allow sb to enter)'],
    ['接近', 'near; close to'],
    ['结实', 'rugged; sturdy'],
    ['阶段', 'stage; section; phase; period; CL:个'],
    ['结构', 'structure; composition; makeup; architecture; CL:座[zuo4] ,个'],
    ['结合', 'to combine; to link; to integrate; binding; CL:次[ci4]'],
    ['结论', 'conclusion; verdict; to conclude; to reach a verdict; CL:个'],
    ['结账', 'to pay the bill; to settle accounts'],
    ['节省', 'saving; to save; to use sparingly; to cut down on'],
    ['借口', 'to use as an excuse; on the pretext'],
    ['届', 'to arrive at (place or time); period; to become due; classifier for events, meetings, elections, sporting fixtures etc'],
    ['戒', 'to guard against; to exhort; to admonish or warn; to give up or stop doing sth; Buddhist monastic discipline; ring (for a finger);'],
    ['戒指', '(finger) ring'],
    ['金属', 'metal; CL:种[zhong3]'],
    ['尽快', 'as quickly as possible; as soon as possible; with all speed'],
    ['紧急', 'urgent; emergency'],
    ['谨慎', 'cautious; prudent'],
    ['尽力', 'to strive one\'s hardest; to spare no effort'],
    ['尽量', 'as much as possible; to the greatest extent'],
    ['近代', 'modern times'],
    ['进步', 'progress; improvement; to improve; to progress; CL:个'],
    ['进口', 'to import; imported'],
    ['精力', 'energy'],
    ['精神', 'spirit; mind; consciousness; thought; mental; psychological; essence; gist; CL:个'],
    ['经典', 'the classics; scriptures; classical'],
    ['经商', 'to trade; to carry out commercial activities; in business'],
    ['经营', 'to engage in (business etc); to run; to operate'],
    ['酒吧', 'bar; pub; saloon; CL:家[jia1]'],
    ['救', 'to rescue; to save; to assist'],
    ['救护车', 'ambulance; CL:辆[liang4]'],
    ['舅舅', 'mother\'s brother; maternal uncle (informal); CL:个'],
    ['居然', 'unexpectedly; to one\'s surprise; go so far as to'],
    ['桔子', 'tangerine; CL:个,瓣[ban4]'],
    ['俱乐部', 'club (i.e. a group or organization); CL:个'],
    ['具备', 'to possess; to have; equipped with; able to fulfill (conditions or requirements)'],
    ['具体', 'concrete; definite; specific'],
    ['巨大', 'huge; immense; very large; tremendous; gigantic; enormous'],
    ['据说', 'it is said that; reportedly'],
    ['捐', 'to contribute; to donate; contribution; tax; to abandon'],
    ['决赛', 'finals (of a competition)'],
    ['决心', 'determination; resolution; determined; firm and resolute; to make up one\'s mind; CL:个'],
    ['绝对', 'absolute; unconditional'],
    ['角色', 'persona; role; character in a novel'],
    ['军事', 'military affairs; military matters; military'],
    ['均匀', 'well-distributed; homogeneous; even'],
    ['卡车', 'truck; CL:辆[liang4]'],
    ['开发', 'exploit (a resource); open up (for development); to develop'],
    ['开放', 'to lift (a ban or restriction); to open to the outside world (politics); to open for public use; to come into bloom (of flowers)'],
    ['开幕式', 'opening ceremony'],
    ['开水', 'boiled water; boiling water'],
    ['砍', 'to chop; to cut down; to throw sth at sb'],
    ['看不起', 'to look down upon; to despise'],
    ['看望', 'to visit; to pay a call to'],
    ['靠', 'to lean against or on; to stand by the side of; to come near to; to depend on; to trust; to fuck (vulgar); traditional military costume drama where the performers wear armor (old);'],
    ['颗', 'classifier for small spheres, pearls, corn grains, teeth, hearts, satellites etc'],
    ['可见', 'it can clearly be seen (that this is the case); it is (thus) clear; clear; visible'],
    ['可靠', 'reliable'],
    ['可怕', 'awful; dreadful; fearful; formidable; frightful; scary; hideous; horrible; terrible; terribly'],
    ['克', 'to be able to; to subdue; to restrain; to overcome; gram'],
    ['克服', '(try to) overcome (hardships etc); to conquer; to put up with; to endure'],
    ['刻苦', 'hardworking; assiduous'],
    ['客观', 'objective; impartial'],
    ['课程', 'course; class; CL:堂[tang2] ,節[jie2] ,门[men2]'],
    ['空间', 'space (astronomy); CL:个'],
    ['控制', 'control; to exercise control over; to contain'],
    ['空闲', 'idle; free time; leisure'],
    ['口味', 'a person\'s preferences; tastes (in food); flavor'],
    ['夸', 'to boast; to exaggerate; to praise'],
    ['夸张', 'to exaggerate; overstated; exaggerated; hyperbole; (coll.) excessive; extreme; ridiculous;'],
    ['会计', 'accountant; accountancy; accounting'],
    ['宽', 'wide; broad; lenient'],
    ['昆虫', 'insect; CL:只[zhi1] ,群[qun2] ,堆[dui1]'],
    ['扩大', 'to expand; to enlarge; to broaden one\'s scope'],
    ['辣椒', 'hot pepper; chili'],
    ['拦', 'to cut off; to hinder'],
    ['烂', 'well-cooked and soft; mushy; to rot; to decompose; rotten; worn out; chaotic; messy; utterly; thoroughly'],
    ['朗读', 'read aloud; read loudly and clearly'],
    ['劳动', 'physical labor; work; toil; CL:次[ci4]'],
    ['劳驾', 'excuse me'],
    ['姥姥', '(informal) mother\'s mother; maternal grandmother'],
    ['老百姓', 'ordinary people; the \'person in the street\'; CL:个'],
    ['老板', 'boss; keeper'],
    ['老婆', '(coll.) wife;'],
    ['老实', 'honest; sincere; open and guileless; naive'],
    ['老鼠', 'rat; mouse; CL:只[zhi1]'],
    ['乐观', 'optimistic; hopeful'],
    ['雷', 'thunder; (internet slang) terrifying; terrific'],
    ['类型', 'type; category; genre; form; style;'],
    ['冷淡', 'cold; indifferent'],
    ['厘米', 'centimeter'],
    ['梨', 'pear; CL:个'],
    ['离婚', 'to divorce; divorced from (one\'s spouse)'],
    ['理论', 'theory; CL:个'],
    ['理由', 'reason; grounds; justification; CL:个'],
    ['利润', 'profits'],
    ['利息', 'interest (on a loan); CL:笔[bi3]'],
    ['利益', 'benefit; (in sb\'s) interest; CL:个'],
    ['利用', 'exploit; make use of; to use; to take advantage of; to utilize'],
    ['力量', 'power; force; strength'],
    ['立即', 'immediately'],
    ['立刻', 'forthwith; immediate; prompt; promptly; straightway; thereupon; at once'],
    ['联合', 'to combine; to join; unite; alliance'],
    ['连忙', 'promptly; at once'],
    ['连续', 'continuous; in a row; serial; consecutive;'],
    ['恋爱', '(romantic) love; in love; to have an affair; CL:个'],
    ['粮食', 'foodstuff; cereals; CL:种[zhong3]'],
    ['良好', 'good; favorable; well; fine'],
    ['亮', 'bright; clear; resonant; to shine; to show; to reveal'],
    ['了不起', 'amazing; terrific; extraordinary'],
    ['列车', '(railway) train;'],
    ['临时', 'at the instant sth happens; temporary; interim; ad hoc'],
    ['灵活', 'flexible; nimble; agile'],
    ['铃', '(small) bell; CL:只[zhi1]'],
    ['零件', 'part; component'],
    ['零食', 'between-meal nibbles; snacks'],
    ['领导', 'to lead; leading; leadership; leader; CL:位[wei4] ,个'],
    ['领域', 'domain; sphere; field; territory; area'],
    ['流传', 'to spread; to circulate; to hand down'],
    ['流泪', 'to shed tears'],
    ['浏览', 'to skim over; to browse'],
    ['龙', 'dragon; CL:条[tiao2]; imperial'],
    ['漏', 'to leak; to divulge; to leave out by mistake; waterclock or hourglass (old)'],
    ['录取', 'to recruit; to enroll'],
    ['录音', 'to record (sound); sound-recording; CL:个'],
    ['陆地', 'dry land (as opposed to the sea)'],
    ['陆续', 'in turn; successively; one after the other; bit by bit'],
    ['轮流', 'to alternate; to take turns'],
    ['论文', 'treatise; thesis; paper; CL:篇[pian1]; to discuss a paper or thesis (old)'],
    ['逻辑', 'logic (loanword)'],
    ['落后', 'to fall behind; to lag (in technology etc); backward; to retrogress'],
    ['骂', 'to scold; abuse; CL:通[tong4] ,顿[dun4]'],
    ['麦克风', 'microphone (loanword)'],
    ['馒头', 'steamed roll; steamed bun; steamed bread; CL:个'],
    ['满足', 'to satisfy; to meet (the needs of)'],
    ['毛病', 'fault; problem; defect; shortcomings; CL:个'],
    ['矛盾', 'contradictory; contradiction; CL:个'],
    ['冒险', 'to take risks; to take chances; foray; adventure'],
    ['贸易', '(commercial) trade; CL:个'],
    ['媒体', 'media, esp. news media'],
    ['煤炭', 'coal'],
    ['眉毛', 'eyebrow; CL:根[gen1]'],
    ['美术', 'art; fine arts; painting; CL:种[zhong3]'],
    ['魅力', 'charm; fascination; glamor; charisma'],
    ['梦想', 'to dream of; to hope in vain'],
    ['密切', 'close; familiar; intimate; closely (related); to foster close ties; to pay close attention'],
    ['秘密', 'secret; CL:个'],
    ['秘书', 'secretary'],
    ['蜜蜂', 'bee; honeybee; CL:只[zhi1] ,群[qun2]'],
    ['面对', 'to confront; to face'],
    ['面积', 'surface area'],
    ['面临', 'to face sth; to be confronted with'],
    ['描写', 'to describe; to depict; to portray; description'],
    ['苗条', 'slim; slender; graceful'],
    ['敏感', 'sensitive; susceptible; politically sensitive (pretext for censorship)'],
    ['名牌', 'famous brand'],
    ['名片', '(business) card'],
    ['名胜古迹', 'historical sites and scenic spots'],
    ['明确', 'to clarify; explicit; clear-cut; definite; to specify; to make definite'],
    ['明显', 'obvious; distinct; clear'],
    ['明星', 'star; celebrity'],
    ['命令', 'order; command; CL:道[dao4] ,个'],
    ['命运', 'destiny; fate; CL:个'],
    ['摸', 'to feel with the hand; to touch; to stroke; to grope; to feel (one\'s pulse)'],
    ['摩托车', 'motorbike; motorcycle; CL:辆[liang4] ,部[bu4]'],
    ['模仿', 'to imitate; to copy; to emulate; to mimic; model'],
    ['模糊', 'vague; indistinct; fuzzy'],
    ['模特', '(fashion) model (loanword);'],
    ['陌生', 'strange; unfamiliar'],
    ['某', 'a certain; sb or sth indefinite; some; such-and-such'],
    ['木头', 'slow-witted; blockhead; log (of wood, timber etc); CL:块[kuai4] ,根[gen1]'],
    ['目标', 'target; goal; objective; CL:个'],
    ['目录', 'catalog; table of contents; directory (on computer hard drive); list; contents'],
    ['目前', 'at the present time; currently'],
    ['哪怕', 'no matter how; even; even if; even though'],
    ['难怪', '(it\'s) no wonder (that...); (it\'s) not surprising (that)'],
    ['难免', 'hard to avoid; difficult to escape from'],
    ['脑袋', 'head; skull; brain; mental capability; CL:颗[ke1] ,个'],
    ['内部', 'interior; inside (part, section); internal;'],
    ['内科', 'medicine; \'internal\' medicine, i.e. treatment by administering drugs, as opposed to surgical intervention; medical department'],
    ['嫩', 'tender; soft; delicate; light (color); inexperienced; unskilled'],
    ['能干', 'capable; competent'],
    ['能源', 'energy; power source; CL:个'],
    ['嗯', 'm-hm (sound used to express agreement or assent)'],
    ['年代', 'a decade of a century (e.g. the Sixties); age; era; period; CL:个'],
    ['年纪', 'year (in school, college etc); grade CL:把[ba3] ,个'],
    ['念', 'to miss (someone); to read (aloud); to study (a degree course); to miss (sb); idea; remembrance; twenty (banker\'s anti-fraud numeral corresponding to 廿, 20)'],
    ['宁可', 'preferably; one would prefer to...(or not to...); would rather; (would) be better to; (to pick) the lesser of two evils'],
    ['牛仔裤', 'jeans; CL:条[tiao2]'],
    ['农村', 'rural area; village; CL:个'],
    ['农民', 'peasant; CL:个'],
    ['农业', 'agriculture; farming'],
    ['浓', 'concentrated; dense'],
    ['女士', 'lady; madam; CL:个,位[wei4]'],
    ['欧洲', 'Europe; European'],
    ['偶然', 'incidentally; occasional; occasionally; by chance; randomly'],
    ['拍', 'to take (a photograph);to clap; to pat; to slap; fly-swatter; racket'],
    ['派', 'clique; school; group; faction; to dispatch; to send; to assign; to appoint; pi (Greek letter Ππ); the circular ratio pi = 3.1415926'],
    ['盼望', 'to hope for; look forward to'],
    ['培训', 'to cultivate; to train; to groom; training'],
    ['培养', 'to educate; to nurture; to breed; to groom (for a position)'],
    ['赔偿', 'to compensate'],
    ['佩服', 'admire'],
    ['配合', 'matching; fitting in with; compatible with; to correspond; to fit; to conform to; rapport; to coordinate with; to act in concert with; to cooperate; to become man and wife; to combine parts of machine'],
    ['盆', 'basin; flower pot; unit of volume equal to 12 斗 and 8 升, approx 128 liters; CL:个'],
    ['碰', 'to touch; to meet with; to bump;'],
    ['批', 'to ascertain; to act on; to criticize; to pass on; classifier for batches, lots, military flights; tier (for the ranking of universities and colleges)'],
    ['批准', 'to approve; to ratify'],
    ['披', 'to drape over one\'s shoulders; to unroll; to split open; to spread out'],
    ['疲劳', 'fatigue; wearily; weariness; weary'],
    ['匹', 'classifier for horses, mules and tissue; ordinary person'],
    ['片', 'thin piece; flake; a slice; film; TV play; to slice; to carve thin; partial; incomplete; one-sided; classifier for slices, tablets, tract of land, area of water; classifier for CDs, movies, DVDs etc; used with numeral 一[yi1]: classifier for scenario, scene, feeling, atmosphere, sound etc'],
    ['片面', 'unilateral; one-sided'],
    ['飘', 'to float'],
    ['拼音', 'pinyin (Chinese romanization); phonetic writing'],
    ['频道', 'frequency; (television) channel'],
    ['凭', 'to lean against; to rely on; on the basis of; no matter (how, what etc); proof'],
    ['平均', 'average; on average; evenly; in equal proportions'],
    ['平', 'flat; level; equal; to tie (make the same score); to draw (score); calm; peaceful'],
    ['平安', 'safe and sound; well; without mishap; quiet and safe; at peace;'],
    ['平常', 'ordinary; common; usually; ordinarily'],
    ['平等', 'equality; equal'],
    ['平方', 'square (as in square foot, square mile, square root)'],
    ['平衡', 'balance; equilibrium'],
    ['平静', 'tranquil; undisturbed; serene'],
    ['评价', 'to evaluate; to assess'],
    ['破产', 'to go bankrupt; to become impoverished; bankruptcy'],
    ['破坏', 'destruction; damage; to wreck; to break; to destroy'],
    ['迫切', 'urgent; pressing'],
    ['期待', 'to look forward to; to await; expectation'],
    ['期间', 'period of time; time; time period; period; CL:个'],
    ['其余', 'the rest; the others; remaining; remainder; apart from them'],
    ['奇迹', 'miracle; miraculous; wonder; marvel'],
    ['企业', 'company; firm; enterprise; corporation; CL:家[jia1]'],
    ['启发', 'to enlighten; to explain and arouse interest; to inspire; inspiration; heuristic (attributively); to teach; CL:个'],
    ['气氛', 'atmosphere; mood'],
    ['汽油', 'gas; gasoline; CL:升[sheng1]'],
    ['签', 'to sign one\'s name; to make brief comments on a document; autograph; visa'],
    ['谦虚', 'modest; self-effacing; to make modest remarks'],
    ['前途', 'future outlook; prospects; journey'],
    ['浅', 'shallow, not deep; superficial'],
    ['欠', 'deficient; owe; to lack; yawn'],
    ['枪', 'gun; firearm; rifle; spear; CL:支[zhi1] ,把[ba3]'],
    ['墙', 'wall; CL:面[mian4] ,堵[du3]'],
    ['强调', 'to emphasize (a statement); to stress'],
    ['强烈', 'intense; (violently) strong'],
    ['抢', 'to fight over; to rush; to scramble; to grab; to rob; to snatch'],
    ['悄悄', 'stealthily; quietly; secretly;'],
    ['瞧', 'to look at; to see'],
    ['巧妙', 'ingenious; clever'],
    ['切', 'to cut; to slice; tangent (math)'],
    ['亲爱', 'Dear or beloved (way of starting a letter)'],
    ['亲切', 'amiable; friendliness; gracious; hospitality; intimate; cordial; kind; close and dear; familiar'],
    ['亲自', 'personally'],
    ['勤奋', 'hardworking; diligent'],
    ['清淡', 'light (of food, not greasy or strongly flavored); insipid; slack (sales)'],
    ['轻视', 'contempt; contemptuous; to despise; to scorn; scornful'],
    ['轻易', 'easily; lightly; rashly;'],
    ['青', 'green or blue; nature\'s color; greenish black; abbr. for Qinghai province 青海'],
    ['青春', 'youth; youthfulness'],
    ['青少年', 'teenager; teenage; an adolescent; a youth; young person'],
    ['情景', 'scene; sight; circumstances; CL:个'],
    ['情绪', 'feeling; sentiment; CL:种[zhong3]'],
    ['请求', 'to request; request; to ask; CL:个'],
    ['庆祝', 'to celebrate'],
    ['球迷', 'soccer fan; crazy about ball sports; CL:个'],
    ['趋势', 'trend; tendency'],
    ['取消', 'to cancel; cancellation'],
    ['娶', 'to take a wife; to marry (a woman)'],
    ['去世', 'to pass away; to die'],
    ['圈', 'circle; ring; loop; classifier for loops, orbits, laps of race etc; CL:个'],
    ['全面', 'all-around; comprehensive; total; overall'],
    ['权利', 'privilege; right; power'],
    ['权力', 'authority; power'],
    ['劝', 'to advise; to urge; to try to persuade; to exhort'],
    ['缺乏', 'shortage; be lacking; to be short of; to lack; scarcity'],
    ['确定', 'definite; certain; fixed; to fix (on sth); to determine; to be sure; to ensure; to make certain; to ascertain; to clinch; to recognize; to confirm; OK (on computer dialog box)'],
    ['确认', 'to confirm; to verify; confirmation'],
    ['群', 'group; crowd; flock; herd; pack'],
    ['燃烧', 'to ignite; combustion; flaming; kindle'],
    ['绕', 'to wind; to coil (thread); to rotate around; to spiral; to move around; to go round (an obstacle); to by-pass; to make a detour; to confuse; to perplex'],
    ['热爱', 'to love ardently; to adore'],
    ['热烈', 'warm (welcome etc)'],
    ['热心', 'enthusiasm; zeal; zealous; zest; enthusiastic; ardent; warmhearted'],
    ['人才', 'a person\'s talent; talented person; distinguished person; a talent (worth head-hunting); person\'s looks; an attractive woman; used interchangeably with 人材; CL:个'],
    ['人口', 'population'],
    ['人类', 'humanity; human race; mankind'],
    ['人民币', 'Renminbi (RMB); Chinese Yuan (CNY)'],
    ['人生', 'human life'],
    ['人事', 'human resources; personnel; human affairs; ways of the world; consciousness of the world; what is humanly possible; personnel matters; sexual awareness; sexual passion; facts of life'],
    ['人物', 'a person; a character (in a play, novel etc); a protagonist; CL:个'],
    ['人员', 'staff; crew; personnel; CL:个'],
    ['忍不住', 'cannot help; unable to bear'],
    ['日常', 'daily; everyday'],
    ['日程', 'schedule; itinerary; CL:个'],
    ['日历', 'calendar; CL:张[zhang1] ,本[ben3]'],
    ['日期', 'date (calendar); CL:个'],
    ['日用品', 'articles for daily use; CL:件[jian4] ,个'],
    ['日子', 'a given day (calendar); days of one\'s life; time'],
    ['如何', 'what way; how; what'],
    ['如今', 'nowadays; now'],
    ['软', 'soft; flexible'],
    ['软件', '(computer) software'],
    ['弱', 'weak; feeble; young; inferior; (following a decimal or fraction) slightly less than'],
    ['洒', 'to sprinkle; to spray; to spill; to shed'],
    ['嗓子', 'throat; voice; CL:把[ba3]'],
    ['色彩', 'tint; coloring; coloration; character'],
    ['杀', 'to kill; to murder; to fight; to weaken or reduce; to smart (topolect); to counteract; (used after a verb) extremely'],
    ['沙漠', 'desert; CL:个'],
    ['沙滩', 'beach; sand bar; CL:片[pian4]'],
    ['傻', 'foolish; silly'],
    ['晒', 'to dry in the sun; to sunbathe; to share files (loan from \'share\')'],
    ['删除', 'to delete; to cancel'],
    ['闪电', 'lightning; CL:道[dao4]'],
    ['善良', 'good and honest; kind-hearted'],
    ['善于', 'be good at; be adept in'],
    ['扇子', 'fan; CL:把[ba3]'],
    ['伤害', 'to injure; to harm;'],
    ['商品', 'good; commodity; merchandise; CL:个'],
    ['商务', 'commercial affairs; commercial; commerce; business;'],
    ['商业', 'business; trade; commerce'],
    ['上当', 'taken in (by sb\'s deceit); to be fooled; to be duped'],
    ['蛇', 'snake; serpent; CL:条[tiao2]'],
    ['舍不得', 'to hate to do sth; to hate to part with; to begrudge'],
    ['射击', 'to shoot; to fire (a gun)'],
    ['摄影', 'to take a photograph; photography; to shoot (a movie)'],
    ['设备', 'equipment; facilities; installations; CL:个'],
    ['设计', 'plan; design; to design; to plan; CL:个'],
    ['设施', 'facilities; installation'],
    ['伸', 'to stretch; to extend'],
    ['深刻', 'profound; deep; deep-going'],
    ['身材', 'stature; build (height and weight); figure'],
    ['身份', 'status; capacity; dignity; position; rank; identity'],
    ['神话', 'fairy tale; mythology; myth'],
    ['神秘', 'mysterious; mystery'],
    ['升', 'to raise; to hoist; to promote; metric liter (also written 公升); measure for dry grain equal to one-tenth (斗 dǒu)'],
    ['声调', 'tone; note; a tone (on a Chinese syllable); CL:个'],
    ['生产', 'to give birth to a child; parturition; to produce; manufacture'],
    ['生动', 'vivid; lively'],
    ['生长', 'to grow'],
    ['绳子', 'rope; string; cord; CL:条[tiao2]'],
    ['省略', 'to leave out; omission'],
    ['胜利', 'victory; CL:个'],
    ['失眠', '(suffer from) insomnia'],
    ['失去', 'to lose'],
    ['失业', 'unemployment'],
    ['湿润', 'moist'],
    ['狮子', 'lion'],
    ['诗', 'poem; poetry; verse; abbr. for Book of Songs 诗经[shi1 jing1]; CL:首[shou3]'],
    ['实话', 'truth'],
    ['实践', 'to practice; to put into practice; to fulfill'],
    ['实习', 'to practice; field work'],
    ['实现', 'to achieve; to implement; to realize; to bring about'],
    ['实验', 'to experiment; experiments; CL:个,次[ci4]'],
    ['实用', 'practical; functional; pragmatic; applied (science)'],
    ['时差', 'jet lag; time lag; jet lag'],
    ['时代', 'age; era; epoch; period; CL:个'],
    ['时刻', 'moment; constantly; always; CL:个'],
    ['时髦', 'in vogue; fashionable'],
    ['时期', 'a period in time or history; period; time (interval); phase; CL:个'],
    ['时尚', 'fashion'],
    ['石头', 'stone; CL:块[kuai4]'],
    ['食物', 'food; CL:种[zhong3]'],
    ['使劲儿', 'to exert all one\'s strength'],
    ['始终', 'from beginning to end; all along'],
    ['事实', 'a fact; the fact that; CL:个'],
    ['事物', 'thing; object; CL:个'],
    ['事先', 'in advance; before the event; beforehand; prior'],
    ['似的', 'seems as if; rather like;'],
    ['士兵', 'soldier; CL:个'],
    ['市场', 'market place; market (also in abstract); abbr. for 超级市场 supermarket; CL:个'],
    ['试卷', 'examination paper; test paper; CL:份[fen4] ,张[zhang1]'],
    ['收获', 'harvest; results; gains'],
    ['收据', 'receipt; CL:张[zhang1]'],
    ['手工', 'handwork; manual'],
    ['手术', 'surgical operation; operation; surgery; CL:个'],
    ['手套', 'glove; mitten; CL:双[shuang1] ,只[zhi1]'],
    ['手续', 'formalities; procedures; CL:道[dao4] ,个'],
    ['手指', 'finger; CL:个,只[zhi1]'],
    ['首', 'head; chief; first (occasion, thing etc); classifier for poems and songs'],
    ['受伤', 'to sustain injuries; wounded (in an accident etc); harmed'],
    ['寿命', 'life span; life expectancy'],
    ['书架', 'bookshelf; CL:个'],
    ['梳子', 'comb; CL:把[ba3]'],
    ['舒适', 'cozy; snug; comfortable'],
    ['蔬菜', 'vegetables; produce; CL:种[zhong3]'],
    ['输入', 'to import; to input'],
    ['熟练', 'practiced; proficient; skilled; skillful'],
    ['属于', 'classified as; to belong to; to be part of'],
    ['数', 'number; figure; several; a few'],
    ['鼠标', 'mouse (computing)'],
    ['数据', 'data; numbers; digital'],
    ['数码', 'numeral; figures; digital; amount; numerical code'],
    ['摔倒', 'to fall down; to slip and fall; to throw sb to the ground;'],
    ['甩', 'to throw; to fling; to swing; to leave behind; to throw off'],
    ['双方', 'bilateral; both sides; both parties involved'],
    ['税', 'taxes; duties'],
    ['说不定', 'can\'t say for sure; maybe'],
    ['说服', 'to persuade; to convince; to talk sb over'],
    ['丝绸', 'silk cloth; silk'],
    ['丝毫', 'the slightest amount or degree; a bit'],
    ['思考', 'to reflect on; to ponder over'],
    ['思想', 'thought; thinking; idea; ideology; CL:个'],
    ['撕', 'to tear; to rip'],
    ['私人', 'private (citizen); private'],
    ['似乎', 'apparently; to seem; to appear; as if; seemingly'],
    ['搜索', 'to search; to look for sth; to scour (search meticulously); to look sth up; internet search; database search'],
    ['宿舍', 'dormitory; dorm room; living quarters; hostel; CL:间[jian1]'],
    ['随身', 'to (carry) on one\'s person; to (take) with one'],
    ['随时', 'at any time'],
    ['随手', 'conveniently; without extra trouble; while doing it; in passing'],
    ['碎', 'to break down; to break into pieces; fragmentary'],
    ['损失', 'loss; damage; CL:个; to lose; to damage'],
    ['缩短', 'to curtail; to cut down'],
    ['所', 'place; location; classifier for houses, small buildings, institutions etc; that which; particle introducing a relative clause or passive; CL:个'],
    ['锁', 'to lock up; to lock; CL:把[ba3]'],
    ['台阶', 'flight of steps (leading up to a house); step (over obstacle); bench; fig. way out of an embarrassing situation'],
    ['太极拳', 'shadowboxing or Taiji, T\'aichi or T\'aichichuan; traditional form of physical exercise or relaxation; a martial art'],
    ['太太', 'married woman; Mrs.; Madam; wife; CL:个,位[wei4]'],
    ['谈判', 'to negotiate; negotiation; talks; conference; CL:个'],
    ['坦率', 'frank (discussion); blunt; open'],
    ['烫', 'to scald; to burn; to iron; hot'],
    ['桃', 'peach'],
    ['淘气', 'naughty; bad'],
    ['逃', 'to escape; to run away; to flee'],
    ['逃避', 'to evade; to avoid; to shirk; to escape;'],
    ['讨价还价', 'haggle over price'],
    ['套', 'cover; sheath; to encase; a case ; to overlap; to interleave; bend (of a river or mountain range, in place names); harness; classifier for sets, collections; tau (Greek letter Ττ)'],
    ['特色', 'characteristic; distinguishing feature or quality'],
    ['特殊', 'special; particular; unusual; extraordinary'],
    ['特征', 'distinctive feature; characteristic; diagnostic property; trait'],
    ['疼爱', 'to love dearly'],
    ['提倡', 'to promote; to advocate'],
    ['提纲', 'outline; synopsis'],
    ['提问', 'to question; to quiz; to grill'],
    ['题目', 'subject; title; topic; CL:个'],
    ['体会', 'to know (through learning or by experience); to realize; experience; CL:个'],
    ['体贴', 'considerate (of other people\'s needs)'],
    ['体现', 'to embody; to reflect; to incarnate'],
    ['体验', 'to experience for oneself'],
    ['天空', 'sky'],
    ['天真', 'naive; innocent; artless'],
    ['调皮', 'naughty; mischievous; unruly'],
    ['调整', 'adjustment; revision; CL:个'],
    ['挑战', 'challenge'],
    ['通常', 'usual; regular; ordinary; normal'],
    ['统一', 'to unify; to unite; to integrate; unified'],
    ['痛苦', 'pain; suffering; painful; CL:个'],
    ['痛快', 'overjoyed; delighted; happily; heartily; enjoying; also pr. tong4 kuai5'],
    ['偷', 'to steal; to pilfer; to snatch; thief; stealthily;'],
    ['投入', 'to participate in; to invest in; to throw into; to put into; absorbed; engrossed;'],
    ['投资', 'investment; to invest'],
    ['透明', 'transparent; open (non-secretive)'],
    ['突出', 'prominent; outstanding; to give prominence to; to protrude; to project'],
    ['土地', 'territory; land; soil; local god; Lar; genius loci; CL:片[pian4]'],
    ['土豆', 'potato; CL:个'],
    ['兔子', 'hare; rabbit; CL:只[zhi1]'],
    ['吐', 'to vomit; to throw up'],
    ['团', 'round; circular; group; society; regiment'],
    ['推辞', 'to decline (an appointment, invitation etc)'],
    ['推广', 'to extend; to spread; to popularize; CL:个'],
    ['推荐', 'to recommend; recommendation'],
    ['退', 'to move back; to decline; to move back; to withdraw'],
    ['退步', 'degenerate; regress'],
    ['退休', 'retirement (from work)'],
    ['歪', 'askew; at a crooked angle; devious; noxious'],
    ['外公', '(coll.) mother\'s father; maternal grandfather;'],
    ['外交', 'diplomacy; diplomatic; foreign affairs; CL:个'],
    ['完美', 'perfection; perfect; perfectly'],
    ['完善', 'to perfect; to make perfect; to improve'],
    ['完整', 'intact; complete'],
    ['玩具', 'plaything; toy'],
    ['万一', 'just in case; if by any chance; contingency'],
    ['王子', 'prince; son of a king'],
    ['往返', 'to go back and forth; to go to and fro'],
    ['网络', 'Internet; network (computing, telecommunications, transport etc)'],
    ['危害', 'to jeopardize; to harm; to endanger; CL:个'],
    ['威胁', 'to threaten; to menace'],
    ['微笑', 'smile; CL:个'],
    ['唯一', 'sole; only'],
    ['围巾', 'scarf; shawl; CL:条[tiao2]'],
    ['围绕', 'to revolve around; to center on (an issue)'],
    ['维修', 'maintenance (of equipment); to protect and maintain'],
    ['违反', 'to violate (a law)'],
    ['伟大', 'huge; great; mighty; large'],
    ['委屈', 'to feel wronged; to nurse a grievance; to cause sb to feel wronged'],
    ['尾巴', 'tail'],
    ['位于', 'to be located at; to be situated at; to lie'],
    ['位置', 'position; place; seat; CL:个'],
    ['未必', 'not necessarily; maybe not'],
    ['未来', 'future; tomorrow; approaching; coming; pending; CL:个'],
    ['胃', 'stomach; CL:个'],
    ['胃口', 'appetite'],
    ['温暖', 'warm'],
    ['温柔', 'gentle and soft; tender'],
    ['文件', 'document; file; CL:份[fen4]'],
    ['文具', 'stationery; item of stationery (pen, pencil, eraser, pencil sharpener etc)'],
    ['文明', 'civilized; civilization; culture; CL:个'],
    ['文学', 'literature; CL:种[zhong3]'],
    ['文字', 'writing style; script; writing; written language; phraseology; CL:個|个[ge4];'],
    ['闻', 'to hear; news; well-known; famous; reputation; fame; to smell; to sniff at; surname Wen'],
    ['吻', 'to kiss; kiss; mouth'],
    ['稳定', 'steady; stable; stability; to stabilize; to pacify'],
    ['问候', 'to give one\'s respects; to send a greeting'],
    ['卧室', 'bedroom; CL:间[jian1]'],
    ['握手', 'to shake hands'],
    ['屋子', 'house; room; CL:间[jian1]'],
    ['无奈', 'unfortunately; cannot help but; helpless; without choice; for lack of better option; grudgingly; willy-nilly; nolens volens; abbr. for 無可奈无可奈何[wu2 ke3 nai4 he2]'],
    ['无数', 'countless; numberless; innumerable'],
    ['无所谓', 'not to matter; to be indifferent; cannot be said to be;'],
    ['武术', 'martial art; military skill or technique (in former times); all kinds of martial art sports (some claiming spiritual development); self-defense; tradition of choreographed fights from opera and film (recent usage); also called kungfu 功夫; CL:种[zhong3]'],
    ['勿', 'do not'],
    ['物理', 'physics; physical'],
    ['物质', 'matter; substance; material; materialistic; CL:个'],
    ['雾', 'fog; mist; CL:场[chang2] ,阵[zhen4]'],
    ['吸取', 'to absorb; to draw (a lesson, insight etc); to assimilate'],
    ['吸收', 'to absorb; to assimilate; to ingest'],
    ['戏剧', 'drama; play; theater'],
    ['系', 'relation; to connect; to relate to; to tie up; to bind; department'],
    ['系统', 'system; CL:个'],
    ['细节', 'details; particulars'],
    ['瞎', 'blind; groundlessly; foolishly; to no purpose'],
    ['下载', 'to download'],
    ['吓', 'to frighten; to scare; to intimidate; to threaten'],
    ['夏令营', 'summer camp'],
    ['鲜艳', 'bright-colored; gaily-colored'],
    ['显得', 'to seem; to look; to appear'],
    ['显然', 'evident; clear; obvious(ly)'],
    ['显示', 'to show; to demonstrate; to illustrate; to display; to demonstrate'],
    ['县', 'county; PRC administrative division below prefecture 地地区[di4 qu4]; CL:个'],
    ['现代', 'modern times; modern age; modern era'],
    ['现实', 'reality; actuality; real; actual; CL:个'],
    ['现象', 'appearance; phenomenon; CL:个'],
    ['限制', 'to restrict; to limit; to confine; restriction; limit; CL:个'],
    ['相处', 'get along with each other'],
    ['相当', 'equivalent to; appropriate; considerably; to a certain extent; fairly'],
    ['相对', 'relatively; opposite; to resist; to oppose; relative; vis-a-vis'],
    ['相关', 'interrelated; correlation; dependence; relevance; mutuality'],
    ['相似', 'to resemble; similar; like; resemblance; similarity'],
    ['香肠', 'sausage; CL:根[gen1];'],
    ['享受', 'to enjoy; to live it up; pleasure; CL:种[zhong3]'],
    ['想念', 'to miss; remember with longing; long to see again'],
    ['想象', 'to imagine; to fancy; CL:个'],
    ['象棋', 'Chinese chess; CL:副[fu4]'],
    ['象征', 'emblem; symbol; token; badge; to symbolize; to signify; to stand for'],
    ['项', 'back of neck; thing; item; classifier for principles, items, clauses, tasks, research projects'],
    ['项链', 'necklace'],
    ['项目', 'item; project; sports event; CL:个'],
    ['消费', 'to consume; CL:个'],
    ['消化', 'digest; digestion; digestive'],
    ['消极', 'negative; passive; inactive'],
    ['消失', 'to disappear; to fade away'],
    ['销售', 'to sell; to market; sales'],
    ['小麦', 'wheat; CL:粒[li4]'],
    ['小气', 'stingy; petty; miserly; narrow-minded'],
    ['孝顺', 'to be obedient to one\'s parents'],
    ['效率', 'efficiency'],
    ['歇', 'to take a break; to rest; to stop'],
    ['斜', 'inclined; slanting; oblique; tilting'],
    ['写作', 'writing; written works'],
    ['心理', 'mental; psychological'],
    ['心脏', 'heart (lit. and fig.); CL:颗[ke1] ,个'],
    ['欣赏', 'to appreciate; to enjoy; to admire'],
    ['信号', 'signal'],
    ['信任', 'to trust; to have confidence in'],
    ['形成', 'to form; to take shape'],
    ['形容', 'to describe; description; appearance; look'],
    ['形势', 'circumstances; situation; terrain (topography); CL:个'],
    ['形式', 'shape; outer appearance; form; CL:个'],
    ['形象', 'image; form; figure; visualization; vivid; CL:个'],
    ['形状', 'form; shape; CL:个'],
    ['行动', 'action; to move; operation; mobile; CL:个'],
    ['行人', 'pedestrian; traveler on foot; passer-by; official responsible for arranging audiences with the emperor'],
    ['行为', 'behavior; conduct; activity; action'],
    ['幸亏', 'fortunately; luckily'],
    ['幸运', 'fortunate; fortune; luck'],
    ['性质', 'nature; characteristic; CL:个'],
    ['兄弟', 'older and younger brother; brothers; brotherly; fraternal; CL:个'],
    ['胸', 'chest; bosom; heart; mind; thorax'],
    ['休闲', 'leisure; relaxation; not working; idle'],
    ['修改', 'to amend; to alter; to modify'],
    ['虚心', 'modest; open-minded'],
    ['叙述', 'to relate (a story or information); to tell or talk about; to recount; narration; telling; narrative; account'],
    ['宣布', 'to declare; to announce; to proclaim'],
    ['宣传', 'to disseminate; to give publicity to; propaganda; CL:个'],
    ['学历', 'educational background; academic qualifications'],
    ['学术', 'learning; science; academic; CL:个'],
    ['学问', 'learning; knowledge; academic; CL:个'],
    ['血', 'blood; CL:滴[di1] ,片[pian4]'],
    ['寻找', 'to seek; to look for'],
    ['询问', 'to inquire'],
    ['训练', 'to train; to drill; training; CL:个'],
    ['迅速', 'rapid; speedy; fast'],
    ['押金', 'deposit; cash pledge'],
    ['牙齿', 'tooth; dental; CL:顆|颗[ke1];'],
    ['严肃', 'solemn; solemnity'],
    ['延长', 'to prolong; to extend; to delay'],
    ['演讲', 'lecture; to make a speech'],
    ['宴会', 'banquet; feast; dinner party; CL:席[xi2] ,个'],
    ['阳台', 'balcony; porch'],
    ['痒', 'to itch; to tickle'],
    ['样式', 'type; style'],
    ['腰', 'waist; lower back; pocket; middle; loins'],
    ['摇', 'to shake; to rock; to row; to crank'],
    ['咬', 'to bite; to nip'],
    ['要不', 'otherwise; or; or else; how about...? (one choice or the other); either this, or else...'],
    ['业务', 'vocational work; professionnal work; business CL:个'],
    ['业余', 'in one\'s spare time; outside working hours; amateur; non professional; extra-curricular'],
    ['夜', 'night; evening'],
    ['依然', 'as before; still'],
    ['一辈子', '(for) a lifetime'],
    ['一旦', 'in case (sth happens); if; once (sth happens, then...); when; in a short time; in one day'],
    ['一律', 'uniformly; same; all; without exception'],
    ['一再', 'repeatedly'],
    ['一致', 'unanimous; identical (views or opinions)'],
    ['疑问', 'a question; something not understood; to query; interrogative (gramm.); CL:个'],
    ['移动', 'to move; movement; migration; mobile; portable'],
    ['移民', 'to immigrate; to migrate; emigrant; immigrant'],
    ['遗憾', 'to regret; regret; pity; sorry'],
    ['乙', 'second in order; second of 10 heavenly stems 十天干; letter \'B\' or roman \'II\' in list \'A, B, C\', or \'I, II, III\' etc; second party (in legal contract, usually 乙方[yi3 fang1] , as opposed to 甲方[jia3 fang1]); ethyl; bent; winding; radical in Chinese characters (Kangxi radical 5)'],
    ['以及', 'as well as; too; and'],
    ['以来', 'since (a previous event)'],
    ['义务', 'duty; obligation; commitment; volunteer duty; mandatory; voluntary; CL:项[xiang4]'],
    ['亿', 'a hundred million; 100,000,000; many'],
    ['意外', 'unexpected; accident; mishap; CL:个'],
    ['意义', 'meaning; significance; CL:个'],
    ['议论', 'to comment; to talk about; to discuss; discussion; CL:个'],
    ['因而', 'therefore; as a result; thus; and as a result,...'],
    ['因素', 'element; factor; CL:个'],
    ['银', 'silver; silver-colored; relating to money or currency'],
    ['印刷', 'to print; printing'],
    ['英俊', 'handsome; brilliant; smart'],
    ['英雄', 'hero; heroic; CL:个'],
    ['营养', 'nutrition; nourishment; CL:种[zhong3]'],
    ['营业', 'to do business; to trade'],
    ['迎接', 'to meet; to welcome; to greet'],
    ['影子', 'shadow; reflection; indication; influence; CL:个'],
    ['应付', 'to deal with; to cope'],
    ['应用', 'to use; to apply; application; applicable'],
    ['硬', 'hard; able (person); stiff; strong; firm; to manage to do sth with difficulty; good (quality); able (person)'],
    ['硬件', 'hardware'],
    ['拥抱', 'to embrace; to hug; to hold in one\'s arms'],
    ['拥挤', 'to be crowded; to throng; to push and squeeze; congestion'],
    ['勇气', 'courage; valor; (to have) the nerve'],
    ['用功', 'to study hard; industrious (in one\'s studies); to make great effort; diligent'],
    ['用途', 'use; application'],
    ['优惠', 'preferential; favorable'],
    ['优美', 'graceful; fine; elegant'],
    ['优势', 'superiority; dominance; advantage'],
    ['悠久', 'established; long (about culture, tradtition, history)'],
    ['油炸', 'to deep fry'],
    ['游览', 'to go sight-seeing; to tour; to visit; CL:次[ci4]'],
    ['犹豫', 'to hesitate'],
    ['有利', 'advantageous; to have advantages; favorable'],
    ['幼儿园', 'kindergarten; nursery school'],
    ['娱乐', 'to entertain; to amuse; entertainment; recreation; amusement; hobby; fun; joy'],
    ['与其', 'rather than...; 與与其 A 不如 B (rather than A, better to B)'],
    ['语气', 'tone; manner of speaking; mood; CL:个'],
    ['玉米', 'corn; maize; CL:粒[li4]'],
    ['预报', 'forecast'],
    ['预订', 'to place an order; to book ahead'],
    ['预防', 'to prevent; to take precautions against; to protect; to guard against; precautionary; prophylactic'],
    ['元旦', 'New Year\'s Day'],
    ['原料', 'raw material; CL:个'],
    ['原则', 'principle; doctrine; CL:个'],
    ['员工', 'staff; personnel; employee;'],
    ['圆', 'circle; round; circular; spherical; (of the moon) full; unit of Chinese currency (Yuan); tactful; to justify'],
    ['愿望', 'wish ; desire; CL:个'],
    ['乐器', 'musical instrument; CL:件[jian4];'],
    ['晕', 'confused; dizzy; giddy; faint; swoon; to lose consciousness; to pass out'],
    ['运气', 'luck (good or bad)'],
    ['运输', 'transport; haulage; transit; CL:个'],
    ['运用', 'to use; to put to use'],
    ['灾害', 'disastrous damage; scourge; CL:个'],
    ['再三', 'over and over again; again and again'],
    ['在乎', 'to care about; to mind; determined by'],
    ['在于', 'to be in; to lie in; to consist in; to depend on; to rest with;'],
    ['赞成', 'to approve; to endorse'],
    ['赞美', 'to admire; to applause; to praise; to eulogize'],
    ['糟糕', 'too bad; how terrible; what bad luck; terrible; bad'],
    ['造成', 'to bring about; to create; to cause'],
    ['则', 'thus; then; standard; norm; rule; to imitate; to follow; principle; classifier for written items (such as an official statement); conjunction used to express contrast with a previous sentence or clause (like \'thus\');'],
    ['责备', 'to blame; to criticize'],
    ['摘', 'to borrow; to take; to pick (flowers, fruit etc); to pluck; to select; to remove; to take off (glasses, hat etc)'],
    ['窄', 'narrow; narrow-minded; badly off'],
    ['粘贴', 'to stick; to affix; to adhere; to paste (as in cut, copy and paste)'],
    ['展开', 'to unfold; to carry out; to be in full swing; to launch'],
    ['展览', 'to put on display; to exhibit; exhibition; show; CL:个,次[ci4]'],
    ['占', 'to take possession of; to occupy; to take up;'],
    ['战争', 'war; warfare; conflict; CL:场[chang2] ,次[ci4]'],
    ['掌握', 'to grasp (often fig.); to control; to master; to know well; to understand sth well and know how to use it; fluency; to seize (initiative, opportunity, destiny)'],
    ['涨', 'to rise (of prices, rivers)'],
    ['长辈', 'one\'s elders; older generation'],
    ['账户', 'account (bank or online)'],
    ['招待', 'to receive (guests); to entertain; reception'],
    ['着火', 'to ignite; to burn'],
    ['着凉', 'to catch cold'],
    ['召开', 'to convene (a conference or meeting) ／ to convoke; to convoke; to call together'],
    ['照常', '(business etc) as usual'],
    ['哲学', 'philosophy; CL:个'],
    ['珍惜', 'to cherish; to value; to treasure'],
    ['真实', 'true; real'],
    ['针对', 'to be directed against; to be aimed at; to counter; in the light of; in connection with'],
    ['诊断', 'diagnosis; to diagnose'],
    ['振动', 'vibration'],
    ['阵', 'disposition of troops; wave; spate; burst; spell; short period of time; classifier for events or states of short duration'],
    ['争论', 'to argue; to debate; to contend; argument; contention; controversy; debate; CL:次[ci4] ,场[chang3]'],
    ['争取', 'to fight for; to strive for; to win over'],
    ['征求', 'to solicit; to seek; to request (opinions, feedback etc); to petition'],
    ['挣', 'to struggle to get free; to strive to acquire; to make (money);'],
    ['睁', 'to open (eye)'],
    ['整个', 'whole; entire; total'],
    ['整齐', 'orderly; neat; even; tidy'],
    ['整体', 'whole entity; entire body; synthesis; as a whole (situation, construction, team etc); global; macrocosm; integral; holistic; whole'],
    ['政府', 'government; CL:个'],
    ['政治', 'politics; political'],
    ['正', 'upright; centrally located; (of time or figure) exactly; precisely; correct; positive (maths); (of taste) pure'],
    ['证件', 'certificate; credentials'],
    ['证据', 'evidence; proof; testimony'],
    ['支', 'to prop up; to bear; to send away; to pay or draw money; to support; to sustain; to erect; to raise; branch; division; surname Zhi; classifier for rods such as pens and guns, for army divisions and for songs or compositions; watt, classifier for power of light bulbs'],
    ['支票', 'check (bank); cheque; CL:本[ben3]'],
    ['执照', 'license; permit'],
    ['直', 'directly; straight; to straighten; fair and reasonable; frank; straightforward; (indicates continuing motion or action); vertical; vertical downward stroke in Chinese characters'],
    ['指导', 'to guide; to give directions; to direct; to coach; guidance; tuition; CL:个'],
    ['指挥', 'commander; to command; to conduct; to direct; director; conductor (of an orchestra); CL:个'],
    ['制定', 'to draw up; to formulate; to lay down; to draft'],
    ['制度', 'system (e.g. political, administrative etc); institution; CL:个'],
    ['制造', 'to engineer; to create; to manufacture; to make'],
    ['制作', 'to make; to manufacture'],
    ['志愿者', 'volunteer'],
    ['智慧', 'wisdom; knowledge; intelligent; intelligence'],
    ['治疗', 'to treat; to cure; medical treatment; cure'],
    ['秩序', 'order (orderly, sequence, social); the state (of society); CL:个'],
    ['至今', 'until now; so far; to this day'],
    ['至于', 'as for; as to; to go so far as to'],
    ['中介', 'to act as intermediary; to link; intermediate; inter-; agency; agent'],
    ['中心', 'center; heart (fig.); core; CL:个'],
    ['中旬', 'middle third of a month'],
    ['种类', 'kind; genus; type; category; variety; species; sort; class;'],
    ['重大', 'important; great; major; significant;'],
    ['重量', 'weight; CL:个'],
    ['周到', 'thoughtful; considerate; attentive; thorough'],
    ['猪', 'pork; hog; pig; swine; CL:口[kou3] ,头[tou2]'],
    ['竹子', 'bamboo; CL:棵[ke1] ,支[zhi1] ,根[gen1]'],
    ['逐步', 'progressively; step by step'],
    ['逐渐', 'gradually'],
    ['主持', 'to take charge of; to manage or direct; to preside over; to uphold; to stand for (eg justice); to host (a TV or radio program etc)'],
    ['主动', 'to take the initiative; to do sth of one\'s own accord; active; opposite: passive 被被动[bei4 dong4]; drive (of gears and shafts etc)'],
    ['主观', 'subjective'],
    ['主人', 'master; host; owner; CL:个'],
    ['主任', 'director; head; CL:個|个[ge4];'],
    ['主题', 'theme; subject'],
    ['主席', 'chairperson; premier; chairman; CL:个,位[wei4]'],
    ['主张', 'to advocate; to stand for; view; position; stand; proposition; viewpoint; assertion; CL:个'],
    ['煮', 'to cook; to boil'],
    ['注册', 'to register; to enroll'],
    ['祝福', 'blessings; wish well'],
    ['抓', 'to grab; to catch; to arrest; to snatch; to scratch;'],
    ['抓紧', 'to grasp firmly; to pay special attention to; to rush in; to make the most of'],
    ['专家', 'expert; specialist; CL:个'],
    ['专心', 'to concentrate; absorption; concentration; engrossed'],
    ['转变', 'to change; to transform; CL:个'],
    ['转告', 'to pass on; to communicate; to transmit'],
    ['装', 'to pretend; to attire; outfit; adornment; to adorn; to dress; clothing; costume (of an actor in a play); to play a role; to pretend; to install; to fix; to wrap (sth in a bag); to load; to pack'],
    ['装饰', 'to decorate; decoration; decorative; ornamental'],
    ['装修', 'to fit up; to renovate; to decorate; interior decoration;'],
    ['撞', 'to hit; to strike; to meet by accident; to run into; to bump against; to bump into'],
    ['状况', 'condition; state; situation; CL:个'],
    ['状态', 'state of affairs; state; mode; situation; CL:个'],
    ['追', 'to sculpt; to carve; musical instrument (old);'],
    ['追求', 'to pursue (a goal etc) stubbornly; to seek after; to woo'],
    ['咨询', 'to consult; to seek advice; consultation; (sales) inquiry (formal)'],
    ['姿势', 'posture; position'],
    ['资格', 'qualifications; seniority'],
    ['资金', 'funds; funding; capital'],
    ['资料', 'material; resources; data; information; profile (Internet); CL:份[fen4] ,个'],
    ['资源', 'resources (natural, manpower etc)'],
    ['紫', 'purple; violet; amethyst; Lithospermum erythrorhizon (flowering plant whose root provides red purple dye); Japanese: murasaki'],
    ['字母', 'letter (of the alphabet); alphabet; CL:个'],
    ['字幕', 'caption; subtitle'],
    ['自从', 'since (a time); ever since'],
    ['自动', 'automatic; voluntarily'],
    ['自豪', 'pride; to be proud of sth (in a good way)'],
    ['自觉', 'conscious; aware; on one\'s own initiative; conscientious'],
    ['自私', 'selfish; selfishness; self-centred'],
    ['自由', 'freedom; free; liberty; CL:个'],
    ['自愿', 'voluntary; of one\'s own free will'],
    ['综合', 'to sum up; to integrate; to synthesize; comprehensive; composite; synthesized; t'],
    ['总裁', 'chairman; director-general (of a company etc); CEO'],
    ['总共', 'altogether; in sum; in all; in total'],
    ['总理', 'premier; prime minister; CL:个,位[wei4] ,名[ming2]'],
    ['总算', 'at long last; finally; on the whole'],
    ['总统', 'president (of a country); CL:个,位[wei4] ,名[ming2] ,届[jie4]'],
    ['总之', 'in a word; in short; in brief'],
    ['组', 'to form; to organize; class; section; classifier for sets, series, groups of people, batteries'],
    ['组成', 'to form; to make up; to compose'],
    ['组合', 'to assemble; combination; combinatorial'],
    ['组织', 'to organize; organization; organized system; nerve; tissue; CL:个'],
    ['阻止', 'to prevent; to block'],
    ['最初', 'first; primary; initial'],
    ['醉', 'intoxicated; drunk; addicted to'],
    ['尊敬', 'to respect; to revere; to honour'],
    ['遵守', 'to comply with; to abide by; to respect (an agreement)'],
    ['作品', 'work (of art); opus; CL:部[bu4] ,篇[pian1]'],
    ['作为', 'one\'s conduct; deed; activity; accomplishment; achievement; to act as; as (in the capacity of); qua; to view as; to look upon (sth as); to take sth to be'],
    ['作文', 'to write an essay; composition (student essay); CL:篇[pian1]'],
];