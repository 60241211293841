export const hsk3Wordlist: [string, string][] = [
    ['啊', 'modal particle ending sentence, showing affirmation, approval, or consent'],
    ['阿姨', 'maternal aunt; step-mother; childcare worker; nursemaid; woman of similar age to one\'s parents (term of address used by child); CL:个'],
    ['矮', 'low; short (in length)'],
    ['爱好', 'interest; hobby; appetite for; CL:个'],
    ['安静', 'quiet; peaceful; calm'],
    ['把', 'to hold; to contain; to grasp; to take hold of; a handle; particle marking the following noun as a direct object; classifier for objects with handle'],
    ['搬', 'to move; to shift; to remove; to transport; to apply indiscriminately; to copy mechanically'],
    ['班', 'team; class; squad; work shift; classifier for groups; ranking; surname Ban; CL:个'],
    ['办法', 'means; method; way (of doing sth); CL:条[tiao2] ,个'],
    ['办公室', 'an office; business premises; a bureau; CL:间[jian1]'],
    ['半', 'half; semi-; incomplete; (after a number) and a half'],
    ['帮忙', 'to help; to lend a hand; to do a favor; to do a good turn'],
    ['包', 'to cover; to wrap; to hold; to include; to take charge of; package; wrapper; container; bag; to hold or embrace; bundle; packet; to contract (to or for); surname Bao; CL:个,只[zhi1]'],
    ['饱', 'to eat till full; satisfied'],
    ['北方', 'north; the northern part a country; China north of the Yellow River'],
    ['被', 'by (indicates passive-voice sentences or clauses); quilt; to cover (literary)'],
    ['鼻子', 'nose; CL:个,只[zhi1]'],
    ['比较', 'compare; contrast; fairly; comparatively; relatively; quite; rather'],
    ['比赛', 'competition (sports etc); match; CL:场[chang3] ,次[ci4]'],
    ['笔记本', 'notebook; CL:本[ben3]'],
    ['必须', 'to have to; must; compulsory; necessarily'],
    ['变化', 'change; variation; to change; to vary; CL:个'],
    ['别人', 'others'],
    ['冰箱', 'icebox; freezer cabinet; refrigerator; CL:台[tai2] ,个'],
    ['不但…而且…', 'not only ... but also...'],
    ['菜单', 'menu; CL:份[fen4] ,张[zhang1]'],
    ['参加', 'to participate; to take part; to join'],
    ['草', 'grass; straw; manuscript; draft (of a document); careless; rough; CL:棵[ke1] ,撮[zuo3] ,株[zhu1] ,根[gen1]'],
    ['层', 'layer; stratum; laminated; floor (of a building); storey; classifier for layers; repeated; sheaf (math.)'],
    ['差', 'differ from; short of; to lack; poor'],
    ['超市', 'supermarket (abbr.); CL:家[jia1]'],
    ['衬衫', 'shirt; blouse; CL:件[jian4]'],
    ['城市', 'city; town; CL:座[zuo4]'],
    ['成绩', 'achievement; performance records; grades; CL:项[xiang4] ,个'],
    ['迟到', 'to arrive late'],
    ['除了', 'besides; apart from (... also...); in addition to; except (for)'],
    ['船', 'a boat; vessel; ship; CL:条[tiao2] ,艘[sou1] ,只[zhi1]'],
    ['春', 'spring (time); gay; joyful; youthful; love; lust; life'],
    ['词典', 'dictionary (of Chinese compound words); CL:部[bu4] ,本[ben3]'],
    ['聪明', 'acute (of sight and hearing); clever; intelligent; bright; smart'],
    ['打扫', 'to clean; to sweep'],
    ['打算', 'to plan; to intend; to calculate; plan; intention; calculation; CL:个'],
    ['带', 'band; belt; girdle; ribbon; tire; area; zone; region; to wear; to carry; to lead; to bring; to look after; to raise; CL:条[tiao2];'],
    ['担心', 'anxious; worried; uneasy; to worry; to be anxious'],
    ['蛋糕', 'cake; CL:块[kuai4] ,个'],
    ['当然', 'of course; without doubt; only natural; as it should be; certainly;'],
    ['地', '-ly; structural particle: used before a verb or adjective, linking it to preceding modifying adverbial adjunct;'],
    ['灯', 'lamp; light; lantern; CL:盏[zhan3]'],
    ['地方', 'region; regional (away from the central administration)'],
    ['地铁', 'subway; metro'],
    ['地图', 'map; CL:张[zhang1] ,本[ben3]'],
    ['电梯', 'elevator; CL:台[tai2] ,部[bu4]'],
    ['电子邮件', 'electronic mail; email; CL:封[feng1]'],
    ['东', 'east; host (i.e. sitting on east side of guest); landlord; surname Dong'],
    ['冬', 'winter'],
    ['动物', 'animal; CL:只[zhi1] ,群[qun2] ,个'],
    ['短', 'short or brief; to lack; weak point; fault'],
    ['段', 'paragraph; section; segment; stage (of a process); classifier for stories, periods of time, lengths of thread etc'],
    ['锻炼', 'to engage in physical exercise; to toughen; to temper'],
    ['多么', 'how (wonderful etc); what (a great idea etc); however (difficult it may be etc)'],
    ['饿', 'to be hungry; hungry'],
    ['耳朵', 'ear; CL:只[zhi1] ,个,对[dui4]'],
    ['发', 'to send out; to show (one\'s feeling); to issue; to develop; classifier for gunshots (rounds)'],
    ['发烧', 'have a high temperature (from illness); have a fever'],
    ['发现', 'to find; to discover'],
    ['方便', 'convenient; to help out; to make things easy for people; convenience; suitable; having money to spare; (euphemism) to go to the toilet'],
    ['放', 'to release; to free; to let go; to put; to place; to let out; to set off (fireworks)'],
    ['放心', 'to set one\'s mind at rest; to be at ease; to rest'],
    ['分', 'to divide; to separate; to allocate; to distinguish (good and bad); part or subdivision; fraction; one tenth (of certain units); unit of length equivalent to 0.33 cm; minute; a point (in sports or games); 0.01 yuan (unit of money)'],
    ['复习', 'to revise; to review; revision; CL:次[ci4]'],
    ['附近', '(in the) vicinity; nearby; neighboring; next to'],
    ['干净', 'neat and tidy; clean'],
    ['感冒', 'to catch cold; (common) cold; CL:次[ci4]'],
    ['感兴趣', 'to be interested;'],
    ['刚才', 'just now; a moment ago'],
    ['个子', 'height; stature; build; size'],
    ['根据', 'according to; based on; basis; foundation; CL:个'],
    ['跟', 'with (someone); towards; as (compared to); and (in addition to); heel; to follow closely; to go with; to marry sb (of woman)'],
    ['更', 'more; still more; even more'],
    ['公斤', 'kilogram (kg)'],
    ['公园', 'public park; CL:场[chang3]'],
    ['故事', 'story; tale; narrative'],
    ['刮风', 'to be windy'],
    ['关', 'to close; to shut; to turn off; mountain pass; to concern; to involve'],
    ['关系', 'relationship (network); relation; to concern; to affect; to have to do with; guanxi; CL:个'],
    ['关心', 'to care for sth; caring; concerned'],
    ['关于', 'pertaining to; concerning; regarding; with regards to; about; a matter of'],
    ['国家', 'country; nation; state; CL:个'],
    ['过', 'to cross; to go over; to pass (time); to get along'],
    ['过去', '(in the) past; former; previous; to go over; to pass by'],
    ['还是', 'or; still; nevertheless'],
    ['害怕', 'to be afraid; to be scared'],
    ['黑板', 'blackboard; CL:块[kuai4] ,个'],
    ['后来', 'afterwards; later'],
    ['护照', 'passport; CL:本[ben3] ,个'],
    ['花', 'to blossom; to spend (money, time)'],
    ['花', 'flower; blossoms; bloom: pattern: design'],
    ['画', 'to draw; picture; painting; CL:幅[fu2] ,张[zhang1]'],
    ['坏', 'bad; spoiled; broken; to break down'],
    ['欢迎', 'to welcome; welcome'],
    ['环境', 'environment; circumstances; surroundings; ambient; CL:个'],
    ['还', 'to come back; to give back; to return; to pay back'],
    ['换', 'change; exchange'],
    ['黄河', 'Yellow River'],
    ['回答', 'to reply; to answer; the answer; CL:个'],
    ['会议', 'meeting; conference; CL:场[chang3] ,届[jie4]'],
    ['或者', 'or; possibly; maybe; perhaps'],
    ['几乎', 'almost; nearly; practically'],
    ['机会', 'opportunity; chance; occasion; CL:个'],
    ['极', 'extremely; pole (geography, physics); utmost; top'],
    ['季节', 'time; season; period; CL:个'],
    ['记得', 'to remember'],
    ['检查', 'inspection; to examine; to inspect; CL:次[ci4]'],
    ['简单', 'simple; not complicated'],
    ['健康', 'health; healthy'],
    ['见面', 'to meet; to see sb; CL:次[ci4]'],
    ['讲', 'to explain; to speak; to negotiate; to emphasise; to be particular about; as far as sth is concerned; speech; lecture'],
    ['教', 'to teach'],
    ['脚', 'foot; leg; base; kick; CL:双[shuang1] ,只[zhi1]'],
    ['角', 'horn; angle; corner ; horn-shaped; unit of money equal to 0.10 yuan; CL:个'],
    ['接', 'to receive; to answer (the phone); to meet or welcome sb; to connect; to catch; to join; to extend; to take one\'s turn on duty; take over for sb'],
    ['街道', 'street; CL:条[tiao2]'],
    ['结婚', 'to marry; to get married; CL:次[ci4]'],
    ['结束', 'to finish; to end; to conclude; to close; termination'],
    ['节目', 'program; item (on a program); CL:台[tai2] ,个,套[tao4]'],
    ['节日', 'holiday; festival; CL:个'],
    ['解决', 'to settle (a dispute); to resolve; to solve'],
    ['借', 'to lend; to borrow; excuse; pretext; by means of; to seize (an opportunity); to take (an opportunity)'],
    ['经常', 'day to day; everyday; daily; frequently; constantly; regularly; often'],
    ['经过', 'to pass; to go through; process; course; CL:个'],
    ['经理', 'manager; director; CL:个,位[wei4] ,名[ming2]'],
    ['久', '(long) time; (long) duration of time'],
    ['旧', 'old; opposite: new 新; former; worn (with age)'],
    ['句子', 'sentence; CL:个'],
    ['决定', 'to decide (to do something); to resolve; decision; certainly; CL:个,项[xiang4]'],
    ['可爱', 'amiable; cute; lovely'],
    ['渴', 'thirsty'],
    ['刻', 'quarter (hour); moment; to carve; to engrave; to cut; oppressive; classifier for short time intervals'],
    ['客人', 'visitor; guest; customer; client; CL:位[wei4]'],
    ['空调', 'air conditioning'],
    ['口', 'mouth; classifier for things with mouths (people, domestic animals, cannons, wells etc)'],
    ['哭', 'to cry; to weep'],
    ['裤子', 'trousers; pants; CL:条[tiao2]'],
    ['筷子', 'chopsticks; CL:对[dui4] ,根[gen1] ,把[ba3] ,双[shuang1]'],
    ['蓝', 'blue; indigo plant'],
    ['老', 'old (of people); venerable (person); prefix used before the surname of a person or a numeral indicating the order of birth of the children in a family or to indicate affection or familiarity; experienced; of long standing; always; all the time; of the past; very; outdated; (of meat etc) tough'],
    ['离开', 'to depart; to leave'],
    ['礼物', 'gift; present; CL:件[jian4] ,个,份[fen4]'],
    ['历史', 'History; CL:门[men2] ,段[duan4]'],
    ['脸', 'face; CL:张[zhang1] ,个'],
    ['练习', 'exercise; drill; practice; CL:个'],
    ['辆', 'classifier for vehicles'],
    ['聊天', 'to chat; to gossip'],
    ['了解', 'to understand; to realize; to find out'],
    ['邻居', 'neighbor; next door; CL:个'],
    ['留学', 'to study abroad'],
    ['楼', 'house with more than 1 story; storied building; floor; CL:层[ceng2] ,座[zuo4] ,栋[dong4]'],
    ['绿', 'green'],
    ['马', 'horse; abbr. for Malaysia,CL:匹'],
    ['马上', 'right away; at once; immediately; lit. on horseback'],
    ['满意', 'satisfied; pleased; to one\'s satisfaction'],
    ['帽子', 'hat; cap; CL:顶[ding3]'],
    ['米', 'rice; meter (classifier); CL:粒[li4]'],
    ['面包', 'bread; CL:片[pian4] ,袋[dai4] ,块[kuai4]'],
    ['明白', 'to understand (clearly); to realize; clear; obvious; unequivocal;'],
    ['拿', 'to hold; to seize; to catch; to apprehend; to take'],
    ['奶奶', '(informal) father\'s mother; paternal grandmother; CL:位[wei4]'],
    ['南', 'south'],
    ['难', 'difficult (to...); problem; difficulty; difficult; not good'],
    ['难过', 'feel sorry; be grieved'],
    ['年级', 'grade; CL:个'],
    ['年轻', 'young'],
    ['鸟', 'bird; CL:只[zhi1] ,群[qun2]'],
    ['努力', 'great effort; to strive; to try hard'],
    ['爬山', 'to climb a mountain; to mountaineer; hiking; mountaineering'],
    ['盘子', 'plate; tray; dish'],
    ['胖', 'fat; plump'],
    ['啤酒', 'beer; CL:杯[bei1] ,瓶[ping2] ,罐[guan4] ,桶[tong3] ,缸[gang1]'],
    ['皮鞋', 'leather shoes'],
    ['瓶子', 'bottle; CL:个'],
    ['其实', 'actually; in fact; really'],
    ['其他', 'other; the others; else; other than (that person); in addition to the person mentioned above'],
    ['奇怪', 'strange; odd'],
    ['骑', 'to ride (an animal or bike); to sit astride'],
    ['起飞', 'to take off (in an airplane)'],
    ['起来', 'beginning or continuing an action; upward movement; stand up'],
    ['清楚', '（be) clear (about); clearly understood; clear; distinct'],
    ['请假', 'ask for time off'],
    ['秋', 'autumn; fall; harvest time; a swing; surname Qiu'],
    ['裙子', 'skirt; CL:条[tiao2]'],
    ['然后', 'then (afterwards); after that; afterwards; after'],
    ['热情', 'cordial; enthusiastic; passion; passionate; passionately'],
    ['认为', 'to believe; to think; to consider; to feel'],
    ['认真', 'conscientious; earnest; serious; to take seriously; to take to heart'],
    ['容易', 'easy; likely; liable (to)'],
    ['如果', 'if; in case; in the event that'],
    ['伞', 'umbrella; parasol; CL:把[ba3]'],
    ['上网', 'to be on the internet; to stretch a net (in a sports game or for covering sth); to be netted (of fish)'],
    ['声音', 'voice; sound; CL:个'],
    ['生气', 'angry; mad; offended; animated; to get angry; to be enraged; to take offense; animation'],
    ['世界', 'world; CL:个'],
    ['试', 'to test; to try; experiment; examination; test'],
    ['瘦', 'tight; thin; lean'],
    ['叔叔', 'father\'s younger brother; uncle; CL:个'],
    ['舒服', 'comfortable; feeling well'],
    ['数学', 'mathematics; mathematical'],
    ['树', 'tree; CL:棵[ke1]'],
    ['刷牙', 'to brush teeth'],
    ['双', 'pair; double; two; both'],
    ['水平', 'level (of achievement etc); standard; horizontal'],
    ['司机', 'chauffeur; driver; CL:个'],
    ['太阳', 'sun; CL:个'],
    ['特别', 'especially; special; particular; unusual'],
    ['疼', '(it) hurts; love fondly; ache; pain; sore'],
    ['提高', 'to raise; to increase'],
    ['体育', 'sports; physical education'],
    ['甜', 'sweet'],
    ['条', 'strip; item; article; clause (of law or treaty); classifier for long thin things (ribbon, river, road, trousers etc)'],
    ['同事', 'colleague; co-worker; CL:个,位[wei4]'],
    ['同意', 'to agree; to consent; to approve'],
    ['头发', 'hair (on the head)'],
    ['突然', 'sudden; abrupt; unexpected'],
    ['图书馆', 'library; CL:家[jia1] ,个'],
    ['腿', 'leg; CL:条[tiao2]'],
    ['完成', 'complete; accomplish; perfect tense (grammar)'],
    ['碗', 'bowl; cup; CL:只[zhi1] ,个'],
    ['万', 'ten thousand; a great number'],
    ['忘记', 'to forget'],
    ['为', 'because of; for; to'],
    ['为了', 'in order to; for the purpose of; so as to'],
    ['位', 'position; location; place; seat; classifier for people (honorific); classifier for binary bits (e.g. 十六位 16-bit or 2 bytes)'],
    ['文化', 'culture; civilization; cultural; CL:个,种[zhong3]'],
    ['西', 'west'],
    ['习惯', 'habit; custom; usual practice; to be used to; CL:个'],
    ['洗手间', 'toilet; lavatory; washroom'],
    ['洗澡', 'to bathe; to take a shower'],
    ['夏', 'summer'],
    ['先', 'early; prior; former; in advance; first'],
    ['相信', 'to believe; to be convinced (that something is true); to accept sth as true'],
    ['香蕉', 'banana; CL:枝[zhi1] ,根[gen1] ,个,把[ba3]'],
    ['像', '(look) like; similar (to); appearance; to appear; to seem; image; portrait; resemble; seem'],
    ['向', 'direction; orientation; to face; to turn toward; to; towards; shortly before; formerly; to side with; to be partial to; all along (previously); surname Xiang'],
    ['小心', 'to be careful; to take care'],
    ['校长', '(college, university) president; headmaster; CL:个,位[wei4] ,名[ming2]'],
    ['新闻', 'news; CL:条[tiao2] ,个'],
    ['新鲜', 'fresh (experience, food etc); freshness'],
    ['信用卡', 'credit card'],
    ['行李箱', 'suitcase'],
    ['熊猫', 'panda; CL:只[zhi1]'],
    ['需要', 'to need; to want; to demand; needs; to require'],
    ['选择', 'to select; to pick; choice; option; alternative'],
    ['要求', 'to request; to require; to stake a claim; to ask; to demand; CL:点[dian3]'],
    ['爷爷', '(informal) father\'s father; paternal grandfather; CL:个'],
    ['一直', 'straight (in a straight line); continuously; always; from the beginning of... up to...; all along'],
    ['一定', 'surely; certainly; necessarily; fixed; a certain (extent etc); given; particular; must'],
    ['一共', 'altogether'],
    ['一会儿', 'a while'],
    ['一样', 'same; like; equal to; the same as; just like'],
    ['以前', 'before; formerly; previous; ago'],
    ['一般', 'ordinary; common; general; generally; in general; same'],
    ['一边', 'one side; either side; on the one hand; on the other hand; doing while'],
    ['音乐', 'music; CL:张[zhang1] ,曲[qu3] ,段[duan4]'],
    ['银行', 'bank; CL:家[jia1] ,个'],
    ['饮料', '(a) drink; beverage'],
    ['应该', 'ought to; should; must'],
    ['影响', 'influence; effect; to influence; to affect (usually adversely); to disturb; CL:股[gu3]'],
    ['用', 'to use; to employ; to have to; to eat or drink; expense or outlay; usefulness; hence; therefore'],
    ['游戏', 'game; play; CL:场[chang3]'],
    ['有名', 'famous; well-known'],
    ['又', '(once) again; also; both... and...; again'],
    ['遇到', 'to meet; to run into; to come across'],
    ['元', 'Yuan (RMB); first; primary; basic'],
    ['愿意', 'to wish; to want; ready; willing (to do sth)'],
    ['月亮', 'moon'],
    ['越', 'to exceed; to climb over; to surpass; the more... the more'],
    ['站', 'station; to stand; to halt; to stop; branch of a company or organisation'],
    ['张', 'classifier for flat objects, sheet; sheet of paper; classifier for votes; to open up; to spread;'],
    ['长', 'elder; senior; to grow; to develop; to come into being; to acquire; to enhance; to increase'],
    ['着急', 'to worry; to feel anxious'],
    ['照顾', 'to take care of; to show consideration; to attend to; to look after'],
    ['照片', 'photo; photograph; picture; CL:张[zhang1] ,套[tao4] ,幅[fu2]'],
    ['照相机', 'camera; CL:个,架[jia4] ,部[bu4] ,台[tai2] ,只[zhi1]'],
    ['只', 'only; just; merely'],
    ['只', 'single; one-only'],
    ['只有…才…', 'only if… then…'],
    ['中间', 'between; intermediate; mid; middle'],
    ['中文', 'Chinese written language; Chinese writing; Chinese'],
    ['终于', 'at last; in the end; finally; eventually'],
    ['种', 'kind; sort; type'],
    ['重要', 'important; significant; major'],
    ['周末', 'weekend'],
    ['主要', 'main; principal; major; primary'],
    ['注意', 'to take note of; to pay attention to'],
    ['自己', 'self; (reflexive pronoun); own'],
    ['自行车', 'bicycle; bike; CL:辆[liang4]'],
    ['总是', 'always'],
    ['嘴', 'mouth; beak; spout (of teapot etc); CL:张[zhang1] ,个'],
    ['最后', 'final; last; finally; ultimate'],
    ['最近', 'recent; recently; these days; latest; soon; nearest (of locations); shortest (of routes)'],
    ['作业', 'school assignment; homework; work; task; operation; to operate; CL:个'],
];