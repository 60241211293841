export const hsk2Wordlist: [string, string][] = [
    ['吧', 'modal particle indicating polite suggestion;...right?;...OK?'],
    ['白', 'white; snowy; pure; bright; empty; blank; plain; clear; to make clear; in vain; gratuitous; free of charge; reactionary; anti-communist; funeral; to stare coldly; to write wrong character; to state; to explain; vernacular; spoken lines in opera; surname Bai'],
    ['百', 'hundred; numerous; all kinds of; surname Bai'],
    ['帮助', 'assistance; aid; to help; to assist'],
    ['报纸', 'newspaper; newsprint; CL:份[fen4] ,期[qi1] ,张[zhang1]'],
    ['比', 'particle used for comparison and \'-er than\'; to compare; to contrast; to gesture (with hands); ratio'],
    ['别', 'do not; must not; to pin; to leave; to depart; to separate; to distinguish; to classify; other; another'],
    ['宾馆', 'guesthouse; CL:个,家[jia1]'],
    ['长', 'long; length'],
    ['唱歌', 'to sing a song'],
    ['出', 'to go out; to come out; to occur; to produce; to go beyond; to rise; to put forth; to happen; classifier for dramas, plays, operas etc'],
    ['穿', 'to wear; to put on; to dress; to bore through; pierce; perforate; penetrate; pass through; to thread'],
    ['次', 'next in sequence; second; the second (day, time etc); secondary; vice-; sub-; infra-; inferior quality; substandard; order; sequence; hypo- (chemistry); classifier for enumerated events: time'],
    ['从', 'from; via; passing through; through'],
    ['错', 'mistake; error; blunder; fault; cross; uneven; wrong; CL:个'],
    ['打篮球', 'play basketball'],
    ['大家', 'everyone'],
    ['到', 'to (a place); until (a time); up to; to go; to arrive'],
    ['得', 'structural particle: used after a verb (or adjective as main verb), linking it to following phrase indicating effect, degree, possibility etc;'],
    ['等', 'to wait for; to await; class (grade); et cetera; and so on; et al. (and other authors); after; as soon as; once;'],
    ['弟弟', 'younger brother; CL:个,位[wei4]'],
    ['第一', 'first; number one'],
    ['懂', 'to understand; to know'],
    ['对', 'towards; at; for'],
    ['对', 'right; correct'],
    ['房间', 'room; CL:间[jian1]'],
    ['非常', 'extraordinary; unusual; extreme; very; exceptional'],
    ['服务员', 'waiter; waitress; attendant; customer service personnel; CL:个'],
    ['高', 'high; tall; above average; loud; your (honorific); surname Gao'],
    ['告诉', 'to tell; to inform; to let know'],
    ['哥哥', 'older brother; CL:个,位[wei4]'],
    ['给', 'to (someone); for; for the benefit of; to give; to allow; to do sth (for sb); (passive particle)'],
    ['公共汽车', 'bus; CL:辆[liang4] ,班[ban1]'],
    ['公司', '(business) company; company; firm; corporation; incorporated; CL:家[jia1]'],
    ['贵', 'expensive; noble; your (name); precious'],
    ['过', '(used after a verb) to indicate the completion of an action'],
    ['孩子', 'child'],
    ['还', 'still; yet; even more; still more; also; too; as well'],
    ['好吃', 'tasty; delicious'],
    ['黑', 'black; dark; abbr. for Heilongjiang 黑龙江 province in northeast China'],
    ['红', 'red; bonus; popular; revolutionary'],
    ['火车站', 'train station'],
    ['机场', 'airport; airfield; CL:家[jia1] ,处[chu4]'],
    ['鸡蛋', '(chicken) egg; hen\'s egg; CL:个,打[da2]'],
    ['件', 'item; component; classifier for events, things, clothes etc'],
    ['教室', 'classroom; CL:间[jian1]'],
    ['姐姐', 'older sister; CL:个'],
    ['介绍', 'to present; to introduce; to recommend; to suggest; to let know; to brief'],
    ['近', 'near; close to; approximately'],
    ['进', 'to enter; to advance; to come (or go) into; to receive or admit; to eat or drink; to submit or present; (used after a verb) into, in; to score a goal'],
    ['就', 'just (emphasis); at once; right away; only; as early as; already; as soon as; then; in that case; as many as; even if; to approach; to move towards; to undertake; to engage in; to suffer; subjected to; to accomplish; to take advantage of; to go with (of foods); with regard to; concerning'],
    ['觉得', 'to think; to feel'],
    ['咖啡', 'coffee; CL:杯[bei1]'],
    ['开始', 'to begin; beginning; to start; initial; CL:个'],
    ['考试', 'exam; CL:次[ci4]'],
    ['可能', 'might (happen); possible; probable; possibility; probability; maybe; perhaps; CL:个'],
    ['可以', 'can; may; possible; able to'],
    ['课', 'subject; course; class; lesson; CL:堂[tang2] ,节[jie2] ,门[men2]'],
    ['快', 'rapid; quick; speed; rate; soon; almost; to make haste; clever; sharp (of knives or wits); forthright; plain-spoken; gratified; pleased; pleasant'],
    ['快乐', 'happy; merry'],
    ['累', 'tired; weary; to strain; to wear out; to work hard'],
    ['离', 'to leave; to part from; to be away from; (in giving distances) from; without (sth); independent of; one of the eight trigrams of the Book of Changes representing fire (old)'],
    ['两', 'two (quantities); both; ounce; some; a few; tael; weight equal to 50 grams'],
    ['零', 'zero; nought; zero sign; fractional; fragmentary; odd (of numbers); (placed between two numbers to indicate a smaller quantity followed by a larger one); fraction; (in mathematics) remainder (after division)'],
    ['路', 'road; path; way; CL:条[tiao2]'],
    ['旅游', 'trip; journey; tourism; travel; tour'],
    ['卖', 'to sell; to betray; to spare no effort; to show off or flaunt'],
    ['慢', 'slow'],
    ['忙', 'busy; hurriedly'],
    ['每', 'each; every'],
    ['妹妹', 'younger sister; fig. younger woman (esp. girl friend or rival); CL:个'],
    ['门', 'gate; doo; gateway; doorway; opening; valve; switch; way to do something; knack; family; house; (religious) sect; school (of thought); class; category; phylum or division (taxonomy); classifier for large guns; classifier for lessons, subjects, branches of technologyr; CL:个, 扇[shan4]'],
    ['面条', 'noodles'],
    ['男', 'male; Baron, lowest of five orders of nobility 五等爵位[wu3 deng3 jue2 wei4]; CL:個|个[ge4];'],
    ['您', 'you (polite); as opposed to informal you 你[ni3])'],
    ['牛奶', 'cow\'s milk; CL:瓶[ping2] ,杯[bei1]'],
    ['女', 'female; woman; daughter;'],
    ['旁边', 'lateral; side; to the side; beside'],
    ['跑步', 'to walk quickly; to march; to run'],
    ['便宜', 'cheap; inexpensive; small advantages; to let sb off lightly'],
    ['票', 'ticket; ballot; bank note; person held for ransom; amateur performance of Chinese opera; classifier for shipments and business transactions (topolect); CL:张[Zhang1]'],
    ['妻子', 'wife; CL:个'],
    ['起床', 'to get up'],
    ['千', 'thousand'],
    ['铅笔', '(lead) pencil; CL:支[zhi1] ,枝[zhi1] ,杆[gan3]'],
    ['晴', 'clear; fine (weather)'],
    ['去年', 'last year'],
    ['让', 'to permit; to let sb do sth; to have sb do sthto yield'],
    ['日', 'day; day of the month; date; sun; abbr. for 日本 Japan'],
    ['上班', 'to go to work; to be on duty; to start work; to go to the office'],
    ['身体', '(human) body; health; CL:个'],
    ['生病', 'to fall ill; to sicken'],
    ['生日', 'birthday; CL:个'],
    ['时间', 'time; period; CL:段[duan4]'],
    ['事情', 'affair; matter; thing; business'],
    ['手表', 'wrist watch; CL:块[kuai4] ,只[zhi1] ,个'],
    ['手机', 'cell phone; cellular phone; mobile phone; CL:部[bu4]'],
    ['说话', 'to speak; to say; to talk; to gossip; to tell stories; talk; word'],
    ['送', 'to deliver; to carry; to give (as a present); to present (with); to see off; to send'],
    ['虽然…但是…', 'although…still…; even if…nevertheless...'],
    ['它', 'it (inanimate thing or animal)'],
    ['踢足球', 'play soccer (football)'],
    ['题', 'topic; problem for discussion; exam question; subject; to inscribe; to mention; surname Ti; CL:个,道[dao4]'],
    ['跳舞', 'to dance'],
    ['外', 'outside; in addition; foreign; external'],
    ['完', 'to finish; to be over; whole; complete; entire'],
    ['玩', 'to play; toy; sth used for amusement; curio or antique (Taiwan pr. wan4); to have fun; to trifle with; to keep sth for entertainment'],
    ['晚上', 'in the evening; CL:个'],
    ['往', 'to go (in a direction); to; towards; (of a train) bound for; past; previous'],
    ['为什么', 'for what reason?; why?'],
    ['问', 'to ask'],
    ['问题', 'question; problem; issue; topic; CL:个'],
    ['希望', 'to wish for; to desire; hope CL:个'],
    ['西瓜', 'watermelon; CL:条[tiao2]'],
    ['洗', 'to wash; to bathe'],
    ['小时', 'hour; CL:个'],
    ['笑', 'laugh; smile; CL:个'],
    ['新', 'new; newly; meso- (chemistry)'],
    ['姓', 'family name; surname; name; CL:个'],
    ['休息', 'rest; to rest'],
    ['雪', 'snow; snowfall; to have the appearance of snow; to wipe away, off or out; to clean CL:场[chang2]'],
    ['颜色', 'color; CL:个'],
    ['眼睛', 'eye; CL:只[zhi1] ,双[shuang1]'],
    ['羊肉', 'mutton'],
    ['药', 'medicine; drug; cure; CL:种[zhong3] ,服[fu4]'],
    ['要', 'to want; will; going to (as future auxiliary); important; vital; may; must'],
    ['也', 'also; too; (in classical Chinese) final particle serving as copula'],
    ['一下', '(used after a verb) give it a go; to do (sth for a bit to give it a try); one time; once; in a while; all of a sudden; all at once;'],
    ['已经', 'already'],
    ['一起', 'together; in the same place; with; altogether (in total)'],
    ['意思', 'idea; opinion; meaning; wish; desire; CL:个'],
    ['因为…所以…', 'because… thus…; on account of…then…'],
    ['阴', 'overcast (weather); cloudy; shady; Yin (the negative principle of Yin and Yang); negative (electric.); feminine; moon; implicit; hidden; genitalia'],
    ['游泳', 'swim'],
    ['右边', 'right side; right, to the right'],
    ['鱼', 'fish; CL:条[tiao2] ,尾[wei3]'],
    ['远', 'far; distant; remote'],
    ['运动', 'movement; campaign; sports; CL:场[chang3]'],
    ['再', 'again; once more; re-; second; another; then (after sth, and not until then)'],
    ['早上', 'early morning; Good morning!; CL:个'],
    ['丈夫', 'husband; CL:个'],
    ['找', 'to try to find; to look for; to call on sb; to find; to seek; to return; to give change'],
    ['着', 'particle attached after verb to indicate action in progress, like -ing ending'],
    ['真', 'really; truly; indeed; real; true; genuine'],
    ['正在', 'in the process of (doing something or happening); while (doing)'],
    ['知道', 'to know; to be aware of'],
    ['准备', 'preparation; prepare'],
    ['走', 'to walk; to go; to run; to move (of vehicle); to visit; to leave; to go away; to die (euph.); from; through; away (in compound verbs, such as 撤走); to change (shape, form, meaning)'],
    ['最', 'most; the most; -est'],
    ['左边', 'left; the left side; to the left of'],
];