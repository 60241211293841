// These are taken by hand from the web at http://hanzidb.org/character-list/by-frequency
// and stored privately in https://docs.google.com/spreadsheets/d/1b4V0k0h5n_ey7IHogO8741lM6dxnpri3z3KIxs6sqGA/edit#gid=0
// All characters without a given definition are excluded from this list, 79 of the top 5k characters.
export const junDaWordlist: [string, string][] = [
    ['的', "possessive, adjectival suffix"],
    ['一', "one; a, an; alone"],
    ['是', "indeed, yes, right; to be; demonstrative pronoun, this, that"],
    ['不', "no, not; un-; negative prefix"],
    ['了', "to finish; particle of completed action"],
    ['在', "be at, in, on; consist in, rest"],
    ['人', "man; people; mankind; someone else"],
    ['有', "have, own, possess; exist"],
    ['我', "our, us, i, me, my, we"],
    ['他', "other, another; he, she, it"],
    ['这', "this, the, here"],
    ['个', "numerary adjunct, piece; single"],
    ['们', "adjunct pronoun indicate plural"],
    ['中', "central; center, middle; in the midst of; hit (target); attain"],
    ['来', "come, coming; return, returning"],
    ['上', "top; superior, highest; go up, send up"],
    ['大', "big, great, vast, large, high"],
    ['为', "do, handle, govern, act; be"],
    ['和', "harmony, peace; peaceful, calm"],
    ['国', "nation, country, nation-state"],
    ['地', "earth; soil, ground; region"],
    ['到', "go to, arrive, been to"],
    ['以', "by means of; thereby, therefore; consider as; in order to"],
    ['说', "speak, say, talk; scold, upbraid"],
    ['时', "time, season; era, age, period"],
    ['要', "necessary, essential; necessity"],
    ['就', "just, simply; to come, go to; to approach, near"],
    ['出', "go out, send out; stand; produce"],
    ['会', "assemble, meet together; meeting"],
    ['可', "may, can, -able; possibly"],
    ['也', "also; classical final particle of strong affirmation or identity"],
    ['你', "you, second person pronoun"],
    ['对', "correct, right; facing, opposed"],
    ['生', "life, living, lifetime; birth"],
    ['能', "to be able; can, permitted to; ability"],
    ['而', "and; and then; and yet; but"],
    ['子', "offspring, child; fruit, seed of; 1st terrestrial branch"],
    ['那', "that, that one, those"],
    ['得', "obtain, get, gain, acquire"],
    ['于', "in, on, at; go to; surname"],
    ['着', "make move, take action"],
    ['下', "under, underneath, below; down; inferior; bring down"],
    ['自', "self, private, personal; from"],
    ['之', "marks preceding phrase as modifier of following phrase; it, him her, them; go to"],
    // ['年', ""],
    ['过', "pass, pass through, go across"],
    ['发', "issue, dispatch, send out; hair"],
    ['后', "queen, empress, sovereign; (simp. for 後) behind, rear, after"],
    ['作', "make; work; compose, write; act, perform"],
    ['里', "unit of distance; village; lane"],
    ['用', "use, employ, apply, operate; use"],
    ['道', "path, road, street; method, way"],
    ['行', "go; walk; move, travel; circulate"],
    ['所', "place, location; numerary adjunct"],
    ['然', "yes, certainly; pledge, promise"],
    ['家', "house, home, residence; family"],
    ['种', "seed; race, breed; to plant"],
    ['事', "affair, matter, business; to serve; accident, incident"],
    ['成', "completed, finished, fixed"],
    ['方', "a square, rectangle; a region; local"],
    ['多', "much, many; more than, over"],
    ['经', "classic works; pass through"],
    ['么', "interrogative particle; repetition of a tune small; tender"],
    ['去', "go away, leave, depart"],
    ['法', "law, rule, regulation, statute; France, French"],
    ['学', "learning, knowledge; school"],
    ['如', "if, supposing; as if; like, as"],
    ['都', "metropolis, capital; all, the whole; elegant, refined"],
    ['同', "same, similar; together with"],
    ['现', "appear, manifest, become visible"],
    ['当', "bear, accept, undertake; just"],
    ['没', "not, have not, none; drown, sink"],
    ['动', "move, happen; movement, action"],
    ['面', "face; surface; plane; side, dimension"],
    ['起', "rise, stand up; go up; begin"],
    ['看', "look, see; examine, scrutinize"],
    ['定', "decide, settle, fix"],
    ['天', "sky, heaven; god, celestial"],
    ['分', "divide; small unit of time etc."],
    ['还', "still, yet, also, besides"],
    ['进', "advance, make progress, enter"],
    ['好', "good, excellent, fine; well"],
    ['小', "small, tiny, insignificant"],
    ['部', "part, division, section"],
    ['其', "his, her, its, their; that"],
    ['些', "little, few; rather, somewhat"],
    ['主', "master, chief owner; host; lord"],
    ['样', "shape, form, pattern, style"],
    ['理', "reason, logic; manage"],
    ['心', "heart; mind, intelligence; soul"],
    ['她', "she, her"],
    ['本', "root, origin, source; basis"],
    ['前', "in front, forward; preceding"],
    ['开', "open; initiate, begin, start"],
    ['但', "only; but, however, yet, still"],
    ['因', "cause, reason; by; because (of)"],
    ['只', "only, just, simply"],
    ['从', "from, by, since, whence, through"],
    ['想', "think, speculate, plan, consider"],
    ['实', "real, true; honest, sincere"],
    ['日', "sun; day; daytime"],
    ['军', "army, military; soldiers, troops"],
    ['者', "that which; he who; those who"],
    ['意', "thought, idea, opinion; think"],
    ['无', "negative, no, not; KangXi radical 71"],
    ['力', "power, capability, influence"],
    ['它', "it; other"],
    ['与', "and; with; to; for; give, grant"],
    ['长', "long; length; excel in; leader"],
    ['把', "hold, take; guard; regard as"],
    ['机', "desk; machine; moment"],
    ['十', "ten, tenth; complete; perfect"],
    ['民', "people, subjects, citizens"],
    ['第', "sequence, number; grade, degree"],
    ['公', "fair, equitable; public; duke"],
    ['此', "this, these; in this case, then"],
    ['已', "already; finished; stop"],
    ['工', "labor, work; worker, laborer"],
    ['使', "cause, send on a mission, order; envoy, messenger, ambassador"],
    ['情', "feeling, sentiment, emotion"],
    ['明', "bright, light, brilliant; clear"],
    ['性', "nature, character, sex"],
    ['知', "know, perceive, comprehend"],
    ['全', "maintain, keep whole or intact"],
    ['三', "three"],
    ['又', "and, also, again, in addition"],
    ['关', "frontier pass; close; relation"],
    ['点', "dot, speck, spot; point, degree"],
    ['正', "right, proper, correct"],
    ['业', "profession, business; GB radical 111"],
    ['外', "out, outside, external; foreign"],
    ['将', "will, going to, future; general"],
    ['两', "two, both, pair, couple; ounce"],
    ['高', "high, tall; lofty, elevated"],
    ['间', "midpoint, space; place, locality"],
    ['由', "cause, reason; from"],
    ['问', "ask about, inquire after"],
    ['很', "very, quite, much"],
    ['最', "most, extremely, exceedingly"],
    ['重', "heavy, weighty; double"],
    // ['并', ""],
    ['物', "thing, substance, creature"],
    ['手', "hand"],
    ['应', "should, ought to, must"],
    ['战', "war, fighting, battle"],
    ['向', "toward, direction, trend"],
    ['头', "head; top; chief, first; boss"],
    ['文', "literature, culture, writing"],
    ['体', "body; group, class, body, unit; inferior"],
    ['政', "government, political affairs"],
    ['美', "beautiful, pretty; pleasing"],
    ['相', "mutual, reciprocal, each other"],
    ['见', "see, observe, behold; perceive"],
    // ['被', ""],
    ['利', "gains, advantage, profit, merit"],
    ['什', "file of ten soldiers; mixed, miscellaneous"],
    ['二', "two; twice"],
    // ['等', ""],
    ['产', "give birth, bring forth, produce"],
    ['或', "or, either, else; perhaps, maybe"],
    ['新', "new, recent, fresh, modern"],
    ['己', "self, oneself; personal, private; 6th heavenly stem"],
    ['制', "system; establish; overpower"],
    ['身', "body; trunk, hull; rad. no. 158"],
    ['果', "fruit; result"],
    ['加', "add to, increase, augment"],
    ['西', "west(ern); westward, occident"],
    ['斯', "this, thus, such; to lop off; emphatic particle"],
    ['月', "moon; month; KangXi radical 74"],
    ['话', "speech, talk, language; dialect"],
    ['合', "combine, unite, join; gather"],
    ['回', "return, turn around; a time"],
    ['特', "special, unique, distinguished"],
    ['代', "replace, replacement (of person or generation); era, generation"],
    ['内', "inside"],
    ['信', "trust, believe; letter"],
    ['表', "show, express, manifest, display"],
    ['化', "change, convert, reform; -ize"],
    ['老', "old, aged; experienced"],
    ['给', "give; by, for"],
    ['世', "generation; world; era"],
    ['位', "throne; position, post; rank, status; seat"],
    ['次', "order, sequence; next"],
    ['度', "degree, system; manner; to consider"],
    ['门', "gate, door, entrance, opening"],
    ['任', "trust to, rely on, appoint; to bear, duty, office; allow"],
    ['常', "common, normal, frequent, regular"],
    ['先', "first, former, previous"],
    ['海', "sea, ocean; maritime"],
    ['通', "pass through, common, communicate"],
    ['教', "teach, class"],
    ['儿', "son, child; KangXi radical 10"],
    ['原', "source, origin, beginning"],
    ['东', "east, eastern, eastward"],
    ['声', "sound, voice, noise; tone; music"],
    ['提', "hold in hand; lift in hand"],
    ['立', "stand; let stand; establish, set"],
    ['及', "extend; reach; come up to; and"],
    ['比', "to compare, liken; comparison; than"],
    ['员', "member; personnel, staff member"],
    ['解', "loosen, unfasten, untie; explain"],
    ['水', "water, liquid, lotion, juice"],
    ['名', "name, rank, title, position"],
    ['真', "real, actual, true, genuine"],
    ['论', "debate; discuss; discourse"],
    ['处', "place, locale; department"],
    ['走', "walk, go on foot; run; leave"],
    ['义', "right conduct, righteousness"],
    ['各', "each, individually, every, all"],
    ['入', "enter, come in(to), join"],
    ['几', "small table"],
    ['口', "mouth; open end; entrance, gate"],
    ['认', "recognize, know, understand"],
    ['条', "clause, condition; string, stripe"],
    ['平', "flat, level, even; peaceful"],
    ['系', "system; line, link, connection"],
    ['气', "steam, vapor; KangXi radical 84"],
    ['题', "forehead; title, headline; theme"],
    ['活', "live, exist, survive; lively"],
    ['尔', "you; that, those; final particle"],
    ['更', "more, still further, much more"],
    ['别', "separate, other; do not"],
    ['打', "strike, hit, beat; fight; attack"],
    ['女', "woman, girl; feminine; rad. 38"],
    ['变', "change, transform, alter; rebel"],
    ['四', "four"],
    ['神', "spirit, god, supernatural being"],
    ['总', "collect; overall, altogether"],
    ['何', "what, why, where, which, how"],
    ['电', "electricity; electric; lightning"],
    ['数', "number; several, count; fate"],
    ['安', "peaceful, tranquil, quiet"],
    ['少', "few, less, inadequate"],
    ['报', "report, tell, announce"],
    ['才', "talent, ability; just, only"],
    ['结', "knot, tie; join, connect"],
    ['反', "reverse, opposite, contrary, anti"],
    ['受', "receive, accept, get; bear, stand"],
    ['目', "eye; look, see; division, topic"],
    ['太', "very, too, much; big; extreme"],
    ['量', "measure, quantity, capacity"],
    ['再', "again, twice, re-"],
    ['感', "feel, perceive, emotion"],
    ['建', "build, establish, erect, found"],
    ['务', "affairs, business; must, should"],
    ['做', "work, make; act"],
    ['接', "receive; continue; catch; connect"],
    ['必', "surely, most certainly; must"],
    ['场', "open space, field, market"],
    ['件', "numerary adjunct for article; matter"],
    ['计', "plan, plot; strategem; scheme"],
    ['管', "pipe, tube, duct; woodwind music"],
    ['期', "period of time; date; time limit"],
    ['市', "market, fair; city, town; trade"],
    ['直', "straight, erect, vertical"],
    ['德', "ethics, morality, virtue"],
    ['资', "property; wealth; capital"],
    ['命', "life; destiny, fate, luck; an order, instruction"],
    ['山', "mountain, hill, peak"],
    ['金', "gold; metals in general; money"],
    ['指', "finger, toe; point, indicate"],
    ['克', "gram; overcome; transliteration"],
    ['许', "allow, permit; promise; betroth"],
    ['统', "govern, command, control; unite"],
    ['区', "area, district, region, ward"],
    ['保', "protect, safeguard, defend, care"],
    ['至', "reach, arrive; extremely, very"],
    ['队', "team, group; army unit"],
    ['形', "form, shape, appearance"],
    ['社', "god of the soil and altars to him; group of families; company, society"],
    ['便', "convenience, ease; expedient"],
    ['空', "empty, hollow, bare, deserted"],
    ['决', "decide, determine, judge"],
    ['治', "govern, regulate, administer"],
    ['展', "open, unfold; stretch, extend"],
    ['马', "horse; surname; KangXi radical 187"],
    ['科', "section, department, science"],
    ['司', "take charge of, control, manage; officer"],
    ['五', "five; surname"],
    ['基', "foundation, base"],
    ['眼', "eye; eyelet, hole, opening"],
    ['书', "book, letter, document; writings"],
    ['非', "not, negative, non-; oppose"],
    ['则', "rule, law, regulation; grades"],
    ['听', "hear; understand; obey, comply"],
    ['白', "white; pure, unblemished; bright"],
    ['却', "still, but; decline; retreat"],
    ['界', "boundary, limit; domain; society; the world"],
    ['达', "arrive at, reach; intelligent; smooth, slippery"],
    ['光', "light, brilliant, shine; only"],
    ['放', "put, release, free, liberate"],
    ['强', "strong, powerful, energetic"],
    ['即', "promptly, quickly, immediately"],
    ['像', "a picture, image, figure; to resemble"],
    ['难', "difficult, arduous, hard; unable"],
    ['且', "moreover, also (post-subject); about to, will soon (pre-verb)"],
    ['权', "power, right, authority"],
    ['思', "think, consider, ponder; final particle"],
    ['王', "king, ruler; royal; surname"],
    ['象', "elephant; ivory; figure, image"],
    ['完', "complete, finish, settle; whole"],
    ['设', "build; establish; display"],
    ['式', "style, system, formula, rule"],
    ['色', "color, tint, hue, shade; form, body; beauty, desire for beauty"],
    ['路', "road, path, street; journey"],
    ['记', "record; keep in mind, remember"],
    ['南', "south; southern part; southward"],
    ['品', "article, product, commodity"],
    ['住', "reside, live at, dwell, lodge; stop"],
    ['告', "tell, announce, inform; accuse"],
    ['类', "class, group, kind, category"],
    ['求', "seek; demand, request; beseech"],
    ['据', "to occupy, take possession of; a base, position"],
    ['程', "journey, trip; schedule, agenda"],
    ['北', "north; northern; northward"],
    ['边', "edge, margin, side, border"],
    ['死', "die; dead; death"],
    ['张', "stretch, extend, expand; sheet"],
    ['该', "should, ought to, need to"],
    ['交', "mix; intersect; exchange, communicate; deliver"],
    ['规', "rules, regulations, customs, law"],
    ['万', "ten thousand; innumerable"],
    ['取', "take, receive, obtain; select"],
    ['拉', "pull, drag; seize, hold; lengthen"],
    ['格', "pattern, standard, form; style"],
    ['望', "to look at, look forward; to hope, expect"],
    ['觉', "to wake up from sleep; conscious"],
    ['术', "art, skill, special feat; method, technique"],
    ['领', "neck; collar; lead, guide"],
    ['共', "together with, all, total; to share"],
    ['确', "sure, certain; real, true"],
    ['传', "summon; propagate, transmit"],
    ['师', "teacher, master, specialist"],
    ['观', "see, observe, view; appearance"],
    ['清', "clear, pure, clean; peaceful"],
    ['今', "now, today, modern era"],
    ['切', "cut, mince, slice, carve"],
    ['院', "courtyard, yard, court; school"],
    ['让', "allow, permit, yield, concede"],
    ['识', "recognize, understand, know"],
    ['候', "wait; expect; visit; greet"],
    ['带', "belt, girdle, band, strap, zone"],
    ['导', "direct, guide, lead, conduct"],
    ['争', "dispute, fight, contend, strive"],
    ['运', "run; simplified form of 運 luck, fortune; ship, transport"],
    ['笑', "smile, laugh, giggle; snicker"],
    ['飞', "fly; go quickly; dart; high"],
    ['风', "wind; air; manners, atmosphere"],
    ['步', "step, pace; walk, stroll"],
    ['改', "change, alter; improve, remodel"],
    ['收', "gather together, collect; harvest"],
    ['根', "root, base(d on); foundation"],
    ['干', "oppose, offend; invade; dried"],
    ['造', "construct, build, make, begin; prepare"],
    ['言', "words, speech; speak, say"],
    ['联', "connect, join; associate, ally"],
    ['持', "sustain, support; hold, grasp"],
    ['组', "class; section, department"],
    ['每', "every, each"],
    ['济', "help, aid, relieve; ferry, cross"],
    ['车', "cart, vehicle; carry in cart"],
    ['亲', "relatives, parents; intimate the hazel nut or filbert tree a thorny tree"],
    ['极', "extreme, utmost, furthest, final"],
    ['林', "forest, grove; surname"],
    ['服', "clothes; wear, dress"],
    ['快', "rapid, quick, speedy, fast; soon"],
    ['办', "manage, do, handle; deal with"],
    ['议', "consult, talk over, discuss"],
    ['往', "go, depart; past, formerly"],
    ['元', "first; dollar; origin; head"],
    ['英', "petal, flower, leaf; brave, a hero; England, English"],
    ['士', "scholar, gentleman; soldier"],
    ['证', "prove, confirm, verify; proof"],
    ['近', "near, close; approach; intimate"],
    ['失', "lose; make mistake, neglect"],
    ['转', "shift, move, turn"],
    ['夫', "man, male adult, husband; those"],
    // ['令', ""],
    ['准', "approve, allow, permit; in accord"],
    ['布', "cotton cloth, textiles, linen"],
    ['始', "begin, start; then, only then"],
    ['怎', "what? why? how?"],
    ['呢', "interrogative or emphatic final; (Cant.) this"],
    ['存', "exist, live, be; survive; remain"],
    ['未', "not yet; 8th terrestrial branch"],
    ['远', "distant, remote, far; profound"],
    ['叫', "cry, shout; hail, greet, call"],
    ['台', "platform; unit; term of address"],
    ['单', "single, individual, only; lone"],
    ['影', "shadow; image, reflection; photograph"],
    ['具', "tool, implement; draw up, write"],
    ['罗', "net for catching birds; gauze"],
    ['字', "letter, character, word"],
    ['爱', "love, be fond of, like"],
    ['击', "strike, hit, beat; attack, fight"],
    ['流', "flow, circulate, drift; class"],
    ['备', "prepare, ready, perfect"],
    ['兵', "soldier, troops"],
    ['连', "join, connect; continuous; even"],
    ['调', "transfer, move, change; tune"],
    ['深', "deep; depth; far; very, extreme"],
    ['商', "commerce, business, trade"],
    ['算', "count, calculate, figure; plan"],
    ['质', "matter, material, substance"],
    ['团', "sphere, ball, circle; mass, lump"],
    ['集', "assemble, collect together"],
    ['百', "one hundred; numerous, many"],
    ['需', "need, require, must"],
    ['价', "price, value"],
    ['花', "flower; blossoms"],
    ['党', "political party, gang, faction"],
    ['华', "flowery; illustrious; Chinese"],
    ['城', "castle; city, town; municipality"],
    ['石', "stone, rock, mineral; rad. 112"],
    ['级', "level, rank, class; grade"],
    ['整', "orderly, neat, tidy; whole"],
    ['府', "prefecture; prefect; government"],
    ['离', "rare beast; strange; elegant"],
    ['况', "condition, situation; furthermore"],
    ['亚', "Asia; second"],
    ['请', "ask, request; invite; please"],
    ['技', "skill, ability, talent, ingenuity"],
    ['际', "border, boundary, juncture"],
    ['约', "treaty, agreement, covenant"],
    ['示', "show, manifest; demonstrate"],
    ['复', "return; repeat; repeatedly"],
    ['病', "illness, sickness, disease"],
    ['息', "rest, put stop to, end, cease"],
    ['究', "examine, investigate"],
    ['线', "line, thread, wire; clue"],
    ['似', "resemble, similar to; as if, seem"],
    ['官', "official, public servant"],
    ['火', "fire, flame; burn; anger, rage"],
    ['断', "sever, cut off; interrupt"],
    ['精', "essence; semen; spirit"],
    ['满', "fill; full, satisfied"],
    ['支', "disperse, pay; support; branch"],
    ['视', "look at, inspect, observe, see"],
    ['消', "vanish, die out; melt away"],
    ['越', "exceed, go beyond; the more ..."],
    ['器', "receptacle, vessel; instrument"],
    ['容', "looks, appearance; figure, form"],
    ['照', "shine, illumine, reflect"],
    ['须', "must, have to, necessary"],
    ['九', "nine"],
    ['增', "increase, add to, augment"],
    ['研', "grind, rub; study, research"],
    ['写', "write; draw, sketch; compose"],
    ['称', "call; name, brand; address; say"],
    ['企', "plan a project; stand on tiptoe"],
    ['八', "eight; all around, all sides"],
    ['功', "achievement, merit, good result"],
    ['吗', "final interrogative particle"],
    ['包', "wrap, pack, bundle; package"],
    ['片', "slice, splinter, strip; rad. 91"],
    ['史', "history, chronicle, annals"],
    ['委', "appoint, send, commission"],
    ['乎', "interrogative or exclamatory final particle"],
    ['查', "investigate, examine, seek into"],
    ['轻', "light; easy, simple; gentle"],
    ['易', "change; easy"],
    ['早', "early; soon; morning"],
    ['曾', "already; sign of past"],
    ['除', "eliminate, remove, except"],
    ['农', "agriculture, farming; farmer"],
    ['找', "search, seek, look for; find"],
    ['装', "dress, clothes, attire; fill"],
    ['广', "wide, extensive, broad; rad. 53"],
    ['显', "manifest, display; evident, clear"],
    ['吧', "emphatic final particle"],
    // ['阿', ""],
    ['李', "plum; judge; surname"],
    ['标', "mark, symbol, label, sign; stand the bole of a tree"],
    ['谈', "talk; conversation; surname"],
    ['吃', "eat; drink; suffer, endure, bear"],
    ['图', "diagram; chart, map, picture"],
    ['念', "think of, recall, study"],
    ['六', "number six"],
    ['引', "to pull, draw out, attract; to stretch"],
    ['历', "history; calendar"],
    ['首', "head; first; leader, chief; a poem"],
    ['医', "cure, heal; doctor, medical"],
    ['局', "bureau, office; circumstance"],
    ['突', "suddenly, abruptly, unexpectedly"],
    ['专', "monopolize, take sole possession"],
    ['费', "expenses, expenditures, fee"],
    ['号', "mark, sign; symbol; number"],
    ['尽', "exhaust, use up; deplete"],
    ['另', "another, separate, other"],
    ['周', "Zhou dynasty; circumference"],
    ['较', "compare; comparatively, more"],
    ['注', "concentrate, focus, direct"],
    ['语', "language, words; saying, expression"],
    ['仅', "only, merely, solely, just"],
    ['考', "examine, test; investigate"],
    ['落', "fall, drop; net income, surplus"],
    ['青', "blue, green, black; young"],
    ['随', "follow, listen to, submit to"],
    ['选', "choose, select; elect; election"],
    ['列', "a line; to arrange in order, classify"],
    ['武', "military; martial, warlike"],
    ['红', "red, vermillion; blush, flush"],
    ['响', "make sound, make noise; sound"],
    ['虽', "although, even if"],
    ['推', "push, expel; push forward"],
    ['势', "power, force; tendency"],
    ['参', "take part in, intervene; ginseng"],
    ['希', "rare; hope, expect, strive for"],
    ['古', "old, classic, ancient"],
    ['众', "multitude, crowd; masses, public"],
    ['构', "frame, building, structure"],
    ['房', "house, building; room"],
    ['半', "half"],
    ['节', "knot, node, joint; section"],
    ['土', "soil, earth; items made of earth"],
    ['投', "throw, cast, fling, pitch; jump"],
    ['某', "certain thing or person"],
    ['案', "table, bench; legal case"],
    ['黑', "black; dark; evil, sinister"],
    ['维', "maintain, preserve, safeguard"],
    ['革', "leather, animal hides; rad. 177"],
    ['划', "to row or paddle boat; to scratch"],
    ['敌', "enemy, foe, rival; resist"],
    ['致', "send, deliver, present; cause"],
    ['陈', "exhibit, display; plead; a surname"],
    ['律', "statute, principle, regulation"],
    ['足', "foot; attain, satisfy, enough"],
    ['态', "manner, bearing, attitude"],
    ['护', "protect, guard, defend, shelter"],
    ['七', "seven"],
    ['兴', "thrive, prosper, flourish"],
    ['派', "school of thought, sect, branch"],
    ['孩', "baby, child; children"],
    ['验', "test, examine, inspect; verify"],
    // ['责', ""],
    ['营', "encampment, barracks; manage"],
    ['星', "a star, planet; any point of light"],
    ['够', "enough, adequate"],
    ['章', "composition; chapter, section"],
    ['音', "sound, tone, pitch, pronunciation"],
    ['跟', "heel; to follow, accompany; with"],
    ['志', "purpose, will, determination; annals"],
    ['底', "bottom, underneath, underside"],
    ['站', "stand up; a stand, station"],
    ['严', "strict, rigorous, rigid; stern"],
    ['巴', "greatly desire, anxiously hope"],
    ['例', "precedent, example; regulation"],
    ['防', "defend; prevent; embankment"],
    ['族', "a family clan, ethnic group, tribe"],
    ['供', "supply, provide for; offer in worship"],
    ['效', "result, effect; effectiveness"],
    ['续', "continue, carry on; succeed"],
    ['施', "grant, bestow; give; act; name"],
    ['留', "stop, halt; stay, detain, keep"],
    ['讲', "explain; discuss; talk"],
    ['型', "pattern, model, type; law; mold"],
    ['料', "consider, conjecture; materials, ingredients"],
    ['终', "end; finally, in the end"],
    ['答', "answer, reply; return; assent to"],
    ['紧', "tense, tight, taut; firm, secure"],
    ['黄', "yellow; surname"],
    ['绝', "to cut, sever, break off, terminate"],
    ['奇', "strange, unusual, uncanny, occult"],
    ['察', "examine, investigate; notice"],
    ['母', "mother; female elders; female"],
    ['京', "capital city"],
    ['段', "section, piece, division"],
    ['依', "rely on, be set in; consent, obey a wish"],
    ['批', "comment, criticize; wholesale"],
    ['群', "(same as 羣) group, crowd, multitude, mob"],
    ['项', "neck, nape of neck; sum; funds"],
    ['故', "ancient, old; reason, because"],
    ['按', "put hand on, press down with hand"],
    ['河', "river; stream; yellow river"],
    ['米', "hulled or husked uncooked rice"],
    ['围', "surround, encircle, corral"],
    ['江', "large river; yangzi; surname"],
    ['织', "weave, knit; organize, unite"],
    ['害', "injure, harm; destroy, kill"],
    ['斗', "Chinese peck; liquid measure"],
    ['双', "set of two, pair, couple; both"],
    ['境', "boundary, frontier; area, region"],
    ['客', "guest, traveller; customer"],
    ['纪', "record, annal, historical account"],
    ['采', "collect, gather; pick, pluck"],
    ['举', "raise, lift up; recommend"],
    ['杀', "kill, slaughter, murder; hurt"],
    ['攻', "attack, assault; criticize"],
    ['父', "father; KangXi radical 88"],
    // ['苏', ""],
    ['密', "dense, thick, close; intimate"],
    ['低', "low; to lower, hang, bend, bow"],
    ['朝', "dynasty; morning"],
    ['友', "friend, companion; fraternity"],
    ['诉', "accuse; sue; inform; narrate"],
    ['止', "stop, halt, desist; detain"],
    ['细', "fine, tiny; slender, thin"],
    ['愿', "sincere, honest, virtuous"],
    ['千', "thousand; many, numerous; very; (Cant.) a cheater, swindler"],
    ['值', "price"],
    ['仍', "yet, still, as ever; again; keep -ing, continuing"],
    ['男', "male, man; son; baron; surname"],
    ['钱', "money, currency, coins"],
    ['破', "break, ruin, destroy; rout"],
    ['网', "net; network; KangXi radical 122"],
    ['热', "hot; heat; fever; restless; zeal"],
    ['助', "help, aid, assist"],
    ['倒', "fall over; lie down; take turns"],
    ['育', "produce, give birth to; educate"],
    ['属', "class, category, type; belong to"],
    ['坐', "sit; seat; ride, travel by"],
    ['帝', "supreme ruler, emperor; god"],
    ['限', "boundary, limit, line"],
    ['船', "ship, boat, vessel"],
    ['脸', "face; cheek; reputation"],
    ['职', "duty, profession; office, post"],
    ['速', "quick, prompt, speedy"],
    ['刻', "carve, engrave; quarter hour"],
    ['乐', "happy, glad; enjoyable; music"],
    ['否', "not, no, negative; final particle"],
    ['刚', "hard, tough, rigid, strong"],
    ['威', "pomp, power; powerful; dominate"],
    ['毛', "hair, fur, feathers; coarse"],
    ['状', "form; appearance; shape; official"],
    ['率', "to lead; ratio; rate; limit"],
    ['甚', "great extent; considerably"],
    ['独', "alone, single, solitary, only"],
    ['球', "ball, sphere, globe; round"],
    ['般', "sort, manner, kind, category"],
    ['普', "universal, general, widespread"],
    ['怕', "to fear, be afraid of; apprehensive"],
    ['弹', "pellet, bullet, shot, shell"],
    ['校', "school; military field officer"],
    ['苦', "bitter; hardship, suffering"],
    ['创', "establish, create; knife cut"],
    ['假', "falsehood, deception; vacation"],
    ['久', "long time (ago); time passage, grow late"],
    ['错', "error, blunder, mistake; wrong"],
    ['承', "inherit, receive; succeed"],
    ['印', "print, seal, stamp, chop, mark"],
    ['晚', "night, evening; late"],
    ['兰', "orchid; elegant, graceful"],
    ['试', "test, try, experiment"],
    ['股', "thighs, haunches, rump; share"],
    ['拿', "take, hold, grasp; bring; with"],
    ['脑', "brain"],
    ['预', "prepare, arrange; in advance"],
    ['谁', "who? whom? whose? anyone?"],
    ['益', "profit, benefit; advantage"],
    // ['阳', ""],
    ['若', "if, supposing, assuming; similar"],
    ['哪', "which? where? how?"],
    ['微', "small, prefix micro-, trifling"],
    ['尼', "Buddhist nun; transliteration"],
    ['继', "continue, maintain, carry on"],
    ['送', "see off, send off; dispatch, give"],
    ['急', "quick, quickly; urgent, pressing"],
    ['血', "blood; radical number 143"],
    ['惊', "frighten, surprise, startle"],
    ['伤', "wound, injury; fall ill from"],
    ['素', "white (silk); plain; vegetarian; formerly; normally"],
    ['药', "leaf of Dahurian angelica plant; medicine"],
    ['适', "go, reach; appropriate, suitable"],
    ['波', "waves, breakers; undulations"],
    ['夜', "night, dark; in night; by night"],
    ['省', "province; save, economize"],
    ['初', "beginning, initial, primary"],
    ['喜', "like, love, enjoy; joyful thing"],
    ['卫', "guard, protect, defend"],
    ['源', "spring; source, head; surname"],
    ['食', "eat; meal; food; KangXi radical number 184"],
    ['险', "narrow pass, strategic point"],
    ['待', "treat, entertain, receive; wait"],
    ['述', "narrate, state, express"],
    ['陆', "land, continental; army"],
    ['习', "practice; flapping wings"],
    ['置', "place, lay out; set aside"],
    ['居', "live, dwell, reside, sit"],
    ['劳', "labor, toil, do manual work"],
    ['财', "wealth, valuables, riches"],
    ['环', "jade ring or bracelet; ring"],
    ['排', "row, rank, line"],
    ['福', "happiness, good fortune, blessing"],
    ['纳', "admit, take, receive, accept"],
    ['欢', "happy, pleased, glad; joy; enjoy"],
    ['雷', "thunder"],
    ['警', "guard, watch; alert, alarm"],
    ['获', "obtain, get, receive; seize"],
    ['模', "model, standard, pattern; copy"],
    ['充', "fill, be full, supply"],
    ['负', "load, burden; carry, bear"],
    ['云', "say, speak; clouds"],
    ['停', "stop, suspend, delay; suitable"],
    ['木', "tree; wood, lumber; wooden"],
    ['游', "to swim; float, drift; wander, roam"],
    ['龙', "dragon; symbolic of emperor"],
    ['树', "tree; plant; set up, establish"],
    ['疑', "doubt, question, suspect"],
    ['层', "storey, layer, floor, stratum"],
    ['冷', "cold, cool; lonely"],
    ['洲', "continent; island; islet"],
    ['冲', "soar; pour boiling water over"],
    ['射', "shoot, eject, issue forth, emit"],
    ['略', "approximately, roughly; outline"],
    ['范', "surname; bee-like insect"],
    ['竟', "finally, after all, at last"],
    ['句', "sentence"],
    ['室', "room, home, house, chamber"],
    ['异', "different, unusual, strange"],
    ['激', "arouse, excite, incite; quickly"],
    ['汉', "Chinese people; Chinese language"],
    ['村', "village, hamlet; uncouth, vulgar"],
    ['哈', "sound of laughter"],
    ['策', "scheme, plan; to whip; urge"],
    ['演', "perform, put on; exercise"],
    ['简', "simple, terse, succinct, a letter"],
    ['卡', "card, punch card; calorie"],
    ['罪', "crime, sin, vice; evil; hardship"],
    ['判', "judge; discriminate; conclude"],
    ['担', "carry, bear, undertake"],
    ['州', "administrative division, state"],
    ['静', "quiet, still, motionless; gentle"],
    ['退', "step back, retreat, withdraw"],
    ['既', "already; de facto; since; then"],
    ['衣', "clothes, clothing; cover, skin"],
    // ['您', ""],
    ['宗', "lineage, ancestry; ancestor, clan"],
    ['积', "accumulate, store up, amass"],
    ['余', "I, my, me; surname; surplus"],
    ['痛', "pain, ache; sorry, sad; bitter"],
    ['检', "check; examine"],
    ['差', "to differ; different, wrong; nearly, almost; an officer"],
    ['富', "abundant, ample; rich, wealthy"],
    ['灵', "spirit, soul; spiritual world"],
    ['协', "be united; cooperate"],
    ['角', "horn; angle, corner; point"],
    ['占', "divine; observe; versify"],
    ['配', "match, pair; equal; blend"],
    ['征', "invade, attack, conquer"],
    ['修', "study; repair; cultivate"],
    ['皮', "skin, hide, fur, feather; outer"],
    ['挥', "direct; wipe away; squander"],
    ['胜', "victory; excel, be better than"],
    ['降', "descend, fall, drop; lower, down"],
    ['阶', "stairs, steps; rank, degree"],
    ['审', "examine, investigate; judge"],
    ['沉', "sink, submerge; addicted to"],
    ['坚', "hard, strong, firm; resolute"],
    ['善', "good, virtuous, charitable, kind"],
    ['妈', "mother, mama"],
    ['刘', "surname; kill, destroy"],
    ['读', "read, study; pronounce"],
    ['啊', "exclamatory particle"],
    ['超', "jump over, leap over; surpass"],
    ['免', "spare; excuse from; evade"],
    ['压', "press; oppress; crush; pressure"],
    ['银', "silver; cash, money, wealth"],
    ['买', "buy, purchase; bribe, persuade"],
    ['皇', "royal, imperial; ruler, superior"],
    ['养', "raise, rear, bring up; support"],
    ['伊', "third person pronoun; he, she, this, that"],
    ['怀', "bosom, breast; carry in bosom"],
    ['执', "hold in hand; keep; carry out"],
    ['副', "assist; supplement; assistant"],
    ['乱', "confusion, state of chaos, revolt"],
    ['抗', "resist, oppose, defy, reject"],
    ['犯', "commit crime, violate; criminal"],
    ['追', "pursue, chase after; expel"],
    ['帮', "help, assist; defend; shoe upper"],
    ['宣', "declare, announce, proclaim"],
    ['佛', "Buddha; of Buddhism; merciful person; Buddhist image; the dead (Jap.)"],
    ['岁', "year; age; harvest"],
    ['航', "sail; navigate; ship, boat"],
    ['优', "superior, excellent; actor"],
    ['怪', "strange, unusual, peculiar"],
    ['香', "fragrant, sweet smelling, incense"],
    ['著', "manifest; (Cant.) to wear"],
    ['田', "field, arable land, cultivated"],
    ['铁', "iron; strong, solid, firm"],
    ['控', "accuse, charge; control"],
    ['税', "taxes"],
    ['左', "left; east; unorthodox, improper"],
    ['右', "right; west; right-wing"],
    ['份', "portion, part; duty"],
    ['穿', "penetrate, pierce, drill; wear"],
    ['艺', "art; talent, ability; craft"],
    ['背', "back; back side; behind; betray"],
    ['阵', "column, row or file of troops"],
    ['草', "grass, straw, thatch, herbs"],
    ['脚', "leg, foot; base, leg, foundation"],
    ['概', "generally, approximately"],
    ['恶', "evil, wicked, bad, foul"],
    ['块', "piece, lump; dollar"],
    ['顿', "pause, stop; bow, kowtow; arrange"],
    ['敢', "to dare, venture; bold, brave"],
    ['守', "defend, protect, guard, conserve"],
    ['酒', "wine, spirits, liquor, alcoholic beverage"],
    ['岛', "island"],
    ['托', "to hold up with palm; to support, rely on"],
    ['央', "center, conclude, run out; beg"],
    ['户', "door; family"],
    ['烈', "fiery, violent, vehement, ardent"],
    ['洋', "ocean, sea; foreign; western"],
    ['哥', "elder brother"],
    ['索', "large rope, cable; rules, laws; to demand, to exact; to search, inquire; isolated"],
    ['胡', "recklessly, foolishly; wildly"],
    ['款', "item, article; clause; fund"],
    ['靠', "lean on, trust, depend on; near"],
    ['评', "appraise, criticize, evaluate"],
    ['版', "printing blocks; edition"],
    ['宝', "treasure, jewel; precious, rare"],
    ['座', "seat; stand, base"],
    ['释', "interprete, elucidate; release"],
    ['景', "scenery, view; conditions"],
    ['顾', "look back; look at; look after"],
    ['弟', "young brother; junior; i, me"],
    ['登', "rise, mount, board, climb"],
    ['货', "goods, commodities, products"],
    ['互', "mutually, reciprocally"],
    ['付', "give, deliver, pay, hand over; entrust"],
    // ['伯', ""],
    ['慢', "slow(ly), leisurely, sluggish"],
    ['欧', "translit.: Europe; ohm; surname"],
    ['换', "change"],
    ['闻', "hear; smell; make known; news"],
    ['危', "dangerous, precarious; high"],
    ['忙', "busy, pressed for time; hustling"],
    ['核', "seed, kernel, core, nut; atom"],
    ['暗', "dark; obscure; in secret, covert"],
    ['姐', "elder sister, young lady"],
    ['介', "forerunner, herald, harbinger; to lie between; sea shell; to wear armor"],
    ['坏', "rotten, spoilt, bad, broken down"],
    ['讨', "to discuss; ask for, beg; demand; dun; marry"],
    ['丽', "beautiful, magnificent, elegant"],
    ['良', "good, virtuous, respectable"],
    ['序', "series, serial order, sequence"],
    ['升', "arise, go up; hoist; advance"],
    ['监', "supervise, control, direct"],
    ['临', "draw near, approach; descend"],
    ['亮', "bright, brilliant, radiant, light"],
    ['露', "dew; bare, open, exposed"],
    ['永', "long, perpetual, eternal, forever"],
    ['呼', "breathe sigh, exhale; call, shout"],
    ['味', "taste, smell, odor; delicacy"],
    ['野', "open country, field; wilderness"],
    ['架', "rack, stand, prop; prop up"],
    ['域', "district, region, boundary; land"],
    ['沙', "sand, gravel, pebbles; granulated"],
    ['掉', "turn, move; shake, wag; drop down"],
    ['括', "include, embrace, enclose"],
    ['舰', "warship"],
    ['鱼', "fish; surname; KangXi radical 195"],
    ['杂', "mixed, blended; mix, mingle"],
    ['误', "err, make mistake; interfere"],
    ['湾', "bay, cove, inlet, bend of stream"],
    ['吉', "lucky, propitious, good"],
    ['减', "decrease, subtract, diminish"],
    ['编', "knit, weave; arrange; compile"],
    ['楚', "name of feudal state; clear"],
    ['肯', "willing; consent to, permit"],
    ['测', "measure, estimate, conjecture"],
    ['败', "be defeated, decline, fail"],
    ['屋', "house; room; building, shelter"],
    ['跑', "run, flee, leave in hurry"],
    ['梦', "dream; visionary; wishful"],
    ['散', "scatter, disperse, break up"],
    ['温', "lukewarm"],
    ['困', "to surround, beseige; to be surrounded; difficult"],
    ['剑', "sword, dagger, saber"],
    ['渐', "gradually"],
    ['封', "letter, envelope; feudal"],
    ['救', "save, rescue, relieve; help, aid"],
    ['贵', "expensive, costly, valuable"],
    ['枪', "spear, lance; gun, rifle"],
    ['缺', "be short of, lack; gap, deficit"],
    ['楼', "building of two or more stories"],
    ['县', "county, district, subdivision"],
    ['尚', "still, yet; even; fairly, rather"],
    ['毫', "fine hair; measure of length"],
    ['移', "change place, shift; move about"],
    ['娘', "mother; young girl; woman; wife"],
    ['朋', "friend, pal, acquaintance"],
    ['画', "a painting, picture, drawing; to draw"],
    ['班', "class, group, grade; squad; job"],
    ['智', "wisdom, knowledge, intelligence"],
    ['亦', "also, too; likewise"],
    ['耳', "ear; merely, only; handle"],
    ['恩', "kindness, mercy, charity"],
    ['短', "short; brief; deficient, lacking"],
    ['掌', "palm of hand, sole of foot, paw"],
    ['恐', "fear; fearful, apprehensive"],
    ['遗', "lose; articles lost; omit"],
    ['固', "to become solid, solidify; strength"],
    ['席', "seat; mat; take seat; banquet"],
    ['松', "pine tree; fir tree"],
    ['秘', "secret, mysterious, abstruse"],
    ['谢', "thank; decline"],
    ['鲁', "foolish, stupid, rash; vulgar"],
    ['遇', "meet, come across, encounter"],
    ['康', "peaceful, quiet; happy, healthy"],
    ['虑', "be concerned, worry about"],
    ['幸', "luck(ily), favor, fortunately"],
    ['均', "equal, even, fair; all, also"],
    ['销', "melt, fuse; market, sell"],
    ['钟', "clock; bell"],
    ['诗', "poetry; poem, verse, ode"],
    ['藏', "hide, conceal; hoard, store up"],
    ['赶', "pursue, follow; expel, drive away"],
    ['剧', "theatrical plays, opera, drama"],
    ['票', "slip of paper or bamboo; ticket"],
    ['损', "diminish; impair; injure"],
    ['忽', "suddenly, abruptly; neglect"],
    ['巨', "large, great, enormous; chief"],
    ['炮', "large gun, cannon; artillery"],
    ['旧', "old, ancient; former, past"],
    ['端', "end, extreme; head; beginning"],
    ['探', "find, locate; search, grope for"],
    ['湖', "a lake; Hubei, Hunan; bluish-green"],
    ['录', "copy, write down, record"],
    ['叶', "to harmonize, to rhyme; to unite; (borrowed for) leaf"],
    ['春', "spring; wanton"],
    ['乡', "country; rural; village"],
    ['附', "adhere to, append; rely on near to"],
    ['吸', "inhale, suck in; absorb; attract"],
    ['予', "I, me; to give"],
    ['礼', "social custom; manners; courtesy"],
    ['港', "port, harbor; small stream; bay"],
    ['雨', "rain; rainy; KangXi radical 173"],
    ['呀', "particle used to express surprise or mild emphasis; (Cant.) slurred form of the number ten"],
    ['板', "plank, board; iron or tin plate"],
    ['庭', "courtyard; spacious hall or yard"],
    ['妇', "married women; woman; wife"],
    ['归', "return; return to, revert to"],
    ['睛', "eyeball; pupil of eye"],
    ['饭', "cooked rice; food, meal"],
    ['额', "forehead; tablet, plaque; fixed"],
    ['含', "hold in mouth; cherish; contain"],
    ['顺', "obey, submit to, go along with"],
    ['输', "transport, carry, haul"],
    ['摇', "wag, swing, wave; shake; scull"],
    ['招', "beckon, summon; recruit, levy"],
    ['婚', "get married; marriage, wedding"],
    ['脱', "take off"],
    ['补', "mend, patch, fix, repair, restore"],
    ['谓', "say, tell; call, name; be called"],
    ['督', "supervise, oversee, direct"],
    ['毒', "poison, venom; poisonous"],
    ['油', "oil, fat, grease, lard; paints"],
    ['疗', "be healed, cured, recover"],
    ['旅', "trip, journey; travel; traveler"],
    ['泽', "marsh, swamp; grace, brilliance"],
    ['材', "material, stuff; timber; talent"],
    ['灭', "extinguish; wipe out, exterminate"],
    ['逐', "chase, expel; one by one"],
    ['莫', "do not, is not, can not; negative"],
    ['笔', "writing brush; write; stroke"],
    ['亡', "death, destroyed; lose, perish"],
    ['鲜', "fresh; delicious; attractive"],
    ['词', "words; phrase, expression"],
    ['圣', "holy, sacred"],
    ['择', "select, choose, pick out"],
    ['寻', "seek, search, look for; ancient"],
    ['厂', "factory, workshop; radical 27"],
    ['睡', "sleep, doze"],
    ['博', "gamble, play games; wide, broad"],
    ['勒', "strangle, tighten"],
    ['烟', "smoke, soot; opium; tobacco, cigarettes"],
    ['授', "give to, transmit; confer"],
    ['诺', "promise; assent, approve"],
    ['伦', "normal human relationships"],
    ['岸', "bank, shore; beach, coast"],
    ['奥', "mysterious, obscure, profound"],
    ['唐', "Tang dynasty; Chinese"],
    ['卖', "sell; betray; show off"],
    ['俄', "sudden(ly), soon; Russian"],
    ['炸', "to fry in oil; to scald; to explode"],
    ['载', "load; carry; transport, convey"],
    ['洛', "river in Shanxi province; city"],
    ['健', "strong, robust, healthy; strength"],
    ['堂', "hall; government office"],
    ['旁', "side; by side, close by, near"],
    ['宫', "palace"],
    ['喝', "drink; shout, call out"],
    ['借', "borrow; lend; make pretext of"],
    ['君', "sovereign, monarch, ruler, chief, prince"],
    ['禁', "restrict, prohibit, forbid"],
    // ['阴', ""],
    ['园', "garden; park, orchard"],
    ['谋', "plan, scheme; strategem"],
    ['宋', "Song dynasty; surname"],
    ['避', "avoid; turn aside; escape; hide"],
    ['抓', "scratch; clutch, seize, grab"],
    ['荣', "glory, honor; flourish, prosper"],
    // ['姑', ""],
    ['孙', "grandchild, descendent; surname"],
    ['逃', "escape, flee; abscond, dodge"],
    ['牙', "tooth, molars, teeth; serrated"],
    ['束', "bind, control, restrain; bale"],
    ['跳', "jump, leap, vault, bounce; dance"],
    ['顶', "top, summit, peak; to carry on the head"],
    ['玉', "jade, precious stone, gem"],
    ['镇', "town, market place; suppress"],
    ['雪', "snow; wipe away shame, avenge"],
    ['午', "noon; 7th terrestrial branch"],
    ['练', "to practice, drill, exercise, train"],
    ['迫', "coerce, force, compel; urgent"],
    ['爷', "father, grandfather"],
    ['篇', "chapter, section, essay, article"],
    ['肉', "flesh; meat; KangXi radical 130"],
    ['嘴', "mouth, lips"],
    ['馆', "public building"],
    ['遍', "everywhere, all over, throughout"],
    ['凡', "all, any, every; ordinary, common"],
    ['础', "foundation stone, plinth"],
    ['洞', "cave, grotto; ravine; hole"],
    ['卷', "scroll; curl; make a comeback"],
    ['坦', "flat, smooth; self-possessed"],
    ['牛', "cow, ox, bull; KangXi radical93"],
    ['宁', "calm, peaceful, serene; healthy"],
    ['纸', "paper"],
    ['诸', "various, all, these; surname"],
    ['训', "teach, instruct; exegesis"],
    ['私', "private, personal; secret"],
    ['庄', "village, hamlet; villa, manor"],
    ['祖', "ancestor, forefather; grandfather"],
    ['丝', "silk; fine thread; wire; strings"],
    ['翻', "flip over, upset, capsize"],
    ['暴', "violent, brutal, tyrannical"],
    ['森', "forest; luxuriant vegetation"],
    ['塔', "tower, spire, tall building"],
    ['默', "silent; quiet, still; dark"],
    ['握', "grasp, hold fast, take by hand"],
    ['戏', "theatrical play, show"],
    ['隐', "hide, conceal; hidden, secret"],
    ['熟', "well-cooked; ripe; familiar with"],
    ['骨', "bone; skeleton; frame, framework"],
    ['访', "visit; ask, inquire"],
    ['弱', "weak; fragile, delicate"],
    ['蒙', "cover; ignorant; suffer; mongolia"],
    ['歌', "song, lyrics; sing, chant; praise"],
    ['店', "shop, store; inn, hotel"],
    ['鬼', "ghost; spirit of dead; devil"],
    ['软', "soft, flexible, pliable; weak"],
    ['典', "law, canon; documentation; classic, scripture"],
    ['欲', "desire, want, long for; intend"],
    ['萨', "Buddhist gods or immortals"],
    ['伙', "companion, colleague; utensils"],
    ['遭', "come across, meet with, encounter"],
    ['盘', "tray, plate, dish; examine"],
    ['爸', "father, papa"],
    ['扩', "expand, enlarge, stretch"],
    ['盖', "cover, hide, protect"],
    ['弄', "do, play or fiddle with; alley"],
    ['雄', "male of species; hero; manly"],
    ['稳', "stable, firm, solid, steady"],
    ['忘', "forget; neglect; miss, omit"],
    ['亿', "hundred million; many"],
    ['刺', "stab; prick, irritate; prod"],
    ['拥', "embrace, hug, squeeze; crowd"],
    ['徒', "disciple, follower; go on foot"],
    // ['姆', ""],
    ['杨', "willow, poplar, aspen; surname"],
    ['齐', "even, uniform, of equal length"],
    ['赛', "compete, contend; contest, race"],
    // ['趣', ""],
    ['曲', "crooked, bent; wrong, false"],
    ['刀', "knife; old coin; measure"],
    ['床', "bed, couch; framework, chassis"],
    ['迎', "receive, welcome, greet"],
    ['冰', "ice; ice-cold"],
    ['虚', "FALSE"],
    ['玩', "play with, joke, enjoy"],
    ['析', "split wood; break apart; divide"],
    ['窗', "window"],
    ['醒', "wake up; sober up; startle"],
    ['妻', "wife"],
    ['透', "penetrate, pass through"],
    ['购', "buy, purchase; hire"],
    ['替', "change, replace, substitute for"],
    ['塞', "stop up, block, seal, cork; pass, frontier; fortress"],
    ['努', "to exert, strive, make an effort; to pout"],
    ['休', "rest, stop; retire; do not!"],
    ['虎', "tiger; brave, fierce; surname"],
    ['扬', "scatter, spread; praise"],
    ['途', "way, road, path, journey; course"],
    ['侵', "invade, encroach upon, raid"],
    ['刑', "punishment, penalty; law"],
    ['绿', "green; chlorine"],
    ['兄', "elder brother"],
    ['迅', "quick, hasty, rapid, sudden"],
    ['套', "case, cover, wrapper, envelope"],
    ['贸', "trade, barter; mixed; rashly"],
    ['毕', "end, finish, conclude; completed"],
    ['唯', "only; yes"],
    ['谷', "valley, gorge, ravine"],
    ['轮', "wheel; revolve, turn; recur"],
    ['库', "armory, treasury, storehouse"],
    ['迹', "traces, impressions, footprints"],
    ['尤', "especially, particularly"],
    ['竞', "contend, vie, compete"],
    ['街', "street, road, thoroughfare"],
    ['促', "urge, press, hurry; close"],
    ['延', "delay, postpone, defer"],
    ['震', "shake, quake, tremor; excite"],
    ['弃', "reject, abandon, discard"],
    ['甲', "armor, shell; fingernails; 1st heavenly stem"],
    ['伟', "great, robust; extraordinary"],
    ['麻', "hemp, jute, flax; sesame"],
    ['川', "stream, river; flow; boil"],
    ['申', "to state to a superior, report; extend; 9th terrestrial branch"],
    ['缓', "slow, leisurely; to postpone, delay"],
    ['潜', "hide; hidden, secret, latent"],
    ['闪', "flash; avoid, dodge, evade"],
    ['售', "sell"],
    ['灯', "lantern, lamp"],
    ['针', "needle; pin; tack; acupuncture"],
    ['哲', "wise, sagacious; wise man, sage"],
    ['络', "enmesh, wrap around; web, net"],
    ['抵', "resist, oppose; deny; off-set"],
    ['朱', "cinnabar, vermilion; surname"],
    ['埃', "fine dust, dirt"],
    ['抱', "embrace, hold in arms, enfold"],
    ['鼓', "drum; beat, top, strike"],
    ['植', "plant, trees, plants; grow"],
    ['纯', "pure, clean, simple"],
    ['夏', "summer; great, grand, big"],
    ['忍', "endure, bear, suffer; forbear"],
    ['页', "page, sheet, leaf; rad. no. 181"],
    ['杰', "hero; heroic, outstanding"],
    ['筑', "ancient lute; build"],
    ['折', "break off, snap; bend"],
    // ['郑', ""],
    ['贝', "sea shell; money, currency"],
    ['尊', "respect, revere, venerate; honor"],
    ['吴', "name of warring state; surname"],
    ['秀', "ear of grain; flowering, luxuriant; refined, elegant, graceful"],
    ['混', "to mix, blend, mingle; to bumble along"],
    ['臣', "minister, statesman, official"],
    ['雅', "elegant, graceful, refined"],
    ['振', "raise, excite, arouse action"],
    ['染', "dye; be contagious; infect"],
    ['盛', "abundant, flourishing; contain; fill"],
    ['怒', "anger, rage, passion; angry"],
    ['舞', "dance, posture, prance; brandish"],
    ['圆', "circle; round, circular; complete"],
    ['搞', "to clear, clarify"],
    ['狂', "insane, mad; violent; wild"],
    ['措', "place; collect, arrange; employ"],
    // ['姓', ""],
    ['残', "injure, spoil; oppress; broken"],
    ['秋', "autumn, fall; year"],
    ['培', "bank up with dirt; cultivate"],
    ['迷', "bewitch, charm, infatuate"],
    ['诚', "sincere, honest; true, real"],
    ['宽', "broad, wide; spacious, vast"],
    ['宇', "house; building, structure; eaves"],
    ['猛', "violent, savage, cruel; bold"],
    ['摆', "put, place; display; swing, sway"],
    ['梅', "plums; prunes; surname"],
    ['毁', "destroy"],
    ['伸', "extend, stretch out, open up; trust"],
    ['摩', "rub, scour, grind; friction"],
    ['盟', "swear; oath, covenant, alliance"],
    ['末', "final, last, end; insignificant"],
    ['乃', "then; really, indeed; as it turned out, after all; namely"],
    ['悲', "sorrow, grief; sorry, sad"],
    ['拍', "clap, tap, beat; beat or rhythm"],
    ['丁', "male adult; robust, vigorous; 4th heavenly stem"],
    ['赵', "surname; ancient state"],
    ['硬', "hard, firm, strong; obstinate"],
    ['麦', "wheat, barley, oats; simplified form of KangXi radical number 199"],
    ['蒋', "surname; hydropyrum latifalium"],
    ['操', "conduct, run, control, manage"],
    ['耶', "used in transliteration"],
    ['阻', "impede, hinder, obstruct; oppose"],
    ['订', "draw up agreement; arrange"],
    ['彩', "hue, color; variegated colors"],
    ['抽', "draw out, pull out; sprout"],
    ['赞', "help, support, assist, aid"],
    ['魔', "demon, evil spirits; magic power"],
    ['纷', "in disorder, scattered, tangled"],
    ['沿', "follow a course, go along"],
    ['喊', "shout, call out, yell; howl; cry"],
    ['违', "disobey, violate, defy; be apart from"],
    ['妹', "younger sister"],
    ['浪', "wave; wasteful, reckless"],
    ['汇', "concourse; flow together, gather"],
    ['币', "currency, coins, legal tender"],
    ['丰', "abundant, lush, bountiful, plenty"],
    ['蓝', "blue; indigo plant; surname"],
    ['殊', "different, special, unusual"],
    ['献', "offer, present; show, display"],
    ['桌', "table, desk, stand"],
    ['啦', "final particle of assertion"],
    ['瓦', "tile; earthenware pottery; girl"],
    ['莱', "goosefoot, weed; fallow field"],
    ['援', "aid, assist; lead; cite"],
    ['译', "translate; decode; encode"],
    ['夺', "take by force, rob, snatch"],
    ['汽', "steam, vapor, gas"],
    ['烧', "burn; bake; heat; roast"],
    // ['距', ""],
    ['裁', "cut out; decrease"],
    ['偏', "inclined one side; slanting"],
    ['符', "i.d. tag, tally, symbol, charm"],
    ['勇', "brave, courageous, fierce"],
    ['触', "butt, ram, gore; touch"],
    ['课', "lesson; course; classwork"],
    ['敬', "respect, honor; respectfully"],
    ['哭', "weep, cry, wail"],
    ['懂', "understand, comprehend"],
    ['墙', "wall"],
    ['袭', "raid, attack; inherit"],
    ['召', "imperial decree; summon"],
    ['罚', "penalty, fine; punish, penalize"],
    ['侠', "chivalrous person; knight-errant"],
    ['厅', "hall, central room"],
    ['拜', "do obeisance, bow, kowtow"],
    ['巧', "skillful, ingenious, clever"],
    ['侧', "side; incline, slant, lean"],
    ['韩', "fence; surname; Korea"],
    ['冒', "risk, brave, dare"],
    ['债', "debt, loan, liabilities"],
    ['曼', "long, extended, vast; beautiful"],
    ['融', "melt, fuse; blend, harmonize"],
    ['惯', "habit, custom; habitually, usual"],
    ['享', "enjoy"],
    ['戴', "wear on top; support"],
    ['童', "child, boy, servant boy; virgin"],
    ['犹', "like, similar to, just like, as"],
    ['乘', "ride, ascend; avail oneself of; numerary adjunct for vehicles"],
    ['挂', "hang, suspend; suspense"],
    ['奖', "prize, reward; give award to"],
    ['绍', "continue, carry on; join"],
    ['厚', "thick; substantial; greatly"],
    ['纵', "indulge in, give free reign to"],
    ['障', "separate; shield; barricade"],
    ['讯', "inquire; ask; examine; reproach"],
    ['涉', "ford stream, wade across"],
    ['彻', "penetrate, pervade; penetrating"],
    ['刊', "publication, periodical; publish"],
    ['丈', "unit of length equal 3.3 meters; gentleman, man, husband"],
    ['爆', "crackle, pop, burst, explode"],
    ['乌', "crow, rook, raven; black, dark"],
    ['役', "service; a servant, laborer; to serve"],
    ['描', "copy, trace, sketch; depict"],
    ['洗', "wash, rinse; clean, purify"],
    ['玛', "agate; cornelian"],
    ['患', "suffer, worry about; suffering"],
    ['妙', "mysterious, subtle; exquisite"],
    ['镜', "mirror; lens; glass; glasses"],
    ['唱', "sing, chant, call; ditty, song"],
    ['烦', "bother, vex, trouble; troublesome"],
    ['签', "sign, endorse; slip of paper"],
    ['仙', "Taoist super-being, transcendent, immortal"],
    ['彼', "that, there, those"],
    ['弗', "not, negative"],
    ['症', "disease, illness, ailment"],
    ['仿', "imitate, copy; as if"],
    ['倾', "upset, pour out, overflow"],
    ['牌', "signboard, placard"],
    ['陷', "submerge, sink, plunge; trap"],
    ['鸟', "bird; KangXi radical 196"],
    ['轰', "rumble, explosion, blast"],
    ['咱', "we, us"],
    ['菜', "vegetables; dish, order; food"],
    ['闭', "shut, close; obstruct, block up"],
    ['奋', "strive, exert effort; arouse"],
    ['庆', "congratulate, celebrate"],
    ['撤', "omit; remove; withdraw"],
    ['泪', "tears; weep, cry"],
    ['茶', "tea"],
    ['疾', "illness, disease, sickness; to hate"],
    ['缘', "hem, margin; reason, cause; fate"],
    ['播', "sow, spread; broadcast; cast away, reject"],
    ['朗', "clear, bright; distinct"],
    ['杜', "stop, prevent; restrict; surname"],
    // ['奶', ""],
    ['季', "quarter of year; season; surname"],
    ['丹', "cinnabar (native HgS); vermilion (artificial HgS used as pigment)"],
    ['狗', "dog, canis familiaris"],
    ['尾', "tail, extremity; end, stern"],
    ['仪', "ceremony, rites gifts; admire"],
    ['偷', "to steal, burglar, thief"],
    ['奔', "run fast, flee; rush about; run"],
    ['珠', "precious stone, gem, jewel, pearl"],
    ['虫', "insects, worms; KangXi radical 142"],
    ['驻', "to be stationed at, reside at; to stop"],
    ['孔', "opening, hole, orifice; great"],
    ['宜', "suitable, right, fitting, proper"],
    ['艾', "artemisia, mugwort; translit."],
    ['桥', "bridge; beam, crosspiece"],
    ['淡', "weak, watery; insipid, tasteless"],
    ['翼', "wings; fins on fish; shelter"],
    ['恨', "hatred, dislike; resent, hate"],
    ['繁', "complicated, complex, difficult"],
    ['寒', "cold, wintry, chilly"],
    ['伴', "companion, comrade, partner; accompany"],
    ['叹', "sigh, admire"],
    ['旦', "dawn; morning; day"],
    ['愈', "more and more, even more"],
    ['潮', "tide; moist, wet, damp; flow"],
    ['粮', "food, grain, provisions"],
    ['缩', "contract, draw in, reduce"],
    ['罢', "cease, finish, stop; give up"],
    ['聚', "assemble, meet together, collect"],
    ['径', "narrow path; diameter; direct"],
    ['恰', "just, exactly, precisely; proper"],
    ['挑', "a load carried on the shoulders; to carry"],
    ['袋', "pocket, bag, sack, pouch"],
    ['灰', "ashes; dust; lime, mortar"],
    ['捕', "arrest, catch, seize"],
    ['徐', "slowly, quietly, calmly; composed, dignified"],
    ['珍', "precious, valuable, rare"],
    ['幕', "curtain, screen, tent"],
    ['映', "project; reflect light"],
    ['裂', "split, crack, break open; rend"],
    ['泰', "great, exalted, superior; big"],
    ['隔', "separate, partition"],
    ['启', "open; begin, commence; explain"],
    ['尖', "sharp, pointed, acute, keen"],
    ['忠', "loyalty, devotion, fidelity"],
    ['累', "tired; implicate, involve; bother"],
    ['炎', "flame, blaze; hot"],
    ['暂', "temporary"],
    ['估', "merchant; estimate, guess, presume"],
    ['泛', "to drift, float; careless, reckless"],
    ['荒', "wasteland, desert; uncultivated"],
    ['偿', "repay, recompense; restitution"],
    ['横', "across"],
    ['拒', "ward off with hand, defend"],
    ['瑞', "felicitous omen; auspicious"],
    ['忆', "remember, reflect upon; memory"],
    ['孤', "orphan, fatherless; solitary"],
    ['鼻', "nose; first; KangXi radical 209"],
    ['闹', "quarrel; dispute hotly"],
    ['羊', "sheep, goat; KangXi radical 123"],
    ['呆', "dull; dull-minded, simple, stupid"],
    ['厉', "whetstone; grind, sharpen; whet"],
    ['衡', "measure, weigh, judge, consider"],
    ['胞', "womb, placenta, fetal membrane"],
    ['零', "zero; fragment, fraction"],
    ['穷', "poor, destitute, impoverished"],
    ['舍', "house, dwelling; dwell, reside"],
    ['码', "number, numerals; yard; symbol"],
    ['赫', "bright, radiant, glowing"],
    ['婆', "old woman; grandmother"],
    ['魂', "soul, spirit"],
    ['灾', "calamity, disaster, catastrophe"],
    ['洪', "vast, immense; flood, deluge"],
    ['腿', "legs, thighs"],
    ['胆', "gall bladder; bravery, courage"],
    ['津', "ferry; saliva; ford"],
    ['俗', "social customs; vulgar, unrefined"],
    ['辩', "dispute, argue, debate, discuss"],
    ['胸', "breast, bosom, chest; thorax"],
    ['晓', "dawn, daybreak; clear, explicit"],
    ['劲', "strong, unyielding, tough, power"],
    ['贫', "poor, impoverished, needy"],
    ['仁', "humaneness, benevolence, kindness"],
    ['偶', "accidentally, coincidently; an idol"],
    ['辑', "gather up, collect; edit, compile"],
    ['邦', "nation, country, state"],
    ['恢', "restore; big, great, immense, vast"],
    ['赖', "rely, depend on; accuse falsely"],
    ['圈', "to circle; a circle; corral"],
    ['摸', "gently touch with hand; caress"],
    ['仰', "raise the head to look; look up to, rely on, admire"],
    ['润', "soft, moist; sleek; freshen"],
    ['堆', "heap, pile, crowd; pile up"],
    ['碰', "collide, bump into"],
    ['艇', "small boat, dugout, punt"],
    ['稍', "little, slightly; rather"],
    ['迟', "late, tardy; slow; delay"],
    ['辆', "numerary adjunct for vehicles"],
    ['废', "abrogate, terminate, discard"],
    ['净', "clean, pure; cleanse"],
    ['凶', "culprit; murder; bad, sad"],
    ['署', "public office"],
    ['壁', "partition wall; walls of a house"],
    ['御', "drive, ride; chariot; manage"],
    ['奉', "offer; receive; serve; respect"],
    ['旋', "revolve, move in orbit; return"],
    ['冬', "winter, 11th lunar month"],
    ['矿', "mine; mineral, ore"],
    ['抬', "lift, carry"],
    ['蛋', "eggs; term of abuse"],
    ['晨', "early morning, daybreak"],
    ['伏', "crouch, crawl, lie hidden, conceal"],
    ['吹', "blow; puff; brag, boast"],
    ['鸡', "chicken"],
    ['倍', "times, fold, multiple times"],
    ['糊', "paste, stick on with paste"],
    // ['秦', ""],
    ['盾', "shield, shield-shaped object; currency sign for Dutch guilder"],
    ['杯', "cup, glass"],
    ['租', "rent, lease; rental; tax"],
    ['骑', "ride horseback; mount; cavalry"],
    ['乏', "lack; poor"],
    ['隆', "prosperous, plentiful, abundant"],
    ['诊', "examine patient, diagnose"],
    ['奴', "slave, servant"],
    ['摄', "take in, absorb; act as deputy"],
    ['丧', "mourning; mourn; funeral"],
    ['污', "filthy, dirty, impure, polluted"],
    ['渡', "cross, ferry over; ferry"],
    ['旗', "banner, flag, streamer"],
    ['甘', "sweetness; sweet, tasty"],
    ['耐', "endure, bear; resist; patient"],
    ['凭', "lean on, depend on, rely on"],
    ['扎', "pull up; pierce; struggle free"],
    ['抢', "plunder, rob, take by force"],
    ['绪', "end of thread; thread, clue"],
    ['粗', "rough, thick, course; rude"],
    ['肩', "shoulders; to shoulder; bear"],
    ['梁', "bridge; beam; rafters; surname"],
    ['幻', "illusion, fantasy, mirage"],
    ['菲', "fragrant, luxuriant; the Philippines"],
    ['皆', "all, every, everybody"],
    ['碎', "break, smash; broken, busted"],
    ['宙', "time as concept; infinite time"],
    // ['叔', ""],
    ['岩', "cliff; rocks; mountain"],
    ['荡', "pond, pool; wash away, cleanse"],
    ['综', "arrange threads for weaving"],
    ['爬', "crawl, creep; climb; scramble"],
    ['荷', "lotus, water lily, holland"],
    ['悉', "know, learn about, comprehend"],
    ['蒂', "peduncle or stem of plants"],
    ['返', "return, revert to, restore"],
    ['井', "well, mine shaft, pit"],
    ['壮', "big, large; robust; name of tribe"],
    ['薄', "thin, slight, weak; poor, stingy"],
    ['悄', "silent, quiet, still; anxious"],
    ['扫', "sweep, clear away; exterminate"],
    ['敏', "fast, quick, clever, smart"],
    ['碍', "obstruct, hinder, block, deter"],
    ['殖', "breed, spawn; increase; prosper"],
    ['详', "detailed, complete, thorough"],
    ['迪', "enlighten, advance; progress"],
    ['矛', "spear, lance; KangXi radical 110"],
    ['霍', "quickly, suddenly; surname"],
    ['允', "to grant, to allow, to consent"],
    ['幅', "piece, strip, breadth of, hem"],
    ['撒', "release, cast away, let go; disperse; relax"],
    ['剩', "leftovers, residue, remains"],
    ['凯', "triumphant; triumph, victory"],
    ['颗', "grain, kernel"],
    ['骂', "accuse, blame, curse, scold"],
    ['赏', "reward, grant, bestow; appreciate"],
    ['液', "sap, juice, liquid, fluid"],
    ['番', "to take turns; a turn, a time; to repeat"],
    ['箱', "case, box, chest, trunk"],
    ['贴', "paste to, stick on; attached to"],
    ['漫', "overflow of water; spreading"],
    ['酸', "tart, sour; acid; stiff; spoiled"],
    ['郎', "gentleman, young man; husband"],
    ['腰', "waist; kidney"],
    ['舒', "open up, unfold, stretch out; comfortable, easy"],
    ['眉', "eyebrows; upper margin of book"],
    ['忧', "sad, grieved; grief, melancholy"],
    ['浮', "to float, drift, waft; to exceed; superfluous"],
    ['辛', "bitter; toilsome, laborious; 8th heavenly stem"],
    ['恋', "love; long for, yearn for; love"],
    ['餐', "eat, dine; meal; food"],
    ['吓', "scare, frighten; intimidate"],
    ['挺', "to stand upright, straighten; rigid"],
    ['励', "strive; encourage"],
    ['辞', "words, speech, expression, phrase"],
    ['艘', "counter for ships, vessels"],
    ['键', "door bolt, lock bolt; key"],
    ['伍', "five, company of five; troops"],
    ['峰', "peak, summit; hump of camel"],
    // ['尺', ""],
    ['昨', "yesterday; in former times, past"],
    ['黎', "surname; numerous, many; black"],
    ['辈', "generation, lifetime; class"],
    ['贯', "a string of 1000 coins; to go through"],
    ['侦', "spy, reconnoiter; detective"],
    ['滑', "slip, slide; slippery, polished"],
    ['券', "certificate, ticket; title deeds"],
    ['崇', "esteem, honor, revere, venerate"],
    ['扰', "disturb, annoy, agitate"],
    ['宪', "constitution, statute, law"],
    ['绕', "entwine; wind around; surround"],
    ['趋', "hasten, hurry; be attracted to"],
    ['慈', "kind, charitable, benevolent"],
    ['乔', "tall, lofty; proud, stately"],
    ['阅', "examine, inspect, review, read"],
    ['汗', "perspiration, sweat"],
    ['枝', "branches, limbs; branch off"],
    ['拖', "drag, tow, haul; delay, prolong"],
    ['墨', "ink; writing"],
    ['胁', "ribs, armpits; flank; threaten"],
    ['插', "insert, stick into; plant"],
    ['箭', "arrow; type of bamboo"],
    ['腊', "year end sacrifice; dried meat"],
    ['粉', "powder, face powder; plaster"],
    ['泥', "mud, mire; earth, clay; plaster"],
    ['氏', "clan, family; mister"],
    ['彭', "name of ancient country; surname"],
    ['拔', "uproot, pull out"],
    ['骗', "swindle, cheat out of, defraud"],
    ['凤', "male phoenix; symbol of joy"],
    ['慧', "bright, intelligent; intelligence"],
    ['媒', "go-between, matchmaker; medium"],
    ['佩', "belt ornament, pendant; wear at waist, tie to the belt; respect"],
    ['愤', "resent, hate; indignant"],
    ['扑', "pound, beat, strike; attack"],
    ['龄', "age; years"],
    ['驱', "spur a horse on; expel, drive away"],
    ['惜', "pity, regret, rue, begrudge"],
    ['豪', "brave, heroic, chivalrous"],
    ['掩', "to cover (with the hand); shut, conceal; ambush"],
    ['兼', "unite, combine; connect; and"],
    ['跃', "skip, jump, frolic"],
    ['尸', "corpse; to impersonate the dead; to preside; KangXi radical 44"],
    ['肃', "pay respects; reverently"],
    ['帕', "turban, kerchief, veil; wrap"],
    ['驶', "sail, drive, pilot; fast, quick"],
    ['堡', "fort, fortress; town, village"],
    ['届', "numerary adjunct for time, term"],
    ['欣', "happy, joyous, delighted"],
    ['惠', "favor, benefit, confer kindness"],
    ['册', "book, volume, register, list"],
    ['储', "save money, store, reserve; heir"],
    ['飘', "whirlwind, cyclone; floating"],
    ['桑', "mulberry tree; surname"],
    ['闲', "fence, guard; defend; idle time"],
    ['惨', "sad, pitiful, wretched; cruel"],
    ['洁', "clean, purify, pure"],
    ['踪', "footprints, traces, tracks"],
    ['勃', "suddenly, sudden, quick"],
    ['宾', "guest, visitor; surname; submit"],
    ['频', "frequently, again and again"],
    ['仇', "enemy, hate, hatred, enmity"],
    ['磨', "grind; polish; rub; wear out; a millstone"],
    ['递', "hand over, deliver; substitute"],
    ['邪', "wrong, evil, depraved, vicious, perverse, heterodox"],
    ['撞', "knock against, bump into, collide"],
    ['拟', "draft; intend, plan, propose"],
    ['滚', "turn"],
    ['奏', "memorialize emperor; report"],
    ['巡', "patrol, go on circuit, cruise"],
    ['颜', "face, facial appearance"],
    ['剂', "medicinal preparation"],
    ['绩', "spin; achievements"],
    ['贡', "offer tribute; tribute, gifts"],
    ['疯', "crazy, insane, mentally ill"],
    ['坡', "slope, bank, hillside"],
    ['瞧', "glance at, look at, see"],
    ['截', "cut off, stop, obstruct, intersect"],
    ['燃', "burn; light fire, ignite"],
    ['焦', "burned, scorched; anxious, vexed"],
    ['殿', "hall; palace; temple"],
    ['伪', "false, counterfeit, bogus"],
    ['柳', "willow tree; pleasure"],
    ['锁', "lock, padlock; shackles, chains"],
    ['逼', "compel, pressure, force; bother"],
    ['颇', "lean one side; very, rather"],
    ['昏', "dusk, nightfall, twilight, dark"],
    ['劝', "recommend, advise, urge"],
    ['呈', "submit, show; appear; petition"],
    ['搜', "search, seek; investigate"],
    ['勤', "industrious, diligent, attentive"],
    ['戒', "warn, caution, admonish"],
    ['驾', "to drive, sail, fly; a cart, carriage"],
    ['漂', "float, drift; tossed about"],
    ['饮', "drink; swallow; kind of drink"],
    ['曹', "ministry officials; surname"],
    ['朵', "cluster of flowers; earlobe"],
    ['仔', "small thing, child; young animal"],
    ['柔', "soft; gentle, pliant"],
    ['俩', "two, pair"],
    ['孟', "first in series; great, eminent"],
    ['腐', "rot, decay, spoil; rotten"],
    ['幼', "infant, young child; immature"],
    ['践', "trample, tread upon, walk on"],
    ['籍', "record, register, list; census"],
    ['牧', "tend cattle, shepherd"],
    ['凉', "cool, cold; disheartened"],
    ['牲', "sacrificial animal; animal"],
    ['佳', "good, auspicious; beautiful; delightful"],
    ['娜', "elegant, graceful, delicate"],
    ['浓', "thick, strong, concentrated"],
    ['芳', "fragrant; virtuous; beautiful"],
    ['稿', "draft, manuscript, rough copy"],
    ['竹', "bamboo; flute; KangXi radical 118"],
    ['腹', "stomach, belly, abdomen; inside"],
    ['跌', "stumble, slip, fall down; stamp"],
    ['逻', "patrol; inspect; watch; patrol"],
    ['垂', "let down; suspend, hand; down"],
    ['遵', "obey, comply with, follow; honor"],
    ['脉', "blood vessels, veins, arteries"],
    ['貌', "countenance, appearance"],
    ['柏', "cypress, cedar"],
    ['狱', "prison, jail; case; lawsuit"],
    ['猜', "guess, conjecture, suppose; feel"],
    ['怜', "pity, sympathize"],
    ['惑', "confuse, mislead, baffle; doubt"],
    ['陶', "pottery, ceramics"],
    ['兽', "beast, animal; bestial"],
    ['帐', "tent; screen, mosquito net"],
    ['饰', "decorate, ornament, adorn"],
    ['贷', "lend; borrow; pardon"],
    ['昌', "light of sun; good, proper"],
    ['叙', "express, state, relate, narrate"],
    ['躺', "lie down, recline"],
    ['钢', "steel; hard, strong, tough"],
    ['沟', "ditch, drain, narrow waterway"],
    ['寄', "send, transmit, mail; rely on"],
    ['扶', "support, help; protect; hold on"],
    ['铺', "shop, store; place sleep, bed"],
    ['邓', "surname"],
    ['寿', "old age, long life; lifespan"],
    ['惧', "fear, be afraid of, dread"],
    ['询', "inquire into, ask about; consult"],
    ['汤', "hot water; soup, gravy, broth"],
    ['盗', "rob, steal; thief, bandit"],
    ['肥', "fat, plump, obese; fertile"],
    ['尝', "taste; experience, experiment"],
    ['匆', "hastily, in haste, hurriedly"],
    ['辉', "brightness, luster, brilliance"],
    ['奈', "but, how; bear, stand, endure"],
    ['扣', "knock, strike, rap, tap; button"],
    ['廷', "court"],
    ['澳', "inlet, bay; dock, bank"],
    ['嘛', "final exclamatory particle"],
    ['董', "direct, supervise; surname"],
    ['迁', "move, shift, change; transfer; relocate a capital city"],
    ['凝', "coagulate; congeal; freeze"],
    ['慰', "comfort, console, calm"],
    ['厌', "dislike, detest, reject; satiate"],
    ['脏', "internal organs, viscera; dirty"],
    ['腾', "fly; gallop; run; prance; rise"],
    ['幽', "quiet, secluded, tranquil; dark"],
    ['怨', "hatred, enmity, resentment"],
    ['鞋', "shoes, footwear in general"],
    ['丢', "discard"],
    ['埋', "bury, secrete, conceal"],
    ['泉', "spring, fountain; wealth, money"],
    ['涌', "surge up, bubble up, gush forth"],
    ['辖', "linchpin of wheel; control"],
    ['躲', "hide, secrete; avoid, escape"],
    ['晋', "advance, increase; promote"],
    ['紫', "purple, violet; amethyst; surname"],
    ['艰', "difficult, hard; distressing"],
    ['魏', "kingdom of Wei; surname"],
    ['吾', "i, my, our; resist, impede"],
    ['慌', "nervous, panicky, frantic"],
    ['祝', "pray for happiness or blessings"],
    ['邮', "postal, mail; post office"],
    ['吐', "vomit, spew out, cough up"],
    ['狠', "vicious, cruel; severely, extreme"],
    ['鉴', "mirror, looking glass; reflect"],
    ['曰', "say; KangXi radical 73"],
    ['械', "weapons; implements, instruments"],
    ['咬', "bite, gnaw"],
    ['邻', "neighbor; neighborhood"],
    // ['赤', ""],
    ['挤', "crowd, squeeze, push against"],
    ['弯', "bend, curve"],
    ['椅', "chair, seat"],
    ['陪', "accompany, be with, keep company"],
    ['割', "cut, divide, partition; cede"],
    ['揭', "raise, lift up; surname"],
    ['韦', "tanned leather; surname; simplified form of KangXi radical number 178"],
    ['悟', "to apprehend, realize, become aware"],
    ['聪', "intelligent, clever, bright"],
    ['雾', "fog, mist, vapor, fine spray"],
    ['锋', "point of spear, sharp point"],
    ['梯', "ladder, steps, stairs; lean"],
    ['猫', "cat"],
    ['祥', "good luck, good omen; happiness"],
    ['阔', "broad, ample, wide; be apart"],
    ['誉', "fame, reputation; praise"],
    ['筹', "chip, tally, token; raise money"],
    ['丛', "bush, shrub; thicket; collection"],
    ['牵', "drag, pull, lead by hand"],
    ['鸣', "cry of bird or animal; make sound"],
    ['沈', "sink, submerge; addicted to; surname"],
    ['阁', "chamber, pavilion; cabinet"],
    ['穆', "majestic, solemn, reverent; calm"],
    ['屈', "bend, flex; bent, crooked; crouch"],
    ['旨', "purpose, aim; excellent"],
    ['袖', "sleeve; put something in sleeve"],
    ['猎', "hunt; field sports"],
    ['臂', "arm"],
    ['蛇', "snake"],
    ['贺', "congratulate; send present"],
    ['柱', "pillar, post; support; lean on"],
    ['抛', "throw (away)"],
    ['鼠', "rat, mouse; KangXi radical 208"],
    ['瑟', "large stringed musical instrument; dignified, massive; sound of wind"],
    ['戈', "halberd, spear, lance; rad. 62"],
    ['牢', "prison; stable, pen; secure"],
    ['逊', "humble, modest; yield"],
    ['迈', "take a big stride; pass by"],
    ['欺', "cheat, double-cross, deceive"],
    ['吨', "metric ton; (Cant.) to babble, gibberish"],
    ['琴', "Chinese lute or guitar"],
    ['衰', "decline, falter, decrease; weaken"],
    ['瓶', "jug, pitcher, vase, jar, bottle"],
    ['恼', "angered, filled with hate"],
    ['燕', "swallow (bird); comfort, enjoy"],
    ['仲', "middle brother; go between, mediator; surname"],
    ['诱', "persuade, entice, induce; guide"],
    ['狼', "wolf"],
    ['池', "pool, pond; moat; cistern"],
    ['疼', "aches, pains; be fond of; love"],
    ['卢', "cottage, hut; surname; black"],
    ['仗', "rely upon; protector; fight; war, weaponry"],
    ['冠', "cap, crown, headgear"],
    ['粒', "grain; small particle"],
    ['遥', "far away, distant, remote"],
    ['吕', "surname; a musical note"],
    ['玄', "deep, profound, abstruse; KangXi radical 95"],
    ['尘', "dust, dirt, ashes, cinders"],
    ['冯', "surname; gallop; by dint of"],
    ['抚', "pat, console, comfort; pacify"],
    ['浅', "shallow, not deep; superficial"],
    ['敦', "esteem; honest, candid, sincere"],
    ['纠', "investigate, inspect"],
    ['钻', "drill, bore; pierce; diamond"],
    ['晶', "crystal; clear, bright; radiant"],
    ['岂', "how? what?"],
    ['峡', "gorge, strait, ravine; isthmus"],
    ['苍', "blue; green"],
    ['喷', "spurt, blow out, puff out"],
    ['耗', "consume, use up; waste, squander"],
    ['凌', "pure; virtuous; insult; maltreat"],
    ['敲', "strike, beat, pound, hammer; rap"],
    ['菌', "mushroom; germ, microbe"],
    ['赔', "indemnify, suffer loss"],
    ['涂', "surname; name of certain rivers"],
    ['粹', "pure; unadulterated; select"],
    ['扁', "flat; tablet, signboard"],
    ['亏', "lose, fail; damage; deficient"],
    ['寂', "still, silent, quiet, desolate"],
    ['煤', "coal, coke, charcoal, carbon"],
    ['熊', "a bear; brilliant; bright; surname"],
    ['恭', "respectful, polite, reverent"],
    ['湿', "wet, moist, humid, damp; illness"],
    ['循', "obey, comply with, follow"],
    ['暖', "warm, genial"],
    ['糖', "sugar; candy; sweets"],
    ['赋', "tax; give; endow; army; diffuse"],
    ['抑', "press down, repress; curb, hinder"],
    ['秩', "order; orderly; salary; decade"],
    ['帽', "hat, cap; cap-like tops"],
    ['哀', "sad, mournful, pitiful; pity"],
    ['宿', "stop, rest, lodge, stay overnight; constellation"],
    ['踏', "step on, trample, tread on, walk"],
    ['烂', "rotten, spoiled, decayed"],
    ['袁', "robe; surname"],
    ['侯', "marquis, lord; target in archery"],
    ['抖', "tremble, shake, rouse; give shake"],
    ['夹', "be wedged or inserted between"],
    ['昆', "elder brother; descendants"],
    ['肝', "liver"],
    ['擦', "wipe, scrub, rub, scour; brush"],
    ['猪', "pig, hog, wild boar"],
    ['炼', "smelt, refine; distill, condense"],
    ['恒', "constant, regular, persistent"],
    ['慎', "act with care, be cautious"],
    ['搬', "transfer, move, remove, shift"],
    ['纽', "knot; button; handle, knob; tie"],
    ['纹', "line, streak, stripe; wrinkle"],
    ['玻', "glass"],
    ['渔', "to fish; seize; pursue; surname"],
    ['磁', "magnetic; magnetism; porcelain"],
    ['铜', "copper, brass, bronze"],
    ['齿', "teeth; gears, cogs; age; simplified form of the KangXi radical number 211"],
    ['跨', "straddle, bestride, ride; carry"],
    ['押', "mortgage, pledge; deposit; to pawn; to arrest, detain, guard"],
    ['怖', "terror, fear; frighten; terrified"],
    ['漠', "desert; aloof, indifferent, cool"],
    ['疲', "feel tired, be exhausted; weak"],
    ['叛', "rebel; rebellion; rebellious"],
    ['遣', "send, dispatch; send off, exile"],
    ['兹', "now, here; this; time, year"],
    ['祭', "sacrifice to, worship"],
    ['醉', "intoxicated, drunk; addicted to"],
    ['拳', "fist; various forms of boxing"],
    ['弥', "extensive, full; fill; complete"],
    ['斜', "slanting, sloping, inclined"],
    ['档', "shelf; frame, crosspiece"],
    ['稀', "rare, unusual, scarce; sparse"],
    ['捷', "win, victory, triumph"],
    ['肤', "skin; superficial, shallow"],
    ['疫', "epidemic, plague, pestilence"],
    ['肿', "swell; swollen; swelling"],
    ['豆', "beans, peas; bean-shaped"],
    ['削', "scrape off, pare, trim"],
    ['岗', "post; position"],
    ['晃', "bright, dazzling; to sway, shake"],
    ['吞', "swallow; absorb, annex, engulf"],
    ['宏', "wide, spacious, great, vast"],
    ['癌', "cancer; marmoset"],
    ['肚', "belly; abdomen; bowels"],
    ['隶', "subservient; servant; KangXi radical 171"],
    ['履', "footwear, shoes; walk on, tread"],
    ['涨', "rise in price"],
    ['耀', "shine, sparkle, dazzle; glory"],
    ['扭', "turn, twist, wrench; seize, grasp"],
    ['坛', "altar; arena, examination hall"],
    ['拨', "move; dispel; distribute"],
    ['沃', "water, irrigate; fertile, rich"],
    ['绘', "draw, sketch, paint"],
    ['伐', "cut down, subjugate, attack"],
    ['堪', "adequately capable of, worthy of"],
    ['仆', "fall forward; lie prostrate, prone; servant"],
    ['郭', "outer part (of a city); surname"],
    ['牺', "sacrifice, give up; sacrificial"],
    ['歼', "annihilate, wipe out, kill off"],
    ['墓', "grave, tomb"],
    ['雇', "employ, to hire"],
    ['廉', "upright, honorable, honest"],
    ['契', "deed, contract, bond; engrave"],
    ['拼', "join together, link, incorporate"],
    ['惩', "punish, reprimand; warn"],
    ['捉', "grasp, clutch; catch, seize"],
    ['覆', "cover; tip over; return; reply"],
    ['刷', "brush; clean with brush, scrub"],
    ['劫', "take by force, coerce; disaster"],
    ['嫌', "hate, detest; suspect; criticize"],
    ['瓜', "melon, gourd, cucumber; rad. 97"],
    ['歇', "rest, stop, lodge"],
    ['雕', "engrave, inlay, carve; exhaust; used for 鵰 an eagle, vulture"],
    ['闷', "gloomy, depressed, melancholy"],
    ['乳', "breast, nipples; milk, suckle"],
    ['串', "string; relatives; conspire"],
    ['娃', "baby; doll; pretty girl"],
    ['缴', "deliver, submit; hand over"],
    ['唤', "call"],
    ['赢', "win; surplus, gain, profit"],
    ['莲', "lotus, water lily; paradise"],
    ['霸', "rule by might rather than right"],
    ['桃', "peach; marriage; surname"],
    ['妥', "satisfactory, appropriate"],
    ['瘦', "thin, emaciated, lean; meager"],
    ['搭', "join together, attach to; add to"],
    ['赴', "go to; attend, be present"],
    ['岳', "mountain peak; surname"],
    ['嘉', "excellent; joyful; auspicious"],
    ['舱', "hold of ship; cabin"],
    ['俊', "talented, capable; handsome"],
    ['址', "site, location, land for house"],
    ['庞', "disorderly, messy; huge, big"],
    ['耕', "plow, cultivate"],
    ['锐', "sharp, keen, acute, pointed"],
    ['缝', "sew, mend"],
    ['悔', "repent, show remorse, regret"],
    ['邀', "invite, welcome; meet, intercept"],
    ['玲', "tinkling of jade"],
    ['惟', "but, however, nevertheless; only"],
    ['斥', "to scold, upbraid, accuse, reproach"],
    ['宅', "residence, dwelling, home; grave"],
    ['添', "append, add to; increase"],
    ['挖', "dig, dig out, gouge out, scoop"],
    ['呵', "scold; laughing sound; yawn"],
    ['讼', "accuse; argue, dispute; litigate"],
    ['氧', "oxygen"],
    ['浩', "great, numerous, vast, abundant"],
    ['羽', "feather, plume; wings; rad. 124"],
    ['斤', "a catty (approximately 500 g); an axe; keen, shrewd; KangXi radical number 69"],
    ['酷', "strong, stimulating; very"],
    ['掠', "rob, ransack, plunder; pass by"],
    ['妖', "strange, weird, supernatural"],
    ['祸', "misfortune, calamity, disaster"],
    ['侍', "serve, attend upon; attendant, servant; samurai"],
    ['乙', "second; 2nd heavenly stem"],
    ['妨', "interfere with, impede, obstruct"],
    ['贪', "be greedy, covet; covetous"],
    ['挣', "strive, endeavor, struggle"],
    ['汪', "vast, extensive, deep; surname"],
    ['尿', "urine; urinate"],
    ['莉', "white jasmine"],
    ['悬', "hang, suspend, hoist; be hung"],
    ['唇', "lips"],
    ['翰', "writing brush, pen, pencil"],
    ['仓', "granary; berth; sea"],
    ['轨', "track, rut, path"],
    ['枚', "stalk of shrub, trunk of tree"],
    ['盐', "salt"],
    ['览', "look at, inspect; perceive"],
    ['傅', "tutor, teacher; assist; surname"],
    ['帅', "commander, commander-in-chief"],
    ['庙', "temple, shrine; imperial court"],
    ['芬', "fragrance, aroma; perfume"],
    ['屏', "folding screen, shield"],
    ['寺', "court, office; temple, monastery"],
    ['胖', "fat, obese; fatty"],
    ['璃', "glass"],
    ['愚', "stupid, doltish, foolish"],
    ['滴', "drip; drop of water"],
    ['疏', "neglect; careless, lax"],
    ['萧', "common artemisia; dejected"],
    // ['姿', ""],
    ['颤', "shiver, tremble; trembling"],
    ['丑', "clown, comedian; 2nd terrestrial branch"],
    ['劣', "bad, inferior; slightly"],
    ['柯', "axe-handle; stalk, bough; surname"],
    ['寸', "inch; small, tiny; KangXi radical 41"],
    ['扔', "throw, hurl; throw away, cast"],
    ['盯', "rivet gaze upon, keep eyes on"],
    ['辱', "humiliate, insult, abuse"],
    ['匹', "bolt of cloth; counter for horses"],
    ['俱', "all, together; accompany"],
    ['辨', "distinguish, discriminate"],
    ['饿', "hungry; greedy for; hunger"],
    ['蜂', "bee, wasp, hornet"],
    ['哦', "oh? really? is that so?"],
    ['腔', "chest cavity; hollow in body"],
    ['郁', "sweet smelling, rich in aroma; (Cant.) to move, hit"],
    ['溃', "flooding river; militarily defeat"],
    ['谨', "prudent, cautious; attentive"],
    ['糟', "sediment, dregs; pickle"],
    ['葛', "edible bean; surname"],
    ['苗', "sprouts; Miao nationality"],
    ['肠', "intestines; emotions; sausage"],
    ['忌', "jealous, envious; fear"],
    ['溜', "slide, glide, slip; slippery"],
    ['鸿', "species of wild swan; vast"],
    ['爵', "feudal title or rank"],
    ['鹏', "fabulous bird of enormous size"],
    ['鹰', "falcon; Accipiter species (various)"],
    ['笼', "cage; cage-like basket"],
    ['丘', "hill; elder; empty; a name"],
    ['桂', "cassia or cinnamon"],
    ['滋', "grow, multiply, increase; thrive"],
    ['聊', "somewhat, slightly, at least"],
    ['挡', "obstruct, impede; stop; resist"],
    ['纲', "heavy rope, hawser; main points"],
    ['肌', "muscle tissue; meat on bones"],
    ['茨', "thatching; caltrop, Tribulus terrestris"],
    ['壳', "casing, shell, husk"],
    ['痕', "scar; mark; trace"],
    ['碗', "bowl, small dish"],
    ['穴', "cave, den, hole; KangXi radical 116"],
    ['膀', "upper arm; shoulder; wing"],
    ['卓', "profound, brilliant, lofty"],
    ['贤', "virtuous, worthy, good; able"],
    ['卧', "lie down; crouch"],
    ['膜', "membrane; to kneel and worship"],
    ['毅', "resolute, decisive, firm, persist"],
    ['锦', "brocade, tapestry; embroidered"],
    ['欠', "owe, lack, be deficient; KangXi radical number 76"],
    ['哩', "mile"],
    ['函', "correspondence; a case; a box"],
    ['茫', "vast, boundless, widespread"],
    ['昂', "rise, raise; proud, bold; upright"],
    ['薛', "kind of marsh grass; feudal state"],
    ['皱', "wrinkles, creases, folds"],
    ['夸', "extravagant, luxurious; handsome"],
    ['豫', "relaxed, comfortable, at ease"],
    ['胃', "stomach; gizzard of fowl"],
    ['舌', "tongue; clapper of bell; KangXi radical 135"],
    ['剥', "peel"],
    ['傲', "proud, haughty, overbearing"],
    ['拾', "pick up, collect, tidy up; accounting form of the numeral ten"],
    ['窝', "nest; cave, den; hiding place"],
    ['睁', "open eyes; stare"],
    ['携', "lead by hand, take with; carry"],
    ['陵', "hill, mound; mausoleum"],
    ['哼', "hum; sing softly; groan, moan; (Cant.) an interjecting indicating disapproval"],
    ['棉', "cotton; cotton padded"],
    ['晴', "clear weather, fine weather"],
    ['铃', "bell"],
    ['填', "fill in, fill up; make good"],
    ['饲', "raise animals; feed; nourish"],
    ['渴', "thirsty, parched; yearn, pine"],
    ['吻', "kiss; the lips; coinciding"],
    ['扮', "dress up; dress up as"],
    ['逆', "disobey, rebel; rebel, traitor"],
    ['脆', "crisp; fragile, frail; brittle"],
    ['喘', "pant, gasp, breathe heavily"],
    ['罩', "basket for catching fish; cover"],
    ['卜', "fortune telling; prophesy"],
    ['炉', "fireplace, stove, oven, furnace"],
    ['柴', "firewood, faggots, fuel"],
    ['愉', "pleasant, delightful; please"],
    ['绳', "rope, string, cord; control"],
    ['胎', "unborn child, embryo, fetus"],
    ['蓄', "store, save, hoard, gather"],
    ['眠', "close eyes, sleep; hibernate"],
    ['竭', "put forth great effort; exhaust"],
    ['喂', "interjection to call attention"],
    ['傻', "foolish, silly, stupid; an imbecile"],
    ['慕', "long for, desire; admire"],
    ['浑', "muddy, turbid; blend, merge, mix"],
    ['奸', "crafty, villainous, false"],
    ['扇', "fan; door panel"],
    ['柜', "cabinet, cupboard; shop counter"],
    ['悦', "pleased, contented, gratified"],
    ['拦', "obstruct, impede, bar, hinder"],
    ['诞', "bear children, give birth; birth"],
    // ['饱', ""],
    ['乾', "dry; first hexagram; warming principle of the sun, penetrating and fertilizing, heavenly generative principle (male)"],
    ['泡', "bubbles, suds; blister; soak"],
    ['贼', "thief, traitor; kill"],
    ['亭', "pavilion; erect"],
    ['夕', "evening, night, dusk; slanted"],
    ['爹', "father, daddy"],
    ['酬', "toast; reward, recompense"],
    ['儒', "Confucian scholar"],
    ['姻', "relatives by marriage"],
    ['卵', "egg; ovum; roe; spawn"],
    ['氛', "gas, vapor, air"],
    ['泄', "leak; vent; flow; reveal"],
    ['杆', "pole; shaft of spear"],
    ['挨', "near, close by, next to, towards, against; to wait; to lean on"],
    ['僧', "Buddhist priest, monk; san of Sanskrit sangha"],
    ['蜜', "honey; sweet; nectar"],
    ['吟', "sing, hum; recite; type of poetry"],
    ['猩', "species of orangutan"],
    ['遂', "comply with, follow along; thereupon"],
    ['狭', "narrow, limited; narrow-minded"],
    ['肖', "look like, resemble, be like"],
    ['甜', "sweet, sweetness"],
    ['霞', "rosy clouds"],
    ['驳', "varicolored, variegated; mixed"],
    ['裕', "abundant, rich, plentiful"],
    ['顽', "obstinate, stubborn; recalcitrant"],
    ['於', "in, at, on; interjection alas!"],
    ['摘', "pluck, pick; select; specify"],
    ['矮', "short, dwarf; low"],
    ['秒', "beard of grain or corn; a second"],
    ['卿', "noble, high officer"],
    ['畜', "livestock, domestic animals"],
    ['咽', "throat; pharynx"],
    ['披', "wear; split; crack"],
    ['辅', "cheek bone; protective; assist"],
    ['勾', "hook, join, connect; entice"],
    ['盆', "basin, tub, pot, bowl"],
    ['疆', "boundary, border, frontier"],
    ['赌', "bet, gamble, wager; compete"],
    ['塑', "model in clay, sculpt; plastics"],
    ['畏', "fear, dread, awe, reverence"],
    ['吵', "argue, dispute; disturb, annoy"],
    ['囊', "bag, purse, sack; put in bag"],
    ['嗯', "final particle"],
    ['泊', "anchor vessel; lie at anchor"],
    ['肺', "lungs"],
    ['骤', "procedure; gallop; sudden(ly)"],
    ['缠', "wrap, wind around; tie, bind"],
    ['冈', "ridge or crest of hill"],
    ['羞', "disgrace, shame; ashamed; shy"],
    ['瞪', "stare at"],
    ['吊', "condole, mourn, pity; hang"],
    ['贾', "surname; merchant; buy, trade"],
    ['漏', "leak, drip; funnel; hour glass"],
    ['斑', "mottled, striped, freckle"],
    ['涛', "large waves"],
    ['悠', "long, far, remote, distant; liesurely"],
    ['鹿', "deer; surname; KangXi radical 198"],
    ['俘', "prisoner of war; take as prisoner"],
    ['锡', "tin, stannum; bestow, confer"],
    ['卑', "humble, low, inferior; despise"],
    ['葬', "bury, inter"],
    ['铭', "inscribe, engrave"],
    ['滩', "bank, a sandbar, shoal; rapids"],
    ['嫁', "to marry, give a daughter in marriage"],
    ['催', "press, urge"],
    ['璇', "beautiful jade; star"],
    ['翅', "wings; fin"],
    ['盒', "small box or case; casket"],
    ['蛮', "barbarians; barbarous, savage"],
    ['矣', "particle of completed action"],
    ['潘', "surname; water in which rice has been rinsed; a river that flows into the Han"],
    ['歧', "fork of road; branching off"],
    ['赐', "give, bestow favors; appoint"],
    ['鲍', "abalone; dried fish; surname"],
    ['锅', "cooking-pot, saucepan"],
    ['廊', "corridor, porch, veranda"],
    ['拆', "to break up, split apart, rip open; to destroy"],
    ['灌', "pour; water; irrigate, flood"],
    ['勉', "endeavor, make effort; urge"],
    ['盲', "blind; unperceptive, shortsighted"],
    ['宰', "to slaughter; to rule"],
    ['佐', "assist, aid, second; subordinate"],
    ['啥', "what?"],
    ['胀', "swell, inflate, expand"],
    ['扯', "rip up, tear down; raise; haul"],
    ['禧', "happiness; congratulations"],
    ['辽', "distant, far"],
    ['抹', "smear, apply, wipe off, erase"],
    ['筒', "thick piece of bamboo; pipe"],
    ['棋', "chess; any game similar to chess"],
    ['裤', "trousers, pants"],
    ['唉', "alas, exclamation of surprise or pain"],
    ['朴', "simple, unadorned; sincere; surname; a tree"],
    ['咐', "instruct, order"],
    ['孕', "be pregnant, pregnancy"],
    ['誓', "swear, pledge; oath"],
    ['喉', "throat, gullet, larynx; guttural"],
    ['妄', "absurd, foolish, reckless; false"],
    ['拘', "restrain, seize, detain"],
    ['链', "chain, wire, cable; chain, shack"],
    ['驰', "go quickly or swiftly; hurry"],
    ['栏', "railing, balustrade; animal pan"],
    ['逝', "pass away; die"],
    ['窃', "secretly, stealthily; steal; thief"],
    ['艳', "beautiful, sexy, voluptuous"],
    ['臭', "smell, stink, emit foul odor"],
    ['纤', "fine, delicate; minute; graceful"],
    ['玑', "pearl that is not quite round"],
    ['棵', "numerary adjunct for trees"],
    ['趁', "take advantage of, avail oneself"],
    ['匠', "craftsman, artisan; workman"],
    ['盈', "fill; full, overflowing; surplus"],
    ['翁', "old man; father, father-in-law"],
    ['愁', "anxiety; to worry about, be anxious"],
    ['瞬', "wink, blink; in a wink, a flash"],
    ['婴', "baby, infant; bother"],
    ['孝', "filial piety, obedience; mourning"],
    ['颈', "neck, throat"],
    ['倘', "if, supposing, in event of"],
    ['浙', "Zhejiang province; river"],
    ['谅', "excuse, forgive; guess, presume"],
    ['蔽', "cover, hide, conceal; shelter"],
    ['畅', "smoothly, freely, unrestrained"],
    ['赠', "give present; bestow, confer"],
    ['妮', "maid, servant girl; cute girl"],
    ['莎', "kind of sedge grass, used anciently for raincoats"],
    ['尉', "officer, military rank"],
    ['冻', "freeze; cold, congeal; jelly"],
    ['跪', "kneel"],
    ['闯', "rush in, burst in, charge in"],
    ['葡', "grapes"],
    ['後', "behind, rear, after; descendents"],
    ['厨', "kitchen; closet; cupboard"],
    ['鸭', "duck; Anas species (various)"],
    ['颠', "top, peak, summit; upset"],
    ['遮', "cover, shield, protect"],
    ['谊', "friendship; appropriate, suitable"],
    ['圳', "furrow in field, small drainage ditch"],
    // ['吁', ""],
    ['仑', "logical reasons, logical order"],
    ['辟', "law, rule; open up, develop"],
    ['瘤', "tumor, lump, goiter"],
    // ['嫂', ""],
    ['陀', "steep bank, rough terrain"],
    ['框', "frame; framework; door frame"],
    ['谭', "surname"],
    ['亨', "smoothly, progressing, no trouble"],
    ['钦', "respect, admire; respectful"],
    ['庸', "usual, common, ordinary, mediocre"],
    ['歉', "deficient, lacking, insufficient"],
    // ['芝', ""],
    ['吼', "roar, shout; bark, howl"],
    ['甫', "begin; man, father; great; a distance of ten li"],
    ['衫', "shirt; robe; gown; jacket"],
    ['摊', "spread out, open; apportion"],
    ['宴', "to entertain, feast; a feast, banquet"],
    ['嘱', "order, tell, instruct, leave word"],
    // ['衷', ""],
    ['娇', "seductive and loveable; tender"],
    ['陕', "mountain pass; Shanxi province"],
    // ['矩', ""],
    ['浦', "bank of river, shore; surname"],
    ['讶', "express surprise, be surprised"],
    ['耸', "urge on; rise up; stir, excite"],
    ['裸', "bare, nude; undress, strip"],
    ['碧', "jade; green, blue"],
    ['摧', "destroy, break, injure"],
    ['薪', "fuel, firewood; salary"],
    ['淋', "drip, soak, drench; perfectly"],
    ['耻', "shame, humiliation; ashamed"],
    ['胶', "glue, gum, resin, rubber; sound; shin bone"],
    ['屠', "butcher, slaughter, massacre"],
    ['鹅', "goose"],
    ['饥', "hunger, starving; hungry; a famine"],
    ['盼', "look, gaze; expect, hope for"],
    ['脖', "neck"],
    ['虹', "rainbow"],
    ['翠', "color green; kingfisher"],
    ['崩', "rupture, split apart, collapse"],
    ['账', "accounts; bill, debt; credit"],
    ['萍', "duckweed; wandering, traveling"],
    ['逢', "come upon, happen meet; flatter"],
    ['赚', "make money, earn; gain, profit"],
    ['撑', "prop up, support; brace"],
    ['翔', "soar, glide, hover; detailed"],
    ['倡', "guide, leader; lead, introduce"],
    ['绵', "cotton wad; wool; soft, downy"],
    ['猴', "monkey, ape; monkey-like"],
    ['枯', "dried out, withered, decayed"],
    ['巫', "wizard, sorcerer, witch, shaman"],
    ['昭', "bright, luminous; illustrious"],
    ['怔', "a disease resembling neurosis"],
    ['渊', "surge up, bubble up, gush forth"],
    ['凑', "piece together, assemble"],
    ['溪', "mountain stream, creek"],
    ['蠢', "wriggle; stupid; silly; fat"],
    // ['禅', ""],
    ['阐', "explain, clarify, elucidate"],
    ['旺', "prosper; prosperous; increase"],
    ['寓', "residence; lodge; dwell"],
    ['藤', "rattan, cane; creeper plant"],
    ['匪', "bandits, robbers, gangsters"],
    ['伞', "umbrella, parasol, parachute"],
    ['碑', "stone tablet; gravestone"],
    ['挪', "move, shift on one side"],
    ['琼', "jade; rare, precious; elegant; (Cant.) to coagulate"],
    ['脂', "fat, grease, lard; grease"],
    ['谎', "lie"],
    ['慨', "sigh, regret; generous"],
    ['菩', "herb, aromatic plant"],
    ['萄', "grapes"],
    ['狮', "lion"],
    ['掘', "dig, excavate; excavate cave"],
    ['抄', "copy, confiscate, seize"],
    ['岭', "mountain ridge, mountain peak"],
    ['晕', "halo in sky; fog; dizzy, faint"],
    ['逮', "seize, catch; reach, arrive"],
    ['砍', "hack, chop, cut, fell"],
    ['掏', "take out; pull out; clean out"],
    ['狄', "tribe from northern china; surnam"],
    ['晰', "clear, evident; clearly"],
    ['罕', "rare, scarce; surname"],
    ['挽', "pull; pull back, draw back"],
    ['脾', "spleen, pancreas; disposition"],
    ['舟', "boat, ship; KangXi radical 137"],
    ['痴', "foolish, stupid, dumb, silly"],
    ['蔡', "surname; species of tortoise"],
    ['剪', "scissors; cut, divide, separate"],
    ['脊', "spine, backbone; ridge"],
    ['弓', "bow; curved, arched; KangXi radical number 57"],
    ['懒', "lazy, languid, listless"],
    ['叉', "crotch; fork, prong"],
    ['拐', "kidnap, abduct; turn"],
    ['喃', "keep talking, chattering; mumble"],
    ['僚', "companion, colleague; officials; bureaucracy; a pretty face"],
    ['捐', "contribute; give up, renounce"],
    ['姊', "elder sister"],
    ['骚', "harass, bother, annoy, disturb"],
    ['拓', "expand; open up; support or push"],
    ['歪', "slant; inclined; askewd, awry"],
    ['粘', "viscous, mucous; glutinous"],
    ['柄', "handle, lever, knob; authority"],
    ['坑', "pit, hole; bury, trap; harry"],
    ['陌', "foot path between rice fields"],
    ['窄', "narrow, tight; narrow-minded"],
    ['湘', "Hunan province"],
    ['兆', "omen; million; mega; also trillion. China = million; Japan and Taiwan = trillion"],
    ['崖', "cliff, precipice; precipitous"],
    ['骄', "spirited horse; haughty"],
    ['刹', "temple, shrine, monastary"],
    ['鞭', "whip; whip; string of firecrackers"],
    ['芒', "Miscanthus sinensis"],
    ['筋', "muscles; tendons"],
    ['聘', "engage, employ; betroth"],
    ['钩', "hook, barb; sickle; stroke with"],
    ['棍', "stick, cudgel; scoundrel"],
    ['嚷', "shout, brawl, make uproar, cry"],
    ['腺', "gland"],
    ['弦', "string; hypotenuse, crescent"],
    ['焰', "flame, blaze; glowing, blazing"],
    ['耍', "frolic, play, amuse, play with"],
    ['俯', "bow down, face down, look down"],
    ['厘', "thousandth part of tael"],
    ['愣', "be in a daze"],
    ['厦', "big building, mansion"],
    ['恳', "sincere, earnest, cordial"],
    ['饶', "bountiful, abundant, plentiful"],
    ['钉', "nail, spike; pursue closely"],
    ['寡', "widowed; alone, friendless"],
    ['憾', "to regret, remorse; dissatisfied"],
    ['摔', "fall ground, stumble, trip"],
    ['叠', "pile; be piled up; fold up"],
    ['惹', "irritate, vex, offend, incite"],
    ['喻', "metaphor, analogy; example; like"],
    ['谱', "list, table; musical score"],
    ['愧', "ashamed, conscience-stricken"],
    ['煌', "bright, shining, luminous"],
    ['徽', "a badge, insignia"],
    ['溶', "to melt, dissolve; overflowing with"],
    ['坠', "fall down, drop, sink, go to ruin"],
    ['煞', "malignant deity; baleful, noxious; strike dead"],
    ['巾', "kerchief; towel; turban; KangXi radical number 50"],
    ['滥', "flood, overflow; excessive"],
    ['洒', "sprinkle; scatter; pour; to wipe away; to shiver"],
    ['堵', "wall; stop, prevent; stop up"],
    ['瓷', "crockery, porcelain, chinaware"],
    ['咒', "curse, damn, incantation"],
    // ['姨', ""],
    ['棒', "stick, club, truncheon; hit"],
    ['郡', "administrative division"],
    ['浴', "bathe, wash; bath"],
    ['媚', "charming, attractive; flatter"],
    ['稣', "revive, to rise again; collect"],
    ['淮', "river in Anhui province"],
    ['哎', "interjection of surprise"],
    ['屁', "break wind; fart; buttocks"],
    ['漆', "varnish, lacquer, paint"],
    ['淫', "obscene, licentious, lewd"],
    ['巢', "nest, living quarter in tree"],
    ['吩', "order, command, instruct"],
    ['撰', "compose, write, compile"],
    ['啸', "roar, howl, scream; whistle"],
    ['滞', "block up, obstruct; stagnant"],
    ['玫', "rose"],
    ['硕', "great, eminent; large, big"],
    ['钓', "fish; fishhook; tempt, lure"],
    ['蝶', "butterfly"],
    ['膝', "knee"],
    ['姚', "handsome, elegant; surname"],
    ['茂', "thick, lush, dense; talented"],
    ['躯', "body"],
    ['吏', "government official, magistrate"],
    ['猿', "ape"],
    ['寨', "stockade, stronghold, outpost; brothel"],
    ['恕', "forgive, excuse, show mercy"],
    ['渠', "ditch, canal, channel, gutter"],
    ['戚', "relative; be related to; sad"],
    ['辰', "early morning; 5th terrestrial branch"],
    ['舶', "large, ocean-going vessel"],
    ['颁', "confer, bestow; publish, promulgate"],
    ['惶', "fearful, afraid, anxious, nervous"],
    ['狐', "species of fox"],
    ['讽', "recite, incant; satirize"],
    ['笨', "foolish, stupid, dull; awkward"],
    ['袍', "long gown, robe, cloak"],
    ['嘲', "ridicule, deride, scorn, jeer at"],
    ['啡', "morphine; coffee"],
    ['泼', "pour, splash, water, sprinkle"],
    ['衔', "bit; hold in mouth, bite; gag"],
    ['倦', "be tired of, weary"],
    ['涵', "soak, wet; tolerate, be lenient"],
    ['雀', "sparrow"],
    ['旬', "ten-day period; period of time"],
    ['僵', "stiff and motionless, stock still"],
    ['撕', "rip, tear; buy cloth"],
    ['肢', "human limbs; animal feet"],
    ['垄', "grave, mound; ridge in field"],
    ['夷', "ancient barbarian tribes"],
    ['逸', "flee, escape, break loose"],
    ['茅', "reeds, rushes, grass; surname"],
    ['侨', "sojourn, lodge"],
    ['舆', "cart, palanquin, sedan chair"],
    ['窑', "kiln; coal mine pit"],
    ['涅', "blacken; black mud, slime"],
    ['蒲', "type of rush; vine"],
    ['谦', "humble, modest"],
    ['杭', "cross stream; navigate"],
    ['噢', "moan; interjection for pain, sad"],
    ['弊', "evil, wrong, bad; criminal"],
    ['勋', "meritorious deed; merits; rank"],
    ['刮', "shave, pare off, scrape"],
    ['郊', "suburbs; waste land, open spaces"],
    ['凄', "bitter cold, miserable, dreary"],
    ['捧', "hold up in two hands"],
    ['浸', "soak, immerse, dip, percolate"],
    ['砖', "tile, brick"],
    ['鼎', "large, three-legged bronze caldron"],
    ['篮', "basket"],
    ['蒸', "steam; evaporate"],
    ['饼', "rice-cakes, biscuits"],
    ['亩', "Chinese land measure; fields"],
    ['肾', "kidneys; testes, gizzard"],
    ['陡', "steep, sloping; abruptly, sudden"],
    ['爪', "claw, nail, talon; animal feet"],
    ['兔', "rabbit, hare"],
    ['殷', "many, great; abundant, flourishing"],
    ['贞', "virtuous, chaste, pure; loyal"],
    ['荐', "repeat, reoccur; recommend"],
    ['哑', "dumb, mute; become hoarse"],
    ['炭', "charcoal; coal; carbon"],
    ['坟', "grave, mound; bulge; bulging"],
    ['眨', "wink"],
    ['搏', "seize; spring upon; strike"],
    ['咳', "cough"],
    ['拢', "collect, bring together"],
    // ['舅', ""],
    ['昧', "obscure, dark; darken"],
    ['擅', "monopolize; claim; arbitrarily; to dare"],
    ['爽', "happy, cheerful; refreshing"],
    ['咖', "coffee; a phonetic"],
    ['搁', "place, put, lay down; delay"],
    ['禄', "blessing, happiness, prosperity"],
    ['雌', "female; feminine; gentle, soft"],
    ['哨', "whistle, blow whistle; chirp"],
    ['巩', "bind; firm, secure, strong"],
    ['绢', "kind of thick stiff silk"],
    ['螺', "spiral shell; conch; spiral"],
    ['裹', "wrap, bind; encircle, confine"],
    ['昔', "formerly; ancient; in beginning"],
    ['轩', "carriage; high; wide; balcony"],
    ['谬', "error, exaggeration; erroneous"],
    ['谍', "an intelligence report; to spy; spying"],
    ['龟', "turtle or tortoise; cuckold"],
    ['媳', "daughter-in-law"],
    ['姜', "surname; ginger"],
    ['瞎', "blind, reckless; rash"],
    ['冤', "grievance, injustice, wrong"],
    ['鸦', "crow; Corvus species (various)"],
    ['蓬', "type of raspberry; fairyland"],
    ['巷', "alley, lane"],
    ['琳', "beautiful jade, gem"],
    ['栽', "to cultivate, plant; to care for plants"],
    ['沾', "moisten, wet, soak; touch"],
    ['诈', "cheat, defraud, swindle; trick"],
    ['斋', "vegetarian diet; study"],
    ['瞒', "deceive, lie; eyes half-closed"],
    ['彪', "tiger; tiger stripes; tiger-like"],
    ['厄', "adversity, difficulty, distress"],
    ['咨', "inquire, consult, discuss; plan"],
    ['纺', "spin, reel, weave; reeled pongee"],
    ['罐', "jar, jug, pitcher, pot"],
    ['桶', "pail, bucket, tub; cask, keg"],
    ['壤', "soil, loam, earth; rich"],
    ['糕', "cakes, pastry"],
    ['颂', "laud, acclaim; hymn; ode"],
    ['膨', "to swell; swollen, bloated, inflated"],
    ['谐', "harmonize, agree; joke, jest"],
    ['垒', "rampart, military wall"],
    ['咕', "mumble, mutter, murmur; rumble"],
    ['隙', "crack, split, fissure; grudge"],
    ['辣', "peppery, pungent, hot; cruel"],
    ['绑', "bind, tie, fasten"],
    ['宠', "favorite, concubine; favor"],
    ['嘿', "be silent, be quiet"],
    ['兑', "cash; exchange"],
    ['霉', "mildew, mold; moldy, mildewed"],
    ['挫', "push down; chop down; grind"],
    ['稽', "examine, investigate; delay"],
    ['辐', "spokes of wheel"],
    ['乞', "beg; request"],
    ['纱', "gauze, thin silk; yarn, thread"],
    ['裙', "skirt, apron, petticoat"],
    ['嘻', "mirthful, happy; interjection"],
    ['哇', "vomit; cry of an infant"],
    ['绣', "embroider; embroidery; ornament"],
    ['杖', "cane, walking stick"],
    ['塘', "pond; tank; dike, embankment"],
    ['衍', "overflow, spill over, spread out"],
    ['轴', "axle, axletree; pivot; axis"],
    ['攀', "climb; pull; hang on to"],
    ['膊', "shoulders, upper arms"],
    ['譬', "metaphor, simile, example"],
    ['斌', "refined, having both appearance"],
    ['祈', "pray; entreat, beseech"],
    ['踢', "kick"],
    ['肆', "indulge; excess; numeral four; particle meaning now, therefore; shop"],
    ['坎', "pit, hole; snare, trap; crisis"],
    ['轿', "sedan-chair, palanquin"],
    ['棚', "tent, awning; booth; shed"],
    ['泣', "cry, sob, weep"],
    ['屡', "frequently, often, again and again"],
    ['躁', "tense, excited, irritable"],
    ['邱', "surname; hill; mound; grave"],
    ['凰', "female phoenix"],
    ['溢', "overflow, brim over; full"],
    ['椎', "hammer, mallet; vertebra"],
    ['砸', "smash, crush, break; pound, mash"],
    ['趟', "time, occasion; take journey"],
    ['帘', "flag-sign of a tavern"],
    ['帆', "sail; boat"],
    ['栖', "perch; roost; stay"],
    ['窜', "run away; revise, edit; expel"],
    ['丸', "small round object; pellet, pill"],
    ['斩', "cut, chop, sever; behead"],
    ['堤', "dike"],
    ['塌', "fall in ruins, collapse"],
    ['贩', "peddler, hawker, street merchant"],
    ['厢', "side-room, wing; theatre box"],
    ['掀', "lift, raise; stir"],
    ['喀', "vomit; used in transliterations"],
    ['乖', "rebel; crafty, shrewd"],
    ['谜', "riddle, conundrum; puzzle"],
    ['捏', "pick with fingers; knead; mold"],
    ['阎', "village gate; surname"],
    ['滨', "beach, sea coast; river bank"],
    ['虏', "to capture, imprison, seize; a prison"],
    ['匙', "spoon; surname"],
    ['芦', "rushes, reeds"],
    ['苹', "artemisia; duckweed; apple"],
    ['卸', "lay down; retire from office"],
    ['沼', "lake, fishpond, swamps"],
    ['钥', "key; lock"],
    ['株', "numerary adjunct for trees; root"],
    ['祷', "pray; entreat, beg, plead; prayer"],
    ['剖', "split in two, slice; dissect"],
    ['熙', "bright, splendid, glorious"],
    ['哗', "rushing sound; gush forward"],
    ['劈', "cut apart, split, chop"],
    ['怯', "lacking in courage, afraid"],
    ['棠', "crab apple tree; wild plums"],
    ['胳', "armpit, arms"],
    ['桩', "stake, post; affair, matter"],
    ['瑰', "extraordinary, fabulous; rose"],
    ['娱', "pleasure, enjoyment, amusement"],
    ['娶', "marry, take wife"],
    ['沫', "froth, foam, bubbles, suds"],
    ['嗓', "voice; throat"],
    ['蹲', "squat, crouch; idle about"],
    ['焚', "burn"],
    ['淘', "wash in sieve; weed out"],
    ['嫩', "soft, tender, delicate; young"],
    ['韵', "rhyme; vowel"],
    ['衬', "underwear; inner garments"],
    // ['匈', ""],
    ['钧', "unit of measure equivalent to thirty catties"],
    ['竖', "perpendicular, vertical; erect"],
    ['峻', "high, steep, towering; stern"],
    ['豹', "leopard, panther; surname"],
    ['捞', "scoop out of water; dredge, fish"],
    ['菊', "chrysanthemum"],
    ['鄙', "mean; low"],
    ['魄', "vigor; body; dark part of moon"],
    ['兜', "pouch"],
    ['哄', "coax; beguile, cheat, deceive"],
    ['颖', "rice tassel; sharp point; clever"],
    ['镑', "pound sterling"],
    ['屑', "bits, scraps, crumbs, fragments"],
    ['蚁', "ants"],
    ['壶', "jar, pot, jug, vase; surname"],
    ['怡', "harmony; pleasure, joy; be glad"],
    ['渗', "soak through, infiltrate"],
    ['秃', "bald"],
    ['迦', "character for transliteration"],
    ['旱', "drought; dry; dry land"],
    ['哟', "ah, final particle"],
    ['咸', "together; all, completely; united"],
    ['焉', "thereupon, then; how? why? where?"],
    ['谴', "reprimand, scold, abuse"],
    ['宛', "seem, as if, crooked"],
    ['稻', "rice growing in field, rice plant"],
    ['铸', "melt, cast; coin, mint"],
    ['锻', "forge metal; temper, refine"],
    // ['伽', ""],
    ['詹', "surname; talk too much, verbose"],
    ['毙', "kill; die violent death"],
    ['恍', "seemingly; absent-minded"],
    ['贬', "decrease, lower; censure, criticize"],
    ['烛', "candle, taper; shine, illuminate"],
    ['骇', "terrify, frighten, scare; shock"],
    ['芯', "pith from rush (juncus effusus)"],
    ['汁', "juice, liquor, fluid, sap, gravy, sauce"],
    ['桓', "variety of tree; surname"],
    // ['坊', ""],
    ['驴', "donkey, ass"],
    ['朽', "decayed, rotten; rot, decay"],
    ['靖', "pacify; appease; calm, peaceful"],
    ['佣', "commission fee"],
    ['汝', "you"],
    ['碌', "rough, uneven, rocky; mediocre"],
    ['迄', "extend, reach; until; till"],
    ['冀', "hope for; wish; Hebei province"],
    ['荆', "thorns; brambles; my wife; cane"],
    ['崔', "high, lofty, towering; surname"],
    ['雁', "wild goose"],
    ['绅', "girdle; tie, bind; gentry"],
    ['珊', "coral"],
    ['榜', "placard; list of successful exam candidates"],
    ['诵', "recite, chant, repeat"],
    ['傍', "by side of, beside, near, close"],
    ['彦', "elegant"],
    ['醇', "rich, good as wine; pure, unmixed"],
    ['笛', "bamboo flute; whistle"],
    ['禽', "birds, fowl; surname;; capture"],
    ['勿', "must not, do not; without, never"],
    ['娟', "beautiful, graceful"],
    ['瞄', "take aim at; look at"],
    ['幢', "carriage curtain; sun screen"],
    ['寇', "bandits, thieves; enemy; invade"],
    ['睹', "look at, gaze at; observe"],
    ['贿', "bribe; bribes; riches, wealth"],
    ['踩', "step on"],
    ['霆', "a sudden peal of thunder"],
    ['呜', "sound of crying, sobbing"],
    ['拱', "fold hands on breast; bow, salute"],
    ['妃', "wife, spouse; imperial concubine"],
    ['蔑', "disdain, disregard; slight"],
    ['谕', "proclaim, instruct; edict"],
    ['缚', "to tie"],
    ['诡', "deceive, cheat, defraud; sly"],
    ['篷', "awning, covering; sail; boat"],
    ['淹', "drown; cover with liquid, steep"],
    ['腕', "wrist"],
    ['煮', "cook"],
    ['倩', "beautiful, lovely; son-in-law"],
    ['卒', "soldier; servant; at last, finally"],
    ['勘', "investigate; compare; collate"],
    ['馨', "fragrant, aromatic; distant fragrance"],
    ['逗', "tempt, allure, arouse, stir"],
    ['甸', "suburbs of capital; govern; crops"],
    ['贱', "mean, low; cheap, worthless"],
    ['炒', "fry, saute, roast, boil, cook"],
    ['灿', "vivid, illuminating; bright"],
    ['敞', "roomy, spacious, open, broad"],
    ['蜡', "wax; candle; waxy, glazed; maggot; as a non-simplified form sometimes used as an equivalent to 䄍, meaning imperial harvest"],
    ['囚', "prisoner, convict; confine"],
    ['栗', "chestnut tree, chestnuts; surname"],
    ['辜', "crime, criminal offense"],
    ['垫', "advance money, pay for another"],
    ['妒', "jealous, envious"],
    ['魁', "chief; leader; best; monstrous"],
    ['谣', "sing; folksong, ballad; rumor"],
    ['寞', "silent, still, lonely, solitary"],
    ['蜀', "name of an ancient state"],
    ['甩', "throw away, discard"],
    // ['涯', ""],
    ['枕', "pillow"],
    ['丐', "beggar; beg; give"],
    ['泳', "dive; swim"],
    ['奎', "stride of man; one of the twenty-eight lunar mansions"],
    ['泌', "to seep out, excrete"],
    ['逾', "go over, pass over, exceed"],
    ['叮', "exhort or enjoin repeatedly"],
    ['黛', "blacken eyebrows; black"],
    ['燥', "dry, parched, arid; quick-tempered"],
    ['掷', "throw, hurl, cast, fling"],
    ['藉', "mat, pad; rely on; pretext"],
    ['枢', "door hinge; pivot; center of power"],
    ['憎', "hate, detest, abhor; hatred"],
    ['鲸', "whale"],
    ['弘', "enlarge, expand; liberal, great"],
    ['倚', "rely on, depend on; lean heavily"],
    ['侮', "insult, ridicule, disgrace"],
    ['藩', "fence; boundary; outlying border"],
    ['拂', "shake off, brush away; dust"],
    ['鹤', "crane; Grus species (various)"],
    ['蚀', "nibble away; erode; eclipse"],
    ['浆', "any thick fluid; starch; broth"],
    ['芙', "hibiscus"],
    ['垃', "garbage, refuse, waste"],
    ['烤', "bake, roast, toast, cook"],
    ['晒', "dry in sun, expose to sun"],
    ['霜', "frost; crystallized; candied"],
    ['剿', "destroy, exterminate, annihilate"],
    ['蕴', "to collect, gather, store; profound"],
    ['圾', "garbage, rubbish; shaking; danger"],
    ['绸', "silk cloth, satin damask"],
    ['屿', "island"],
    ['氢', "ammonia; hydrogen nitride"],
    ['驼', "a camel; humpbacked; to carry on the back"],
    ['妆', "to adorn oneself, dress up, use make-up"],
    ['捆', "tie up; bind, truss up; bundle"],
    ['铅', "lead"],
    ['逛', "ramble, stroll, roam, wander"],
    ['淑', "good, pure, virtuous, charming"],
    ['榴', "pomegranate"],
    ['丙', "third; 3rd heavenly stem"],
    ['痒', "itch"],
    ['钞', "paper money, bank notes; copy"],
    ['蹄', "hoof; leg of pork; little witch"],
    ['犬', "dog; radical number 94"],
    ['躬', "body; personally, in person"],
    ['昼', "daytime, daylight"],
    ['藻', "splendid, magnificent; algae"],
    ['蛛', "spider"],
    ['褐', "coarse woolen cloth; dull, dark"],
    ['颊', "cheeks, jaw"],
    ['奠', "pay respect; settle"],
    ['募', "levy, raise; summon; recruit"],
    ['耽', "indulge in; be negligent"],
    ['蹈', "stamp feet; dance"],
    ['陋', "narrow; crude, coarse; ugly"],
    ['侣', "companion; associate with"],
    ['魅', "kind of forest demon, elf"],
    ['岚', "mountain mist, mountain haze"],
    ['侄', "nephew"],
    ['虐', "cruel, harsh, oppressive"],
    ['堕', "fall, sink, let fall; degenerate"],
    ['陛', "steps leading throne; throne"],
    ['莹', "luster of gems; bright, lustrous"],
    ['荫', "shade, shelter; protect"],
    ['狡', "cunning, deceitful, treacherous"],
    ['阀', "powerful and influential group"],
    ['绞', "twist, wring; intertwine; winch"],
    ['膏', "grease, fat; paste, ointment"],
    ['垮', "be defeated, fail, collapse"],
    ['茎', "stem, stalk"],
    ['缅', "distant, remote; think of"],
    ['喇', "horn, bugle; lama; final particle"],
    ['绒', "silk, cotton, or woolen fabric"],
    ['搅', "disturb, agitate, stir up"],
    ['凳', "bench; stool"],
    // ['梭', ""],
    ['丫', "forked; bifurcation"],
    ['姬', "beauty; imperial concubine"],
    ['诏', "decree, proclaim; imperial decree"],
    ['钮', "button, knob; surname"],
    ['棺', "coffin"],
    ['耿', "bright, shining; have guts"],
    ['缔', "tie, join, connect; connection"],
    ['懈', "idle, relaxed, remiss"],
    ['嫉', "jealousy; be jealous of"],
    ['灶', "kitchen stove, cooking stove"],
    ['匀', "equal"],
    ['嗣', "to connect, inherit; descendants, heirs"],
    ['鸽', "pigeon, dove; Columba species (various)"],
    ['澡', "wash, bathe"],
    ['凿', "chisel; bore, pierce"],
    ['纬', "woof; parallels of latitude"],
    ['沸', "boil, bubble up, gush"],
    ['畴', "farmland, arable land; category"],
    ['刃', "edged tool, cutlery, knife edge"],
    ['遏', "stop, suppress, curb, check; a bar"],
    ['烁', "shine, glitter, sparkle"],
    ['嗅', "smell, scent, sniff; olfactive"],
    ['叭', "trumpet"],
    ['熬', "cook down, to boil; endure"],
    ['瞥', "take fleeting glance at"],
    ['骸', "skeleton, body; leg bone"],
    ['奢', "extravagant, wasteful; exaggerate"],
    ['拙', "stupid, clumsy, crude; convention"],
    ['栋', "main beams supporting house"],
    ['毯', "rug, carpet, blanket"],
    ['桐', "name applied various trees"],
    ['砂', "sand; pebbles, gravel; gritty"],
    ['莽', "thicket, underbrush; poisonous"],
    ['泻', "drain off, leak; flow, pour down"],
    ['坪', "level ground; Japanese measure"],
    ['梳', "comb; brush"],
    ['杉', "various species of pine and fir"],
    ['晤', "have interview with; meet"],
    ['稚', "young, immature; childhood"],
    ['蔬', "vegetables, greens"],
    ['蝇', "flies"],
    ['捣', "hull; thresh; beat, attack"],
    ['顷', "a moment; a measure of area equal to 100 mu or 6.7 hectares; to lean"],
    ['麽', "interrogative final particle; insignificant, small, tiny"],
    ['尴', "embarrassed; ill at ease"],
    ['镖', "dart, spear, harpoon; escort"],
    ['诧', "be surprised, be shocked"],
    ['尬', "limp, staggering gait; embarrass"],
    ['硫', "sulfur"],
    ['嚼', "prattle, be glib"],
    ['羡', "envy, admire; praise; covet"],
    ['沦', "be lost; sink, be submerged"],
    ['沪', "Shanghai; river near Shanghai"],
    ['旷', "extensive, wide, broad; empty"],
    ['彬', "cultivated, well-bred"],
    ['芽', "bud, sprout, shoot"],
    ['狸', "fox"],
    ['冥', "dark, gloomy, night; deep"],
    ['碳', "carbon"],
    ['咧', "stretch mouth, grimace, grin"],
    ['惕', "be cautious, careful, alert"],
    ['暑', "hot"],
    ['咯', "final particle"],
    ['萝', "type of creeping plant; turnip"],
    ['汹', "turbulent; noisy, restless"],
    ['腥', "raw meat; rank, strong-smelling"],
    ['窥', "peep, watch, spy on, pry"],
    ['俺', "personal pronoun, I"],
    ['潭', "deep pool, lake; deep, profound"],
    ['崎', "rough, uneven, jagged, rugged"],
    ['麟', "female of Chinese unicorn"],
    ['捡', "to pick up"],
    ['拯', "help, save, aid; lift, raise"],
    ['厥', "personal pronoun he, she, it"],
    ['澄', "purify water by allowing sediment to settle; clear, pure"],
    ['萎', "wither, wilt"],
    ['哉', "final exclamatory particle"],
    ['涡', "swirl, whirlpool, eddy"],
    ['滔', "overflow; rushing water, a torrent"],
    ['暇', "leisure, relaxation, spare time"],
    ['溯', "go upstream, go against current; formerly"],
    ['鳞', "fish scales"],
    ['酿', "brew, ferment"],
    ['茵', "a cushion, mattress; wormwood; Skimmia japon"],
    ['愕', "startled, alarmed, astonished"],
    ['瞅', "see, look, gaze at"],
    ['暮', "evening, dusk, sunset; ending"],
    ['衙', "public office; official residence"],
    ['诫', "warn, admonish; warning"],
    ['斧', "axe, hatchet; chop, hew"],
    ['兮', "exclamatory particle"],
    ['焕', "shining"],
    ['棕', "hemp palm; palm tree"],
    ['佑', "help, protect, bless"],
    ['嘶', "neighing of a horse; gravel voiced, husky throated; (Cant.) to hiccough"],
    ['妓', "prostitute"],
    ['喧', "lively, noisy; clamor, talk loudly"],
    ['蓉', "hibiscus; Chengdu, Sichuan"],
    ['删', "to cut; delete"],
    ['樱', "cherry, cherry blossom"],
    ['伺', "serve, wait upon, attend; examine"],
    ['嗡', "sound of flying bees, airplanes"],
    ['娥', "be beautiful; good; surname"],
    ['梢', "pointed tip of something long like a branch; rudder"],
    ['坝', "embankment; dam"],
    ['蚕', "silkworms"],
    ['敷', "spread, diffuse; apply, paint"],
    ['澜', "overflowing; waves, ripples"],
    ['杏', "apricot; almond"],
    ['绥', "soothe, appease, pacify"],
    ['冶', "smelt, fuse metals; cast, found"],
    ['庇', "cover, shield, shelter, protect"],
    ['挠', "scratch; disturb, bother; submit"],
    ['搂', "hug, embrace; drag, pull"],
    ['倏', "hastily, suddenly, abruptly"],
    ['聂', "whisper; surname"],
    ['婉', "amiable, congenial; restrained"],
    ['噪', "be noisy; chirp loudly"],
    ['稼', "sow grain; sheaves of grain"],
    ['鳍', "fin"],
    ['菱', "water-chestnut, water caltrop"],
    ['盏', "small cup or container"],
    ['匿', "hide; go into hiding"],
    ['吱', "chirping, squeaking, hissing"],
    ['寝', "sleep, rest; bed chamber"],
    ['揽', "grasp, take hold of; monopolize"],
    ['髓', "bone marrow; essences, substances"],
    ['秉', "grasp, hold; bundle; authority"],
    ['哺', "chew food; feed"],
    ['矢', "arrow, dart; vow, swear"],
    ['啪', "syllable"],
    ['帜', "flag, pennant; sign; fasten"],
    ['邵', "surname; various place names"],
    ['嗽', "cough, gargle, clear throat"],
    ['挟', "clasp under arm; hold to bosom"],
    ['缸', "earthen jug, crock, cistern"],
    ['揉', "rub, massage; crush by hand"],
    ['腻', "greasy, oily, dirty; smooth"],
    ['驯', "tame, docile, obedient"],
    ['缆', "hawser, heavy-duty rope, cable"],
    ['晌', "noon, midday; moment; unit land measurement equal to seven mou (畝), or the area that can be sown in one day"],
    ['瘫', "paralysis, palsy, numbness"],
    ['贮', "store, stockpile, hoard"],
    ['觅', "seek; search"],
    ['朦', "condition or appearance of moon"],
    ['僻', "out-of-the-way, remote; unorthodox"],
    ['隋', "Sui dynasty; surname"],
    ['蔓', "creeping plants, tendrils, vines"],
    ['咋', "question-forming particle, why? how? what?; to bite; loud"],
    ['嵌', "inlay, set in; fall into; rugged"],
    ['虔', "act with reverence; reverent"],
    ['畔', "boundary path dividing fields"],
    ['琐', "trifling, petty; troublesome"],
    ['碟', "small dish, plate"],
    ['涩', "astringent; harsh; uneven, rough"],
    ['胧', "condition or appearance of moon"],
    ['嘟', "sound of horn tooting"],
    ['蹦', "hop, leap, jump; bright"],
    ['冢', "burial mound, mausoleum; grand"],
    ['浏', "clear; bright; whistling"],
    ['裔', "progeny, descendants, posterity"],
    ['襟', "lapel, collar"],
    ['叨', "talkative; quarrelous"],
    ['诀', "take leave of, bid farewell"],
    ['旭', "rising sun; brilliance; radiant"],
    ['虾', "shrimp, prawn"],
    ['簿', "register, account book, notebook"],
    ['啤', "beer"],
    ['擒', "catch, capture, seize, arrest"],
    ['枣', "date tree; dates, jujubes; surname"],
    ['嘎', "sound of laughter; bad, malevolent"],
    ['苑', "pasture, park, garden; mansion"],
    ['牟', "make; seek, get; barley; low"],
    ['呕', "vomit; annoy, enrage"],
    ['骆', "a white horse with black mane; a camel"],
    ['凸', "protrude, bulge out, convex"],
    ['熄', "put out, extinguish, quash"],
    ['兀', "to cut off the feet"],
    ['喔', "descriptive of crying or of crowing"],
    ['裳', "clothes; skirt; beautiful"],
    ['凹', "concave, hollow, depressed; a pass, valley"],
    ['赎', "buy, redeem; ransome; atone for"],
    ['屯', "village, hamlet; camp; station"],
    ['膛', "chest; hollow space, cavity"],
    ['浇', "spray, water, sprinkle"],
    ['灼', "burn; broil; cauterize; bright"],
    ['裘', "fur garments; surname"],
    ['砰', "sound of crashing stones, bang!"],
    ['棘', "jujube tree; thorns, brambles"],
    ['橡', "chestnut oak; rubber tree; rubber"],
    ['碱', "alkaline, alkali, lye, salt"],
    ['聋', "deaf"],
    ['姥', "maternal grandmother; midwife"],
    ['瑜', "flawless gem or jewel"],
    ['毋', "do not; not; surname; rad. 80"],
    ['娅', "mutual term of address used by sons-in-law; a brother-in-law"],
    ['沮', "stop, prevent; defeated, dejected"],
    ['萌', "bud, sprout"],
    ['俏', "like, similar; resemble; pretty"],
    ['黯', "dark, black; sullen, dreary"],
    ['撇', "discard, abandon, throw away"],
    ['粟', "unhusked millet; grain"],
    ['粪', "manure, dung, shit, excrement, night soil"],
    ['尹', "govern; oversee; director"],
    ['苟', "careless, frivolous; illicit; grammatical particle: if, but, if only; surname; grass name; distinguish DKW 30853 (ji4)"],
    ['癫', "crazy, mad; madness, mania, insan"],
    ['蚂', "ant; leech"],
    ['禹', "legendary hsia dynasty founder"],
    ['廖', "surname; name of an ancient state"],
    ['俭', "temperate, frugal, economical"],
    ['帖', "invitation card; notice"],
    ['煎', "fry in fat or oil; boil in water"],
    ['缕', "thread; detailed, precise"],
    ['窦', "surname; hole, burrow; corrupt"],
    ['簇', "swarm, crowd together, cluster"],
    ['棱', "squared timber; angle, edge"],
    ['叩', "knock, ask; kowtow, bow"],
    ['呐', "raise voice, yell out loud, shout; stammer"],
    ['瑶', "precious jade"],
    ['墅', "villa, country house"],
    ['莺', "oriole, green finch; Sylvia species (various)"],
    ['烫', "scald, heat; wash; iron clothes"],
    ['蛙', "frog"],
    ['歹', "bad, vicious, depraved, wicked"],
    ['伶', "lonely, solitary; actor"],
    ['葱', "scallions, leeks, green onions"],
    ['哮', "cough; pant; roar"],
    ['眩', "to confuse; dizzy, dazed, disoriented"],
    ['坤', "earth; feminine, female"],
    ['廓', "broad, wide, open, empty; to expand"],
    ['讳', "conceal; shun; regard as taboo"],
    ['啼', "weep, whimper; howl, twitter"],
    ['乍', "first time, for the first time"],
    ['瓣', "petal; segment; valves"],
    ['矫', "correct, rectify, straighten out"],
    ['跋', "go by foot; epilogue, colophon"],
    ['枉', "useless, in vain; bent, crooked"],
    ['梗', "stem of flower, branch of plant"],
    ['厕', "mingle with; toilet, lavatory"],
    ['琢', "polish jade; cut jade"],
    ['讥', "ridicule, jeer, mock; inspect"],
    ['釉', "glaze"],
    ['窟', "hole, cave; cellar; underground"],
    ['敛', "draw back, fold back; collect"],
    ['轼', "horizontal wooden bar in front"],
    ['庐', "hut, cottage; name of a mountain"],
    ['胚', "embryo; unfinished things"],
    ['呻', "groan, moan; recite with intonation"],
    ['绰', "graceful, delicate; spacious"],
    ['扼', "grasp, clutch; choke, strangle"],
    ['懿', "virtuous, admirable, esteemed"],
    ['炯', "bright, brilliant, clear; hot"],
    ['竿', "bamboo pole; penis"],
    ['慷', "ardent; generous, magnanimous"],
    ['虞', "concerned about, anxious, worried"],
    ['锤', "balance weight on scale; hammer"],
    ['栓', "wooden peg, post or stick"],
    ['桨', "oar, paddle"],
    ['蚊', "mosquito; gnat"],
    ['磅', "pound; weigh"],
    ['孽', "evil; son of concubine; ghost"],
    ['惭', "ashamed, humiliated; shameful"],
    ['戳', "prick, stab; stamp, seal, chop"],
    ['禀', "report to, petition"],
    ['鄂', "Hubei province; startled"],
    ['馈', "offer food superior; send gift"],
    ['垣', "low wall"],
    ['溅', "sprinkle, spray; spill, splash"],
    ['咚', "onomatopoetic, a thumping sound"],
    ['钙', "calcium"],
    ['礁', "reef; jetty; submerged rocks"],
    ['彰', "clear, manifest, obvious"],
    ['豁', "open up, clear; exempt"],
    ['眯', "be blinded"],
    ['磷', "phosphorus; water rushing between"],
    ['雯', "cloud patterns, coloring of cloud"],
    ['墟', "high mound; hilly countryside; wasteland"],
    ['迂', "doctrinaire, abstruse, unrealistic"],
    ['瞻', "look; look out for; respect"],
    ['颅', "skull"],
    ['琉', "sparkling stone; glazed, opaque"],
    ['悼', "grieve, mourn, lament; grieved"],
    ['蝴', "butterfly"],
    ['拣', "choose; select; pick up; gather"],
    ['渺', "endlessly long, boundless, vast"],
    ['眷', "take interest in, care for"],
    ['悯', "pity, sympathize with, grieve for"],
    ['汰', "excessive; scour, wash out"],
    ['慑', "afraid, scared, fearful"],
    // ['婶', ""],
    ['斐', "graceful, elegant, beautiful"],
    ['嘘', "exhale; blow out; deep sigh; hiss; praise"],
    ['镶', "insert, inlay, set, mount; fill"],
    ['炕', "the brick-bed in northern China"],
    ['宦', "officialdom, government official"],
    ['趴', "lying prone, leaning over"],
    ['绷', "bind, draw firmly, strap"],
    ['窘', "embarrassed; hard-pressed"],
    ['襄', "aid, help, assist; undress"],
    ['珀', "amber"],
    ['嚣', "be noisy; treat with contempt"],
    ['拚', "risk, disregard; go all out for"],
    ['酌', "serve wine; feast; deliberate"],
    ['浊', "muddy, turbid, dirty, filthy"],
    ['毓', "give birth to; bring up, educate"],
    ['撼', "move, shake; (Cant.) to fight"],
    ['嗜', "be fond of, have weakness for"],
    ['扛', "carry on shoulders; lift"],
    ['峭', "steep, precipitous, rugged"],
    ['磕', "hit; collide, knock into; sound"],
    ['翘', "turn up, lift, elevate, raise"],
    ['槽', "trough; manger; vat, tank; groove; a distillery"],
    ['淌', "trickle; flow down; drip"],
    ['栅', "fence; palisade; grid"],
    ['颓', "ruined, decayed; disintegrate"],
    ['熏', "smoke, fog, vapor; smoke, cure"],
    ['瑛', "luster of gem; crystal"],
    ['颐', "cheeks; jaw; chin; rear; to nourish"],
    ['忖', "guess, suppose, conjecture"],
    ['牡', "male of animals; bolt of door"],
    ['缀', "patch together, link, connect"],
    ['徊', "linger, walk to and fro, hesitain"],
    ['梨', "pear; opera; cut, slash"],
    ['肪', "animal fat"],
    ['涕', "tear; snivel, nasal mucus"],
    ['惫', "tired, weary, fatigued"],
    ['摹', "trace, copy, duplicate; pattern"],
    ['踱', "stroll, pace, walk slowly"],
    ['肘', "the elbow; help a person shoulder a load"],
    ['熔', "melt, smelt, fuse; mold"],
    ['挚', "sincere, warm, cordial; surname"],
    ['氯', "chlorine"],
    ['凛', "to shiver with cold or fear"],
    ['绎', "to unravel or unreel silk; to interpret"],
    ['庶', "numerous, various; multitude"],
    ['脯', "dried meat; preserved fruits"],
    ['迭', "repeatedly, frequently"],
    ['睦', "friendly, amiable, peaceful"],
    ['窍', "hole, opening, aperture"],
    ['粥', "rice gruel, congee"],
    ['庵', "Buddhist monastery or nunnery"],
    ['沧', "blue, dark green; cold"],
    ['怠', "idle, remiss, negligent; neglect"],
    ['沁', "soak into, seep in, percolate"],
    ['奕', "in sequence, orderly; abundant"],
    ['咙', "throat"],
    ['氨', "ammonia; hydrogen nitride"],
    ['矗', "straight, upright, erect, lofty"],
    ['盔', "helmet; bowl; basin"],
    ['拇', "thumb; big toe"],
    ['沛', "abundant, full, copious; sudden"],
    ['榻', "cot, couch, bed"],
    ['揣', "put things under clothes"],
    ['崭', "high, steep, precipitous; new"],
    ['鞘', "scabbard, sheath"],
    ['鞠', "bow, bend; rear, raise, nourish"],
    ['垦', "cultivate, reclaim, to farm land"],
    ['洽', "to blend with, be in harmony; to penetrate; to cover; a river in Shenxi"],
    ['唾', "spit, spit on; saliva"],
    ['橱', "cabinet, wardrobe, cupboard"],
    ['仕', "official; serve government"],
    ['蜘', "spider"],
    ['痰', "phlegm, mucus, spittle"],
    ['袜', "socks, stockings"],
    ['峙', "stand erect, stand up; pile up"],
    ['柬', "letter, invitation; choose"],
    ['蝉', "cicada; continuous"],
    ['蟹', "crab, brachyura"],
    ['谏', "remonstrate, admonish"],
    ['鹃', "cuckoo"],
    ['擎', "lift up, hold up, support"],
    ['皓', "bright, luminous; clear; hoary"],
    // ['朕', ""],
    ['疤', "scar, cicatrix; birthmark"],
    ['禺', "district; mountain in Zhejiang"],
    ['铲', "spade, shovel, trowel, scoop"],
    ['酶', "enzymes; (Cant.) soft"],
    ['钝', "blunt, obtuse; dull; flat; dull-witted"],
    ['氓', "people; subjects; vassals"],
    ['匣', "small box, case, coffer"],
    ['弧', "wooden bow; arc, crescent"],
    ['峨', "lofty"],
    ['锥', "gimlet, awl, drill, auger; bore"],
    ['揪', "grasp with hand, pinch"],
    ['杠', "lever, pole, crowbar; sharpen"],
    ['吭', "throat"],
    ['崛', "towering, eminent; rise abruptly"],
    ['诬', "make false accusation; defame"],
    ['冉', "tender; weak; proceed gradually"],
    ['抒', "express; eliminate; relieve"],
    ['庚', "7th heavenly stem"],
    ['悍', "courageous, brave; violent"],
    ['靡', "divide, disperse, scatter"],
    ['晦', "dark, unclear, obscure; night"],
    ['醋', "vinegar; jealousy, envy"],
    ['壕', "trench, ditch, channel, moat"],
    ['锯', "a saw; to saw; amputate"],
    ['夭', "young, fresh-looking; die young"],
    ['咦', "exclamation of surprise"],
    ['侈', "luxurious, extravagant"],
    ['婢', "servant girl; your servant"],
    ['猾', "crafty, cunning, shrewd; deceitful"],
    ['徘', "walk back and forth, hesitate"],
    ['硝', "saltpeter, niter; to tan"],
    ['煽', "stir up, incite, agitate, provoke"],
    ['皂', "soap; black; menial servant"],
    ['舵', "rudder, helm"],
    ['嗦', "suck"],
    ['狈', "a legendary animal with short forelegs which rode a wolf"],
    ['靴', "boots"],
    ['捂', "resist"],
    ['疮', "tumor, boil, sore, wound"],
    ['郝', "surname; place in modern Shanxi"],
    ['苛', "small, petty; harsh, rigorous"],
    ['秽', "dirty, unclean; immoral, obscene"],
    ['茜', "madder, rubia cordifolia; reeds"],
    ['搓', "to rub or roll between the hands"],
    ['芸', "rue, herb used to keep insects away; to cut grass or weeds; art, talent, ability, craft, technique, performance, acting, trick, stunt"],
    ['酱', "any jam-like or paste-like food"],
    ['赁', "rent, hire; hired person"],
    ['檐', "eaves of house; brim, rim"],
    ['饷', "rations and pay for soldiers"],
    ['蕉', "banana; plantain"],
    ['铀', "uranium"],
    ['苔', "moss, lichen"],
    ['赦', "forgive, remit, pardon"],
    ['缎', "satin"],
    ['舷', "the sides of a boat, bulwarks; a gunwale"],
    ['筷', "chopsticks"],
    ['朔', "first day of lunar month; the north"],
    ['婪', "covet; covetous, avaricious"],
    ['紊', "confused, disorder"],
    ['厮', "servant; to make a disturbance"],
    ['婿', "son-in-law; husband"],
    ['寥', "few, scarce; empty, deserted"],
    ['兢', "fearful, cautious, wary"],
    ['糙', "coarse, harsh, rough, unpolished rice"],
    ['卦', "fortune telling; tell fortunes"],
    ['槐', "locust tree"],
    ['扒', "scratch; dig up; crawl; crouch"],
    ['裴', "surname; look of a flowing gown"],
    ['祀', "to sacrifice, worship"],
    ['埔', "plain, arena; port, market"],
    ['絮', "waste cotton, raw silk or cotton"],
    ['芭', "plantain or banana palm; fragrant"],
    ['屉', "drawer; tray; pad; screen"],
    ['痪', "paralysis, numbness of limbs"],
    ['霄', "sky; clouds, mist; night"],
    ['绽', "ripped seam, rend, crack"],
    ['宵', "night, evening, dark"],
    ['邑', "area, district, city, state"],
    ['霖', "long spell of rain, copious rain"],
    ['岔', "diverge, branch off; fork in road"],
    ['饵', "bait; bait, entice; dumplings"],
    ['茄', "eggplant"],
    ['韧', "strong and pliable, resilient"],
    ['琪', "type of jade"],
    ['邹', "name of an ancient state; surname"],
    ['瑚', "coral; person of virtue"],
    ['憋', "to suppress inner feelings; hasty"],
    ['殆', "dangerous, perilous; endanger"],
    ['噜', "verbose, talkative; mumbling"],
    ['忒', "excessive; too; very-usually of objectionable things; to err; to mistake; changeable"],
    ['忿', "get angry; fury, exasperation"],
    ['衅', "consecrate sacrificial vessels by smearing blood; rift"],
    ['淳', "honest, simple, unsophisticated; cyanogen; ethane dinitrile"],
    ['悖', "be contradictory to, go counter"],
    ['髦', "flowing hair of young child"],
    ['孜', "be as diligent as possible"],
    ['粤', "Guangdong and Guangxi provinces; initial particle"],
    ['隘', "narrow, confined; a strategic pass"],
    ['濒', "approach, be on verge of; near"],
    ['铮', "clanging sound; small gong"],
    ['畸', "odd, fractional, remainder, odds"],
    ['剔', "pick out; scrape off; scrape meat"],
    ['坞', "entrenchment, bank, low wall"],
    ['篱', "bamboo or wooden fence; hedge"],
    ['淀', "shallow water, swamp; swampy"],
    ['蓦', "suddenly, quickly, abruptly"],
    ['唬', "to intimidate; to scare"],
    ['锣', "gong"],
    ['汀', "sandbar, beach, bank, shore"],
    ['趾', "toe; tracks, footprints"],
    ['缉', "to sew in close stitches"],
    ['嫦', "name of a moon goddess"],
    ['斟', "to pour wine or tea into a cup; to gauge"],
    ['鞍', "saddle; any saddle-shaped object"],
    ['扳', "pull; drag; right itself"],
    ['拴', "bind with rope, fasten"],
    ['诅', "curse; swear, pledge"],
    ['谟', "scheme, plan; plan; be without"],
    ['呃', "belch; hiccup"],
    ['懦', "weak, timid, cowardly"],
    ['逞', "indulge oneself; brag, show off"],
    ['犁', "plow"],
    ['忏', "regret, repent; confess sins"],
    ['拧', "pinch; twist, wring; determined"],
    ['亥', "12th terrestrial branch"],
    ['佟', "a name"],
    ['叱', "scold, shout at, bawl out"],
    ['舜', "legendary ruler"],
    ['绊', "loop, catch; fetter, shackle"],
    ['龚', "give, present; reverential"],
    ['腮', "lower part of face; jaw; gills of a fish"],
    ['邸', "official residence, residence of"],
    ['椒', "pepper, spices"],
    ['蔚', "luxuriant, thick; ornamental"],
    ['湛', "deep; profound; clear; tranquil, placid"],
    ['狩', "winter hunting; imperial tour"],
    ['眶', "eye socket; rim of eye"],
    ['栈', "warehouse; tavern, inn"],
    ['薇', "Osmunda regalis, a species of fern"],
    ['肮', "dirty"],
    ['瀑', "waterfall, cascade; heavy rain"],
    ['渣', "refuse, dregs, lees, sediment"],
    ['褂', "jacket, overcoat, coat; gown"],
    ['叽', "sigh in disapproval; take small"],
    ['臀', "buttocks"],
    ['妞', "girl"],
    ['巍', "high, lofty, majestic, eminent"],
    ['唔', "hold in mouth; bite; (Cant.) not, negation"],
    ['疚', "chronic disease, chronic illness; sorrow"],
    ['鲤', "carp"],
    ['戎', "arms, armaments; military affair"],
    ['肇', "begin, commence, originate"],
    ['笃', "deep, true, sincere, genuine"],
    ['辙', "wagon ruts, wheel tracks"],
    ['娴', "elegant, refined; skillful"],
    ['阮', "ancient musical instrument: surname"],
    ['札', "letter, note; correspondence"],
    ['懊', "vexed, worried, nervous; regret"],
    ['焘', "shine, illuminate; cover, envelope"],
    ['恤', "show pity; relieve, help"],
    ['疹', "measles; rash; fever"],
    ['潇', "sound of beating wind and rain"],
    ['铝', "aluminum"],
    ['涤', "wash, cleanse, purify; sweep"],
    ['恃', "rely on, presume on, trust to"],
    ['喽', "used in onomatopoetic expressions"],
    ['砌', "to build, pile up; stone steps, brick walk"],
    ['遁', "hide, conceal oneself; escape"],
    ['楞', "used for Ceylon in Buddhist texts"],
    ['阱', "pitfall, trap, snare"],
    ['咎', "fault, defect; error, mistake"],
    ['洼', "hollow; pit; depression; swamp"],
    ['炳', "bright, luminous; glorious"],
    ['噬', "bite; gnaw; snap at"],
    ['枫', "maple tree"],
    ['拷', "torture and interrogate; hit"],
    ['哆', "tremble, shiver, shudder, quiver"],
    ['矶', "jetty; submerged rock; eddy"],
    ['苇', "reed"],
    ['翩', "fly, flutter"],
    ['窒', "stop up, obstruct"],
    ['侬', "I; you; family name"],
    ['靶', "target; splashboard on chariot"],
    ['胰', "pancreas; soap"],
    ['芜', "luxurious growth of weeds"],
    ['辫', "braid; pigtail, plait, queue"],
    ['嚎', "cry loudly, yell, scream"],
    ['妾', "concubine"],
    ['幌', "curtain, cloth screen"],
    ['踉', "hop, jump; hurriedly, urgently"],
    ['佃', "tenant farmer; be a tenant farmer"],
    ['葫', "bottle-gourd"],
    ['皖', "Anhui province"],
    ['拽', "drag, tow; throw; twist"],
    ['滤', "strain out, filter"],
    ['睬', "notice; pay attention to"],
    ['俞', "surname; consent, approve"],
    ['匕', "spoon, ladle; knife, dirk"],
    ['谤', "slander, libel"],
    ['嗤', "laugh at, ridicule, sneer; snort"],
    ['捍', "ward off, guard against, defend"],
    ['孵', "sit on eggs, hatch"],
    ['倪', "feeble, tiny, young and weak"],
    ['瘾', "rash; addiction, craving, habit"],
    ['敝', "break, destroy; broken, tattered"],
    ['匡', "correct, restore, revise"],
    ['磋', "polish, buff; scrutinize"],
    ['绫', "thin silk, damask silk"],
    ['淆', "confused, in disarray, mixed up"],
    ['尧', "a legendary ancient emperor-sage"],
    ['蕊', "unopened flowers, flower buds"],
    ['烘', "bake, roast; dry by fire"],
    ['璋', "jade plaything; jade ornament"],
    ['亢', "high, proud; violent, excessive; skilled; name"],
    ['轧', "crush by weight; grind"],
    ['赂', "bribe; give present"],
    ['蝗', "kind of locust"],
    ['榆', "elm tree"],
    ['骏', "excellent horse, noble steed"],
    ['诛', "execute, kill, put to death; punish"],
    ['勺', "spoon, ladle; unit of volume"],
    ['梵', "Buddhist, Sanskrit"],
    ['炽', "burning-hot, intense; to burn, blaze; splendid, illustrious"],
    ['笠', "bamboo hat; bamboo covering"],
    ['颌', "mouth; jaw"],
    ['闸', "sluice; flood gate, canal lock"],
    ['狒', "baboon"],
    ['樊', "a railing; a fence an enclosed place"],
    ['镕', "fuse, melt, smelt; mold"],
    ['垢', "dirt, filth, stains; dirty"],
    ['瘟', "epidemic, plague, pestilence"],
    ['缪', "wind around, bind; prepare"],
    ['菇', "mushrooms"],
    ['琦', "gem, precious stone, jade"],
    ['剃', "shave"],
    ['迸', "gush out; burst forth; split"],
    ['溺', "drown; submerge in water; indulge"],
    ['炫', "shine, glitter; show off, flaunt"],
    ['惚', "absent-minded, confused"],
    ['嗨', "an exclamation"],
    ['陨', "fall, slip; let fall; die"],
    ['赃', "booty, loot, stolen goods; bribe"],
    ['羁', "halter; restrain, hold, control"],
    ['臻', "reach, arrive; utmost, superior"],
    ['嘀', "backbite"],
    ['膳', "meals, provisions, board"],
    ['赣', "Jiangxi province; places therein"],
    ['踌', "hesitate, falter; smug, self-satisfied"],
    ['殉', "die for cause, be martyr for"],
    ['桔', "Chinese bellflower; well-swept; the inner fibers of corn-stalks"],
    ['瞿', "surname"],
    ['闽', "fujian province; a river; a tribe"],
    ['豚', "small pig, suckling pig; suffle"],
    ['掺', "mix, blend, adulterate"],
    ['沌', "chaotic, confused; turbid, murky"],
    ['惰', "indolent, careless, lazy, idle"],
    ['喳', "whispering"],
    ['椭', "oval-shaped, elliptical, tubular"],
    // ['咪', ""],
    ['霎', "light rain, drizzle; an instant; passing"],
    ['侃', "upright and strong; amiable"],
    ['猝', "abruptly, suddenly; abrupt"],
    ['窖', "pit, cellar"],
    ['戮', "kill, massacre; oppress"],
    ['祠', "ancestral temple; offer sacrifice"],
    ['瞩', "watch carefully, stare at, focus on"],
    ['菁', "flower of leek family; turnip"],
    ['躇', "hesitate, falter, be undecided"],
    ['佬', "(Cant.) man, person; mature"],
    ['肋', "ribs; chest"],
    ['咄', "noise of rage, cry out in anger"],
    ['忡', "a sad, uneasy countenance"],
    ['雍', "harmony, union; harmonious"],
    ['忱', "truth, sincerity; sincere"],
    ['蕾', "buds, unopened flowers"],
    ['跄', "walk rapidly"],
    ['硅', "silicon"],
    ['伎', "talent, skill, ability"],
    ['炊', "cook; meal"],
    ['钊', "endeavor, strive; encourage; cut"],
    ['蝠', "kind of bat"],
    ['屎', "excrement, shit, dung"],
    ['拭', "wipe away stains with cloth"],
    ['谛', "careful, attentive"],
    ['褪', "strip, undress; fall off; fade"],
    ['丞', "assist, aid, rescue"],
    ['卉', "general term for plants; myriads"],
    ['隧', "tunnel, underground passageway, path to a tomb"],
    ['茸', "soft, downy; buds, sprouts"],
    ['钳', "pincers, pliers, tongs; to compress"],
    ['啃', "gnaw, chew, bite"],
    ['伢', "child"],
    // ['闺', ""],
    ['舔', "lick with tongue; taste"],
    ['蹬', "step on, tread on; lose energy"],
    ['挛', "tangled; entwined; crooked"],
    ['眺', "look at, gaze at, scan, survey"],
    ['袱', "a piece of cloth used wrap bundles"],
    ['陇', "mountain located between Shanxi"],
    ['殴', "beat, fight with fists, hit"],
    ['柿', "persimmon"],
    ['梧', "Chinese parasoltree, Sterculia platanifolia"],
    ['惺', "intelligent, clever, astute"],
    ['弛', "loosen, relax, unstring a bow"],
    ['侥', "be lucky; by chance, by luck"],
    ['琛', "treasure, valuables"],
    ['捅', "jab"],
    ['酝', "liquor, spirits, wine; ferment"],
    ['薯', "yam, tuber, potato"],
    ['曳', "trail, tow, drag, pull"],
    ['澈', "thoroughly, completely"],
    ['锈', "rust, corrode"],
    ['稠', "dense, crowded, packed; soupy"],
    ['眸', "pupil of eye; eye"],
    ['咆', "roar"],
    ['簧', "reed of woodwind instrument"],
    ['鸥', "seagull, tern; Larus species (various)"],
    ['疡', "ulcers, sores; infection"],
    ['渎', "ditch, sluice, gutter, drain"],
    ['汲', "draw water from well; imbibe"],
    ['嬉', "enjoy; play, amuse oneself"],
    ['脓', "pus"],
    ['骡', "mule"],
    ['穗', "ear of grain; tassel; Guangzhou"],
    ['槛', "threshold, door-sill"],
    ['拎', "to haul; to lift; to take"],
    ['巳', "the hours from 9 to 11; 6th terrestrial branch"],
    // ['邢', ""],
    ['廿', "twenty, twentieth"],
    ['搀', "give helping hand"],
    ['曙', "bright, light of rising sun"],
    ['樵', "woodcutter; firewood; gather wood"],
    ['隅', "corner, nook, remote place"],
    ['筛', "sieve, filter, screen; sift"],
    ['谒', "visit, pay respects"],
    ['倭', "dwarf; dwarfish, short"],
    ['痹', "paralysis, numbness"],
    ['猖', "mad, wild, reckless, unruly"],
    ['佯', "pretend, feign; false, deceitful"],
    ['肛', "anus"],
    ['奚', "where? what? how? why?; servant"],
    ['甭', "there is no need"],
    ['抨', "impeach, censure; attack"],
    ['蛾', "moth"],
    ['唠', "chat, jaw, gossip, talk"],
    ['荧', "shine, shimmer; shining, dazzling"],
    ['嵩', "high, lofty; one of the 5 peaks, situated in Hunan"],
    ['漱', "gargle, rinse; wash, scour"],
    ['酋', "chief of tribe, chieftain"],
    ['攘', "seize, take by force; repel"],
    ['诘', "question, interrogate"],
    ['篡', "usurp"],
    ['睿', "shrewd, astute, clever, keen"],
    ['噩', "bad, ill-omened, unlucky"],
    ['怅', "disappointed, dissatisfied"],
    ['盎', "cup; pot; bowl; abundant"],
    // ['徙', ""],
    // ['鞅', ""],
    ['漓', "river in Guangxi province; water dripping"],
    ['祟', "evil spirit; evil influence"],
    ['睫', "eyelashes"],
    ['攸', "distant, far; adverbial prefix"],
    ['翎', "feather; plume; wing"],
    ['呛', "choke by smoke; irritates nose"],
    ['筐', "bamboo basket or chest"],
    ['堑', "moat, trench, pit, cavity"],
    ['檀', "sandalwood, hardwood; surname"],
    ['寅', "to respect, reverence; respectfully; 3rd terrestrial branch"],
    ['磊', "pile of rocks or stones; great"],
    ['驭', "drive, ride; manage, control"],
    ['惘', "disconcerted, dejected, discouraged"],
    ['吠', "bark"],
    ['驮', "carry on back"],
    ['瑙', "agate; cornelian"],
    ['炬', "torch"],
    ['痉', "convulsions, fits"],
    ['曝', "sun, air in sun, expose or dry in the sun"],
    ['恺', "enjoy, be contented, joyful"],
    ['胺', "amine"],
    ['萤', "glow-worm, luminous insect"],
    ['敕', "an imperial order or decree"],
    ['筝', "stringed musical instrument; kite"],
    ['幡', "pennant, banner, streamer, flag"],
    ['霹', "thunder, crashing thunder"],
    ['竺', "India; bamboo; surname"],
    ['烙', "brand, burn; branding iron"],
    ['毗', "help, assist; connect, adjoin"],
    ['鸠', "pigeon; collect, assemble"],
    ['埠', "port city"],
    ['蒜', "garlic"],
    ['阜', "mound; abundant, ample, numerous"],
    ['嘈', "noisy"],
    ['乒', "used with pong for ping pong"],
    ['帷', "tent; curtain, screen"],
    ['啄', "to peck; (Cant.) to slander"],
    ['鳌', "huge sea turtle"],
    ['毡', "felt; rug, carpet"],
    ['阙', "watch tower; palace"],
    ['褥', "mattress, cushion; bedding"],
    ['搔', "to scratch"],
    ['笋', "bamboo shoots"],
    ['冕', "crown; ceremonial cap"],
    ['狞', "ferocious appearance; hideous"],
    ['韶', "music of the emperor Shun; beautiful"],
    ['骼', "bone; skeleton; corpse"],
    ['蔼', "lush; affable, friendly"],
    ['烹', "boil, cook; quick fry, stir fry"],
    ['奄', "ere long; remain, tarry; feeble"],
    ['嫖', "patronize prostitutes, frequent"],
    ['沐', "bathe, cleanse, wash, shampoo"],
    ['噗', "burst"],
    ['岑', "steep, precipitous; peak"],
    ['蛟', "scaly dragon with four legs"],
    ['掳', "capture, seize"],
    ['咏', "sing song or poem, hum, chant"],
    ['弩', "cross-bow, bow; downward stroke"],
    ['捻', "to twist or nip with the fingers"],
    ['圃', "garden, cultivated field"],
    ['孚', "brood over eggs; have confidence"],
    ['悴', "suffer, become emaciated, haggard"],
    ['诣', "reach; achievement, accomplishment"],
    ['呱', "wail, crying of child; swear at"],
    ['祁', "pray; numerous, ample, abundant"],
    ['捶', "strike with stick, lash, beat"],
    ['钠', "sodium, natrium; sharpen wood"],
    ['袄', "outer garments; coat, jacket"],
    ['澎', "splatter"],
    ['氮', "nitrogen"],
    ['恪', "respectful, reverent"],
    ['雏', "chick, fledging; infant, toddler"],
    ['撮', "little bit, small amount, pinch"],
    ['堰', "dam; embankment, dike, bank"],
    ['彷', "like, resembling; resemble"],
    ['鹦', "parrot"],
    ['晖', "sunshine; light, bright, radiant"],
    ['犀', "rhinoceros; sharp, well-tempered"],
    ['腑', "bowels, entrails, internal organs"],
    ['沽', "buy and sell; inferior in quality"],
    ['橄', "olive"],
    ['掐', "hold; gather with hand; choke"],
    ['亵', "slight, insult, treat with disrespect"],
    ['龋', "tooth decay"],
    ['嗒', "absent-minded"],
    ['咀', "suck; chew, masticate"],
    ['祺', "good luck, good fortune"],
    ['锚', "anchor"],
    ['匾', "flat, round split-bamboo contain"],
    ['乓', "used with ping for ping pong"],
    ['萃', "dense, thick, close-set; to collect together"],
    ['贻', "give to, hand down, bequeath"],
    ['揖', "salute, bow; defer to, yield"],
    ['觑', "peep at; watch, spy on"],
    ['吝', "stingy, miserly, parsimonious"],
    ['憔', "be worn-out, emaciated, haggard"],
    ['羌', "Qiang nationality; surname"],
    ['诲', "teach, instruct; encourage, urge"],
    ['砾', "gravel, pebbles"],
    ['蠕', "eumenes polifomis, kind of wasp"],
    ['肴', "cooked or prepared meat"],
    ['撩', "lift up, raise; leave, depart"],
    ['坍', "collapse; landslide"],
    ['酥', "butter; flaky, crispy, light, fluffy"],
    ['袅', "curling upwards; wavering gently"],
    ['黝', "black"],
    ['俾', "so that, in order that; to cause; (Cant.) to give (synonymous with Mandarin 給)"],
    ['嫣', "charming, fascinating; gay"],
    ['穹', "high and vast; elevated; arched"],
    ['秧', "rice seedlings, young rice plants"],
    ['妊', "conceive, be pregnant"],
    ['溉', "water, irrigate, flood; wash"],
    ['鹊', "magpie; Pica species (various)"],
    ['聿', "writing brush, pencil; thereupon"],
    ['疙', "pimple, sore, boil, wart, pustule"],
    ['蘑', "type of edible mushroom"],
    ['睾', "testicle"],
    ['楷', "model style of Chinese writing"],
    ['酵', "yeast, leaven"],
    ['茹', "roots; vegetables; eat; bear"],
    ['锌', "zinc"],
    ['滇', "Yunnan province"],
    ['辗', "turn over, roll"],
    ['纂', "edit, compile; topknot, chignon"],
    ['圭', "jade pointed at top"],
    ['幔', "curtain, screen, tent"],
    ['褒', "praise, commend, honor, cite"],
    ['揍', "hit, beat; smash, break"],
    ['诽', "slander, vilify, condemn"],
    ['倔', "stubborn, obstinate, intransigent; firm"],
    ['腓', "calf; avoid; be ill; wither"],
    ['颉', "fly upward, soar; contest"],
    ['锄', "hoe; eradicate"],
    ['嗔', "be angry at, scold, rebuke"],
    ['磺', "sulphur; brimstone"],
    ['攒', "save, hoard"],
    ['瘩', "pimples"],
    ['雳', "thunderclap, crashing thunder"],
    ['吆', "bawl, yell, shout, cry out"],
    ['悚', "afraid, scared, frightened"],
    ['墩', "heap, mound, block of stone"],
    ['彝', "Yi (nationality); tripod, wine vessel; rule"],
    ['囱', "chimney"],
    ['逍', "ramble, stroll, jaunt, loiter"],
    ['辄', "sides of chariot where weapons"],
    ['桅', "mast of ship"],
    ['俨', "grave, respectful, majestic"],
    ['纶', "green silk thread or tassel"],
    ['悸', "fearful, apprehensive, perturbed"],
    ['殃', "misfortune, disaster, calamity"],
    ['帧', "picture, scroll; one of pair of"],
    ['俐', "smooth; active; clever, sharp"],
    ['绮', "fine thin silk; elegant, beautifu"],
    ['袒', "strip; lay bare; bared; naked"],
    ['籽', "seed, pip, pit, stone"],
    ['孰', "who? which? what? which one?"],
    ['愫', "guileless, sincere, honest"],
    ['拌', "mix"],
    ['橙', "orange"],
    ['暨', "and; attain, reach; confines"],
    ['敖', "ramble, play about; leisurely; surname"],
    ['赘', "unnecessary, superfluous"],
    ['抉', "choose, select; gouge, pluck out"],
    ['淤', "mud, sediment; clog up, silt up"],
    ['剌', "slash, cut in two; contradict"],
    ['娼', "prostitute, harlot"],
    ['顼', "grieved"],
    ['葵', "sunflower; measure"],
    ['哝', "whisper"],
    ['酣', "enjoy intoxicants"],
    ['麓', "foot of hill; foothill"],
    ['钵', "earthenware basin; alms bowl"],
    ['琅', "a variety of white carnelian; pure"],
    ['簸', "winnower"],
    ['禾', "grain still on stalk; rice plant"],
    ['铢', "unit of weight, one twenty-fourth of a Chinese ounce (liang)"],
    ['璧', "piece of jade with hole in it"],
    ['娠', "pregnant"],
    ['彗', "broomstick; comet"],
    ['惋', "regret, be sorry; alarmed"],
    ['腋', "armpit"],
    ['螂', "mantis, dung beetle"],
    ['阪', "hillside farmland; slope"],
    ['掣', "drag, pull; hinder by pulling back"],
    ['劾', "examine into, impeach, charge"],
    ['沥', "trickle, drip; strain; dregs"],
    ['粱', "better varieties of millet"],
    ['嚓', "a cracking or snapping sound"],
    ['惮', "dread, shrink from, shirk, fear"],
    ['氖', "neon"],
    ['捎', "to select; to take; to carry"],
    ['羔', "lamb, kid"],
    ['俟', "wait for, wait until, as soon as"],
    ['渲', "add repeated washes of color"],
    ['榄', "olive"],
    ['茧', "cocoon; callus, blister"],
    ['霓', "rainbow; variegated, colored"],
    ['鹉', "species of parrot"],
    ['胥', "all, together, mutually"],
    ['琶', "guitar-like instrument"],
    ['撬', "lift, raise; pry open"],
    ['橘', "orange, tangerine"],
    ['醫', "cure, heal; doctor, medical"],
    ['拈', "pick up with fingers; draw lots"],
    ['笆', "bamboo fence"],
    ['痊', "be healed, be cured; recover"],
    ['亟', "urgently, immediately, extremely"],
    ['渭', "name of a river in Shanxi"],
    ['狙', "an ape, monkey; to spy, watch for; to lie"],
    ['珂', "inferior kind of jade"],
    // ['刨', ""],
    ['蜕', "molt"],
    ['谚', "proverb, maxim"],
    ['憧', "irresolute, indecisive; yearn for"],
    ['瞟', "glare at; look askance at; squint"],
    ['馒', "steamed bread; steamed dumplings"],
    ['拗', "to pull, drag, break off, to pluck (a flower); bent, warped; perverse, obstinate"],
    ['帚', "broom, broomstick"],
    ['钗', "ornamental hairpin"],
    ['哧', "sound of ripping or giggling"],
    ['喋', "nag; chatter, babble, twitter"],
    ['箫', "bamboo flute"],
    ['刁', "tricky, sly, crafty, cunning"],
    ['怦', "eager, ardent, impulsive; anxious"],
    ['缭', "wind round, rap around, bind"],
    ['迥', "distant, far; separated; different"],
    // ['湄', ""],
    ['磐', "large rock, boulder; firm"],
    ['渝', "change; chongqing"],
    ['冗', "excessive; superfluous"],
    ['闵', "mourn, grieve; urge on, incite"],
    ['噶', "used in transliterations"],
    ['黏', "stick to; glutinous, sticky; glue"],
    ['蕃', "foreign things"],
    ['弼', "aid, assist, help; correct"],
    ['驿', "relay station"],
    ['淄', "river in Shandong province"],
    ['饺', "stuffed dumplings"],
    ['踞', "crouch, squat; sit, occupy"],
    ['韬', "sheath, scabbard, bow case"],
    ['婷', "pretty; attractive; graceful"],
    ['唆', "make mischief, incite, instigate"],
    ['蜒', "millipede"],
    ['偎', "cling to, cuddle, embrace, fondle"],
    ['榨', "to press or extract juices; a press to extract juices; a vegetable"],
    ['漉', "filter, strain; wet, dripping"],
    ['碉', "room made of stone; watchtower"],
    ['皈', "follow, comply with"],
    ['矜', "pity, feel sorry for, show sympat"],
    ['笈', "bamboo box used carry books"],
    ['枷', "cangue scaffold"],
    ['鲨', "shark"],
    ['蹑', "tread, step on; follow, track"],
    ['瀚', "vast, wide, extensive"],
    ['酪', "cream, cheese; koumiss"],
    ['谑', "jeer"],
    ['癖', "craving, weakness for; indigestion"],
    ['烬', "cinders, ashes, embers; remnants"],
    ['揩', "rub and wipe, dust, clean"],
    ['炙', "roast, broil; toast; cauterize"],
    // ['蜷', ""],
    ['侏', "small, little, tiny, dwarf"],
    ['凋', "be withered, fallen; exhausted"],
    ['漪', "ripples on water; swirling"],
    ['悻', "anger, vexation; angry, indignant"],
    ['蹋', "step on, tread on; stumble, slip"],
    ['讪', "abuse, slander; vilify; ridicule"],
    ['搐', "cramp, spasm, convulsion; twitch"],
    ['碘', "iodine"],
    ['帛', "silks, fabrics; wealth, property"],
    ['诠', "explain, expound, comment on"],
    ['碾', "roller, crush; roll"],
    ['擂', "rub, grind; grind with a mortar and pestle; triturate"],
    ['苯', "benzene; luxuriant"],
    ['诃', "scold loudly, curse, abuse"],
    ['铎', "bell; surname"],
    ['戊', "5th heavenly stem"],
    ['荀', "surname; ancient state; plant"],
    ['驹', "colt; fleet, swift; sun; surname"],
    ['攫', "snatch away, seize; catch with"],
    ['憬', "rouse, awaken; become conscious"],
    ['哽', "choke (with grief)"],
    ['踵', "heel; follow; visit, call on"],
    ['蟒', "python, boa constrictor"],
    ['漾', "overflow; swirl, ripple; to be tosssed by waves"],
    ['啧', "interjection of approval or admiration"],
    ['吮', "suck with mouth, sip, lick"],
    ['楠', "name of tree, machilus nanmu"],
    ['氟', "fluorine"],
    ['怂', "instigate, arouse, incite"],
    ['叼', "holding in mouth"],
    ['竣', "terminate, end, finish; quit"],
    ['偕', "together; be in order"],
    ['漩', "eddy, whirlpool"],
    ['蹭', "to shuffle, procrastinate, dilly-dally"],
    ['翌', "bright; daybreak, dawn; the next day"],
    ['臆', "chest, breast, bosom; thought"],
    ['挝', "to beat; to strike"],
    ['绚', "variegated, adorned; brilliant"],
    ['崽', "a child, a servant; a diminutive"],
    ['糜', "rice gruel, congee; mashed"],
    ['瘢', "scar, mole"],
    ['跤', "stumble, fall down; wrestle"],
    ['阑', "door screen; railing fence"],
    ['恬', "quiet, calm, tranquil, peaceful"],
    ['豢', "domestic animals; feed, raise"],
    ['汶', "a river in Shandong province"],
    // ['跷', ""],
    ['琵', "guitar-like instrument"],
    ['憨', "foolish, silly, coquettish"],
    ['蜗', "a snail, Eulota callizoma"],
    ['螅', "intestinal worm"],
    ['惴', "afraid, apprehensive, nervous"],
    ['戟', "halberd with crescent blade"],
    ['匮', "to lack"],
    ['恙', "illness, sickness; indisposition"],
    ['抿', "pucker up lips in smile; press"],
    ['桢', "hardwood; supports, posts"],
    ['笺', "note, memo; stationery; comments"],
    ['蛤', "clam"],
    ['瞳', "pupil of eye"],
    ['藥', "drugs, pharmaceuticals, medicine"],
    ['瓢', "ladle made from dried gourd"],
    ['衹', "only, merely, but"],
    ['秤', "balance, scale, steelyard"],
    ['跺', "stamp feet, step"],
    ['潦', "to flood; a puddle; without care"],
    ['芹', "celery"],
    ['哒', "a sound made to get a horse to move forward"],
    ['饬', "order; command; give command"],
    ['栩', "species of oak; be glad, be pleased"],
    ['曦', "sunlight, sunshine, early dawn"],
    ['骷', "skeleton"],
    ['嫡', "legal wife, child of legal wife"],
    ['卤', "salt"],
    ['丕', "great, grand, glorious, distinguished"],
    ['鬓', "hair on temples"],
    ['梓', "catalpa ovata"],
    ['嗖', "whizzing sound"],
    ['惦', "think of, remember, miss"],
    ['浚', "dredge"],
    ['咔', "used in translation"],
    ['藐', "disregard, slight, disdain"],
    ['荃', "aromatic herb; fine cloth"],
    ['唧', "chirping of insects; pump; (Cant.) a final particle"],
    ['玺', "imperial signet, royal signet"],
    ['汛', "high water, flood tides"],
    ['铐', "shackles, manacles"],
    ['髅', "skull; skeleton"],
    ['渤', "swelling; the Gulf of Hopei (Hebei)"],
    ['皿', "shallow container; rad. no. 108"],
    ['箍', "hoop; bind, surround"],
    ['馅', "filling, stuffing; secret"],
    ['汾', "river in Shanxi province"],
    ['戍', "defend borders, guard frontiers"],
    ['痔', "hemorrhoids, piles"],
    ['褶', "pleat, crease, wrinkles"],
    ['聆', "listen, hear"],
    ['涎', "saliva"],
    ['汞', "element mercury"],
    ['渍', "soak, steep; dye; stains; sodden"],
    ['奂', "be numerous, brilliant"],
    ['巅', "summit of mountain, mountain top"],
    ['疣', "wart; tumor; goiter; papule"],
    ['傩', "rich"],
    ['逵', "thoroughfare, crossroads"],
    ['耆', "man of sixty; aged, old"],
    ['蟋', "cricket"],
    ['鳄', "alligator"],
    ['讹', "swindle, cheat; erroneous, wrong"],
    ['膺', "breast, chest; undertake, bear"],
    ['蹿', "leap; to jump; to spurt out"],
    ['筏', "raft"],
    ['釜', "cauldron, pot, kettle"],
    ['沂', "river in southeast Shandong"],
    ['坯', "dam, embankment; weir"],
    ['峦', "mountain range; pointed mountain"],
    ['茬', "harvest"],
    ['摒', "expel, cast off; arrange"],
    ['蟀', "cricket"],
    ['撵', "drive away, expel, oust"],
    ['浒', "riverbank, shore"],
    ['缤', "flourishing, thriving, abundant"],
    ['嵋', "Omei mountain in Sichuan"],
    ['珑', "a gem cut like dragon"],
    ['苞', "a variety of rush; firm, enduring; to burst forth"],
    ['瑾', "brilliance of gems; fine jade"],
    ['泵', "pump"],
    ['钾', "potassium"],
    ['暧', "obscure, dim, ambiguous, vague"],
    ['赓', "continue"],
    ['叟', "old man; elder"],
    ['佚', "indulge in pleasures; flee"],
    ['沓', "connected, joined; repeated"],
    ['撂', "put down, put aside; drop"],
    ['蛊', "poison; venom; harm; bewitch"],
    // ['甥', ""],
    ['璐', "beautiful variety of jade"],
    ['晏', "peaceful, quiet; clear; late in the day"],
    ['瘪', "shrivelled up, dried up; vexed"],
    ['漳', "name of a river in Henan"],
    ['阉', "castrate; eunuch"],
    ['蹂', "trample under foot, tread on"],
    ['鳃', "fish gills"],
    ['琏', "vessel used to hold grain offerings"],
    ['湃', "sound of waves; turbulent"],
    ['辘', "windlass, pulley, capstan; wheel"],
    ['僭', "assume, usurp"],
    ['躏', "trample down, oppress, overrun"],
    ['鼾', "snore loudly"],
    ['懵', "stupid, ignorant, dull"],
    ['镰', "sickle"],
    ['寐', "sleep; be asleep"],
    ['褚', "bag, valise; stuff, pad; surname"],
    ['攥', "(coll.) hold; grip; grasp"],
    ['涧', "brook, mountain stream"],
    ['蝙', "bat"],
    ['脐', "abdominal area of crab; navel"],
    // ['辕', ""],
    ['涣', "scatter; scattered"],
    ['杞', "willow; medlar tree; a small feudal state (Qi)"],
    ['煜', "bright, shining, brilliant"],
    ['骥', "thoroughbred horse; refined and"],
    ['傣', "the Dai minority living in South China"],
    ['嗳', "interjection; exclamation"],
    ['祯', "lucky, auspicious, good omen"],
    ['酉', "10th terrestrial branch; a wine vessel"],
    ['秸', "stalks of millet, corn"],
    ['捺', "to press down heavily with the fingers"],
    ['瑕', "flaw in gem; fault, defect"],
    ['鑫', "used in names"],
    ['馋', "gluttonous, greedy; lewd, lecherous"],
    ['窿', "mine shaft; cavity, hole"],
    ['楔', "wedge; gatepost; foreword"],
    ['胱', "bladder"],
    ['荔', "lichee"],
    ['蟆', "frog, toad"],
    ['湍', "rapid water current, rapids"],
    ['屹', "to rise high; to stand erect"],
    ['遐', "afar, distant; old, advanced in"],
    ['轲', "axle; personal name of mencius"],
    ['镯', "bracelet, armband; small bell"],
    ['缰', "reins, bridle"],
    ['桦', "type of birch"],
    ['炖', "heat with fire; stew"],
    ['钡', "barium"],
    ['羚', "species of antelope"],
    ['啬', "miserly, thrifty; stingy"],
    ['诩', "boast, brag; popular, well-known"],
    ['绯', "scarlet, dark red, crimson; purpl"],
    ['掖', "support with the arms; stick in, tuck in; fold up"],
    ['箓', "book"],
    ['涸', "dried up; exhausted, tired; dry"],
    ['鸳', "male mandarin duck (Aix galericulata)"],
    ['塾', "village school; private tutorage"],
    ['呸', "expression of reprimand"],
    ['抡', "swing, brandish, flourish"],
    ['擞', "shake, tremble, quake; flutter"],
    ['熹', "dim light, glimmer; warm, bright"],
    ['坷', "clod of earth, lump of soil"],
    ['瓮', "earthen jar; jar for ashes"],
    ['亘', "extend across, through; from"],
    ['嗟', "sigh, alas"],
    ['筵', "bamboo mat; feast, banquet"],
    ['跛', "lame"],
    ['汕', "basket for catching fish; bamboo; Swatow (Shantou)"],
    ['欤', "a final particle used to express admiration, doubt, surprise, or to mark a question"],
    ['壑', "bed of torrent, narrow ravine"],
    ['颍', "river in Anhui"],
    ['溥', "big, great, vast, wide; widespread"],
    ['姗', "slander; ridicule; proceed slowly"],
    ['踊', "leap, jump"],
    ['枭', "owl thus, something evil"],
    ['暄', "warm, comfortable; warm, genial"],
    ['稷', "god of cereals; minister of agriculture"],
    ['跚', "stagger, limp"],
    ['涟', "flowing water; ripples; weeping"],
    ['瀛', "sea, ocean"],
    ['笙', "small gourd-shaped musical instrument"],
    ['滕', "an ancient state in Shandong province; water bursting forth"],
    ['踝', "ankle"],
    ['贰', "number two"],
    ['瞰', "watch, spy; overlook; look down"],
    ['恻', "feel anguish, feel compassion"],
    ['嚏', "sneeze"],
    ['迢', "far; distant"],
    ['獗', "unruly, wild, violent, lawless"],
    ['邯', "city in Hebei province; various"],
    ['睑', "eyelid"],
    ['赡', "support, aid; rich, elegant"],
    ['萦', "entangle, entwine, coil"],
    ['珥', "ear ornament; stick, insert"],
    ['酮', "ketones"],
    ['璞', "unpolished gem, uncarved gem"],
    ['羹', "soup, broth"],
    ['缄', "seal, close; bind; letter"],
    ['晾', "air-dry; sun-dry"],
    ['俸', "wages, salary, official emolument"],
    ['媲', "marry off, pair, match; compare"],
    ['鸾', "fabulous bird"],
    ['恿', "instigate, incite; to alarm"],
    ['蜿', "creep, crawl"],
    ['犊', "calf; victim of sacrifice"],
    ['讷', "slow of speech; mumble; stammer"],
    ['扈', "escort, retinue; insolent"],
    ['蜈', "centipede"],
    ['翟', "surname; a kind of pheasant; plumes"],
    ['藕', "lotus root"],
    ['戌', "11th terrestrial branch"],
    ['蓓', "bud"],
    ['鋆', "gold; character used in personal name"],
    ['谩', "deceive, insult"],
    ['谀', "flatter, truckle"],
    ['卯', "4th terrestrial branch; period from 5-7 a.m."],
    ['谙', "versed in, fully acquainted with"],
    ['岐', "high; majestic; fork in road"],
    ['蝎', "scorpion"],
    ['荼', "bitter vegetable"],
    ['镀', "plate, coat, gild"],
    ['椰', "palm tree, coconut palm"],
    ['甄', "to examine, discern; to grade; a surname"],
    ['蟾', "toad"],
    ['蹊', "footpath, trail; track"],
    ['泞', "mud; miry, muddy, stagnant"],
    // ['撸', ""],
    ['螃', "crab"],
    ['檬', "type of locust oracacia"],
    ['猓', "monkey"],
    ['蔷', "rose"],
    ['羲', "ancient emperor; breath, vapor"],
    ['瘸', "lameness, paralysis of hands, leg"],
    ['蘸', "dip (a brush); remarry"],
    ['蔗', "sugar cane"],
    ['傀', "great, gigantic; puppet"],
    ['蚌', "oysters, mussels; mother-of-pearl"],
    ['锢', "run metal into cracks; confine"],
    ['遽', "suddenly, unexpectedly; at once"],
    ['邃', "profound, detailed; deep"],
    ['恚', "anger, rage"],
    ['皑', "brilliant white"],
    ['锵', "tinkle, clang, jingle"],
    ['簌', "(of flower petals) falling"],
    ['焙', "dry over slow fire; bake; roast"],
    ['昊', "summer time; sky; heaven"],
    ['鹳', "crane, grus japonensis; stork; Ciconia species (various)"],
    ['睽', "staring"],
    ['刽', "amputate, cut off"],
    ['鳖', "turtle"],
    ['噎', "choke; hiccup"],
    ['呗', "final particle of assertion"],
    ['寰', "great domain, country, world"],
    ['唷', "final particle"],
    ['殡', "encoffin; embalm; funeral"],
    ['淖', "slush; mud"],
    ['诰', "inform, notify, admonish, order"],
    ['恣', "indulge oneself, unrestrained"],
    ['睐', "squint at; sidelong glance"],
    ['婵', "beautiful, lovely, pretty, graceful"],
    ['榈', "palm"],
    ['氦', "helium"],
    // ['靳', ""],
    ['蛹', "chrysalis, larva"],
    ['鸯', "female mandarin duck (Aix galericulata)"],
    ['惬', "be satisfied, be comfortable"],
    ['蹙', "urgent, suddenly; grieve, lament"],
    ['诙', "tease, joke with; ridicule, mock"],
    ['眈', "gloat, stare at; to delay, to loiter, to hinder"],
    ['罡', "the name of a certain stars; the god who is supposed to live in them"],
    ['缮', "repair, mend; rewrite, transcribe"],
    ['胤', "heir, successor; progeny, posterity"],
    ['皋', "the high land along a river"],
    ['蛀', "insects that eat books, clothes"],
    ['偌', "thus, so, like, such"],
    ['疵', "flaw, fault, defect; disease"],
    ['绛', "deep red; river in Shanxi provinc"],
    ['葆', "reserve, preserve; conceal"],
    ['黔', "black; Guizhou"],
    ['喙', "beak, bill, snout; pant"],
    ['烽', "signal fire; tower where signal"],
    ['儡', "puppet, dummy"],
    ['佼', "beautiful, handsome, good-looking"],
    ['斓', "multicolored"],
    ['嫔', "court lady; palace maid"],
    ['颚', "jaw"],
    ['龈', "gums"],
    ['盅', "small cup or bowl"],
    ['娓', "comply; complying, agreeable"],
    ['坂', "hillside, inconvenient places"],
    ['町', "raised path between fields"],
    ['芥', "mustard plant; mustard; tiny"],
    ['瘠', "thin, emaciated; barren"],
    ['阂', "blocked or separated; to prevent"],
    ['挎', "carry"],
    ['橇', "a sledge for transportation"],
    ['荟', "luxuriant, flourishing, abundant"],
    ['啜', "sip, suck up; sob, weep"],
    ['垛', "heap, pile; pile up, heap up"],
    ['淇', "river in Henan province"],
    ['瓒', "ceremonial libation cup"],
    ['篓', "bamboo basket"],
    ['虱', "louse; bug; parasite"],
    ['跻', "ascend, go up, rise"],
    ['龛', "niche, shrine"],
    ['蹒', "to jump over; to limp"],
    ['髯', "beard; mustache"],
    ['瞠', "look at, stare at, gaze at"],
    ['痫', "epilepsy, convulsions"],
    ['掂', "heft, hold or weigh in palm"],
    ['潼', "high, lofty; damp"],
    ['酰', "acyl"],
    ['镁', "magnesium"],
    ['灸', "cauterize with moxa; moxibustion"],
    ['腆', "prosperous; good; protruding"],
    // ['筱', ""],
    ['谆', "patient, earnest; earnestly"],
    ['骋', "gallop horse; hasten, hurry"],
    ['壬', "9th heavenly stem"],
    ['茗', "tea; tea plant"],
    ['椋', "fruit"],
    ['蛔', "tapeworm"],
    ['潺', "sound of flowing water"],
    ['扉', "door panel"],
    ['耘', "weed"],
    ['槟', "betel-nut, areca nut"],
    ['雹', "hail"],
    ['甬', "path; river in Ningbo; Ningbo"],
    ['谥', "to confer posthumous titles"],
    ['淞', "name of a river in Jiangsu"],
    ['燎', "to burn, set afire; to illuminate; a signal lamp"],
    ['蕙', "species of fragrant orchid"],
    ['蚪', "tadpole"],
    ['蜻', "dragonfly"],
    ['郸', "county in Hebei province"],
    ['轶', "rush forth; surpass, excel"],
    ['狰', "fierce-looking, ferocious"],
    ['楣', "crossbeam above or under gate"],
    ['捋', "to pluck; to gather in the fingers; to rub; to scrape off"],
    ['涓', "brook, stream; select; pure"],
    ['荪', "aromatic grass; iris, flower"],
    ['娄', "surname; a constellation; to wear"],
    ['麝', "musk deer"],
    ['蚤', "flea; louse"],
    ['薰', "a medicinal herb; to cauterize"],
    ['醮', "religious service; Daoist or Buddhist ceremony"],
    ['搪', "ward off, evade; parry; block"],
    ['谧', "calm, quiet, still; cautious"],
    ['湮', "bury, sink, block up; stain"],
    ['辍', "stop, suspend, halt"],
    ['瞌', "doze off; sleepy"],
    // ['梆', ""],
    ['樟', "camphor tree"],
    ['茉', "white jasmine"],
    ['岖', "steep, sheer; rugged, rough"],
    ['臼', "mortar; bone joint socket"],
    ['癣', "ringworms"],
    ['穑', "farm, harvest grain; stingy"],
    ['玷', "flaw in gem; flaw in character"],
    ['馍', "bread"],
    ['呷', "suck, swallow, drink"],
    ['萼', "the stem and calyx of a flower; a younger brother"],
    ['妩', "charming, enchanting"],
    // ['伫', ""],
    ['彤', "red, vermilion; name of ancient"],
    ['莓', "moss; edible berries"],
    ['岬', "cape; promontory, headland"],
    ['媛', "beauty, beautiful woman"],
    ['惆', "distressed, regretful, sad"],
    ['鳎', "sole"],
    ['啾', "wailing of child; chirp"],
    ['囔', "muttering, indistinct speech"],
    ['蜓', "dragonfly"],
    ['孺', "child; blood relation; affection"],
    ['徇', "comply with, follow; display"],
    ['徵', "summon, recruit; musical note"],
    ['焊', "weld, solder"],
    ['岱', "Daishan, one of the Five Sacred Mountains in China"],
    ['昵', "intimate, close; approach"],
    ['卅', "thirty, thirtieth"],
    ['飙', "whirlwind, stormy gale"],
    ['邙', "mountain in Henan province"],
    ['痞', "dyspepsia, spleen infection"],
    ['隼', "aquiline (nose); a falcon"],
    ['恫', "in pain, sorrowful"],
    ['怆', "sad, broken-hearted, disconsolate"],
    ['桀', "chicken roost; ancient emperor"],
    ['绶', "silk ribbon attached as a seal"],
    ['裆', "crotch or seat of pants; pants"],
    ['盂', "basin; cup"],
    ['桧', "Chinese cypress, Chinese juniper"],
    ['蚓', "earthworm"],
    ['抠', "raise, lift up; tight-fisted"],
    ['嗷', "loud clamor; sound of wailing"],
    ['槌', "hammer, mallet; strike, beat"],
    ['痘', "smallpox"],
    ['痢', "dysentery"],
    // ['芮', ""],
    ['蚣', "centipede"],
    ['闩', "bolt, latch, crossbar"],
    ['铿', "strike, beat, stroke; jingling"],
    ['飓', "cyclone, typhoon, gale"],
    ['疱', "acne"],
    ['蝌', "tadpole"],
    ['撅', "protrude; snap, break; dig"],
    ['蚯', "earthworm"],
    ['斡', "revolve, rotate, turn"],
    ['窠', "nest; hole, indention; den"],
    ['荚', "pods of leguminous plants; pods"],
    ['耷', "ears hanging down"],
    ['砚', "inkstone"],
    ['牒', "documents, records; dispatch"],
    ['赈', "relieve, aid distressed; rich"],
    ['煦', "kind, gentle, gracious, genial"],
    ['嗫', "move lips as when speaking; hesitation"],
    ['耙', "rake"],
    ['榕', "banyan tree"],
    ['鞑', "tatars"],
    ['袤', "longitude, lengthwise; length"],
    ['谌', "sincere, faithful; surname"],
    ['醺', "get drunk, be intoxicated"],
    ['秆', "straw; stalk of grain"],
    ['徨', "doubtful, irresolute, vacillating"],
    ['橹', "oar, scull; row, scull"],
    ['翡', "kingfisher; emerald, jade"],
    ['缨', "a chin strap; tassel; to annoy, bother"],
    ['锹', "shovel"],
    ['嵇', "mountain in Henan; surname"],
    // ['圪', ""],
    ['髻', "hair rolled up in a bun, topknot"],
    ['嗬', "(Cant.) interrogative particle"],
    ['辎', "a supply cart, covered wagon, dray"],
    ['痣', "spots, moles; birthmark"],
    ['娩', "give birth child; complaisant"],
    ['谄', "flatter, truckle, toady"],
    ['蛐', "cricket; worm"],
    ['鹞', "sparrow hawk; kite shaped like"],
    ['翱', "soar, roam"],
    ['庖', "kitchen; cooking, cuisine"],
    ['籁', "bamboo flute; pipe; various sound"],
    ['蓿', "clover, lucerne"],
    ['鳗', "eel"],
    ['疟', "intermittent fever; malaria"],
    ['鲇', "sheatfish, parasilurus asotus"],
    ['這', "this, the, here"],
    ['嚅', "talk indistinctly and falteringly"],
    ['瘀', "a hematoma, contusion; extravasted blood"],
    ['颔', "chin, jowl; give nod"],
    ['黜', "dismiss; demote, downgrade"],
    ['黠', "sly, cunning, shrewd; artful"],
    ['濑', "swift current; rapids"],
    ['馁', "hungry, starving, famished"],
    ['洵', "true, real, truly, really"],
    ['忐', "timorous; nervous"],
    ['忑', "fearful; nervous; timid"],
    ['砥', "a whetstone; to polish"],
    ['咂', "to suck, to smack the lips; (Cant.) to cheat"],
    ['罹', "sorrow, grief; incur, meet with"],
    ['糠', "chaff, bran, husks; poor"],
    ['匝', "full circle; encircle"],
    ['偃', "cease, lay off, lay down"],
    ['淙', "gurgling sound of water"],
    ['纫', "thread needle, sew stitch, string"],
    ['喏', "respectful reply of assent to superiors"],
    ['闾', "village of twenty-five families"],
    ['祛', "expel, disperse, exorcise"],
    ['蛰', "to hibernate"],
    ['腼', "modest"],
    ['涝', "inundate, flood; torrent"],
    ['曜', "glorious, as sun; daylight, sunlight; one of the seven planets of pre-modern astronomy (the sun, the moon, Mercury, Venus, Mars, Jupiter, and Saturn)"],
    ['厩', "stable; barnyard"],
    ['疽', "ulcer, carbuncle, abscess"],
    ['闰', "intercalary; extra, surplus"],
    ['洄', "a back-water; an eddy a whirlpool"],
    ['煊', "warm"],
    ['汐', "night tides, evening ebb tide"],
    ['藓', "moss, lichen"],
    ['璜', "a semicircular jade ornament used as a pendant"],
    ['铬', "chromium"],
    ['經', "classic works; pass through"],
    ['渥', "moisten, soak; great, deep; dye; to enrich"],
    ['靼', "tartars"],
    ['酗', "drunk, to become violent under the influence of alcohol"],
    ['苓', "fungus, tuber; licorice"],
    ['噤', "close; be silent, be unable speak"],
    ['咫', "foot measure of Zhou dynasty"],
    ['椿', "father; a plant with white flowers"],
    ['鲫', "Carassius auratus, crucian carp"],
    ['锭', "spindle, slab, cake, tablet"],
    ['罔', "net; deceive; libel; negative"],
    ['锺', "cup, glass, goblet; surname"],
    ['匍', "crawl; lie prostrate"],
    ['祗', "respect, look up to, revere"],
    ['锰', "manganese"],
    ['岌', "perilous, hazardous; high, steep"],
    ['馀', "surplus, excess, remainder"],
    // ['畹', ""],
    ['糯', "glutinous rice; glutinous, sticky"],
    ['胫', "shinbone; calf of leg"],
    ['熠', "bright and sparkling"],
    ['銮', "bells hung on horse; bells hung"],
    ['沅', "name of a river in western Hunan that flows into Dongting lake"],
    ['棣', "kerria japonica plant, cherry"],
    ['旌', "banner or flag adorned with feathers; to signal"],
    ['豌', "peas"],
    ['孢', "spore"],
    ['镭', "radium"],
    ['驸', "extra horse; imperial son-in-law"],
    ['腌', "salt, pickle; be dirty"],
    ['盹', "to doze; to nap; to nod"],
    ['熵', "entropy"],
    ['镐', "stove; bright"],
    ['馐', "food, meal; eat; offer"],
    ['嘤', "seek friends; also used in onomatopoetic expressions"],
    ['癞', "leprosy, scabies, mange; shoddy"],
    ['骰', "die, dice"],
    ['韭', "scallion, leek; radical 179"],
    ['阖', "close; whole, entire; all; leaf"],
    ['瞑', "close eyes"],
    ['裨', "aid, benefit, help; supplement"],
    ['宕', "stone quarry; cave dwelling"],
    ['戾', "perverse, recalcitrant, rebellious"],
    ['镌', "engraving tool; carve, engrave"],
    ['溟', "drizzling rain; dark, obscure"],
    ['牍', "writing tablet; documents, books"],
    ['隽', "superior, outstanding, talented"],
    ['婊', "whore, prostitute"],
    ['鹄', "target"],
    ['埂', "ditches for irrigation; hole"],
    ['拄', "lean on; post; prod; ridicule"],
    ['娲', "a goddess, the mythological sister and successor to Fuxi"],
    ['虬', "young dragon"],
    ['萱', "day-lily, hemerocallisflava"],
    ['啵', "phonetic"],
    ['蠡', "wood-boring insect; bore into wood"],
    ['芋', "taro"],
    ['胭', "rouge, cosmetics"],
    ['豺', "wolf; cruel, wicked, mean"],
    ['啻', "only, merely; just like; stop at"],
    ['褛', "lapel, collar; tattered, threadbare"],
    ['蛆', "maggots"],
    ['柠', "lemon"],
    ['掰', "(Cant.) to tear, to rip"],
    ['篆', "seal script; seal, official stamp"],
    ['倌', "assistant in wine shop, groom"],
    ['咛', "enjoin, instruct; charge"],
    ['蛭', "leech"],
    ['谡', "rise up; raise up"],
    ['荨', "nettle"],
    ['莞', "smiling; a kind of aquatic herb, clubrush, Scirpus lacustris"],
    ['澹', "calm, quiet, tranquil"],
    ['纭', "confused, in disorder; numerous"],
    ['潞', "river in northern china"],
    ['郅', "to go up to. flourishing a superlative"],
    ['弋', "catch, arrest; shoot with bow"],
    ['飕', "sound of wind; blow chilly"],
    ['螳', "mantis"],
    ['胄', "helmet, headpiece; descendant"],
    ['蟑', "cockroach"],
    ['猥', "vulgar, low, cheap; wanton; obscene"],
    ['宓', "quiet, silent; in good health"],
    ['昙', "become cloudy, overcast"],
    ['锏', "kind of rapier"],
    ['蟠', "coil; coiling, curling; occupy"],
    ['過', "pass, pass through, go across"],
    ['柑', "tangerine, loose-skinned orange"],
    ['烯', "alkene"],
    ['匐', "fall prostrate; crawl"],
    ['濮', "county in Henan province"],
    ['蟮', "type of earthworm"],
    ['祐', "divine intervention, protection"],
    ['仄', "slanting, oblique; oblique tones"],
    ['偈', "brave; martial; hasty; scudding"],
    ['蜃', "marine monster which can change its shape; water spouts; clams"],
    ['箴', "needle, probe; admon"],
    // ['粼', ""],
    ['嗥', "roar, call out, wail; bark, yelp"],
    ['褴', "ragged, tattered, threadbare"],
    ['蕨', "pteris aquilina, common bracken"],
    ['蓟', "circium, thistles; surname"],
    ['圩', "dike, embankment"],
    ['孪', "twins"],
    ['杳', "obscure, dark, mysterious, deep"],
    ['魇', "nightmare, bad dreams"],
    ['荤', "meat diet; strong smelling"],
    ['诿', "pass buck, lay blame on others"],
    ['簪', "hairpin, clasp; wear in hair"],
    ['氲', "life giving influences of nature; spirit of harmony; prosperity"],
    ['摞', "to pile up"],
    ['飒', "the sound of the wind; bleak; melancholy"],
    ['镂', "carve, inlay, engrave, tattoo"],
    ['舀', "dip, ladle; ladle"],
    ['夙', "early in morning, dawn; previous"],
    ['臧', "good, right, generous; command"],
    ['蒿', "mugwort, artemisia; give off"],
    ['貂', "marten, sable, mink"],
    ['蜥', "lizard"],
    ['蹩', "to limp"],
    // ['噼', ""],
    ['钛', "titanium"],
    ['钚', "plutonium"],
    ['獾', "the badger"],
    ['濂', "a waterfall; a river in Hunan"],
    ['铠', "armor, chain mail"],
    ['皙', "white; kind of date"],
    ['霭', "cloudy sky, haze; calm, peaceful"],
    ['鲈', "sea perch, sea bass"],
    ['叵', "cannot, be unable do, improbable; thereupon"],
    ['霾', "misty, foggy; dust storm"],
    ['泯', "destroy, eliminate; perish"],
    ['碴', "chipped edge of a container"],
    ['鸵', "ostrich"],
    ['峪', "valley, ravine"],
    ['饕', "gluttonous, greedy, covetous"],
    ['瘁', "feel tired, be weary, be worn out"],
    ['睢', "gaze at, stare at; uninhibited"],
    ['鬃', "mane; neck bristles"],
    ['迩', "be near, be close; recently"],
    ['纣', "name of an emperor; saddle part"],
    ['夔', "one-legged monster; walrus"],
    ['垠', "boundary, bank of stream or river"],
    ['饨', "stuffed dumplings"],
    ['榭', "kiosk, pavilion"],
    ['隍', "dry ditch, dry moat"],
    ['娑', "dance, frolic; lounge; saunter"],
    ['篝', "bamboo basket; bamboo frame"],
    ['榔', "betel-nut tree"],
    ['洌', "clear"],
    ['浜', "creek, stream; beach, sea coast"],
    ['鲑', "salmon; spheroides vermicularis"],
    ['谔', "honest speech, straightforward"],
    ['汩', "run swiftly"],
    ['浣', "to wash, to rinse"],
    ['舐', "lick with tongue"],
    ['瞭', "bright, clear; clear-sighted"],
    ['忻', "delightful, joyful, pleasant"],
    ['咻', "shout"],
    ['鹑', "quail; Turnix species (various)"],
    ['唑', "(chem.) azole"],
    ['懋', "splendid, grand, majestic"],
    ['皎', "white; bright, brilliant; clear"],
    ['诒', "bequeath, pass on to future generations"],
    ['麾', "a pennant, flag, banner; to signal to"],
    ['辏', "hubs of wheel; converge around"],
    ['氐', "name of an ancient tribe"],
    ['冽', "cold and raw; pure, clear"],
    ['箕', "sieve; dust pan, garbage bag"],
    ['俚', "rustic, vulgar, unpolished; mean"],
    ['汴', "name of a river in Henan; Henan"],
    ['宸', "imperial; imperial palace"],
    ['芍', "peony; water chestnuts"],
    ['捱', "put off, procrastinate; endure"],
    ['摈', "exclude, expel, reject; usher"],
    ['摺', "fold, bend; twisted, curved"],
    ['簦', "large umbrella with a long handle, used for street stalls"],
    ['箔', "reed screen; frame for growing silkworms"],
    ['咝', "hiss; call to come; syllable"],
    ['孀', "widow"],
    ['怏', "discontented, dispirited, sad"],
    ['谝', "brag, boast; quibble"],
    ['砧', "anvil; flat stone; exe"],
    ['馕', "bread (persian naan)"],
    ['耄', "elderly person; very old, senile"],
    ['罂', "long necked jar or bottle"],
    ['漕', "transport by water; canal transportation"],
    ['沣', "river in Shanxi province"],
    ['栾', "name of tree; a part of cornice"],
    // ['榘', ""],
    ['烷', "alkane"],
    ['榷', "footbridge; toll, levy; monopoly"],
    ['俑', "wooden figure buried with dead"],
    ['沱', "rivers, streams, waterways; flow"],
    ['缜', "detailed, fine; closely woven"],
    ['鹫', "condor, vulture"],
    ['蛳', "kind of snail with spiral shell"],
    ['剽', "rob, plunder; slice off; fast"],
    ['衢', "highway; thoroughfare, intersection"],
    ['泗', "mucous; nasal flow; sniffle; river in Shandong"],
    ['臊', "rank; rancid; frowzy, fetid; bashful"],
    ['瘴', "malaria pestilential vapors"],
    ['酚', "carbolic acid; phenol"],
    ['纾', "loosen, relax, relieve; extricate"],
    ['晁', "morning, dawn; surname"],
    ['孛', "comet"],
    ['炀', "roast; scorch; melt; flame"],
    // ['叁', ""],
    ['憩', "rest, take rest"],
    ['掬', "to grasp or hold with both hands"],
    ['椤', "tree"],
    ['啮', "bite, gnaw"],
    ['畿', "imperial domain; area near capital"],
    ['掸', "to dust; a duster"],
    ['镣', "fetters"],
    ['骁', "excellent horse; brave, valiant"],
    ['椽', "beams, rafters, supports"],
    ['侗', "big; ignorant; rude, rustic"],
    ['滦', "river and county in Hebei province"],
    ['荩', "a kind of weed; faithfulness"],
    ['泓', "clear, deep pool of water"],
    ['蚱', "grasshopper; (edible) locust; cicada"],
    ['癜', "erythema"],
    ['酯', "ester"],
    ['體', "body; group, class, body, unit"],
    ['癸', "10th heavenly stem"],
    ['蚜', "plant louse, aphids"],
    ['扪', "stoke, pat, feel by hand, grope"],
    ['庑', "corridor, hallway; luxuriant"],
    ['歆', "like, admire; willingly, gladly; to quicken"],
    ['蝮', "venomous snake, viper"],
    ['蹶', "stumble, fall down; trample"],
    ['弈', "Chinese chess"],
    ['庋', "a cupboard or pantry to store"],
    ['喟', "heave sigh, sigh"],
    ['滂', "torrential; voluminous"],
    ['啕', "wail"],
    ['蛎', "oyster"],
    ['獭', "otter"],
    ['槁', "wither; withered, rotten, dead"],
    ['翊', "flying; assist, help; respect"],
    ['龊', "narrow, small; dirty"],
    // ['邺', ""],
    ['莘', "long; numerous; a marsh plant whose root is used for medicine"],
    ['燮', "harmonize, blend; adjust"],
    ['剁', "chop by pounding, mince, hash"],
    ['觐', "have imperial audience"],
    ['铛', "frying pan; warming vessel"],
    ['谗', "slander, defame, misrepresent"],
    ['镍', "nickel"],
    ['臃', "swell up; swelling; fat"],
    ['墒', "wet tilth"],
    ['晔', "bright; radiant; thriving"],
    ['燔', "to roast; to burn"],
    ['嘭', "syllable; (Cant.) to chase, drive away"],
    ['涿', "drip, dribble, trickle"],
    ['醯', "vinegar; pickle; acid"],
    ['箩', "bamboo basket"],
    ['鄱', "county and lake in Jiangxi"],
    ['睨', "look askance at, glare at; squint"],
    ['诤', "to expostulate; to remonstrate"],
    ['坳', "a hollow in the ground, a cavity, depression; undulating, depressed"],
    ['鹭', "heron, egret; Ardea species (various)"],
    ['砷', "arsenic"],
    ['唏', "weep or sob; grieve"],
    ['伲', "we (Shanghai dialect)"],
    ['猬', "vulgar; wanton; low; many; varied; a hedgehog, porcupine"],
    ['琥', "jewel in shape of tiger; amber"],
    ['殁', "die; death; dead"],
    ['蚩', "worm; ignorant, rustic; laugh"],
    ['泾', "name of a river"],
    ['缥', "light blue silk; dim; misty"],
    ['殓', "dress corpse for burial"],
    ['鳅', "loach"],
    ['氰', "cyanogen; ethane dinitrile"],
    ['诋', "slander, comdemn, reproach"],
    ['刍', "mow, cut grass; hay, fodder"],
    ['芷', "angelica, type of iris"],
    ['嶙', "precipitous"],
    ['逅', "meet unexpectedly"],
    ['舫', "fancy boat, yacht"],
    // ['呓', ""],
    ['唰', "swish, rustle"],
    ['茁', "to sprout, flourish; sprouts appearing above-ground; vigorous"],
    ['馑', "time of famine or crop failure"],
    ['妫', "family name"],
    ['骧', "gallop about with head uplifted"],
    ['苷', "licorice"],
    ['擢', "pull up, draw up; select"],
    ['峋', "ranges of hills stretching on beyond another irregular peaks"],
    ['袂', "sleeves"],
    ['懑', "be sick at heart, sorrowful, sad"],
    ['蓑', "rain coat made of straw, coir, etc."],
    ['與', "and; with; to; for; give, grant"],
    ['涞', "river in Hebei province; creek"],
    ['祉', "happiness, blessings, good luck"],
    ['踹', "trample, tread on, kick; to crush"],
    ['掇', "collect, gather up, pick up"],
    ['沏', "infuse"],
    ['诳', "deceive, lie, delude, cheat"],
    ['噫', "belch; alas"],
    ['饽', "ferrule"],
    ['饪', "cooked food; cook until well done"],
    ['绺', "skein; tuft, lock; wrinkle"],
    ['谘', "consult, confer; communicate in"],
    ['飧', "evening meal, supper; cooked food"],
    ['迳', "pass by, approach; direct"],
    ['铡', "sickle for cutting grass or hay"],
    ['枞', "fir tree"],
    ['熨', "iron, press"],
    ['鋈', "silver plating"],
    ['荭', "herb"],
    ['赊', "buy and sell on credit, distant"],
    ['俦', "companion, mate, colleague"],
    ['戛', "lance; tap or strike lightly"],
    ['湎', "flushed with drink, drunk"],
    ['幺', "one; tiny, small"],
    ['凇', "dewdrop; icicle"],
    ['芪', "celery"],
    ['觯', "wine goblet made from horn"],
    ['龌', "narrow, small; dirty"],
    ['挞', "flog, whip; chastise"],
    ['嬴', "to win; to have a surplus; surname"],
    ['苻', "kind of herb; type of grass"],
    ['嘁', "to be grieved; ashamed; used to imitate sounds"],
    ['鞯', "saddle blanket"],
    ['肽', "a chemical compound"],
    ['恸', "sadness, grief; mourn; be moved"],
    ['迨', "until, when; seize, arrest"],
    ['钰', "rare treasure"],
    ['儆', "warn; warning"],
    ['觎', "desire strongly, covet, long for"],
    ['讫', "finish; conclude, stop; exhaust"],
    ['滓', "sediment, lees, dregs"],
    ['僮', "page, boy servant"],
    ['媾', "marry; be on friendly terms with"],
    ['龇', "to show the teeth; crooked teeth"],
    ['胯', "pelvis; groin; thighs"],
    ['涮', "rinse; cook or boil in juice"],
    ['绾', "to string together, to bind up"],
    ['杈', "fork of a tree; a pitchfork"],
    ['赳', "grand, valiant"],
    ['斛', "dry measure equal to some five or ten dou (舒)"],
    ['觥', "a cup made of horn obstinate"],
    ['疸', "jaundice; disorders of stomach"],
    ['卞', "to be impatient, in a hurry; excitable"],
    ['愠', "angry"],
    ['拮', "laboring hard, occupied; pursue"],
    ['庠', "village school; teach"],
    ['烨', "bright, glorious, splendid, flame"],
    ['龢', "in harmony; calm, peaceful"],
    ['菠', "spinach and similar greens"],
    ['窈', "obscure, secluded; refined"],
    ['罄', "exhaust, run out, use up; empty"],
    ['囤', "grain basket, bin for grain"],
    ['弁', "conical cap worn during Zhou dynasty"],
    ['奘', "large, powerful, stout, thick"],
    ['咣', "the sound of large door closing"],
    ['缫', "draw, reel"],
    ['腴', "fat; fertile, rich; plump, soft"],
    ['缈', "indistinct, dim; minute; distant"],
    ['喵', "the mew of the cat"],
    ['潢', "expanse of water, lake, pond"],
    ['遛', "take walk, stroll"],
    ['柚', "pomelo, grapefruit"],
    ['郏', "county in Henan province; surname"],
    ['荻', "reed, Miscanthus saccariflorus"],
    ['藜', "Chenopodium album, pigweed"],
    ['琨', "beautiful jade, precious stones"],
    ['镳', "bit, bridle; ride"],
    ['雉', "pheasant; crenellated wall"],
    ['橐', "a sack, a bag opening at both ends"],
    // ['陽', ""],
    ['骈', "team of horses; associate, join"],
    ['蛉', "dragonfly, libellulidae"],
    ['艮', "seventh of eight diagrams"],
    ['搽', "smear; rub, wipe; anoint"],
    ['濡', "immerse, moisten; wet, damp"],
    ['寮', "shanty, hut, shack"],
    ['柩', "coffin which contains corpse"],
    ['佗', "other, he; surname; a load"],
    ['啷', "a clanging or rattling sound"],
    ['诜', "inquire, question; numerous"],
    ['視', "look at, inspect, observe, see"],
    ['偻', "humpback; surname"],
    ['夯', "heavy load, burden; lift up"],
    ['闱', "gate, door; living quarters"],
    ['谖', "forget; lie, cheat, deceive"],
    ['夥', "companion; partner; assistant"],
    ['枸', "kind of aspen found in Sichuan"],
    ['膑', "the kneecap"],
    ['虻', "horsefly, gadfly"],
    ['筠', "bamboo skin; bamboo"],
    ['埽', "broom; to sweep, clear away"],
    ['笞', "bamboo rod used for beatings"],
    ['臾', "moment, instant, short while"],
    ['婀', "be beautiful, be graceful"],
    ['珞', "kind of necklace"],
    ['粑', "tsamba (food in Tibet)"],
    ['怵', "fear, be afraid; shy, timid"],
    ['绻', "affectionate, solicitous"],
    ['殒', "die, perish; vanish; fall"],
    ['觊', "covet, long for, desire"],
    ['崂', "Laoshan, mountain in Shandong"],
    ['颧', "cheek bones"],
    ['嗑', "eat seeds; reproach; loquacious"],
    ['榛', "hazelnut; thicket, underbrush"],
    ['昱', "bright light, sunlight; dazzling"],
    ['蜴', "lizard"],
    ['鳝', "eel"],
    ['噙', "hold in mouth; bite"],
    ['淼', "a wide expanse of water"],
    ['矾', "alum"],
    ['硼', "borax, boron"],
    ['囿', "pen up; limit, constrain"],
    ['泅', "swim, float, wade"],
    ['邂', "unexpected meeting; encounter by"],
    ['钜', "steel, iron; great"],
    ['蠹', "moth; insects which eat into clot"],
    ['垩', "holy, sacred, sage; simplification of 堊 chalk; daub with chalk"],
    ['乩', "to divine"],
    ['嗝', "cackling of fowls to gag, to vomit"],
    ['淦', "river in Jiangxi province; water leaking into a boat"],
    ['樽', "goblet; jar, jug; lush"],
    ['诮', "criticize, scold, blame, ridicule"],
    ['揆', "prime minister; to guess, estimate"],
    ['啐', "to taste, to sip; to spit; the sound of sipping; to surprise"],
    ['淅', "water used wash rice; to wash ric"],
    ['榉', "type of elm"],
    ['馗', "cheekbone; path, road; intersection"],
    ['辔', "bridle of horse, reins"],
    ['暹', "rise; advance, go forward"],
    ['骛', "gallop; rush about; pursue, run"],
    ['鱿', "cuttlefish"],
    ['苫', "rush or straw matting"],
    ['犷', "fierce, rude, uncivilized"],
    ['獠', "to hunt at night by torches"],
    ['詈', "scold, abuse verbally, curse"],
    ['竦', "revere, respect, be in awe of"],
    ['篙', "pole used punt boat"],
    ['诨', "jokes, obsene jests; joke"],
    ['铰', "hinge; shears, scissors"],
    ['馄', "dumpling soup, wonton"],
    ['蜚', "cockroach"],
    ['峒', "mountain in Gansu province"],
    ['滢', "clear, pure water; lucid; glossy"],
    ['琬', "the virtue of a gentleman; jade"],
    ['靓', "make up face; ornament; quiet"],
    ['狻', "a fabulous beast"],
    ['璨', "gems; luster of gems; lustrous"],
    ['犟', "stubborn"],
    ['鸬', "cormorant"],
    ['螨', "insect"],
    ['芩', "salt marsh plant"],
    ['嘹', "used describe clarity of voice; resonant"],
    ['锟', "ancient treasured sword"],
    ['蜇', "jellyfish"],
    ['洹', "river in Henan province"],
    ['栉', "comb; comb out; weed out, elimininate"],
    ['俪', "spouse, couple, pair"],
    ['钍', "thorium"],
    ['锨', "shovel"],
    ['瑁', "fine piece of jade"],
    ['壹', "number one"],
    ['痿', "paralysis; impotence"],
    ['竑', "be vast and endless; broad"],
    ['粕', "lees, dregs, sediments left after distilling liquor"],
    ['犄', "animal horns"],
    ['瘙', "itch"],
    ['饯', "farewell party; see off, send off"],
    ['抟', "roll around with hand; model"],
    ['衲', "mend, sew, patch; line; quilt"],
    ['踮', "tip toe"],
    ['龅', "teeth protrude"],
    ['愎', "obstinate, stubborn, headstrong"],
    ['馥', "fragrance, scent, aroma"],
    ['梏', "handcuffs, manacles, fetters"],
    ['讣', "obituary; give notice of death"],
    ['邝', "surname"],
    // ['艿', ""],
    ['趺', "sit cross-legged; back of the foo"],
    ['鲟', "sturgeon"],
    ['剜', "cut, cut out, pick out, scoop out"],
    ['绉', "crepe, crape, wrinkles, creases"],
    ['罅', "crack, fissure, split"],
    ['笥', "a hamper, wicker basket"],
    ['衩', "the open seam of a garment which allows freedom of movement"],
    ['姣', "beautiful, handsome, pretty"],
    ['斫', "cut, chop, lop off"],
    ['鹗', "osprey, fishhawk"],
    ['腎', "kidneys; testes, gizzard"],
    ['爻', "diagrams for divination"],
    ['猕', "macacus monkey"],
    ['晗', "pre-dawn"],
    ['铩', "moult"],
    ['窕', "slender; quiet and modest, charming"],
    ['仨', "(coll.) three (cannot be followed by a measure word)"],
    ['搡', "to push over or push back"],
    ['崴', "high, lofty; precipitous"],
    // ['酢', ""],
    ['檄', "call arms; urgency"],
    ['佞', "flattery; glib"],
    ['孑', "remaining, left-over; lonely"],
    ['璀', "lustre of gems; glitter; shine"],
    ['岷', "min mountain, min river"],
    ['舛', "oppose, deviate, be contrary to"],
    ['邕', "former or literary name for Nanning (in Guangxi)"],
    ['闿', "open; peaceful"],
    ['铂', "platinum; thin sheet of metal"],
    ['霁', "to clear up after rain; to cease be angry"],
    ['犒', "entertain victorious soldiers"],
    ['馏', "distill, distillation"],
    ['阈', "threshold; separated, confined"],
    ['麋', "elk; surname"],
    ['麒', "legendary auspicious animal"],
    ['苁', "medicinal herb"],
    ['摁', "to press with the hand"],
    ['涔', "river in Shaanxi; murky torrent"],
    ['宥', "forgive, pardon, indulge"],
    ['妍', "beautiful, handsome; seductive"],
    ['铤', "ingots, bars of metal; hurry"],
    ['锷', "high, lofty; edge of knife"],
    // ['嗲', ""],
    ['恽', "devise, plan, deliberate; consult"],
    ['麂', "species of deer"],
    ['赝', "false; counterfeit; bogus; sham"],
    ['胛', "the shoulder, shoulder blade"],
    ['哂', "smile, laugh at, sneer at; (Cant.) a final particle indicating doing something to the full extent possible"],
    ['撷', "pick up, gather up; hold in lap"],
    ['呶', "talkative; clamour; hubbub"],
    ['噘', "pouting"],
    ['懔', "be afraid of, to be in awe of"],
    ['栎', "chestnut-leaved oak; oak"],
    ['桎', "fetters, shackles, handcuffs"],
    ['霰', "hail, sleet"],
    ['飨', "host banquet; banquet"],
    ['揄', "lift, raise; praise; hang; flap"],
    ['噔', "syllable; (Cant.) for (a recipient of pity or sympathy)"],
    ['娣', "younger sister, sister-in-law"],
    // ['薏', ""],
    ['忝', "disgraced; ashamed; self-deprecating"],
    ['咤', "scold, bellow, shout at, roar"],
    ['嗵', "used for sound"],
    ['迤', "wind; walk out of straight path"],
    ['贲', "forge ahead; energetic; surname"],
    ['胪', "arrange in order; display"],
    ['鍪', "an iron pan; a metal cap"],
    ['泸', "river in Jiangxi province"],
    ['蔫', "withered, faded, decayed; calm"],
    ['刈', "cut off, reap, mow; sickle"],
    ['僖', "joy, gladness, delight; surname"],
    ['咿', "descriptive of creaking; laugh"],
    ['鹌', "quail; Coturnix coturnix"],
    ['嗪', "character used in translation"],
    ['茏', "tall grass; water-weeds"],
    ['茯', "china root; medicinal fungus"],
    ['岫', "mountain peak; cave, cavern"],
    ['嵘', "high, steep; lofty, towering"],
    ['轱', "wheel; to turn; to revolve"],
    ['怼', "hate, abhor; hatred, resentment"],
    ['铨', "weigh, measure; select officials"],
    ['昕', "dawn; early morning; day"],
    // ['郢', ""],
    ['咩', "the bleating of sheep; (Cant.) an interrogative particle"],
    ['馊', "spoiled, rotten, stale, rancid"],
    ['髡', "to shear tree; ancient punishment"],
    ['澧', "river in northern Hunan province"],
    ['苣', "kind of lettuce"],
    ['濯', "wash out, rinse; cleanse"],
    ['盥', "wash"],
    // ['囡', ""],
    ['砺', "whetstone; sharpen"],
    ['佘', "surname"],
    ['谶', "prophecy, hint, omen"],
    // ['弑', ""],
    ['楂', "a raft, to hew, to fell trees"],
    ['翦', "scissors; cut, clip; annhilate"],
    ['怩', "shy, timid, bashful; look ashamed"],
    // ['蠼', ""],
    ['霏', "falling of snow and rain"],
    ['楹', "column, pillar; numerary adjunct"],
    ['讴', "sing; songs"],
    ['锲', "sickle; cut, carve, engrave"],
    ['慵', "indolent, easy-going, lazy"],
    ['胝', "callous, corn"],
    ['砭', "stone probe; pierce; counsel"],
    ['潍', "county in Shandong province"],
    ['杵', "pestle; baton used beat clothes"],
    ['樾', "the shade of trees"],
    // ['帼', ""],
    ['碣', "stone tablet"],
    ['诌', "play with words, quip; talk nonse"],
    ['徕', "induce, encourage to come"],
    ['胴', "the large intestine; the body"],
    ['钴', "cobalt; household iron"],
    ['裟', "a cassock or robe of a monk"],
    ['啶', "phonetic used in pyridine and pyrimidine"],
    ['铣', "mill"],
    ['铱', "iridium"],
    ['楫', "oar, paddle"],
    ['赭', "reddish brown; hematite; ochre"],
    ['碛', "sand and gravel"],
    ['酊', "drunk, intoxicated"],
    ['魑', "a mountain demon resembling a tiger"],
    ['醛', "aldehyde"],
    ['剐', "cut, cut flesh from bones"],
    ['畦', "sections in vegetable farm"],
    ['陂', "dam, embankment; reservoir"],
    ['闶', "door"],
    ['阄', "lots (to be drawn); draw lots"],
    ['祚', "throne; bless; blessing, happiness"],
    ['鹘', "a kind of pigeon; treron permagna"],
    ['泱', "great, expansive; agitated"],
    ['趄', "weak, lame"],
    ['骅', "an excellent horse"],
    ['陲', "frontier, border"],
    ['郧', "county in Hubei province"],
    ['倜', "raise high; unrestrained"],
    ['呤', "purine"],
    ['燧', "flintstone; beacon, signal fire; torch"],
    ['铉', "device for carrying a tripod"],
    ['粲', "polish; bright, radiant; smiling"],
    ['骶', "coccyx"],
    ['峁', "yellow dirt mount"],
    ['忸', "blush, be bashful, be ashamed"],
    ['渌', "strain"],
    ['骞', "raise, hold high; fly, soar"],
    ['髭', "mustache"],
    ['戡', "subjugate, subdue, quell; kill"],
    ['钨', "tungsten, wolfram"],
    ['谲', "cunning, crafty, sly, wily"],
    ['苋', "amaranth"],
    ['锃', "polish"],
    ['蜊', "clam"],
    ['幄', "tent; mosquito net"],
    ['闼', "door; gate"],
    ['戕', "kill, slay; wound, injure, hurt"],
    ['骊', "a pure black horse; a pair of horses"],
    ['虢', "name of ancient feudal State in Shenxi and Hunan"],
    ['烩', "ragout, cook, braise"],
    ['傥', "if, supposing, in case"],
    ['妲', "concubine of last ruler of the Shang dynasty"],
    ['绌', "sew, stitch; insufficient"],
    ['桠', "the forking branch of a tree"],
    ['袈', "Buddhist cassock"],
    ['鎗', "rifle, small arms, hand gun"],
    ['薮', "marsh, swamp; wild country"],
    ['揿', "press"],
    ['杲', "bright sun; brilliant; high"],
    ['肓', "region between heart and diaphragm"],
    ['厝', "cut or engrave; a grave or tombstone"],
    ['莅', "attend, be present; arrive at"],
    ['氤', "hanging fog, misty"],
    ['缙', "red silk"],
    ['衮', "ceremonial dress worn by the emperor"],
    ['诟', "abuse, scold, berate, insult"],
    ['旖', "romantic; tender; charming"],
    ['硒', "selenium"],
    ['唁', "express condolence"],
    ['嬗', "succession to the throne"],
    ['硎', "whetstone"],
    ['裱', "to mount maps or scrolls to paste"],
    ['颦', "frown, knit brows; with knitted"],
    ['質', "matter, material, substance"],
    ['靥', "dimples"],
    ['纥', "inferior silk; tassel, fringe"],
    ['煨', "to stew, simmer"],
    ['礴', "fill, extend"],
    ['鏖', "to fight to the end, engage in a fierce battle"],
    ['蝈', "small green frog; cicada"],
    ['笏', "tablet held by someone having authority"],
    ['羿', "legendary archer"],
    ['鼐', "incense tripod"],
    ['湟', "river in qinghai province"],
    ['甑', "boiler for steaming rice, pot"],
    ['炜', "brilliant red; glowing"],
    ['煲', "to heat; to boil a saucepan"],
    // ['锉', ""],
    ['笕', "bamboo water pipe"],
    ['喑', "to be dumb; the sobbing of infants"],
    ['嶂', "cliff; mountain barrier"],
    ['浔', "steep bank by stream; jiujiang"],
    ['弭', "stop, desist, end, quell"],
    ['妪', "old woman, hag"],
    ['锂', "lithium"],
    ['苡', "barley"],
    ['孳', "breed in large numbers"],
    ['颏', "chin"],
    ['醴', "sweet wine; sweet spring"],
    ['間', "interval, space; place, between"],
    ['渚', "small sand bank, islet"],
    ['轭', "yoke, collar"],
    ['鹬', "snipe, kingfisher"],
    ['蚝', "hairy and poisonous caterpillars"],
    ['黃', "yellow; surname"],
    ['膘', "fat; rump"],
    ['邛', "in distress; a mound"],
    ['痨', "consumption; tuberculosis"],
    ['褡', "girdle; loincloth; pouch, bag"],
    ['耦', "plow side by side; team of two"],
    ['覃', "reach to, spread to; extensive"],
    ['虛', "false, worthless; empty, hollow"],
    ['馔', "to feed, support, provide for; food"],
    ['篾', "bamboo splints or slats"],
    ['兖', "establish; one of nine empire divisions"],
    ['阋', "feud, fight, quarrel"],
    ['遨', "ramble, roam; travel for pleasure"],
    ['爰', "lead on to; therefore, then"],
    ['痂', "scab"],
    ['艄', "stern of a vessel"],
    ['耨', "hoe, rake; weed"],
    ['沤', "soak, steep; sodden or soaked"],
    ['邋', "rags"],
    ['焓', "(Cant.) onomatopoetic, the sound of a fiercely-burning fire"],
    ['秣', "fodder, horse feed; feed horse"],
    ['昶', "a long day. bright. extended. clear"],
    ['種', "seed; race; offspring; to plant"],
    ['變', "change, transform, alter; rebel"],
    ['窣', "to rush out of a den; rustling, whispering"],
    ['绦', "silk cord, ribbon"],
    ['俎', "chopping board or block; painted"],
    ['榫', "mortise and tenon; fit into"],
    ['蟪', "a kind of cicada"],
    ['稗', "darnels, weeds, tares small"],
    ['謇', "stutter; speak out boldly"],
    ['氩', "argonium"],
    ['類', "class, group, kind, category"],
    ['锴', "high quality iron"],
    ['龉', "uneven teeth; to disagree"],
    ['烃', "hydrocarbon"],
    ['俣', "big"],
    ['嬷', "mother"],
    ['肱', "forearm"],
    ['鸢', "kite; Milvus species (various)"],
    ['笫', "bed boards, sleeping mat"],
    ['痤', "a swelling of the lymph nodes"],
    // ['陰', ""],
    ['菏', "river in Shandong province"],
    ['莆', "a kind of legendary tree"],
    ['芨', "name of a plant, the roots are used for various purposes"],
    ['阕', "close, shut; watch tower"],
    ['砣', "a stone roller; a heavy stone, a weight, a plummet"],
    ['碜', "gritty"],
    ['鼹', "a kind of insectivorous rodent"],
    ['長', "long; length; excel in; leader"],
    ['猷', "plan, scheme; plan, plot; way"],
    ['竽', "ancient woodwind instrument"],
    ['舸', "large boat, barge"],
    ['诓', "cheat, swindle, lie"],
    ['錾', "engraving tool, chisel"],
    ['淬', "temper; dye; soak; change, alter"],
    ['隗', "high; lofty; surname"],
    ['悌', "brotherly, respectful"],
    ['姘', "illicit sexual relations"],
    ['槭', "maple"],
    ['邈', "far, distant, remote; slight"],
    ['婕', "handsome"],
    // ['歙', ""],
    // ['稹', ""],
    ['蹴', "kick; tread on; leap; solemn"],
    ['砒', "arsenic"],
    ['痈', "carbuncle, sore, ulcer, abscess"],
    ['镏', "distil; lutetium; surname"],
    ['羯', "wether, castrated ram; deer skin"],
    ['豕', "a pig, boar; KangXi radical 152"],
    ['鲂', "bream"],
    ['蓖', "castor-oil plant, Ricinus commumis"],
    ['匦', "small box; chest, casket"],
    ['笤', "a broom, a besom"],
    ['峥', "high, lofty, noble; steep, perilous"],
    ['徭', "conscript labor, compulsory labor"],
    ['浃', "saturate, drench; damp, wet"],
    ['烊', "to smelt; to melt"],
    ['補', "mend, patch, fix, repair, restore"],
    ['窸', "faint sound, whisper"],
    ['酆', "name of Zhou period state"],
    ['缢', "hang, strangle"],
    ['褓', "swaddling cloth; infancy"],
    ['蚨', "a kind of water-beetle cash, then dollars and money generally"],
    ['翳', "shade, screen; to hide, screen"],
    ['趔', "not progressing; to be checked"],
    ['炔', "acetylene"],
    ['誊', "copy, transcribe"],
    ['赜', "abstruse, deep, profound"],
    ['仃', "lonely, solitary"],
    ['勖', "enjoin, advise, preach to"],
    ['葺', "thatch; fix, repair; pile up"],
    ['蚴', "larva"],
    ['泷', "raining; wet; soaked; a river in Guangdong"],
    ['蛴', "maggots, grubs"],
    ['結', "knot, tie; join, connect"],
    ['媸', "an ugly woman"],
    ['俳', "actor; vaudeville show; insincere"],
    ['诖', "error, mistake; deceive, mislead"],
    ['茑', "the convovulvus; parasitic plants such as mistletoe; Ribes ambiguum"],
    ['逡', "retreat, withdraw, fall back"],
    ['孱', "weak, unfit, frail, feeble"],
    ['砦', "stockade, fort, military outpost; brothel"],
    ['跸', "clear way, make room for; emp"],
    ['祜', "blessing, happiness, prosperity"],
    ['伉', "compare, match; pair; spouse"],
    ['溴', "bromine"],
    ['屐', "wooden shoes, clogs"],
    ['飚', "whirlwind"],
    ['蛞', "snail; slug, mole cricket"],
    ['鏡', "mirror; lens; glass; glasses"],
    ['掮', "to bear on the shoulders"],
    ['崆', "Kongtong mountain"],
    ['庾', "granary; storehouse"],
    ['橛', "a post, a stake; an axle"],
    ['矸', "a rock or cliff"],
    ['鸨', "bustard; procuress; Otis species (various)"],
];