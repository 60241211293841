export enum Lang {
    English = 100,
    ChineseSimplified,
    ChineseTraditional,
    Pinyin,
}

export enum HskLevel {
    HSK1 = 1000,
    HSK2,
    HSK3,
    HSK4,
    HSK5,
    HSK6,
}
