import React from 'react';
import MatcherGame from "./components/matcherGame";
import './css/App.css';



function App() {

    return (
        <div className="App">
            {/*<header className="App-header"/>*/}
            
            <MatcherGame/>
        </div>
    );
}

export default App;
